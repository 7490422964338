import Api from './Api'

export default {
	latestAppVersion(data) {
		return Api().post(`/updater/version`, data)
	},
	registerDevice(data) {
		return Api().post(`/devices/add`, data)
	},
	addFcmToken(data) {
		return Api().post(`/devices/fcm/add`, data)
	}
}
