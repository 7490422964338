import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
const isAndroid = function() {
	return /Android/i.test(navigator.userAgent) || Capacitor.getPlatform() === 'android'
}
const isiOS = function() {
	return [
			'iPad Simulator',
			'iPhone Simulator',
			'iPod Simulator',
			'iPad',
			'iPhone',
			'iPod'
		].includes(navigator.platform)
		// iPad on iOisNativeS 13 detection
		|| (navigator.userAgent.includes("Mac") && "ontouchend" in document)
		|| Capacitor.getPlatform() === 'ios'
}

export default {
	openDeepLink(type, url, openNative, directLink) {
		let originalUrl = url;
		let deepLink = '';

		switch (type) {
			case 'instagram':
				let username;
				if(url.startsWith('http')) {
					url = new URL(url);
					username = url.pathname.split('/')[1]
				}
				deepLink = 'instagram://user?username=' + username;
				break;
			case 'facebook':
				/*
				 profile
				 https://www.facebook.com/profile.php?id=598883740
				 fb://profile/598883740
				 intent://profile/598883740#Intent;package=com.facebook.katana;scheme=fb;end?id=598883740

				 group
				 https://www.facebook.com/groups/330791763680963
				 fb://group/?id=330791763680963

				 */
				// TODO: Check if the url is a proper URL
				url = new URL(url);
				if(originalUrl.includes('profile.php?id=')) {
					const profileID = url.searchParams.get('id') || null;
					if (profileID) {
						if(isiOS()) deepLink = 'fb://profile/' + profileID;
						if(isAndroid()) deepLink = 'intent://profile/' + profileID + '#Intent;package=com.facebook.katana;scheme=fb;end?id=' + profileID;
					}
				}
				else if(originalUrl.includes('/groups/')) {
					if(url.pathname) {
						const id =  url.pathname.split('/')[2]
						if(id) deepLink = 'fb://group/' + id;
					}
				}
				break;
			case 'youtube':
				url = url.replace('http', 'https');
				url = url.replace('www.', '');
				deepLink = url.replace('https', 'vnd.youtube');
				break;
			case 'whatsapp':
				// https://wa.me/381645089082
				// whatsapp://send?phone=381645089082&text=
				// intent://send?phone=381645089082&text=#Intent;package=com.whatsapp;scheme=whatsapp;end
				originalUrl = originalUrl.replace('+', '');
				if(url.startsWith('http')) {
					url = url.replace('http://wa.me/', '');
					url = url.replace('https://wa.me/', '');
				}
				let number = url.replace('+', '');
				if(isiOS()) deepLink = 'whatsapp://send?phone=' + number + '&text=';
				if(isAndroid()) deepLink = 'intent://send?phone=' + number + '&text=#Intent;package=com.whatsapp;scheme=whatsapp;end';
				break;
			case 'viber':
				originalUrl = originalUrl.replace('+', '');
				if(isAndroid()) deepLink = url.replace('+', '%2B');
				if(isiOS) deepLink = url.replace('+', '');
				break;

			case 'amazon':
				// https://www.amazon.de/-/en/gp/browse.html?node=1966060031&ref_=nav_em__ah_0_2_11_5
				// com.amazon.mobile.shopping.web://amazon.de/-/en/gp/browse.html?node=1966060031&ref_=nav_em__ah_0_2_11_5
				// com.amazon.mobile.shopping.web://amazon.de/-/en/gp/browse.html?node=1966060031&ref_=nav_em__ah_0_2_11_5
				url = url.replace("http:", '');
				url = url.replace("https:", '');
				if(isiOS()) deepLink = 'com.amazon.mobile.shopping.web:' + url;
				if(isAndroid()) deepLink = 'com.amazon.mobile.shopping.web:' + url;
				break;

			case 'applemusic':
				// https://music.apple.com/rs/playlist/a-list-pop/pl.5ee8333dbe944d9f9151e97d92d1ead9
				// music://music.apple.com/rs/playlist/a-list-pop/pl.5ee8333dbe944d9f9151e97d92d1ead9
				// intent://music.apple.com/rs/playlist/a-list-pop/pl.5ee8333dbe944d9f9151e97d92d1ead9/#Intent;package=com.apple.android.music;scheme=https;end
				url = url.replace("http:", '');
				url = url.replace("https:", '');
				if(isiOS()) deepLink = 'music:' + url;
				if(isAndroid()) deepLink = 'intent:' + url + '/#Intent;package=com.apple.android.music;scheme=https;end'
				break;

			default:
			// code block
		}

		// console.log("deeplink: "  + deepLink);
		// console.log("originalUrl: " + originalUrl);
		if(deepLink) {
			if(openNative) return Browser.open({ url: deepLink });
			const start  = Date.now();
			window.location.replace(deepLink);
			setTimeout(() => {
				const now = Date.now();
				if (now - start > 4000) return
				// console.log('fallback to originalUrl')
				if (directLink) window.location.replace(originalUrl);
				else window.open(originalUrl, "_blank");
			}, 501);
		} else {
			if(openNative) return Browser.open({ url: originalUrl });
			if (directLink) window.location.replace(originalUrl);
			else window.open(originalUrl, "_blank");
		}
	}
}
