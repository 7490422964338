  <template>
    <div class="combined-scanner-container" id="scannerOverlay" style="display: none;">
      <div class="scanner--area--container">
        <div class="relative">
          <div class="ai-message">
            <p v-if="activeScanner === 'card'" v-html="lang[appLang].scanner_card_p" />
            <p v-if="activeScanner === 'QR'" v-html="lang[appLang].scanner_qr_p" />
            <p v-if="activeScanner === 'badge'" v-html="lang[appLang].scanner_badge_p" />
          </div>
          <div class="ai-badge">
            <img :src="getIcon('ai-white.svg')" alt="AI"/>
          </div>
        </div>
        <div :class="scannerRectangleClass + ' scanner-area'">
          <div class="scanner--area--outer surround-cover">
            <div class="scanner--area--inner"></div>
          </div>
        </div>
        <div v-if="activeUsage !== 'tagActivation' && activeUsage !== 'qrLogin'" class="scanner-toggle">
          <button :class="{'active': activeScanner === 'card'}" @click="setActiveScanner('card')">
            <img :src="getIcon('card-scan-white.svg')" width="24" height="24" alt="Card Scanner"/>
            {{lang[appLang].paper_card}}
          </button>
          <button :class="{'active': activeScanner === 'QR'}" @click="setActiveScanner('QR')">
            <img :src="getIcon('qr-white.svg')" width="24" height="24" alt="QR Scanner"/>
            {{lang[appLang].qr_code}}
          </button>
          <button :class="{'active': activeScanner === 'badge'}" @click="setActiveScanner('badge')">
            <img :src="getIcon('event-badge.svg')" width="26" height="26" alt="QR Scanner"/>
            {{lang[appLang].event_badge}}
          </button>
        </div>
      </div>
      <button v-if="activeScanner !== 'QR'" class="button white-button capture-cardscanner" @click="capturePicture">
        <span v-if="!captureLoading">
          <img :src="getIcon('camera-capture.svg')" alt="Capture"/>
        </span>
        <span v-else class="button--loading-black button__loader"></span>
      </button>
      <a @click="closeScanner" class="close-camera"><img :src="getIcon('x-white.svg')" width="40" height="40" alt="scanner-close-camera"/></a>
      <a v-if="activeScanner !== 'QR'" class="gallery-pick" @click="galleryPick"><img :src="getIcon('gallery-white.svg')" width="40" height="40" alt="scanner-gallery-pick"/></a>
      <a v-if="flashMode === 'off'" class="camera-flash" @click="toggleFlash"><img :src="getIcon('flashlight-white.svg')" alt="scanner-gallery-pick"/></a>
      <a v-else class="camera-flash" @click="toggleFlash"><img :src="getIcon('flashlight-on-white.svg')" alt="scanner-gallery-pick"/></a>
    </div>
  </template>

<script>
import { EventBus } from '@/store/event-bus';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { CameraPreview } from '@capacitor-community/camera-preview';
import { Camera, CameraSource, CameraResultType } from "@capacitor/camera";
import vCard from 'vcf';
import UtilService from '@/services/UtilService';

export default {
  name: 'SmartScannerOverlay',
  data() {
    return {
      activeUsage: 'universal',
      activeScanner: 'card',
      captureLoading: false,
      flashMode: 'off',
    }
  },
  computed: {

    scannerRectangleClass() {
      if (this.activeScanner === 'card') return 'cardscanner-rectangle';
      if (this.activeScanner === 'QR') return 'square';
      if (this.activeScanner === 'badge') return 'badge';
    }
  },
  mounted() {
    EventBus.$on("startScanner", this.startScanner);
  },
  beforeDestroy() {
    EventBus.$off("startScanner", this.startScanner);
  },
  methods: {
    setActiveScanner(scanner) {
      this.activeScanner = scanner;
      if (scanner !== 'QR') {
        BarcodeScanner.pauseScanning();
      } else {
        BarcodeScanner.resumeScanning();
      }
    },
    async startScanner(type, layout = 'universal') {
      this.captureLoading = false;
      this.activeScanner = type;
      this.activeUsage = layout;
      document.getElementById('scannerOverlay').style.display = 'block';
      document.querySelector("html").style["background-color"] = "transparent";
      document.querySelector("html").style["background"] = "transparent";
      document.getElementById('rootAppId').style.display ='none';
      document.getElementById('scannerOverlay').style.display = '';
      document.getElementById("bodyId").style["background-color"] = 'transparent';
      document.getElementById("bodyId").style["background"] = 'transparent';
      // set to null then add new style. IMPORTANT!
      document.getElementById("bodyId").style["padding-top"] =  null;
      document.getElementById("bodyId").style["padding-top"] = "0px";
      await this.startQRScanner();
    },
    async startQRScanner() {
      let status = await BarcodeScanner.checkPermission({ force: true });
      if (status.denied) {
        const c = confirm('If you want to grant permission for using your camera, enable it in the app settings.');
        if (c) {
          await BarcodeScanner.openAppSettings();
        }
      }
      await BarcodeScanner.hideBackground();
      await BarcodeScanner.startScanning({}, async result => {
        await this.closeScanner();
        if (result && result.hasContent && result.content) {

          if (this.activeUsage === 'addConnection') {
            let payload = {};
            let type;
            let parsedData = {};
            if (result.content.startsWith('http') && (result.content.includes('tapni.co') || result.content.includes('t.link'))) {
              let tempArr = result.content.split('/');
              if (result.content.includes('/t/')) payload.hash = tempArr[tempArr.length - 1].toLowerCase();
              else payload.username = tempArr[tempArr.length - 1].toLowerCase();
              type = 'tapniQR';
            } else if (result.content.startsWith('BEGIN:VCARD')) {
              let vcfData = new vCard().parse(result.content.replace(/\r?\n/g, "\r\n"));
              parsedData = this.parseVCard(vcfData);
              payload = parsedData;
              type = 'vcfQR';
            } else if (result.content.startsWith('http')) {
              payload.website = result.content;
              type = 'urlQR';
            } else {
              payload.note = result.content;
              type = 'otherQR';
            }
            EventBus.$emit("qrDecoded", { usage: 'addConnection', result: result.content, type, payload });
          } else if (this.activeUsage === 'tagActivation') {
            EventBus.$emit("qrDecoded", { usage: 'tagActivation', result: result.content });
          } else if (this.activeUsage === 'qrLogin') {
            EventBus.$emit("qrDecoded", { usage: 'qrLogin', result: result.content });
          }
        }
      });
    },
    parseVCard(vcfData) {
      let parsedData = {};
      if (vcfData.data?.fn?._data) parsedData.name = vcfData.data.fn['_data'];
      if (vcfData.data?.org?._data) parsedData.company = vcfData.data.org['_data'];
      if (vcfData.data?.title?._data) parsedData.title = vcfData.data.title['_data'];
      if (vcfData.data?.tel) {
        if (Array.isArray(vcfData.data.tel)) {
          if (vcfData.data.tel[0]) parsedData.phone = vcfData.data.tel[0]['_data'];
          if (vcfData.data.tel[1]) parsedData.phone2 = vcfData.data.tel[1]['_data'];
          if (vcfData.data.tel[2]) parsedData.phone3 = vcfData.data.tel[2]['_data'];
        } else parsedData.phone = vcfData.data.tel._data;
      }
      if (vcfData.data?.email) {
        if (Array.isArray(vcfData.data.email)) {
          if (vcfData.data.email[0]) parsedData.email = vcfData.data.email[0]['_data'];
          if (vcfData.data.email[1]) parsedData.email2 = vcfData.data.email[1]['_data'];
          if (vcfData.data.email[2]) parsedData.email3 = vcfData.data.email[2]['_data'];
        } else parsedData.email = vcfData.data.email._data;
      }
      if (vcfData.data?.url) {
        if (Array.isArray(vcfData.data.url)) {
          if (vcfData.data.url[0]) parsedData.website = vcfData.data.url[0]['_data'];
          if (vcfData.data.url[1]) parsedData.website2 = vcfData.data.url[1]['_data'];
          if (vcfData.data.url[2]) parsedData.website3 = vcfData.data.url[2]['_data'];
        } else parsedData.website = vcfData.data.url._data;
      }
      parsedData.addConnection = true;
      parsedData.showDetails = true;
      return parsedData;
    },
    async closeScanner() {
      document.getElementById('scannerOverlay').style.display = 'none';
      document.querySelector("html").style["background-color"] = "#ffffff";
      document.querySelector("html").style["background"] = "#ffffff";
      document.getElementById('rootAppId').style.display = '';
      document.getElementById("bodyId").style["background-color"] = '#ffffff';
      document.getElementById("bodyId").style["background"] = '#ffffff';
      document.getElementById("bodyId").style["padding-top"] = "env(safe-area-inset-top)";
      await BarcodeScanner.stopScan();
      await BarcodeScanner.showBackground();
    },
    async capturePicture() {
      if (this.captureLoading) return;
      this.captureLoading = true;
      const cameraPreviewOptions = {
        position: 'rear',
        width: window.screen.width,
        height: window.screen.height,
        disableAudio: true,
        toBack: true,
        enableZoom: false,
        enableHighResolution: true
      };
      await CameraPreview.start(cameraPreviewOptions);
      if (this.flashMode === 'on') await CameraPreview.setFlashMode({ flashMode: 'on' });
      const result = await CameraPreview.capture({ quality: 95 });
      const base64PictureData = result.value;
      UtilService.cropCardScan(base64PictureData, this.activeScanner, (croppedBase64Image) => {
        this.closeScanner();
        EventBus.$emit("toggleScanLoadingModal", croppedBase64Image);
      });
    },
    async toggleFlash() {
      if (this.flashMode === 'off') {
        this.flashMode = 'on';
        await BarcodeScanner.enableTorch()
      } else {
        this.flashMode = 'off';
        await BarcodeScanner.disableTorch()
      }
    },
    async galleryPick() {
      let image = await Camera.getPhoto({
        quality: 95,
        allowEditing: false,
        source: CameraSource.Photos,
        resultType: CameraResultType.Base64
      });
      if (image) {
        EventBus.$emit("toggleScanCropperModal", image.base64String);
        this.closeScanner();
      }
    }
  }
}
</script>



<style scoped>
* {
  box-sizing: border-box;
}
html,
body,
.combined-scanner-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.combined-scanner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.relative {
  position: relative;
  z-index: 1;
  height: 70px;
  width: 90%;
  border: 3px solid white;
  border-radius: 1em;
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  flex-wrap: wrap;
  padding: 15px 20px;
  margin: 0 auto;
  margin-bottom: 60px;
  margin-top: -40px;
}
.relative p {
  color: #fff;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
}
.relative .ai-message {
  width: 80%;
  text-align: left;
}
.relative .ai-badge {
  width: 20%;
}

@media (max-width: 400px) {
  .relative {
    margin-left: 8%;
    width: 75%!important;
    margin-top: -120px!important;
    margin-bottom: 50px!important;
  }
  .relative .ai-message {
    width: 100%!important;
    text-align: left!important;
  }
  .relative .ai-badge {
    display: none;
    width: 25%!important;
  }
  .relative p {
    font-size: 12px!important;
  }
  .close-camera {
    top: 25px!important;
  }
  .scanner-toggle {
    margin-top: 30px!important;
  }
  .scanner-toggle button {
    height: 80px!important;
    font-size: 12px!important;
  }
  .badge {
    margin-top: -50px!important;
  }
}

.relative img {
  margin: 1px 0 0 3px;
  height: 35px;
}
.cardscanner-rectangle {
  width: 90%;
  height: calc(100vw / 2);
  position: relative;
  overflow: hidden;
  max-height: 28vh;
  margin: 0 auto;
}
.cardscanner-rectangle:after {
  content: '';
  top: 0;
  display: block;
  padding-bottom: 100%;
}
.cardscanner-rectangle > div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.scanner-area {
  height: 50vh;
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.5);
  border-radius: 1em;
}
.badge {
  width: 65%!important;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  margin-top: -65px;
  max-height: 35vh;
}
.badge:after {
  content: '';
  top: 0;
  display: block;
  padding-bottom: 100%;
}
.badge > div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.square {
  width: 63vw;
  max-height: 28vh;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
}
.square:after {
  content: '';
  top: 0;
  display: block;
  padding-bottom: 100%;
}
.square > div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.scanner--area--container {
  width: 100%;
  max-width: min(677px, 80vh);
  margin: auto;
}

.card-scanner--area--outer,
.scanner--area--outer {
  display: flex;
  border-radius: 1em;
}
.card-scanner--area--inner,
.scanner--area--inner {
  width: 100%;
  margin: 0.4rem;
  border: 3px solid #fff;
  border-radius: 1rem;
}
.scanner-toggle {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.scanner-toggle button {
  background-color: transparent;
  border: transparent;
  outline: solid 2px white;
  width: 130px;
  height: 110px;
  padding-top: 7.5%;
  margin: 0 15px;
  cursor: pointer;
  border-radius: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
}
.scanner-toggle button img {
  margin-bottom: 10px;
  width: 65px;
}
.scanner-toggle button.active {
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
  border: transparent;
  outline: solid 3px rgba(59, 162, 255, 1);
}
.capture-cardscanner {
  position: absolute;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  width: 88px;
  height: 88px!important;
  border-radius: 30px!important;
}
.capture-cardscanner img {
  width: 46px;
  height: 44px;
}
.close-camera {
  position: absolute;
  right: 10px;
  top: 55px;
  height: 40px;
  width: 40px;
}
.gallery-pick {
  position: absolute;
  left: 35px;
  bottom: 55px;
  height: 43px;
  width: 43px;
}
.camera-flash {
  position: absolute;
  right: 35px;
  bottom: 63px;
  height: 43px;
  width: 43px;
}
.cancel-scanner {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}
@keyframes shake {
  0% {
    transform: translate(0, 0) rotate(0deg) scale(1);
  }
  20% {
    transform: translate(5px, 5px) rotate(-1deg) scale(1.05);
  }
  40% {
    transform: translate(5px, 5px) rotate(-2deg) scale(1.07);
  }
  60% {
    transform: translate(2px, 2px) rotate(0deg) scale(1.04);
  }
  80% {
    transform: translate(-1px, -1px) rotate(-2deg) scale(1.05);
  }
  100% {
    transform: translate(0, 0) rotate(0deg) scale(1);
  }
}
</style>
