export default {
  state: {
    english: "Inglese",
    spanish: "Spagnolo",
    german: "Tedesco",
    korean: "Coreano",
    italian: "Italiano",
    french: "Francese",
    serbian: "Serba",
    turkish: "Turco",
    chinese: "Cinese",
    app_language: "Lingua dell'app",
    profile_preview: "Visualizza Profilo",
    edit_profile: "Modifica Profilo",
    login: "Login",
    referral_reach:
      "Forniscici maggiori informazioni su di te e sulla tua portata",
    referral_apply_placeholder: "Come pensi di promuovere Tapni?",
    referral_apply_note:
      "Agguingi piu link possibili ai tuoi social, In modo che possiamo sapere chi sei. Sii esclusivo. Non tutti vengono accettati",
    referral_apply_now: "Applica ora",
    referral_apply_thanks:
      "Grazie per l'applicazione. Esamineremo presto la tua candidatura!",
    connect: "Connettiti",
    with: "con",
    exchange_contact: "Exchange Contact",
    add_details: "Aggiungi altro",
    show_details: "Mostra di più",
    download_contact: "scaricare il contatto",
    stay_connected: "Rimani connesso",
    no_thanks: "No Grazie",
    connect_join: "Unisciti a Tapni",
    connect_with: "Connettiti con",
    connect_required_fields:
      "Uno dei due campi di contatto devono essere forniti",
    name: "Nome",
    first_name: "Nome",
    last_name: "Cognome",
    email: "Email",
    phone: "Numero di telefono",
    phone_p: "Telefono",
    company: "Azienda",
    address: "Indirizzo",
    website: "Sito web",
    open: "Apri",
    delete: "Cancella",
    confirm: "Conferma",
    cancel: "Annullamento",
    save: "Salva",
    code: "Codice",
    note: "Nota",
    note_p: "Aggiungi una nota",
    contact_card_icon:
      "Imposta il testo sotto l'icona della scheda di contatto",
    contact_card_name: "Inserire il nome per la scheda contatto",
    contact_card_name_p: "Nome della scheda di contatto",
    contact_card_email: "Inserire l'email per la scheda contatto",
    contact_card_email_p: "E-mail della scheda di contatto",
    contact_card_phone: "Inserire telefono per la scheda di contatto",
    contact_card_phone_p: "Telefono della scheda di contatto",
    contact_card_website: "Inserire il sito Web per la scheda di contatto",
    contact_card_website_p: "Sito Web della scheda di contatto",
    contact_card_company_name:
      "Inserisci il nome dell'azienda per la scheda contatto",
    contact_card_company_name_p: "Nome dell'azienda della scheda di contatto",
    contact_card_job_title: "Titolo del lavoro",
    contact_card_job_title_p: "Inserire il titolo del lavoro",
    contact_card_company_website: "Sito web dell'azienda",
    contact_card_company_website_p: "Inserire il sito web dell'azienda",
    contact_card_bio: "Inserisci la bio per la scheda di contatto",
    contact_card_bio_p: "Inserisci la bio per la scheda di contatto",
    contact_card_home_address:
      "Inserire l'indirizzo di casa per la scheda di contatto",
    contact_card_home_address_p: "Indirizzo di casa della scheda di contatto",
    contact_card_work_address:
      "Inserire l'indirizzo di lavoro per la scheda contatto",
    contact_card_work_address_p: "l'indirizzo di lavoro della scheda contatto",
    contact_card_social:
      "Inserisci i link dei social media al tuo profilo Tapni <br>per aggiungerli nella scheda dei contatti",
    contact_card_social_p:
      "Inserisci i link ai social media per la scheda di contatto",
    link_text_p: "Testo del link",
    link_icon: "Imposta il testo sotto l'icona del link",
    direct_pick: "Scegli un link diretto",
    direct_p:
      "Quando con il tuo tapni tocchi il telefono di qualcuno,<br> questo link verrà aperto al posto del tuo profilo completo.",
    password_change: "Cambia la password",
    password_repeat: "Ripeti la password",
    password_reset_t: "Reimpostazione della password",
    password_current: "Inserisci la tua password attuale",
    password_current_p: "Password attuale",
    password_new: "Inserisci la tua nuova password",
    password_new_p: "Nuova Password",
    password_new_repeat: "Inserisci di nuovo la tua nuova password",
    password_new_repeat_p: "Inserisci di nuovo la tua nuova password",
    password_update: "Aggiornamento password",
    password_reset:
      "Se non ti ricordi la tua password attuale,<br>\n puoi resettarla",
    delete_account_permanently:
      "Sei sicuro di voler eliminare il tuo account Tapni in modo permanente?",
    by_clicking_here: "cliccando qui",
    password_8_chars: "La password deve contenere almeno 8 caratteri",
    password_least_one_lowercase: "La password deve contenere almeno una lettera minuscola",
    password_least_one_uppercase: "La password deve contenere almeno una lettera maiuscola",
    password_least_one_number: "La password deve contenere almeno un numero",
    password_not_match: "Le password non corrispondono",
    password_change_success: "Password cambiata con successo",
    or: "o",
    profile: "Profilo",
    account_settings: 'Impostazioni dell\'account',
    profile_public: "Profilo pubblico",
    profile_public_p: "Il tuo profilo è disponibile pubblicamente tramite:",
    profile_active: "Profilo attivo",
    profile_active_p:
      "Se disattivi il tuo profilo, nessuno avrà accesso al tuo profilo e i tag collegati non funzioneranno più",
    profile_change_password: "Cambia la password",
    profile_change_username: "Cambia username",
    profile_change_language: "Cambia la lingua",
    profile_delete_account: "Cancellare l'account",
    profile_confirm_delete_account: "Cancellare l'account",
    profile_cancel_delete_account: "Annullamento",
    profile_delete_account_success: "L'account è stato eliminato con successo",
    profile_remove_p:
      "Se desideri rimuovere il tuo account,<br>\n per favore scrivici a",
    profile_contact_support:
      "Se desiderate rimuovere il vostro account,<br>\n" +
      "\t\t    scriveteci a",
    profile_email_subject: "Tapni App Support",
    profile_remove_subject: "Tapni settings support",
    profile_update_success: "Profilo aggiornato con successo",
    profile_complete: "Completa il tuo profilo",
    completed: "Completo",
    uncompleted: "Incompleto",
    contact_us: "Scrivici",
    contact_us_verify: "per verificare il tuo indirizzo email.",
    verify_email: "Verifica indirizzo e-mail",
    verify_email_subject: "Verificazione E-mail",
    verify_email_inbox: "Controlla il tuo inbox per",
    add_first_link: "Aggiungi il tuo primo link",
    add_first_link_p:
      "Clicca qui per aggiungere i tuoi canali di social media <br>\n                          o le informazioni di contatto sul tuo profilo Tapni.",
    add_picture: "Aggiungi un'immagine del profilo",
    add_picture_p: "Clicca qui per caricare la tua immagine del profilo.",
    add_tag: "Attiva il tuo Tapni",
    add_tag_p: "Clicca qui per attivare il tag NFC Tapni.",
    completing_profile:
      "Problemi con il completamento del tuo profilo?<br>\n                    Sentiti libero di scriverci a",
    completing_profile_subject: "Completa il tuo Profilo",
    share: "Condividi",
    share_profile: "Condividi il profilo",
    share_profile_p:
      "Altri possono scansionare questo codice QR <br> per vedere il tuo profilo",
    share_profile_change_username:
      "Se desideri cambiare o modificare il tuo link, <br> <u>clicca qui</u> per modificare il nome utente.",
    share_profile_qr: "Condividi profilo",
    share_contactcard_qr: "Scheda contatto",
    scan_qr_code: "Scansiona il codice QR",
    enter_activation_code: "Inserisci il codice di attivazione",
    activate_tag: "Attiva tag",
    order_tapni:
      "Se non hai ancora ordinato il tuo Tapni,<br>\n          puoi scegliere il tuo",
    activation_success:
      "Tag collegato correttamente. <br> Ora sei tutto pronto! 🥳",
    activation_success_message:
      "Tag collegato correttamente. Ora sei tutto pronto! ",
    activation_error: "Questo non è un tag valido. <br> Riprova.",
    activation_error_message: "Questo non è un tag valido. <br> Riprova.",
    activation_progress: "Registrazione tag in corso...",
    activation_invalid_code: "Codice di attivazione non valido.",
    activation_qr_failed: "Scansione del codice QR non riuscita",
    activation_qr_area:
      "Inserisci un QR Codice all'interno dell'area di scansione",
    activation_code: "Codice di attivazione",
    invalid_code: "Codice non valido",
    total_taps: "Tap totali",
    active: "Attivo",
    tag_active_p:
      "Se disattivato, non aprirà il tuo profilo <br> quando tocchi il telefono di qualcuno.",
    tag_delete_confirm:
      "Salva il codice di attivazione prima di scollegare il tag,<br> altrimenti non può essere riattivato.",
    upload_profile_picture: "Carica l'immagine del profilo",
    upload_cover_picture: "Carica l'immagine di cover",
    upload_link_picture: "Carica l'immagine del link",
    select_from_device: "Seleziona dal dispositivo",
    select_from_mobile: "Seleziona l'immagine dal tuo dispositivo mobile",
    select_from_computer:
      "o trascinala e rilasciala se stai utilizzando un computer",
    crop: "Ritaglia",
    crop_upload: "Ritaglia e Carica",
    crop_image: "Ritagliare l'immagine",
    drag_and_drop: "Trascina e rilascia file qui",
    upload_success: "File multimediali caricati correttamente",
    browser_not_supported: "Il tuo browser non supporta",
    change_username: "Cambia nome utente",
    change_username_success: "Nome utente cambiato con successo",
    update_username: "Aggiorna username",
    new_username: "Inserisci il tuo nuovo username",
    username_is_free: "il nome utente è libero",
    username_check: "Si prega di fornire un nome di utente corretto",
    anonymous: "Anonymous",
    connected: "Connesso",
    exchanged: "Scambiato",
    accept: "Accetta",
    view: "Visualizza",
    join: "Iscriviti",
    add_account: "Aggiungi account",
    complete_profile: "Completa profilo",
    my_profile: "Il mio profilo",
    my_qr: "Il mio QR",
    my_connections: "Le mie connessioni",
    contacts: "Contatti",
    my_contacts: "I Miei Contatti",
    contact_description: "Il tuo CRM personale per tutte le connessioni a cui tieni",
    my_tags: "I miei prodotti",
    my_tags_description: "Gestisci Carte da Visita Intelligenti collegate al tuo account",
    buy_tapni: "Compra Tapni",
    referral_program: "programma di Ambasciatore",
    device_compatibility: "compatibilità dei dispositivi",
    help: "Aiuto",
    settings: "Impostazioni",
    logout: "Logout",
    version: "Versione",
    configure: "Configura",
    unexpected_err:
      "Si è verificato un errore imprevisto, contattaci all'indirizzo support@tapni.com",
    success_login: "Accesso riuscito con un altro account.",
    password_reset_success:
      "Il codice di reimpostazione della password è stato inviato alla tua email.",
    password_reseted_success:
      "Password modificata con successo. Puoi accedere ora.",
    success_remove_link: "Link rimosso con successo",
    success_remove_picture: "Immagine rimossa con successo.",
    profile_active_message: "Il tuo profilo è ora attivo",
    profile_inactive_message: "Il tuo profilo è ora disabilitato",
    profile_public_message: "Il tuo profilo è ora pubblico",
    profile_private_message: "Il tuo profilo è ora privato.",
    redirect_enabled: "Collegamento di reindirizzamento automatico abilitato",
    redirect_disabled: "Reindirizzamento automatico Link disabilitato",
    tag_active: "Il tag è ora attivo. Facciamo i tap!",
    tag_inactive:
      "Il tag è ora disabilitato. I tuoi visitatori saranno reindirizzati su tapni.co",
    tag_removed: "Tag rimossa. Scrivici a support@tapni.com",
    connection_accepted: "Richiesta di connessione accettata.",
    connection_added: "Nuova connessione aggiunta con successo",
    connection_exist: "Sei già connesso a questo utente",
    not_found: "Non trovato",
    not_found_p: "Sembra che tu sia perso",
    go_back: "Torna indietro",
    referral_p1:
      "Guadagna il 20% di commissione<br> sulle vendite di affiliazione",
    referral_p2:
      "Regala agli altri il 20% di sconto<br> con il tuo codice sconto 🎁",
    referral_p3: "È un vantaggio per tutti 🤝",
    referral_p4:
      "Se desideri modificare i tuoi referral,<br>\n                                  o se hai domande,<br>\n                                  scrivici su",
    referral_p5: "Inizia a guadagnare ora!",
    referral_p66: "Condividi il tuo link di affiliazione e guadagna!",
    referral_p6:
      "Se sei un blogger, un influencer o un appassionato di social media, sei perfetto per il nostro programma Ambasciatore Tapni\n                                Promuovi il nostro Brand e guadagna una commissione del 20% sugli acquisti effettuati con il tuo codice sconto o link di riferimento.",
    referral_p7:
      "Dopo la registrazione al programma Tapni Ambassador, riceverai un link di riferimento personale e un codice sconto.\n                                Condividili sul tuo sito Web e sui social media e guadagna mentre noi offriamo un'esperienza di connessione eccezionale.",
    referral_p8: "Nota di pagamenti",
    referral_p9:
      "Paypal (Worldwide) e trasferimenti bancari (EU) <br> l'importo minimo di pagamento è di 25€.",
    referral_p10: "Richiedi pagamento",
    referral_p11: "Pagamento richiesto",
    your_connections: "Le tue connessioni",
    connections_p1: "Mettiti in contatto con le persone che hai incontrato",
    connections_p2:
      "<br><br>Nessuna  connessione finora. <br> Facciamo i tap!💥",
    connections_p3: "Nessuna connessione trovata",
    connection_success: "Inviata con successo una richiesta di connessione.",
    exchange_success: "Scambio di contatti riuscito.",
    create_free_account:
      "Crea un account Tapni gratuito e tieni aggiornati i tuoi contatti!",
    connection_edit: "Modifica connessione",
    connection_edit_success: "Connessione aggiornata con successo",
    connection_delete_success: "Connessione eliminata con successo",
    search: "Cerca",
    devices_p1: "Elenco dei dispositivi compatibili",
    devices_p2:
      "Tapni utilizza una tecnologia compatibile con la maggior parte dei nuovi dispositivi iPhone e Android",
    devices_p3:
      "Alcuni telefoni Android potrebbero avere il NFC disattivato. Se il tuo Tapni non funziona su un dispositivo Android, cerca NFC nelle impostazioni e assicurati che sia acceso.",
    devices_p4: "con iOS 14, il widget NFC si trova nel centro di controllo",
    download_p1: "Scarica Tapni App",
    download_p2: "Disponibile per dispositivi Apple e Android",
    user_not_found: "Utente non trovato",
    user_not_found_p:
      "Non siamo riusciti a trovare l'utente nel nostro database🤷",
    taps: "I Tap",
    private_profile: "Profilo privato",
    private_profile_p:
      "è un profilo privato. <br> Il profilo è visibile solo utilizzando i tag Tapni",
    profile_bio_p: "Scrivi qualcosa su di te o sul tuo marchio",
    add_to_contacts: "Aggiungi ai contatti",
    drag_and_drop_links: "Trascina e rilascia i link nell'ordine desiderato",
    no_links: "Non ci sono link nel tuo profilo.",
    add_link: "Aggiungi un link",
    direct_link_p1: "I visitatori verranno automaticamente reindirizzati a:",
    profile_not_active:
      "Il tuo profilo non è attivo al momento <br> e solo tu puoi vederlo una volta effettuato il login.",
    profile_not_public:
      "Il tuo profilo al momento non è pubblico <br> e solo chi ti fa un tap, può vederlo.",
    activate_profile_now: "Attiva ora il tuo profilo",
    publish_profile_now: "Rendi pubblico il tuo profilo ora",
    link_add_p1: "Scegli un tipo di link",
    link_add_not_found: "Nessun link trovato 🤷",
    link_add_not_found_p2:
      "Se abbiamo dimenticato la tua social piattaforma preferita, inviaci un'e-mail (<b>support@tapni.com</b>) o inviaci un messaggio DM sui social media (<b>@tapni</b>)",
    link_add_not_found_p3:
      "Faremo del nostro meglio per supportare tutte le piattaforme 😉",
    featured: "In primo piano",
    social_media: "I Social media",
    contact: "Contatto",
    finance: "La Finanza",
    portfolio: "Il portfolio",
    music: "Musica",
    business: "Business",
    personal: "Personale",
    fun: "Divertimento",
    ecommerce: "E-commerce",
    more: "mostra di più",
    email_username: "Email/Nome utente",
    username: "Username",
    password: "Password",
    sign_in: "Registrazione",
    sign_in_with: "Registrati con",
    sign_in_with_new: "Accedi con un nuovo account",
    sign_up_with: "Iscriviti con",
    select_sign_method: "Seleziona un metodo di accesso",
    verify_account: "Verifica Account",
    terms_by_signing_in: "Effettuando l'accesso, accetti il ​​nostro",
    terms_by_registering: "Registrandoti accetti il ​​nostro",
    terms_by_submitting:
      "Inviando questo modulo, accettate i nostri Termini di servizio.",
    terms_of_service: "Termini di servizio",
    and: "e",
    refresh: "Ricaricare",
    privacy_policy: "Politica sulla riservatezza",
    create_account: "Crea un profilo",
    reset_password: "Resetta la password",
    reset_password_p1:
      "Se non sei riuscito a reimpostare la password,<br> non esitare a contattarci all'indirizzo",
    reset_password_p2:
      "Se non sei riuscito a resettare la password,<br> non esitare a contattarci all'indirizzo",
    create_new_account: "Crea un nuovo account",
    maintenance_p1: "Torniamo presto",
    maintenance_p2: "Nel frattempo esplora le collezioni Tapni",
    maintenance_p3:
      "Tapni è inattivo per una manutenzione programmata. <br> e dovrebbe tornare online tra pochi minuti.",
    offline_p1: "Nessuna connessione internet",
    offline_p2: "Controlla la tua connessione, quindi aggiorna la pagina",
    offline_p3_1: "Mentre si è offline,",
    offline_p3_2:
      "è possibile condividere la scheda di contatto utilizzando questo codice QR",
    products_p1:
      "Stupisci tutti quelli che incontri 😎 <br> Ottieni il tuo ora 👇",
    cards: "Biglietti da visita",
    stickers: "Adesivi",
    keychains: "Portachiavi",
    explore_collection: "Esplora collezione",
    register: "Registrati",
    register_success: "Hai quasi finito. Per favore, conferma la tua email.",
    register_success_modal: "Accesso riuscito con un altro account.",
    check_your_inbox: "Controlla il tuo DM",
    tapni_tags: "I Tapni tag",
    tapni_tags_p1: "Configura i tag collegati al tuo profilo",
    tapni_tags_p2:
      "Non ci sono tag collegati al tuo profilo. <br> Collegane uno ora!",
    verify_account_p1:
      "Se non hai ricevuto un codice, controlla la tua cartella spam di <br> o contattaci all'indirizzo",
    verify_account_success:
      "Account verificato con successo. Si prega di cambiare password ora.",
    verify_account_success2:
      "Account verificato con successo. Ora sei tutto pronto!",
    welcome: "Benvenuto",
    welcome_p1: "al futuro dei biglietti da visita",
    how_it_works: "Come funziona",
    already_registered: "Già registrato",
    error: "Errore",
    success: "Successo",
    copied: "Copiato negli appunti",
    connect_validate: "Deve essere fornito uno dei due campi di contatto",
    connect_validate_name: "Per favore inserisci il tuo nome",
    connect_validate_email: "Inserire un'e-mail valida",
    link_validate: "Dati di collegamento non validi.",
    link_text_contact_card: "Scheda di contatto",
    link_text_custom_link: "Link personalizzato",
    link_text_menu: "Menù",
    link_text_phone: "Telefono",
    save_to_apple_wallet: "Aggiungi a Apple Wallet",
    save_to_google_wallet: "Aggiungi a Google Wallet",
    card: "Carta",
    sticker: "Adesivo",
    keychain: "Portachiavi",
    band: "Banda",
    case: "Caso",
    standee: "Standee",
    decoded: "Decoded",
    wristbanditz: "Wristbanditz",
    update_p1: "Rilevata versione app obsoleta",
    update_p2: "Aggiorna",
    update_p3: "Aggiorna l'app alla versione più recente",
    company_domain: "Il dominio della tua azienda",
    search_company_domain: "Cerca il dominio della tua azienda",
    enter_company_email: "Inserisci l'indirizzo e-mail aziendale",
    company_not_found: "Azienda non trovata",
    ik_company_domain: "Conosco il dominio dell'azienda",
    idk_company_domain: "Non conosco il dominio dell'azienda",
    continue: "Continua",
    forbidden_rule:
      "Questa opzione è disabilitata, contatta l'amministratore della tua azienda.",
    companyName: "Nome della società",
    createMarkerPlaceholder: "Creare o selezionare un marcatore",
    clickToCreateMarker: "Fare clic qui per creare il marcatore",
    pleaseInviteUser:
      "Invitate l'utente a iscriversi a Tapni per avere i dati sincronizzati con voi.",
    connectionSynchronized:
      "I dati di connessione vengono sincronizzati automaticamente.",
    viewProfile: "Visualizza il profilo",
    createConnectionHeader: "Aggiungi nuova connessione",
    createConnectionButton: "Aggiungi",
    createConnectionSuccess: "La connessione è stata creata.",
    allow_company_management: "Consentire la gestione dell'azienda",
    company_join_request:
      "chiede di gestire il suo profilo e di associarlo al suo account Enterprise.",
    account_ownership_transfer:
      "Se accettate la richiesta, la proprietà del vostro account sarà trasferita all'azienda. L'azienda potrà modificare il vostro profilo, modificare e aggiungere link, vedere le vostre connessioni, modificare la vostra password, cancellare il vostro account e altro ancora. Prima di accettare la richiesta, verificare con l'azienda.",
    request_expired:
      "La richiesta è scaduta, chiedete alla vostra azienda di inviare una nuova richiesta.",
    request_expiration_desc:
      "Per maggiore sicurezza, la richiesta funzionerà solo per 14 giorni dall'invio.",
    invitation_link_not_valid: "Il link di invito non è valido!",
    invitation_link_not_valid2:
      "Fare nuovamente clic sul pulsante dell'e-mail di invito o copiare il link dell'invito.",
    accept_request: "Accettare la richiesta",
    close: "Chiudere",
    request_accepted: "La richiesta è stata accettata con successo",
    companyEmail: "Email aziendale",
    companyPhone: "Telefono dell'azienda",
    addBusinessInformation: "Aggiungi informazioni sulle attività commerciali",
    resetFilter: "Reset",
    selectMarkers: "Selezionare i marcatori",
    filterConnectionsHeader: "Connessioni del filtro",
    selectConnectionType: "Selezionare il tipo di connessione",
    all: "Tutti",
    direct: "Diretto",
    form: "Forma",
    manually: "Manualmente",
    scan: "Scansione",
    sortOptions: "Opzioni di ordinamento",
    date: "Data di creazione",
    fullName: "Nome completo",
    descending: "In discesa",
    ascending: "Ascendente",
    selectDateRange: "Selezionare l'intervallo di date",
    follow_up_reminder: "Promemoria di follow-up",
    now: "Ora",
    one_hour: "1 Ora",
    one_day: "1 Gioro",
    three_days: "3 Giorni",
    one_week: "1 Settimana",
    two_weeks: "2 Settimane",
    one_month: "1 Mese",
    custom: "Scegliere l'ora",
    set_reminder: "Imposta promemoria",
    select_date_time: "Selezionare data e ora",
    enter_reminder_text: "Inserire il testo del promemoria",
    reminder_created: "Promemoria creato con successo",
    connectionNameLabel: "Inserire il nome della connessione",
    connectionEmailLabel: "Inserire l'indirizzo e-mail privato",
    privatePhoneLabel: "Inserire il numero di telefono privato",
    companyNameLabel: "Inserire il nome della società",
    businessEmail: "Indirizzo e-mail aziendale",
    businessEmailLabel: "Inserire l'indirizzo e-mail aziendale",
    businessPhone: "Numero di telefono aziendale",
    businessPhoneLabel: "Inserire il numero di telefono dell'azienda",
    businessFax: "Fax aziendale",
    businessFaxLabel: "Inserisci il numero di fax aziendale",
    noteLabel: "Inserire la nota",
    markersLabel: "Aggiungere i marcatori di connessione",
    showScan: "Mostra scansione",
    hideScan: "Nascondi scansione",
    create_connection: "Aggiungi connessioni",
    create_manually: "Aggiungi manualmente",
    create_using_scanner: "Aggiungi utilizzando lo scanner per schede",
    companyTitle: "Titolo dell'azienda",
    companyTitleLabel: "Inserire il titolo dell'azienda",
    allConnectionsLabel: "Tutti i tipi di connessione",
    directConnectionsLabel: "Connessione diretta in-app con gli utenti Tapni",
    formConnectionsLabel: "Connessioni compilate sul modulo di connessione",
    manualConnectionsLabel: "Connessioni create manualmente",
    scanConnectionsLabel: "Lo scanner per schede ha creato connessioni",
    analyticsHeader: "Analisi",
    analyticsProfileViews: "Visualizzazioni del profilo",
    analyticsLinkClicks: "Link cliccati",
    analyticsClickTroughRatio: "Rapporto click-trough",
    analyticsTotalConnections: "Connessioni totali",
    analyticsTopLinks: "I link più popolari",
    analyticsProfileVisits: "Visite al profilo",
    analyticsLinkVisits: "Visite di collegamento",
    analyticsNewConnections: "Nuove connessioni",
    analyticsTopCountries: "Paesi Top",
    analyticsTopReferers: "I migliori referenti",

    tapni_for_business: "Tapni per le Aziende",
    supercharge_your_business: "Potenzia la tua attività con la piattaforma di biglietti da visita digitali n°1, creata per le imprese e i team.",

    for_teams_and_businesses: "Per Team & Aziende",
    number_one_digital_business_card_platform: "Piattaforma di Biglietti da Visita Digitali N°1",
    manage_teams_collect_leads_automate_workflows: "Gestisci Team, Raccogli Lead & Automatizza Flussi di Lavoro",
    read_more: "Leggi di più",
    navigate_to_business_tapni_com: "1) Naviga verso business.tapni.com",
    start_qr_scanner_via_button_below: "2) Avvia lo Scanner QR tramite il pulsante sottostante",
    scan_the_qr_code_from_the_screen: "3) Scansiona il codice QR dallo schermo",
    enjoy_using_our_platform: "4) Divertiti ad usare la nostra piattaforma",
    start_qr_scanner: "Avvia Scanner QR",

    change_language: "Cambia Lingua",
    terms_and_privacy: "Termini & Privacy",

    lead_capture_mode: "Modulo per la cattura dei lead",
    lead_capture_mode_description: "Il modulo di cattura lead verrà visualizzato sul tuo profilo",
    public_profile_mode: "Modalità Profilo Pubblico",
    public_profile_mode_description: "Rendi il tuo profilo",
    public_profile_mode_available: "disponibile pubblicamente",

    link_a_device: "Collega un dispositivo",

    streetName: "Nome della strada",
    streetNr: "Numero",
    plz: "CAP",
    city: "Città",
    country: "Paese",

    choose_template: "Scegli il template",
    contactcard: "Carta di contatto",
    customlink: "Link personalizzato",
    onboarding: "Integrazione",
    tag_text_p: "Nome del tag",
    tag_text_p2: "Imposta un nome personalizzato per il prodotto",
    connect_validate_consent: "Si prega di accettare il consenso sulla privacy",
    links: "Link",
    manage: "Gestisci",
    buy_domain: "Acquista Dominio",
    buy_domain_p: "Personalizza la tua Identità Online con un Dominio Personalizzato.",

    enable_lead_button: "Attiva il pulsante 'Scambia Contatto'",
    enable_lead_button_p: "Il pulsante di scambio contatti verrà visualizzato sul tuo profilo",
    form_auto: 'Apri il modulo alla visita del profilo',
    form_auto_p: 'Il modulo di scambio contatti si aprirà automaticamente quando qualcuno apre il tuo profilo',
    form_required: 'Compilazione del modulo obbligatoria',
    form_required_p: 'Rendi obbligatoria la compilazione del modulo prima che possa essere chiuso.',
    enter_code_manually: 'Inserire il codice manualmente',
    activate_via_nfc: 'Attiva tramite NFC',
    qr_code: 'Codice QR',
    nfc: 'NFC',
    nfc_not_available: 'NFC non disponibile su questo dispositivo',
    nfc_not_enabled: 'NFC non abilitato. Abilita NFC nelle impostazioni del dispositivo',
    nfc_no_link_found: 'Nessun link NFC trovato. Si prega di contattare il supporto.',
    activation_second_step_needed: 'È necessario un ulteriore passaggio. <br> Scansiona ora il prodotto tramite',

    scanner_card_p: 'Punta la fotocamera sulla carta <br> e tocca il pulsante.',
    scanner_qr_p: 'Punta la fotocamera sul QR <br> per auto-rilevamento.',
    scanner_badge_p: 'Punta la fotocamera sul badge <br> e tocca il pulsante.',
    paper_card: 'Carta di carta',
    event_badge: 'Badge dell\'evento'
  },
};
