<template>
  <form
    @submit.prevent="applyTemplate"
    class="menu-wrapper menu-light menu-modal center-text activate-page"
    :class="{ 'active-menu': toggle }"
    style="height: auto; margin-top: -172.5px"
  >
    <h2 class="header">{{lang[appLang].choose_template }}</h2>
    <a
      @click="close"
      class="color-black pull-right pointer"
      style="margin-top: -30px; position: absolute; right: 5px"
    >
      <i class="font-17 fa color-black">
        <img
          src="https://cdn.tapni.co/icons/down-arrow.png"
          class="fa responsive-image"
          style="width: 45%"
        />
      </i>
    </a>

    <div class="swipper" style="overflow-y: scroll; max-height: calc(92vh - 140px); width: 100%">
      <div v-if="loading" class="action-block-top full-top margin-center" style="height: 20vh;">
        <div class="default-text"></div>
      </div>
      <div v-else
        class="container full-top pointer"
        :class="{ selectedTemplate: template.id === selectedTemplate, 'container-profile': type === 'profile', 'container-wallet': type === 'wallet' }"
        v-for="(template, index) in templates"
        :key="index"
        @click.stop.prevent="selectTemplate(template.id)"
      >

        <ProfilePreview
            v-if="template && template.type === 'profile'"
            :profile="{...user, customStyle: template}"
            :templatePreview="true"
        />

        <WalletPreview
            v-if="template && template.type === 'wallet'"
            :templateData="{...template, template: {...template.config}}"
            :profile="user"
        />

        <div class="styleLabel">
          <h4>{{ template.name }}</h4>
        </div>
      </div>
    </div>

    <div class="sticky-footer">
      <button
        class="button white-button half-bottom small-top pointer"
        style="width: auto"
        @click="close"
        type="button"
      >
        {{lang[appLang].cancel }}
      </button>
      <button
        class="button black-button half-bottom small-top pointer"
        :class="{'opacity-60': loading}"
        style="width: 62%; margin-right: 0"
        type="submit"
        :disabled="loading"
      >
        {{lang[appLang].choose_template }}
      </button>
    </div>
  </form>
</template>

<script>
import { EventBus } from "../../store/event-bus";
import UserService from "@/services/UserService";
import to from "await-to-js";
import { mapActions } from "vuex";
import WalletPreview from "@/components/WalletPreview.vue";
import ProfilePreview from "@/plugins/profile-preview/ProfilePreview.vue";

export default {
  name: "Templates",
  components: {ProfilePreview, WalletPreview},
  data() {
    return {
      toggle: false,
      loading: false,
      templates: [],
      selectedTemplate: null,
      type: 'profile'
    };
  },
  mounted() {
    EventBus.$on("toggleTemplatesModal", this.showModal);
    EventBus.$on("closeModal", this.hideModal);
  },
  beforeDestroy() {
    EventBus['_events']['toggleTemplatesModal'].pop()
  },
  methods: {
    ...mapActions(["getUser", "changeStyle"]),
    close() {
      EventBus.$emit("closeModal");
    },
    async showModal(type) {
      this.toggle = true;
      this.type = type;
      this.loading = true;
      await this.getAllTemplates();
      await this.sleep(3000);
      this.loading = false;
    },
    hideModal() {
      this.toggle = false;
    },
    selectTemplate(id) {
      this.selectedTemplate = id;
    },
    async getAllTemplates() {
      const [err, response] = await to(UserService.getAllTemplates(this.type));
      if (err) return this.errorHandler(err);

      this.templates = response.data.sort((a, b) => a.is_public - b.is_public);;

      if (this.type === 'profile') {
        this.selectedTemplate = this.user.customStyle?.id;
      } else if (this.type === 'qr') {
        this.selectedTemplate = this.user.QrTemplateId;
      }
    },
    async applyTemplate() {
      if (!this.selectedTemplate) return;

      if (this.type === 'profile') {
        const [err, response] = await to(
            UserService.applyTemplate(this.selectedTemplate)
        );

        if (!err) {
          this.close();
          this.changeStyle(
              this.templates.find(
                  (template) => template.id === this.selectedTemplate
              )
          );
          EventBus.$emit('previewProfile', true);
        }
      }

      if (this.type === 'wallet') {
        this.close();
        EventBus.$emit('saveToAppleWallet', this.selectedTemplate);
      }
    },
  },
};
</script>

<style scoped>
.container-profile {
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  flex: 0 0 50%;
  aspect-ratio: 1;
  scroll-snap-align: center;
  border: 10px solid #eee;
  border-radius: 50px;
  overflow: hidden;
  height: 70vh;
  min-width: 300px;
  max-height: 600px;
}

.container-wallet {
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  flex: none;
  scroll-snap-align: center;
  border: 5px solid #eee;
  border-radius: 20px;
  overflow: hidden;
}

.image {
  display: flex;
  justify-content: center;
}

.nameAndBio > p {
  font-size: 12px;
  margin-top: 10px;
}

.linksContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 20px;
}

.linksContainer > div {
  width: 25%;
  background: #efece7;
  aspect-ratio: 1;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 10px;
}

.swipper {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  width: 100%;
  gap: 45px;
}

.container:first-child {
  margin-left: 7%;
}

.container:last-child {
  margin-right: 5%;
}

.sticky-footer {
  height: 90px;
  width: 100%;
  position: sticky;
  bottom: 0;
  z-index: 999;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 15px !important;
}

.header {
  text-align: left;
}

.selectedTemplate {
  outline: 5px solid lightblue;
}

.styleLabel {
  position: absolute;
  top: -50px;
  width: 100%;
}
</style>
