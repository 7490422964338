<template>
  <div
    class="menu-wrapper menu-light menu-modal activate-page center-text"
    :class="{ 'active-menu': toggle }"
    :style="`height: auto; min-height: 420px; margin-top: -172.5px;`"
  >
    <a
      @click="close"
      class="color-black pull-right pointer"
      style="margin-top: 5px; position: absolute; right: 5px"
    >
      <i class="font-17 fa color-black">
        <img src="https://cdn.tapni.co/icons/down-arrow.png" class="fa responsive-image" style="width: 45%" />
      </i>
    </a>
    <div v-show="!cropping && !loadingUpload">
      <h1 class="center-text half-bottom full-top">
        <i class="fa bg-white" style="padding: 13px 5px; border-radius: 20px">
          <img src="https://cdn.tapni.co/icons/gallery.png" class="fa responsive-image" style="width: 60%" />
        </i>
      </h1>
      <h1 v-if="type === 'photo'" class="bold center-text color-black half-bottom">
        {{lang[appLang].upload_profile_picture }}
      </h1>
      <h1 v-else-if="type === 'cover'" class="bold center-text color-black half-bottom">
        {{lang[appLang].upload_cover_picture }}
      </h1>
      <h1 v-else-if="type === 'link'" class="bold center-text color-black half-bottom">
        {{lang[appLang].upload_link_picture }}
      </h1>
      <h1 v-else-if="type === 'contactcard'" class="bold center-text color-black half-bottom">
        {{lang[appLang].upload_link_picture }}
      </h1>
      <p class="close-text close-menu center-text full-bottom font-17">
        {{lang[appLang].select_from_mobile }}<br />
        <span v-if="!isNative">{{
          lang[this.appLang].select_from_computer
        }}</span>
      </p>
    </div>

    <!--- Crop image modal--->
    <div v-show="cropping && !loadingUpload" class="full-top">
      <form @submit.prevent="onEditorFile">
        <div class="modal-body">
          <div
            class="form-group"
            v-show="
              editFile.show &&
              editFile.blob &&
              editFile.type &&
              editFile.type.substr(0, 6) === 'image/'
            "
          >
            <div v-show="showCropOptions" class="row justify-content-center">
              <h5 class="col-12 example-title text-center">
                {{lang[appLang].crop }}
              </h5>
            </div>
            <div class="edit-image" style="margin-top: 50px">
              <img :src="editFile.blob" ref="editImage" style="max-height: 400px; max-width: 400px" />
            </div>
          </div>
        </div>
        <div class="modal-footer half-top center-text">
          <!--<button class="button button-red button-round button-xs pull-left edit-shadow pointer" @click.prevent="deleteUpload">{{lang[this.appLang].delete}}</button>-->
          <!--<button type="submit" class="button button-green button-round button-xs pull-right edit-shadow"
                    style="margin-right: 0;">
              <span v-if="!loadingUpload">{{lang[this.appLang].crop_upload}}</span>
              <i v-else class="fa fa-circle-o-notch fa-spin" style="margin: 8px auto 0;width: 100%; text-align: center; font-size: 21px;"></i>
            </button>
            -->
          <button type="submit" class="button white-button small-top pointer pull-left red-text" @click="deleteUpload">
            {{lang[appLang].cancel }}
          </button>
          <button type="submit" class="button black-button small-top pointer pull-right" style="margin-right: 0; width: 60%">
            <i v-if="$refs.upload && $refs.upload.uploaded" class="fa fa-circle-o-notch fa-spin" style="margin: 8px auto 0; width: 100%; text-align: center; font-size: 21px;"></i>
            <span v-else>{{lang[appLang].crop_upload }}</span>
          </button>
        </div>
      </form>
    </div>

    <!-- Upload Button -->
    <file-upload
      id="selectFromDevice"
      v-show="!cropping && !loadingUpload"
      class="button button-round button-m google-button pointer full-top half-bottom pointer pulse edit-shadow button-full"
      style="padding-left: 20px !important"
      :post-action="postAction"
      :extensions="extensions"
      :accept="accept"
      :multiple="multiple"
      :directory="directory"
      :size="size || 0"
      :thread="thread"
      :headers="headers"
      :data="data"
      :drop="drop"
      :drop-directory="dropDirectory"
      :add-index="addIndex"
      name="media"
      v-model="files"
      @input-filter="inputFilter"
      @input-file="inputFile"
      ref="upload"
    >
      <span style="padding-top: 0px">{{
        lang[this.appLang].select_from_device
      }}</span>
    </file-upload>
    <div
      v-show="$refs.upload && $refs.upload.dropActive && !cropping && !loadingUpload"
      class="drop-active no-top"
    >
      <h5>{{lang[appLang].drag_and_drop }}</h5>
    </div>

    <div class="half-bottom" v-if="user && !cropping && !loadingUpload && ((type === 'photo' && !user.photo?.includes('images/user.png')) || (type === 'cover' && user.cover))" >
      <a v-if="!confirmDelete"
         @click="confirmDelete = true"
         class="button white-button pointer small-top"
         style="
          margin: 0;
          width: 60px;
          border-radius: 8px;
          padding-left: 7px;
          padding-right: 7px;
        ">
        <img src="https://cdn.tapni.co/icons/delete.png" alt="delete" height="25" width="25" class="btn-icon" style="margin-right: 0px" />
      </a>
      <a
          v-if="confirmDelete"
          @click="deletePictureHandler"
          class="button white-button pointer small-top"
          style="margin: 0; color: #fc1414; width: auto; max-width: 43%"
      >
        {{ this.lang[this.appLang].confirm }}
      </a>
      <p>Delete current picture</p>
    </div>
    <div v-if="loadingUpload" style="margin-top: 150px; height: 50px; ">
      <span class="preload-spinner" style="left: 0px; top: 0px; position: relative"></span>
    </div>
  </div>
</template>
<script>
import Cropper from "cropperjs";
import Compressor from "compressorjs";
import FileUpload from "vue-upload-component";
import { mapActions } from "vuex";
import { EventBus } from "../../store/event-bus";
import "cropperjs/dist/cropper.css";

const state = {
  toggle: false,
  mediaUrl: "",
  type: "photo",
  aspectRatio: "1",
  init: false,
  upload: false,
  files: [],
  accept: "image/png,image/gif,image/jpeg",
  extensions: "GIF,JPG,JPEG,PNG,gif,jpg,jpeg,png",
  minSize: 1024,
  size: 1024 * 1024 * 30,
  multiple: false,
  directory: false,
  drop: true,
  dropDirectory: true,
  addIndex: false,
  thread: 1,
  name: "file",
  putAction: "/upload/put",
  data: {},
  autoCompress: 600 * 600,
  uploadAuto: false,
  addData: {
    show: false,
    name: "",
    type: "",
    content: "",
  },
  editFile: {
    show: false,
    name: "",
  },
  loadingUpload: false,
  confirmDelete: false
};
export default {
  name: "upload",
  components: {
    FileUpload,
  },
  data() {
    return state;
  },
  computed: {
    headers() {
      return {
        Authorization: "Bearer " + this.storage.token,
      };
    },
    cropping() {
      return (
        this.editFile &&
        this.editFile.show &&
        this.init &&
        !this.editFile.type.includes("gif")
      );
    },
    postAction() {
      return (
        import.meta.env.VITE_APP_API_ROOT + "/v1/media/upload?type=" + this.type
      );
    },
    maxWidth() {
      return this.type === "photo" ? 600 : 900;
    },
    maxAspectRatio() {
      switch (this.type) {
        case "photo":
        case "link": {
          return 1;
        }
        case "contactcard": {
          return 1;
        }
        case "cover": {
          return 2;
        }
        default: {
          return 2;
        }
      }
    },
    minAspectRatio() {
      switch (this.type) {
        case "cover": {
          return 2;
        }
        default: {
          return 1;
        }
      }
    },
    showCropOptions() {
      switch (this.type) {
        case "photo":
          return false;
        case "link":
          return false;
        case "contactcard":
          return false;
        case "cover":
          return false;
        default:
          return true;
      }
    }
  },
  mounted() {
    EventBus.$on("closeModal", () => {
      this.toggle = false;
      this.reset();
    });
    EventBus.$on("toggleUploadModal", this.toggleModal);
    EventBus.$on("typeUploadModal", (type) => {
      this.reset();
      this.type = type;
    });
  },
  beforeDestroy() {
    EventBus['_events']['toggleUploadModal'].pop()
    EventBus['_events']['typeUploadModal'].pop()
  },
  watch: {
    cropping(nv) {
      if (nv && this.type === "link") {
        this.toggle = true;
      }
      if (nv && this.type === "contactcard") {
        this.toggle = true;
      }
    },
    type(nv) {
      this.multiple = false;
      switch (nv) {
        case "photo":
        case "link": {
          this.aspectRatio = "1";
          break;
        }
        case "contactcard": {
          this.aspectRatio = "1";
          break;
        }
        case "cover": {
          this.aspectRatio = "2";
          break;
        }
        default: {
          this.aspectRatio = "1.33";
          break;
        }
      }
    },
    aspectRatio(nv) {
      if (this.editFile.cropper) {
        this.editFile.cropper.setAspectRatio(parseFloat(nv));
      }
    },
    "editFile.show"(newValue, oldValue) {
      let self = this;
      if (!newValue && oldValue) {
        this.$refs.upload.update(this.editFile.id, {
          error: this.editFile.error || "",
        });
      }
      if (newValue) {
        this.$nextTick(function () {
          if (!this.$refs.editImage) return;
          this.editFile.name = "photo" + this.editFile.name.substr(this.editFile.name.indexOf("."));
          let minAspectRatio = self.minAspectRatio;
          let maxAspectRatio = self.maxAspectRatio;
          let cropper = new Cropper(this.$refs.editImage, {
            aspectRatio: parseFloat(this.aspectRatio),
            minCropBoxWidth: 100,
            autoCrop: true,
            viewMode: 1,
            checkOrientation: false,
            zoomable: false,
            ready: function () {
              let cropper = this.cropper;
              let containerData = cropper.getContainerData();
              let cropBoxData = cropper.getCropBoxData();
              let aspectRatio = cropBoxData.width / cropBoxData.height;
              let newCropBoxWidth;
              if (aspectRatio < minAspectRatio || aspectRatio > maxAspectRatio) {
                newCropBoxWidth = cropBoxData.height * ((minAspectRatio + maxAspectRatio) / 2);
                cropper.setCropBoxData({
                  left: (containerData.width - newCropBoxWidth) / 2,
                  width: newCropBoxWidth,
                });
              }
              cropper.autoCrop = true;
            },
          });
          this.editFile = {
            ...this.editFile,
            cropper,
          };
          this.$forceUpdate();
        });
      }
    },
    "addData.show"(show) {
      if (show) {
        this.addData.name = "";
        this.addData.type = "";
        this.addData.content = "";
      }
    }
  },
  methods: {
    ...mapActions(["getUser", "deletePicture", "eventLog"]),
    close() {
      this.reset();
      EventBus.$emit("toggleUploadModal", true, this.type);
    },
    reset() {
      this.confirmDelete = false;
      if (this.editFile.cropper) {
        this.editFile.cropper.reset();
        this.editFile.cropper.clear();
        this.editFile.cropper.destroy();
      }
      this.loadingUpload = false;
      this.editFile.show = false;
      this.init = false;
      this.files.forEach((f) => {
        this.$refs.upload.remove(f);
        this.files.pop();
      });
      this.editFile = {
        show: false,
        name: "",
      };
      setTimeout(() => {
        Object.keys(state).forEach((key) => {
          this[key] = state[key];
        });
        this.$forceUpdate();
      }, 1000);
    },
    toggleModal(shouldToggle = true, type = "") {
      // Necessary to refresh token if expired because upload request from component does not go through axios
      this.eventLog({ type: 'upload_modal' });

      this.toggle = !this.toggle;
      if (type) this.type = type;
      this.$forceUpdate();
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // Filter system files or hide files
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }
        // Filter php html js file
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent();
        }

        // Automatic compression
        if (
          newFile.file &&
          newFile.type.substr(0, 6) === "image/" &&
          !newFile.type.includes("gif")
        ) {
          newFile.error = "compressing";
          var thisInstance = this;

          // eslint-disable-next-line no-new
          new Compressor(newFile.file, {
            convertSize: Infinity,
            maxWidth: this.maxWidth,
            success(file) {
              thisInstance.$refs.upload.update(newFile, {
                error: "",
                file,
                size: file.size,
                type: file.type,
              });
            },
            failure(err) {
              thisInstance.$refs.upload.update(newFile, {
                error: err.message || "compress",
              });
            },
          });
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        // Create a blob field
        newFile.blob = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }
        // Thumbnails
        newFile.thumb = "";
        if (newFile.blob && newFile.type.substr(0, 6) === "image/") {
          newFile.thumb = newFile.blob;
        }
      }
    },
    // add, update, remove File Event
    async inputFile(newFile, oldFile) {
      if (newFile && oldFile) {
        // update
        if (newFile.active && !oldFile.active) {
          // beforeSend
          // min size
          if (
            newFile.size >= 0 &&
            this.minSize > 0 &&
            newFile.size < this.minSize
          ) {
            this.$refs.upload.update(newFile, { error: "size" });
          }
        }
        if (newFile.progress !== oldFile.progress) {
          // progress
          return;
        }
        if (newFile.error && !oldFile.error) {
          this.loadingUpload = false;
          // error
        }
        if (newFile.success && !oldFile.success) {

          EventBus.$emit("toggleUploadModal", this.type !== 'link', this.type);
          this.successSnack(this.lang[this.appLang].upload_success);
          this.init = false;
          await this.getUser({ username: this.route.params.username });
          setTimeout(async () => {
            this.loadingUpload = false;
            this.reset();
            this.$forceUpdate();
          }, 1000);
        }
      }

      if (!newFile && oldFile) {
        // remove
        // oldFile.response.url -> uploaded url from server
        // oldFile.response.id -> media id from server
        if (oldFile.success && oldFile.response.id) {
          // let [err] = await User.deleteMedia(oldFile.response.id)
          // if (err) return this.errorHandler(err)
        }
      }
      // Automatically activate upload
      if (
        Boolean(newFile) !== Boolean(oldFile) ||
        oldFile.error !== newFile.error
      ) {
        if (this.uploadAuto && !this.$refs.upload.active) {
          this.$refs.upload.active = true;
        }
      }

      if (newFile && !newFile.start && !this.init) {
        this.init = true;
        this.editFile = newFile;
        this.$refs.upload.update(newFile, { error: "", start: true });
        this.editFile.show = true;

        if (
          (newFile.el && newFile.el.files[0].type === "video/mp4") ||
          newFile.type.includes("gif")
        ) {
          this.editFile.show = false;
          this.$refs.upload.active = true;
          newFile.start = true;
        }
      }
    },
    onEditFileShow(file) {
      this.editFile = { ...file, show: true };
      this.$refs.upload.update(file, { error: "edit" });
    },
    getBase64(file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (this.type === 'link') {
          EventBus.$emit("linkImageBase64", reader.result);
        }
        if (this.type === 'contactcard') {
          EventBus.$emit("contactCardImageBase64", reader.result);
        }
        this.reset();
        this.$forceUpdate();
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    },
    deleteUpload() {
      this.$refs.upload.remove(this.files[0]);
      this.files.pop();
      this.editFile.show = false;
      this.init = false;
      this.reset();
      if (this.type === "link") {
        this.toggle = false;
        EventBus.$emit("toggleLinkModal", false);
        this.toggleModal();
      } else if (this.type === "contactcard") {
        this.toggle = false;
        EventBus.$emit("toggleContactCardModal", false);
        this.toggleModal();
      } else {
        EventBus.$emit("toggleUploadModal", true, this.type);
      }
    },
    onEditorFile() {
      if (!this.$refs.upload.features.html5) {
        alert(this.lang[this.appLang].browser_not_supported);
        return;
      }
      let data = { name: this.editFile.name };

      this.editFile.show = false;
      if (this.editFile.cropper) {
        if (this.editFile.cropper.getCroppedCanvas() !== null) {
          let binStr = atob(
            this.editFile.cropper
              .getCroppedCanvas({ maxWidth: this.maxWidth })
              .toDataURL(this.editFile.type)
              .split(",")[1]
          );
          let arr = new Uint8Array(binStr.length);
          for (let i = 0; i < binStr.length; i++) {
            arr[i] = binStr.charCodeAt(i);
          }
          data.file = new File([arr], data.name, { type: this.editFile.type });
          data.size = data.file.size;
          if (this.type === "link" || this.type === "contactcard") {
            this.getBase64(data.file);
          }
        }
      }
      this.loadingUpload = true;
      this.$refs.upload.update(this.editFile.id, data);
      this.editFile.error = "";
      this.editFile.show = false;
      this.upload = false;
      if (this.type !== "link" && this.type !== "contactcard") {
        this.$refs.upload.active = true;
      } else {
        this.toggleModal();
      }
    },
    async deletePictureHandler() {
      await this.deletePicture({ type: 'user-' + this.type});
      this.close();
    }
  }
};
</script>

<style>
  .file-uploads, label {
    cursor: pointer;
  }
  .example-full .btn-group .dropdown-menu {
    display: block;
    visibility: hidden;
    transition: all 0.2s;
  }
  .example-full .btn-group:hover > .dropdown-menu {
    visibility: visible;
  }
  .example-full label.dropdown-item {
    margin-bottom: 0;
  }
  .example-full .btn-group .dropdown-toggle {
    margin-right: 0.6rem;
  }
  .example-full .filename {
    margin-bottom: 0.3rem;
  }
  .example-full .btn-is-option {
    margin-top: 0.25rem;
  }
  .example-full .example-foorer {
    padding: 0.5rem 0;
    border-top: 1px solid #e9ecef;
    border-bottom: 1px solid #e9ecef;
  }
  .example-full .edit-image img {
    max-width: 100%;
  }
  .example-full .edit-image-tool {
    margin-top: 0.6rem;
  }
  .example-full .edit-image-tool .btn-group {
    margin-right: 0.6rem;
  }
  .example-full .footer-status {
    padding-top: 0.4rem;
  }
  .example-full .drop-active {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: fixed;
    z-index: 9999;
    opacity: 0.6;
    text-align: center;
    background: #000;
  }
  .example-full .drop-active h3 {
    margin: -0.5em 0 0;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 40px;
    color: #fff;
    padding: 0;
  }
</style>
