export default {
  state: {
    english: "Engleski",
    spanish: "Španski",
    german: "Nemački",
    korean: "Korejski",
    italian: "Italijanski",
    french: "Francuski",
    serbian: "Srpski",
    turkish: "Turski",
    chinese: "Kineski",
    app_language: "Jezik",
    profile_preview: "Pogledaj profil",
    edit_profile: "Uredi profil",
    login: "Prijavi se",
    referral_reach: "Napiši nam nešto više o sebi",
    referral_apply_placeholder: "Na koji način planiraš da promovišeš Tapni?",
    referral_apply_note:
      "Dodaj što više linkova na svom profilu, <br>                  tako da možemo bolje da te upoznamo.\n                  <br><br>Budi ekskluzivan.\n                  <br>Ne prihvataju se svi.😉",
    referral_apply_now: "Prijavi se odmah",
    referral_apply_thanks:
      "Hvala na prijavljivanju. Uskoro ćemo pregledati tvoju prijavu!",
    connect: "Poveži se",
    with: "sa",
    exchange_contact: "Razmeni kontakt",
    add_details: "Dodaj još",
    show_details: "Prikaži više",
    download_contact: "Preuzmi kontakt",
    stay_connected: "Ostani povezan",
    no_thanks: "Ne, hvala",
    connect_join: "Pridruži se",
    connect_with: "Poveži se sa",
    connect_required_fields: "Potrebno je popuniti jedno od dva polja",
    name: "Ime",
    first_name: "Ime",
    last_name: "Prezime",
    email: "Email adresa",
    phone: "Broj telefona",
    phone_p: "Telefon",
    company: "Kompanija",
    address: "Adresa",
    website: "Website",
    open: "Otvori",
    delete: "Izbriši",
    confirm: "Potvrdi",
    cancel: "Otkaži",
    save: "Sačuvaj",
    code: "Kod",
    note: "Beleška",
    note_p: "Dodaj belešku",
    contact_card_icon: "Podesi tekst ispod ikone za kontakt karticu",
    contact_card_name: "Unesite ime i prezime",
    contact_card_name_p: "Ime kontakt kartice",
    contact_card_email: "Unesite privatnu email adresu",
    contact_card_email_p: "Email adresa kontakt kartice",
    contact_card_phone: "Unesite privatni broj telefona",
    contact_card_phone_p: "Telefon kontakt kartice",
    contact_card_website: "Unesite privatnu internet stranicu",
    contact_card_website_p: "Internet stranica kontakt kartice",
    contact_card_company_name: "Unesite ime kompanije",
    contact_card_company_name_p: "Ime kompanije",
    contact_card_job_title: "Pozicija",
    contact_card_job_title_p: "Unesite naziv pozicije",
    contact_card_company_website: "Website kompanije",
    contact_card_company_website_p: "Unesite website kompanije",
    contact_card_bio: "Unesite opis kontakt kartice",
    contact_card_bio_p: "Opis kontakt kartice",
    contact_card_home_address: "Unesite adresu kontakt kartice",
    contact_card_home_address_p: "Adresa kontakt kartice",
    contact_card_work_address: "Unesite poslovnu adresu",
    contact_card_work_address_p: "Poslovna adresa",
    contact_card_social:
      "Unesi linkove društvenih mreža na svoj Tapni profil <br>kako bi ih dodao/la u kontakt karticu",
    contact_card_social_p: "Unesite linkove društvenih mreža u kontakt karticu",
    link_text_p: "Tekst Linka",
    link_icon: "Podesite tekst ispod ikonice za link",
    direct_pick: "Izaberite direktan link",
    direct_p:
      "Kada dodirnete nečiji telefon, <br> otvoriće se ovaj link umesto Vašeg profila.",
    password_change: "Promeni lozinku",
    password_repeat: "Ponovo unesi lozinku",
    password_reset_t: "Resetovanje lozinke",
    password_current: "Unesi svoju trenutnu lozinku",
    password_current_p: "Trenutna lozinka",
    password_new: "Unesi svoju novu lozinku",
    password_new_p: "Nova lozinka",
    password_new_repeat: "Unesi svoju novu lozinku ponovo",
    password_new_repeat_p: "Ponovo unesi svoju novu lozinku",
    password_update: "Ažuriranje lozinke",
    password_reset:
      "Ako ne možete da se setite svoje lozinke,<br>                  resetujte je ",
    delete_account_permanently:
      "Da li ste sigurni da želite da trajno obrišete Vaš Tapni nalog?",
    by_clicking_here: "klikom ovde",
    password_8_chars: "Lozinka mora imati najmanje 8 karaktera",
    password_least_one_lowercase: "Lozinka mora sadržati najmanje jedno malo slovo",
    password_least_one_uppercase: "Lozinka mora sadržati najmanje jedno veliko slovo",
    password_least_one_number: "Lozinka mora sadržati najmanje jedan broj",
    password_not_match: "Lozinke se ne podudaraju",
    password_change_success: "Uspešno promenjena lozinka",
    or: "ili",
    profile: "Profil",
    account_settings: 'Podešavanje naloga',
    profile_public: "Javni profil",
    profile_public_p: "Tvoj profil je javno dostupan preko linka:",
    profile_active: "Aktivni profil",
    profile_active_p:
      "Ukoliko deaktiviraš svoj profil, niko neće imati pristup njemu i svi povezani linkovi neće više raditi",
    profile_change_password: "Promeni lozinku",
    profile_change_username: "Promeni korisničko ime",
    profile_change_language: "Promeni jezik",
    profile_delete_account: "Obriši nalog",
    profile_confirm_delete_account: "Obriši nalog",
    profile_cancel_delete_account: "Odustani",
    profile_delete_account_success: "Nalog je uspešno obrisan",
    profile_remove_p: "Ako želiš da ukloniš svoj nalog,<br> piši nam na:",
    profile_contact_support:
      "Ukoliko imaš problema sa podešavanjima<br> piši nam na: ",
    profile_email_subject: "Tapni App Podrška",
    profile_remove_subject: "Zahtev za uklanjanje naloga",
    profile_update_success: "Profil je uspešno ažuriran",
    profile_complete: "Popuni svoj profil",
    completed: "Dovršeno",
    uncompleted: "Nedovršeno",
    contact_us: "Kontaktiraj nas",
    contact_us_verify: "kako biste verifikovali Vašu email adresu.",
    verify_email: "Verifikuj email adresu",
    verify_email_subject: "Verifikacija Email adrese",
    verify_email_inbox: "Proveri svoj inbox za:",
    add_first_link: "Dodaj svoj prvi link",
    add_first_link_p:
      "Tapni ovde kako bi dodao/la svoje društvene mreže  <br>                          ili kontakt informacije na svoj Tapni profil.",
    add_picture: "Dodaj svoju sliku profila",
    add_picture_p: "Tapni ovde kako bi dodao svoju sliku profila",
    add_tag: "Aktiviraj svoj Tapni",
    add_tag_p: "Tapni ovde kako bi aktivirao svoju Tapni NFC oznaku",
    completing_profile:
      "Imaš problema prilikom popunjavanja svog profila?<br>                    Slobodno nam piši na:",
    completing_profile_subject: "Popuni profil",
    share: "Podeli",
    share_profile: "Podeli profil",
    share_profile_p:
      "Ostali mogu da skeniraju ovaj QR kod <br> kako bi videli tvoj profil",
    share_profile_change_username:
      "Ukoliko želiš da izmeniš svoj link, <br> <u>klikni ovde</u> za promenu korisničkog imena.",
    share_profile_qr: "Podeli profil",
    share_contactcard_qr: "Kontakt kartica",
    scan_qr_code: "Skeniraj QR kod",
    enter_activation_code: "Unesite aktivacioni kod",
    activate_tag: "Aktiviraj tag",
    order_tapni:
      "Ukoliko još uvek nisi naručio/la Tapni proizvod,<br>          možeš izabrati svoj ovde ",
    activation_success: "Tag je uspesno povezan. <br> Sad je sve spremno!🥳",
    activation_success_message: "Tag je uspesno povezan. Sad je sve spremno!🥳",
    activation_error: "Ovaj tag nije važeći. <br> Pokušaj ponovo.",
    activation_error_message: "Ovo nije važeći tag. <br> Pokušaj ponovo.",
    activation_progress: "Registracija u procesu...",
    activation_invalid_code: "Aktivacioni kod nije važeći.",
    activation_qr_failed: "Skeniranje QR koda nije uspelo.",
    activation_qr_area: "Postaviti QR kod unutar oblasti za skeniranje",
    activation_code: "Aktivacioni kod",
    invalid_code: "Nevažeći kod",
    total_taps: "Ukupan broj deljenja",
    active: "Aktivan",
    tag_active_p:
      "Ako je deaktiviran, Vaš profil neće biti otvoren <br> kada tapnete nečiji telefon.",
    tag_delete_confirm:
      "Sačuvajte aktivacioni kod pre nego što prekinete vezu sa tagom,<br> inače se ne može ponovo aktivirati",
    upload_profile_picture: "Dodaj sliku profila",
    upload_cover_picture: "Dodaj sliku pozadine",
    upload_link_picture: "Dodaj sliku linka",
    select_from_device: "Izaberi sa uređaja",
    select_from_mobile: "Izaberite sliku sa svog mobilnog uređaja",
    select_from_computer:
      "ili je prevucite i pustite ukoliko koristite računar",
    crop: "Iseci",
    crop_upload: "Iseci i dodaj",
    crop_image: "Isecite sliku",
    drag_and_drop: "Prevuci i pusti datoteke ovde",
    upload_success: "Datoteke uspešno otpremljene",
    browser_not_supported: "Tvoj pretraživač ne podržava",
    change_username: "Promeni korisničko ime",
    change_username_success: "Uspešno promenjeno korisničko ime",
    update_username: "Ažuriraj korisničko ime",
    new_username: "Unesi svoje novo korisničko ime",
    username_is_free: "korisničko ime je slobodno",
    username_check: "Molimo Vas da navedete odgovarajuće korisničko ime",
    anonymous: "Anonimno",
    connected: "Povezano",
    exchanged: "Razmenjeno",
    accept: "Prihvati",
    view: "Pogledaj",
    join: "Pridruži se",
    add_account: "Dodaj nalog",
    complete_profile: "Dovršite profil",
    my_profile: "Moj profil",
    my_qr: "Moj QR kod",
    my_connections: "Moja povezivanja",
    contacts: "Kontakti",
    my_contacts: "Moji Kontakti",
    contact_description: "Vaš lični CRM za sve veze koje vam znače",
    my_tags: "Moji proizvodi",
    my_tags_description: "Upravljajte pametnim poslovnim karticama povezanim sa vašim nalogom",
    buy_tapni: "Kupi Tapni",
    referral_program: "Ambasador program",
    device_compatibility: "Kompatibilnost uređaja",
    help: "Pomoć",
    settings: "Podešavanja",
    logout: "Odjavi se",
    version: "Verzija",
    configure: "Konfiguriši",
    unexpected_err:
      "Došlo je do neočekivane greške, kontaktirajte nas na support@tapni.com",
    success_login: "Uspešno ste prijavljeni sa drugim nalogom.",
    password_reset_success:
      "Kod za resetovanje lozinke je poslat na vašu email adresu.",
    password_reseted_success:
      "Uspešno promenjena lozinka. Možete se prijaviti sada.",
    success_remove_link: "Link je uspešno uklonjen",
    success_remove_picture: "Slika je uspešno uklonjena",
    profile_active_message: "Tvoj profil je sada aktivan",
    profile_inactive_message: "Tvoj profil je sada onemogućen",
    profile_public_message: "Tvoj profil je sada javan",
    profile_private_message: "Tvoj profil je sada privatan",
    redirect_enabled: "Link za automatsko preusmeravanje je omogućen",
    redirect_disabled: "Link za automatsko preusmeravanje je onemogućen",
    tag_active: "Tag je sada aktivan. Preostaje samo da tapneš!",
    tag_inactive:
      "Tag je sada onemogućen. Tvoj posetioci će biti preusmereni na tapni.co",
    tag_removed: "Tag je uklonjen. Pišite nam na support@tapni.com",
    connection_accepted: "Zahtev za povezivanje je prihvaćen",
    connection_added: "Nova konekcija uspešno dodata",
    connection_exist: "Konekcija već postoji",
    not_found: "Nije pronađen",
    not_found_p: "Izgleda da ste se izgubili",
    go_back: "Vratite se nazad",
    referral_p1:
      "Zaradite 20% provizije<br> na prodaju ostvarenu preko affiliate marketinga. 💰",
    referral_p2: "Dajte drugima 20% popusta<br> pomoću Vašeg promo koda🎁",
    referral_p3: "To je vin-vin situacija 🤝",
    referral_p4:
      "Ako želite da promenite svoje preporuke,<br>                                  ili ako imate pitanja,<br>                                  pišite nam na",
    referral_p5: "Počnite da zarađujete sada!",
    referral_p66:
      "Podelite svoj personalni affiliate link za prodaju i zaradite!",
    referral_p6:
      "Ako ste bloger, influencer ili entuzijasta društvenih medija, savršeno se uklapate za naš program ambasadora.\n                                Promovišite naš brend i zaradite 20% provizije na kupovine obavljene sa vašim kodom ili ličnim linkom za prodaju Tapni proizvoda.",
    referral_p7:
      "Nakon registracije za program Tapni Ambasador, dobićete svoj link za prodaju, preporuke i kod za popust.\n                                Podelite ga na svojoj veb stranici i društvenim medijima i zaradite dok mi pružamo odlično iskustvo povezivanja.",
    referral_p8: "Napomena o isplati",
    referral_p9:
      "Paypal (širom sveta) i bankovni transferi (EU) <br> Minimalni iznos isplate je 25€.",
    referral_p10: "Zahtevaj isplatu",
    referral_p11: "Isplata zahtevana",
    your_connections: "Tvoja povezivanja",
    connections_p1: "Stupite u kontakt sa ljudima koje ste upoznali",
    connections_p2: "<br><br>Bez konekcija za sada <br> Započni deljenje!💥",
    connections_p3: "Nije pronađena nijedna konekcija",
    connection_success: "Uspešno je poslat zahtev za povezivanje",
    exchange_success: "Uspešno razmenjeni kontakti.",
    create_free_account:
      "Napravite besplatan Tapni nalog i održavajte svoje kontakte ažurnim!",
    connection_edit: "Izmeni konekciju",
    connection_edit_success: "Uspešno izmenjena konekcija",
    connection_delete_success: "Uspešno obrisana konekcija",
    search: "Pretraga",
    devices_p1: "Lista kompatibilnih uređaja",
    devices_p2:
      "Tapni koristi tehnologiju koja je kompatibilna sa većinom novijih iPhone i Android uređaja.",
    devices_p3:
      "Neki Android telefoni možda imaju isključen NFC. Ako vaš Tapni ne radi na Android uređaju, potražite NFC u podešavanjima i proverite da li je uključen.",
    devices_p4: "sa iOS 14 NFC vidžet se nalazi u kontrolnom centru",
    download_p1: "Preuzmi Tapni aplikaciju",
    download_p2: "Dostupno za Apple i Android uređaje",
    user_not_found: "Korisnik nije pronađen\n",
    user_not_found_p:
      "Nismo mogli da pronađemo korisnika u našoj bazi podataka🤷",
    taps: "deljenja",
    private_profile: "Privatan profil",
    private_profile_p:
      "je privatan profil. <br> Profil je vidljiv samo pomoću Tapni tagova",
    profile_bio_p: "Napišite nešto o sebi ili vašem brendu",
    add_to_contacts: "Dodaj u kontakte",
    drag_and_drop_links: "Prevucite i nalepite linkove po željenom redosledu",
    no_links: "Nema linkova na vašem profilu.",
    add_link: "Dodaj link",
    direct_link_p1: "Posetioci će automatski biti preusmereni na:",
    profile_not_active:
      "Vaš profil trenutno nije aktivan <br> i samo ga Vi možete videti kada ste prijavljeni.",
    profile_not_public:
      "Vaš profil trenutno nije javan <br> i mogu ga videti samo oni koji prislone uređaj uz Vaš Tapni.",
    activate_profile_now: "Aktivirajte svoj profil sada",
    publish_profile_now: "Učinite svoj profil sada javnim",
    link_add_p1: "Izaberite tip linka",
    link_add_not_found: "Nisu pronađeni linkovi 🤷",
    link_add_not_found_p2:
      "Ako smo propustili vašu omiljenu platformu, javite nam se i pošaljite nam email (<b>support@tapni.com</b>) ili nam pošaljite DM na društvenim mrežama (<b>@tapni</b>)",
    link_add_not_found_p3: "Daćemo sve od sebe da podržimo sve platforme 😉",
    featured: "Istaknuto",
    social_media: "Društvene mreže",
    contact: "Kontakt",
    finance: "Finansije",
    portfolio: "Portfolio",
    music: "Muzika",
    business: "Biznis",
    personal: "Lično",
    fun: "Zabava",
    ecommerce: "Ecommerce",
    more: "Još",
    email_username: "Email / Korisničko ime",
    username: "Korisničko ime",
    password: "Lozinka",
    sign_in: "Prijavi se",
    sign_in_with: "Prijavi se preko:",
    sign_in_with_new: "Prijavite se sa novim nalogom",
    sign_up_with: "Registruj se preko:",
    select_sign_method: "Izaberite metod prijave",
    verify_account: "Verifikuj nalog",
    terms_by_signing_in: "Prijavljivanjem prihvatate naše",
    terms_by_registering: "Registracijom prihvatate naše",
    terms_by_submitting:
      "Podnošenjem ovog obrasca slažete se sa našim uslovima koriscenja",
    terms_of_service: "Uslovima korišćenja",
    and: "i",
    refresh: "Osveži",
    privacy_policy: "Pravilima o privatnosti",
    create_account: "Kreiraj nalog",
    reset_password: "Resetuj šifru",
    reset_password_p1:
      "Ako niste uspeli da resetujete lozinku,<br> slobodno nas kontaktirajte na",
    reset_password_p2:
      "Ako niste uspeli da resetujete lozinku,<br> slobodno nas kontaktirajte na",
    create_new_account: "Napravite novi nalog",
    maintenance_p1: "Vraćamo se uskoro",
    maintenance_p2: "u međuvremenu pregledajte Tapni proizvode",
    maintenance_p3:
      "Tapni ne radi zbog planiranog održavanja <br> i očekuje se da će ponovo biti na mreži za nekoliko minuta.",
    offline_p1: "Nema internet veze",
    offline_p2: "Proverite svoju internet konekciju, a zatim osvežite stranicu",
    offline_p3_1: "Dok ste van mreže,",
    offline_p3_2: "možete deliti svoju kontakt karticu pomoću ovog QR koda",
    products_p1:
      "Impresionirajte sve koje sretnete 😎 <br> Nabavite svoj sada 👇",
    cards: "Kartice",
    stickers: "Stikeri",
    keychains: "Privesci za ključeve",
    explore_collection: "Istražite kolekciju",
    register: "Registruj se",
    register_success: "Skoro smo gotovi. Molimo Vas da potvrdite email.",
    register_success_modal: "Uspešno ste prijavljeni sa drugim nalogom.",
    check_your_inbox: "Proverite svoj inbox",
    tapni_tags: "Tapni tags",
    tapni_tags_p1: "Konfigurišite tagove povezane sa Vašim profilom",
    tapni_tags_p2:
      "Nema tagova povezanih sa vašim profilom. <br> Povežite se sada!",
    verify_account_p1:
      "Ako niste primili kod, proverite svoj <br> spam folder ili nas kontaktirajte na",
    verify_account_success:
      "Nalog je uspešno verifikovan. Molimo vas da promenite svoju lozinku.",
    verify_account_success2: "Nalog je uspešno verifikovan. Sve je spremno! 🥳",
    welcome: "Dobrodošli",
    welcome_p1: "u budućnost vizitkarti",
    how_it_works: "Kako funkcioniše?",
    already_registered: "Već registrovano",
    error: "Greška",
    success: "Uspešno",
    copied: "Kopirano u međuspremniku",
    connect_validate: "Potrebno je popuniti jedno od dva kontakt polja",
    connect_validate_name: "Molimo Vas da unesete svoje ime",
    connect_validate_email: "Molimo Vas, unesite validnu e-mail adresu",
    link_validate: "Nevažeći podaci link",
    link_text_contact_card: "Kontakt kartica",
    link_text_custom_link: "Custom link",
    link_text_menu: "Meni",
    link_text_phone: "Telefon",
    save_to_apple_wallet: "Dodaj u Apple Wallet",
    save_to_google_wallet: "Dodaj u Google Wallet",
    card: "Kartica",
    sticker: "Stiker",
    keychain: "Privezak",
    band: "Narukvica",
    case: "Maska",
    standee: "Stend",
    decoded: "Decoded",
    wristbanditz: "Wristbanditz",
    update_p1: "Otkrivena je zastarela verzija aplikacije",
    update_p2: "Ažuriraj",
    update_p3: "Ažuriraj aplikaciju na najnoviju verziju",

    company_domain: "Domain tvoje kompanije",
    search_company_domain: "Pretražite domain vaše kompanije",
    enter_company_email: "Unesite poslovnu e-mail adresu",
    company_not_found: "Kompanija nije pronađena",
    ik_company_domain: "Znam domain kompanije",
    idk_company_domain: "Ne znam domain kompanije",
    continue: "Nastavi",
    forbidden_rule:
      "Ova opcija je onemogućena, obratite se administratoru kompanije.",
    companyName: "Ime Kompanije",
    createMarkerPlaceholder: "Kreirajte ili izaberite marker",
    clickToCreateMarker: "Kliknite ovde da biste kreirali marker",
    pleaseInviteUser:
      "Molimo pozovite korisnika da se pridruzi Tapniju da bi imali sinhronizovane podatke.",
    connectionSynchronized: "Podaci o vezi se automatski sinhronizuju.",
    viewProfile: "Pogledaj Profil",
    createConnectionHeader: "Dodaj novu konekciju",
    createConnectionButton: "Dodaj",
    createConnectionSuccess: "Konekcija je dodata.",
    allow_company_management: "Dozvolite kompanijsko upravljanje",
    company_join_request:
      "zahteva da upravlja Vašim profilom i da ga poveže sa svojim Enterprise nalogom.",
    account_ownership_transfer:
      "Ukoliko prihvatite zahtev, vlasništvo nad Vašim nalogom biće preneto na kompanijU. Kompanija će moći da menja Vaš profil, izmeni, doda i vidi  veze, izmeni Vašu lozinku, izbriše vaš nalog i još mnogo toga. Obavezno proverite sa Vašom kompanijom pre nego što prihvatite zahtev.",
    request_expired:
      "Zahtev je istekao, zatražite od kompanije da pošalje novi zahtev.",
    request_expiration_desc:
      "Radi dodatne sigurnosti, zahtev biće validan samo 14 dana nakon što je poslat.",
    invitation_link_not_valid: "Link za poziv nije validan!",
    invitation_link_not_valid2:
      "Ponovo kliknite na dugme u e-mailu sa pozivnicom ili kopirajte link.",
    accept_request: "Prihvati zahtev",
    close: "Zatvori",
    request_accepted: "Zahtev je uspešno prihvaćen",
    companyEmail: "Kompanijski Email",
    companyPhone: "Kompanijski Telefon",
    addBusinessInformation: "Dodaj kompanijske podatke",
    resetFilter: "Resetuj",
    selectMarkers: "Izaberi marker",
    filterConnectionsHeader: "Filtriraj Konekcije",
    selectConnectionType: "Izaberi tip konekcije",
    all: "Sve",
    direct: "Direktne",
    form: "Forme",
    manually: "Manuelne",
    scan: "Skeniranja",
    sortOptions: "Opcije Sortiranja",
    date: "Datum Kreiranja",
    fullName: "Ime i Prezime",
    descending: "Silazno",
    ascending: "Uzlazno",
    selectDateRange: "Izaberi opseg datuma",
    follow_up_reminder: "Follow-up podsetnik",
    now: "Sad",
    one_hour: "1 Sat",
    one_day: "1 Dan",
    three_days: "3 Dana",
    one_week: "1 Nedelja",
    two_weeks: "2 Nedelje",
    one_month: "1 Mesec",
    custom: "Izaberite vreme",
    set_reminder: "Podesi podsetnik",
    select_date_time: "Izaberite datum i vreme",
    enter_reminder_text: "Unesite tekst podsetnika",
    reminder_created: "Podsetnik je uspešno kreiran",
    connectionNameLabel: "Unesite ime konekcije",
    connectionEmailLabel: "Unesite privatnu email adresu",
    privatePhoneLabel: "Unesi privatni broj telefona",
    companyNameLabel: "Unesi ime kompanije",
    businessEmail: "Poslovna email adresa",
    businessEmailLabel: "Unesite poslovnu email adresu",
    businessPhone: "Poslovni broj telefona",
    businessPhoneLabel: "Unesite poslovni broj telefona",
    businessFax: "Poslovni faks",
    businessFaxLabel: "Unesite broj poslovnog faksa",
    noteLabel: "Dodajte belešku",
    markersLabel: "Dodajte markere konekcije",
    showScan: "Prikazi sliku",
    hideScan: "Sakrij sliku",
    create_connection: "Dodaj konekcije",
    create_manually: "Dodaj ručno",
    create_using_scanner: "Dodajte pomoću skenera kartica",
    companyTitle: "Titula",
    companyTitleLabel: "Dodajte tituli",
    allConnectionsLabel: "Sve konekcije",
    directConnectionsLabel:
      "Direktne konekcije sa korisnicima Tapni aplikacije",
    formConnectionsLabel: "Konekcije ostvarne putem forme za povezivanje",
    manualConnectionsLabel: "Manualno dodate konekcije",
    scanConnectionsLabel: "Konekcije kreirane skenerom za kartice",
    analyticsHeader: "Analitika",
    analyticsProfileViews: "Broj Pregleda profila",
    analyticsLinkClicks: "Broj Klikova na Link",
    analyticsClickTroughRatio: "Stopa učestalosti klika",
    analyticsTotalConnections: "Ukupno Konekcija",
    analyticsTopLinks: "Najpopularniji Linkovi",
    analyticsProfileVisits: "Broj Poseta Profila",
    analyticsLinkVisits: "Broj Posecenih Linkova",
    analyticsNewConnections: "Nove Konekcije",
    analyticsTopCountries: "Top Zemlje",
    analyticsTopReferers: "Top Referali",

    tapni_for_business: "Tapni za kompanije",
    supercharge_your_business: "Unapredite svoj biznis sa vodećom platformom za digitalne poslovne kartice, namenjenu biznisima i timovima.",

    for_teams_and_businesses: "Za Timove i Kompanije",
    number_one_digital_business_card_platform: "Broj 1 Platforma za Digitalne Vizit Kartice",
    manage_teams_collect_leads_automate_workflows: "Upravljajte Timovima, Prikupljajte kontakt podatke i Automatizujte Procese",
    read_more: "Pročitajte Više",
    navigate_to_business_tapni_com: "1) Posetite business.tapni.com",
    start_qr_scanner_via_button_below: "2) Pokrenite QR Skener preko dugmeta ispod",
    scan_the_qr_code_from_the_screen: "3) Skenirajte QR kod sa ekrana",
    enjoy_using_our_platform: "4) Uživajte koristeći našu platformu",
    start_qr_scanner: "Pokreni QR Skener",

    change_language: "Promeni Jezik",
    terms_and_privacy: "Uslovi & Privatnost",

    lead_capture_mode: "Forma za Prikupljanje Kontakt Podataka",
    lead_capture_mode_description: "Formular za prikupljanje potencijalnih klijenata će biti prikazan na vašem profilu",
    public_profile_mode: "Mod Javnog Profila",
    public_profile_mode_description: "Učinite vaš profil",
    public_profile_mode_available: "javno dostupnim",

    link_a_device: "Poveži uređaj",

    streetName: "Naziv ulice",
    streetNr: "Broj",
    plz: "Poštanski broj",
    city: "Grad",
    country: "Država",

    choose_template: "Izaberi templejt",
    contactcard: "Kontaktna kartica",
    customlink: "Prilagođeni link",
    onboarding: "Onboarding",
    tag_text_p: "Naziv taga",
    tag_text_p2: "Postavite određeno ime za proizvod",
    connect_validate_consent: "Molimo prihvatite saglasnost o privatnosti",
    links: "Linkovi",
    manage: "Upravljaj",
    buy_domain: "Kupi domen",
    buy_domain_p: "Prilagodite svoj Online Identitet sa Prilagođenim Domenom.",

    enable_lead_button: "Aktiviraj dugme 'Razmena Kontakta'",
    enable_lead_button_p: "Dugme za razmenu kontakata biće prikazano na vašem profilu",
    form_auto: 'Otvori formular prilikom posete profila',
    form_auto_p: 'Formular za razmenu kontakata će se automatski otvoriti kada neko otvori vaš profil',
    form_required: 'Obavezno popunjavanje formulara',
    form_required_p: 'Učinite obaveznim popunjavanje formulara pre nego što se zatvori.',
    enter_code_manually: 'Unesite kod ručno',
    activate_via_nfc: 'Aktivirajte preko NFC-a',
    qr_code: 'QR Kod',
    nfc: 'NFC',
    nfc_not_available: 'NFC nije dostupan na ovom uređaju',
    nfc_not_enabled: 'NFC nije uključen. Molimo uključite NFC u podešavanjima uređaja.',
    nfc_no_link_found: 'NFC link nije pronađen. Molimo kontaktirajte podršku.',
    activation_second_step_needed: 'Potreban je još jedan korak. <br> Skenirajte proizvod sada preko',

    scanner_card_p: 'Usmerite kameru na karticu <br> i pritisnite dugme.',
    scanner_qr_p: 'Usmerite kameru na QR <br> za automatsko prepoznavanje.',
    scanner_badge_p: 'Usmerite kameru na bedž <br> i pritisnite dugme.',
    paper_card: 'Papirna kartica',
    event_badge: 'Bedž'
  },
};
