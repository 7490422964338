import { createRouter, createWebHistory } from 'vue-router'
import routes from '../routes'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      // Commented out because of the iframe scrolling in the dashboard
      // document.getElementById('app').scrollIntoView();
      return { top: 0, left: 0 };
    }
  }
})

/*
const originalPush = router.prototype.push;
router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};
 */

export default router
