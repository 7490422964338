<template>
    <form @submit.prevent="submit" class="menu-wrapper menu-light menu-modal center-text activate-page" :class="{'active-menu': toggle}" style="height: auto; margin-top: -172.5px;">
      <a @click="close" class="color-black pull-right pointer" style="margin-top: 5px; position: absolute; right: 5px;">
	      <i class="font-17 fa color-black">
		      <img src="https://cdn.tapni.co/icons/down-arrow.png" class="fa responsive-image" style="width: 45%;"/>
	      </i>
      </a>
      <img :src="'https://cdn.tapni.co/icons/privacy.png'" class="password-img small-bottom" :alt="lang[appLang].password_change"/>
      <input type="password" v-model="currentPassword" :placeholder="lang[appLang].password_current_p"
           class="edit-input h-40 lh-40" required minlength="8" style="width: 100%"/>
      <p class="small-text small-top-padding small-bottom">{{lang[appLang].password_current}}</p>

      <input type="password" v-model="password" :placeholder="lang[appLang].password_new_p"
           class="bg-white edit-input h-40 lh-40" required minlength="8" style="width: 100%"/>
      <p class="small-text small-top-padding small-bottom">{{lang[appLang].password_new}}</p>

      <input type="password" v-model="passwordRepeat" :placeholder="lang[appLang].password_new_repeat_p"
           class="edit-input h-40 lh-40" required minlength="8" style="width: 100%"/>
      <p class="small-text small-top-padding small-bottom">{{lang[appLang].password_new_repeat}}</p>

      <button type="submit" class="button white-button  button-full" style="margin: 0 auto; margin-top: 13px;">
          {{lang[appLang].password_update}}
      </button>
      <p class="center-text font-13 half-top half-bottom">
        <span v-html="lang[appLang].password_reset"></span>
        <router-link to="/reset" exact class="color-black" @click.native="forceClose">{{lang[appLang].by_clicking_here}}</router-link>
      </p>
    </form>
</template>

<script>
import {EventBus} from '../../store/event-bus'
import {mapState, mapActions} from 'vuex'
export default {
  name: 'Password',
  data () {
    return {
      password: '',
      passwordRepeat: '',
      currentPassword: '',
      toggle: false
    }
  },
  mounted () {
    EventBus.$on('togglePasswordModal', this.toggleModal)
    EventBus.$on('closeModal', () => { this.toggle = false })
  },
  beforeDestroy() {
    EventBus['_events']['togglePasswordModal'].pop()
  },
  methods: {
    ...mapActions(['newPassword']),
    forceClose () {
      EventBus.$emit('closeModal')
    },
    close () {
      this.toggleModal()
      EventBus.$emit('toggleAccountSettingsModal', false)
    },
    async submit () {
      if (this.password.length < 8) return this.errorSnack(this.lang[this.appLang].password_8_chars)
      if (this.password !== this.passwordRepeat) return this.errorSnack(this.lang[this.appLang].password_not_match)

      const result = await this.newPassword({
        currentPassword: this.currentPassword,
        password: this.password,
        passwordRepeat: this.passwordRepeat
      })
      if (result === true) {
        this.successSnack(this.lang[this.appLang].password_change_success)
        EventBus.$emit('closeModal')
      }
    },
    toggleModal () {
      this.toggle = !this.toggle
    }
  }
}
</script>

<style scoped>
  .password-img {
    margin: 0 auto;
    margin-top: 2px;
    width: 65px;
  }
  input {
	margin: 0 auto;
	height: 20px;
  }
</style>
