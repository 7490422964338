export default {
	state: {
		english: "英文",
		spanish: "西班牙文",
		german: "德文",
		korean: "韩文",
		italian: "意大利文",
		french: "法文",
		serbian: "塞尔维亚文",
		turkish: "土耳其文",
		chinese: "簡体中文",
		app_language: "语言",
		profile_preview: "个人资料预览",
		edit_profile: "编辑个人资料",
		login: "登录",
		referral_reach: "向我们提供有关您和您的推广范围的更多信息。",
		referral_apply_placeholder: "您打算如何推广Tapni？",
		referral_apply_note: "在您的帐户中添加尽可能多的链接，<br>\n以便我们了解您是谁。<br><br>独一无二。<br>并非每个人都会被接受。😉",
		referral_apply_now: "立即申请",
		referral_apply_thanks: "感谢申请。我们将尽快审查您的申请！",
		connect: "连接",
		with: "与",
		exchange_contact: "交换联系方式",
		add_details: "添加更多信息",
		show_details: "显示更多信息",
		download_contact: "下载联系方式",
		stay_connected: "与...保持联系",
		no_thanks: "不，谢谢",
		connect_join: "加入Tapni",
		connect_with: "与...交换联系方式",
		connect_required_fields: "必须提供两个联系方式之一",
		name: "姓名",
		first_name: "名字",
		last_name: "姓氏",
		email: "电子邮件",
		phone: "电话号码",
		phone_p: "电话",
		company: "公司",
		address: "地址",
		website: "网站",
		open: "打开",
		delete: "删除",
		confirm: "确认",
		cancel: "取消",
		save: "保存",
		code: "代码",
		note: "备注",
		note_p: "添加备注",
		contact_card_icon: "在联系人卡片图标下设置文本",
		contact_card_name: "输入名字和姓氏",
		contact_card_name_p: "联系人卡片姓名",
		contact_card_email: "输入个人电子邮件地址",
		contact_card_email_p: "联系人卡片电子邮件",
		contact_card_phone: "输入个人电话号码",
		contact_card_phone_p: "联系人卡片电话",
		contact_card_website: "输入个人网站",
		contact_card_website_p: "联系人卡片网站",
		contact_card_company_name: "输入公司名称",
		contact_card_company_name_p: "联系人卡片公司名称",
		contact_card_job_title: "职位",
		contact_card_job_title_p: "输入职位",
		contact_card_company_website: "公司网站",
		contact_card_company_website_p: "输入公司网站",
		contact_card_bio: "输入联系人卡片的个人简介",
		contact_card_bio_p: "输入联系人卡片的个人简介",
		contact_card_home_address: "输入家庭地址",
		contact_card_home_address_p: "联系人卡片家庭地址",
		contact_card_work_address: "输入工作地址",
		contact_card_work_address_p: "联系人卡片工作地址",
		contact_card_social: "选择与您的Tapni个人资料相关的社交媒体链接<br>以便将其添加到联系人卡片中",
		contact_card_social_p: "选择联系人卡片的社交媒体链接",
		link_text_p: "链接文本",
		link_icon: "在链接图标下设置文本",
		direct_pick: "选择直接链接",
		direct_p: "当您点击某人的电话时，将打开此链接，而不是您的完整个人资料。",
		password_change: "更改密码",
		password_repeat: "重复密码",
		password_reset_t: "重置密码",
		password_current: "输入当前密码",
		password_current_p: "当前密码",
		password_new: "输入新密码",
		password_new_p: "新密码",
		password_new_repeat: "再次输入新密码",
		password_new_repeat_p: "再次输入新密码",
		password_update: "更新密码",
		password_reset: "如果您无法记住当前密码，您可以重置密码",
		delete_account_permanently: "您确定要永久删除Tapni帐户吗？",
		by_clicking_here: "点击此处",
		password_8_chars: "密码必须至少包含8个字符",
		password_least_one_lowercase: "密码必须至少包含一个小写字母",
		password_least_one_uppercase: "密码必须至少包含一个大写字母",
		password_least_one_number: "密码必须包含至少一个数字",
		password_not_match: "密码不匹配",
		password_change_success: "密码已成功更改",
		or: "或",
		profile: "个人资料",
		account_settings: "帐户设置",
		profile_public: "公开个人资料",
		profile_public_p: "通过以下方式公开您的个人资料：",
		profile_active: "激活个人资料",
		profile_active_p: "如果停用个人资料，将无法再访问该资料，并且相关的标签将不再起作用",
		profile_change_password: "更改密码",
		profile_change_username: "更改用户名",
		profile_change_language: "更改语言",
		profile_delete_account: "删除帐户",
		profile_confirm_delete_account: "删除帐户",
		profile_cancel_delete_account: "取消",
		profile_delete_account_success: "帐户已成功删除",
		profile_remove_p: "如果您想删除帐户，请发送邮件至",
		profile_contact_support: "如果您对设置有问题，请发送邮件至",
		profile_email_subject: "Tapni应用支持",
		profile_remove_subject: "帐户删除请求",
		profile_update_success: "个人资料已成功更新",
		profile_complete: "完成个人资料",
		completed: "已完成",
		uncompleted: "未完成",
		contact_us: "联系我们",
		contact_us_verify: "以验证您的电子邮件地址。",
		verify_email: "验证电子邮件地址",
		verify_email_subject: "电子邮件验证",
		verify_email_inbox: "请在收件箱中查看",
		add_first_link: "添加您的第一个链接",
		add_first_link_p: "点击此处将您的社交媒体渠道<br>或联系信息添加到您的Tapni个人资料。",
		add_picture: "添加个人头像",
		add_picture_p: "点击此处上传您的个人头像。",
		add_tag: "激活您的Tapni",
		add_tag_p: "点击此处激活您的Tapni NFC标签。",
		completing_profile: "填写个人资料时有问题？<br>请随时写信给我们，联系方式为",
		completing_profile_subject: "完善个人资料",
		share: "分享",
		share_profile: "分享个人资料",
		share_profile_p: "他人可以扫描此二维码<br>以查看您的个人资料",
		share_profile_change_username: "如果您想更改链接，<br><u>点击此处</u>更改用户名。",
		share_profile_qr: "分享个人资料",
		share_contactcard_qr: "联系卡",
		scan_qr_code: "扫描二维码",
		enter_activation_code: "输入激活码",
		activate_tag: "激活标签",
		order_tapni: "如果您还没有订购Tapni，请选择您的",
		activation_success: "成功连接标签。一切就绪！🥳",
		activation_success_message: "成功连接标签。一切就绪！🥳",
		activation_error: "这不是一个有效的标签。请重试。",
		activation_error_message: "这不是一个有效的标签。请重试。",
		activation_progress: "标签注册进行中...",
		activation_invalid_code: "无效的激活码。",
		activation_qr_failed: "扫描二维码失败",
		activation_qr_area: "将二维码放置在扫描区域内",
		activation_code: "激活码",
		invalid_code: "无效的代码",
		total_taps: "总点击次数",
		active: "激活",
		tag_active_p: "如果停用，当您点击某人的电话时，将不会打开您的个人资料。",
		tag_delete_confirm: "在取消标签链接之前，请保存激活码，否则将无法重新激活。",
		upload_profile_picture: "上传个人头像",
		upload_cover_picture: "上传封面图片",
		upload_link_picture: "上传链接图片",
		select_from_device: "从设备中选择",
		select_from_mobile: "从您的移动设备选择图片",
		select_from_computer: "或者，如果您使用的是计算机，可以拖放图片",
		crop: "裁剪",
		crop_upload: "裁剪并上传",
		crop_image: "裁剪图片",
		drag_and_drop: "拖放文件到这里",
		upload_success: "媒体上传成功",
		browser_not_supported: "您的浏览器不支持",
		change_username: "更改用户名",
		change_username_success: "用户名更改成功",
		update_username: "更新用户名",
		new_username: "请输入新用户名",
		username_is_free: "用户名可用",
		username_check: "请提供正确的用户名",
		anonymous: "匿名",
		connected: "已连接",
		exchanged: "已交换",
		accept: "接受",
		view: "查看",
		join: "加入",
		add_account: "添加账号",
		complete_profile: "完善个人资料",
		my_profile: "我的个人资料",
		my_qr: "我的二维码",
		my_connections: "我的联系人",
		contacts: "联系人",
		my_contacts: "我的联系人",
		contact_description: "您关心的所有联系的个人CRM",
		my_tags: "我的产品",
		my_tags_description: "管理与您账户连接的智能商务卡",
		buy_tapni: "购买 Tapni",
		referral_program: "推荐计划",
		device_compatibility: "设备兼容性",
		help: "帮助",
		settings: "设置",
		logout: "退出",
		version: "版本",
		configure: "配置",
		unexpected_err: "出现意外错误，请联系我们：support@tapni.com",
		success_login: "成功使用其他帐户登录。",
		password_reset_success: "密码重置代码已发送到您的电子邮件。",
		password_reseted_success: "密码成功更改。您现在可以登录了。",
		success_remove_link: "链接已成功移除",
		success_remove_picture: "图片已成功移除",
		profile_active_message: "您的个人资料现已激活",
		profile_inactive_message: "您的个人资料现已停用",
		profile_public_message: "您的个人资料现已公开",
		profile_private_message: "您的个人资料现已设为私密",
		redirect_enabled: "自动重定向链接已启用",
		redirect_disabled: "自动重定向链接已禁用",
		tag_active: "标签现已激活。开始使用 Tapni 吧！",
		tag_inactive: "标签现已停用。您的访客将会进入 tapni.co",
		tag_removed: "标签已成功移除",
		connection_accepted: "连接请求已接受",
		connection_added: "新的连接已成功添加",
		connection_exist: "您已与此用户建立连接",
		not_found: "未找到",
		not_found_p: "看起来你迷路了",
		go_back: "返回",
		referral_p1: "在推荐销售中赚取20%的佣金💰",
		referral_p2: "通过您的折扣代码给别人打20%的折扣🎁",
		referral_p3: "这是一个双赢的局面🤝",
		referral_p4: "如果您想更改您的推荐人，<br>\n\t\t\t\t 或者如果您有任何问题，<br>\n\t\t\t\t 请写信给我们",
		referral_p5: "立即开始赚钱！",
		referral_p66: "分享您的推荐链接并赚钱！",
		referral_p6: "如果您是博主、影响者或社交媒体爱好者，那么我们的推荐计划非常适合您。\n\t\t\t\t在使用您的折扣代码或推荐链接购买时，推广我们的品牌并获得20%的佣金。",
		referral_p7: "在注册Tapni推荐计划后，您将获得个人推荐链接和折扣代码。\n\t\t\t\t在您的网站和社交媒体上分享它们，在我们提供出色的连接体验的同时赚钱。",
		referral_p8: "支付注意事项",
		referral_p9: "Paypal（全球范围）和银行转账（欧盟）<br>最低支付金额为25€。",
		referral_p10: "申请支付",
		referral_p11: "已申请支付",
		your_connections: "您的联系人",
		connections_p1: "与您认识的人保持联系",
		connections_p2: "<br><br>目前还没有联系人。开始连接吧💥",
		connections_p3: "未找到联系人。",
		connection_success: "成功发送连接请求",
		exchange_success: "成功交换联系方式。",
		create_free_account: "创建免费的Tapni账户，保持联系人的最新信息！",
		connection_edit: "管理联系人",
		connection_edit_success: "成功更新联系人",
		connection_delete_success: "成功删除联系人",
		search: "搜索",
		devices_p1: "设备兼容性列表",
		devices_p2: "Tapni使用的技术与大多数较新的iPhone和Android设备兼容。",
		devices_p3: "一些Android手机可能已关闭NFC功能。如果您的Tapni在Android设备上无法工作，请在设置中搜索NFC并确保其已打开。",
		devices_p4: "在iOS 14控制中心的NFC小部件中",
		download_p1: "下载Tapni应用",
		download_p2: "适用于苹果和安卓设备",
		user_not_found: "未找到用户",
		user_not_found_p: "在我们的数据库中找不到该用户🤷",
		taps: "次点击",
		private_profile: "私人资料",
		private_profile_p: "是一个私人资料。<br>只有使用Tapni标签才能看到个人资料",
		profile_bio_p: "写点关于您或您的品牌的内容",
		add_to_contacts: "添加到联系人",
		drag_and_drop_links: "拖放链接以按所需顺序排列",
		no_links: "您的个人资料中没有链接。",
		add_link: "添加链接",
		direct_link_p1: "访客将自动重定向到：",
		profile_not_active: "您的个人资料当前未激活<br>只有在登录时才能看到。",
		profile_not_public: "您的个人资料当前未公开<br>只有点击您的人才能看到。",
		activate_profile_now: "立即激活您的个人资料",
		publish_profile_now: "立即公开您的个人资料",
		link_add_p1: "选择链接类型",
		link_add_not_found: "未找到链接🤷",
		link_add_not_found_p2: "如果我们遗漏了您最喜欢的平台，请给我们发送电子邮件（<b>support@tapni.com</b>）或在社交媒体上私信我们（<b>@tapni</b>）",
		link_add_not_found_p3: "我们将尽力支持所有平台😉",
		featured: "特色",
		social_media: "社交媒体",
		contact: "联系方式",
		finance: "金融",
		portfolio: "作品集",
		music: "音乐",
		business: "商务",
		personal: "个人的",
		fun: "娱乐",
		ecommerce: "电子商务",
		more: "更多",
		email_username: "电子邮件/用户名",
		username: "用户名",
		password: "密码",
		sign_in: "登录",
		sign_in_with: "使用以下方式登录",
		sign_in_with_new: "使用新账户登录",
		sign_up_with: "使用以下方式注册",
		select_sign_method: "选择登录方式",
		verify_account: "验证账户",
		terms_by_signing_in: "通过登录，即表示您同意我们的",
		terms_by_registering: "通过注册，即表示您同意我们的",
		terms_by_submitting: "提交此表单即表示您同意我们的服务条款",
		terms_of_service: "服务条款",
		and: "和",
		refresh: "刷新",
		privacy_policy: "隐私政策",
		create_account: "创建账户",
		reset_password: "重置密码",
		reset_password_p1: "如果您无法重置密码，<br>请随时联系我们 ",
		reset_password_p2: "如果您无法重置密码，<br>请随时联系我们",
		create_new_account: "创建新账户",
		maintenance_p1: "我们即将回来",
		maintenance_p2: "在此期间，请浏览Tapni的收藏品",
		maintenance_p3: "Tapni正在进行计划中的维护<br>预计将在几分钟内恢复在线状态。",
		offline_p1: "没有网络连接",
		offline_p2: "检查您的连接，然后刷新页面",
		offline_p3_1: "当您离线时，",
		offline_p3_2: "您可以使用此二维码分享您的联系卡",
		products_p1: "给您遇见的每个人留下深刻印象 😎 <br> 立即获取您的产品 👇",
		cards: "名片",
		stickers: "贴纸",
		keychains: "钥匙扣",
		explore_collection: "探索收藏",
		register: "注册",
		register_success: "您就快完成了。请确认您的电子邮件。",
		register_success_modal: "成功用另一个账户登录。",
		check_your_inbox: "查看您的收件箱",
		tapni_tags: "Tapni标签",
		tapni_tags_p1: "配置与您的个人资料相关联的标签",
		tapni_tags_p2: "您的个人资料中没有连接的标签。<br>立即连接一个吧！",
		verify_account_p1: "如果您未收到验证码，请检查您的<br>垃圾邮件文件夹或与我们联系",
		verify_account_success: "账户验证成功。请立即更改您的密码。",
		verify_account_success2: "账户验证成功。您已准备就绪！🥳",
		welcome: "欢迎",
		welcome_p1: "来到名片的未来",
		how_it_works: "工作原理",
		already_registered: "已注册",
		error: "错误",
		success: "成功",
		copied: "已复制到剪贴板",
		connect_validate: "必须提供两个联系方式字段中的一个",
		connect_validate_name: "请输入您的姓名",
		connect_validate_email: "请输入有效的电子邮件地址",
		link_validate: "无效的链接数据。",
		link_text_contact_card: "联系卡",
		link_text_custom_link: "自定义链接",
		link_text_menu: "菜单",
		link_text_phone: "电话",
		save_to_apple_wallet: "保存到Apple Wallet",
		save_to_google_wallet: "添加到Google Wallet",
		card: "名片",
		sticker: "贴纸",
		keychain: "钥匙扣",
		band: "手环",
		case: "盒子",
		standee: "立牌",
		decoded: "解码",
		wristbanditz: "Wristbanditz",
		update_p1: "检测到过时的应用版本",
		update_p2: "更新",
		update_p3: "请更新至最新版本的应用",
		company_domain: "您的公司域名",
		search_company_domain: "搜索您的公司域名",
		enter_company_email: "输入公司电子邮件",
		company_not_found: "未找到公司",
		ik_company_domain: "我知道公司域名",
		idk_company_domain: "我不知道公司域名",
		continue: "继续",
		forbidden_rule: "此选项已禁用，请联系您的公司管理员。",
		companyName: "公司名称",
		createMarkerPlaceholder: "创建或选择标记",
		clickToCreateMarker: "点击此处创建标记",
		pleaseInviteUser: "请邀请用户加入Tapni，以便与您同步数据。",
		connectionSynchronized: "连接数据会自动同步。",
		viewProfile: "查看个人资料",
		createConnectionHeader: "创建新连接",
		createConnectionButton: "创建连接",
		createConnectionSuccess: "连接已创建。",
		allow_company_management: "允许公司管理",
		company_join_request: "正在请求管理您的个人资料，并将其关联到其企业账户。",
		account_ownership_transfer: "如果您接受请求，您的账户所有权将转移给公司。公司将能够修改您的个人资料，编辑和添加链接，查看您的连接，修改您的密码，删除您的账户等。在接受请求之前，请与您的公司核实。",
		request_expired: "请求已过期，请向您的公司发送新的请求。",
		request_expiration_desc: "为增加安全性，请求在发送后的14天内有效。",
		invitation_link_not_valid: "邀请链接无效！",
		invitation_link_not_valid2: "请再次点击邀请电子邮件中的按钮或复制邀请链接。",
		accept_request: "接受请求",
		close: "关闭",
		request_accepted: "请求已成功接受",
		companyEmail: "公司电子邮件",
		companyPhone: "公司电话",
		addBusinessInformation: "添加商业信息",
		resetFilter: "重置",
		selectMarkers: "选择标记",
		filterConnectionsHeader: "筛选连接",
		selectConnectionType: "选择连接类型",
		all: "全部",
		direct: "直接",
		form: "表单",
		manually: "手动",
		scan: "扫描",
		sortOptions: "排序选项",
		date: "创建日期",
		fullName: "全名",
		descending: "降序",
		ascending: "升序",
		selectDateRange: "选择日期范围",
		follow_up_reminder: "跟进提醒",
		now: "现在",
		one_hour: "1小时",
		one_day: "1天",
		three_days: "3天",
		one_week: "1周",
		two_weeks: "2周",
		one_month: "1个月",
		custom: "自定义时间",
		set_reminder: "设置提醒",
		select_date_time: "选择日期和时间",
		enter_reminder_text: "输入提醒内容",
		reminder_created: "提醒创建成功",
		connectionNameLabel: "输入连接名称",
		connectionEmailLabel: "输入私人电子邮件地址",
		privatePhoneLabel: "输入私人电话号码",
		companyNameLabel: "输入公司名称",
		businessEmail: "商业电子邮件地址",
		businessEmailLabel: "输入商业电子邮件地址",
		businessPhone: "商业电话号码",
		businessPhoneLabel: "输入商业电话号码",
		businessFax: "商务传真",
		businessFaxLabel: "输入商务传真号码",
		noteLabel: "输入备注",
		markersLabel: "添加连接标记",
		showScan: "显示扫描",
		hideScan: "隐藏扫描",
		create_connection: "创建连接",
		create_manually: "手动创建",
		create_using_scanner: "扫描纸质名片",
		companyTitle: "职位",
		companyTitleLabel: "输入职位",
		allConnectionsLabel: "所有连接类型",
		directConnectionsLabel: "与Tapni用户的直接应用内连接",
		formConnectionsLabel: "在连接表单上填写的连接",
		manualConnectionsLabel: "手动创建的连接",
		scanConnectionsLabel: "通过名片扫描仪创建的连接",
		analyticsHeader: "分析",
		analyticsProfileViews: "个人资料浏览次数",
		analyticsLinkClicks: "链接点击次数",
		analyticsClickTroughRatio: "点击转化率",
		analyticsTotalConnections: "总连接数",
		analyticsTopLinks: "最受欢迎的链接",
		analyticsProfileVisits: "个人资料访问次数",
		analyticsLinkVisits: "链接访问次数",
		analyticsNewConnections: "新连接数",
		analyticsTopCountries: "热门国家",
		analyticsTopReferers: "热门引荐来源",
		tapni_for_business: "Tapni商务版",
		supercharge_your_business: "使用排名第一的数字商务卡平台为您的企业和团队增添动力。",

		for_teams_and_businesses: "团队和企业",
		number_one_digital_business_card_platform: "数字名片平台排名第一",
		manage_teams_collect_leads_automate_workflows: "管理团队，收集线索和自动化工作流程",
		read_more: "阅读更多",
		navigate_to_business_tapni_com: "1) 导航至 business.tapni.com",
		start_qr_scanner_via_button_below: "2) 通过下面的按钮启动二维码扫描器",
		scan_the_qr_code_from_the_screen: "3) 从屏幕扫描二维码",
		enjoy_using_our_platform: "4) 享受使用我们的平台",
		start_qr_scanner: "开始二维码扫描",

		change_language: "更改语言",
		terms_and_privacy: "条款和隐私",

		lead_capture_mode: "潜在客户捕获模式",
		lead_capture_mode_description: "潜在客户捕获表单将显示在您的个人资料上",
		public_profile_mode: "公共配置文件模式",
		public_profile_mode_description: "使您的个人资料",
		public_profile_mode_available: "公开可用",

		link_a_device: "链接设备",

		streetName: "街道名称",
		streetNr: "号码",
		plz: "邮政编码",
		city: "城市",
		country: "国家",

		choose_template: "选择模板",
		contactcard: "联系卡",
		customlink: "自定义链接",
		onboarding: "入职培训",
		tag_text_p: "标签名称",
		tag_text_p2: "为产品设置自定义名称",
		connect_validate_consent: "请接受隐私同意",
		links: "链接",
		manage: "管理",
		buy_domain: "购买域名",
		buy_domain_p: "用自定义域名定制您的在线身份。",

		enable_lead_button: "启用'交换联系方式'按钮",
		enable_lead_button_p: "您的个人资料上将显示联系方式交换按钮",
		form_auto: "访问个人资料时自动打开表单",
		form_auto_p: "当有人打开您的个人资料时，联系方式交换表单将自动打开",
		form_required: "必须填写表单",
		form_required_p: "在关闭之前必须填写表单",
		enter_code_manually: "手动输入代码",
		activate_via_nfc: "通过NFC激活",
		qr_code: "二维码",
		nfc: "NFC",
		nfc_not_available: "此设备不支持NFC",
		nfc_not_enabled: "NFC未启用",
		nfc_no_link_found: "未发现NFC链接。请联系支持。",
		activation_second_step_needed: "需要再多一步。<br> 现在通过扫描产品",

		scanner_card_p: '将相机对准纸卡 <br> 然后点击按钮。',
		scanner_qr_p: '将相机对准二维码 <br> 自动检测。',
		scanner_badge_p: '将相机对准徽章 <br> 然后点击按钮。',
		paper_card: '纸卡',
		event_badge: '活动徽章'
	}
}
