export default {
  state: {
    english: "İngilizce",
    spanish: "İspanyolca",
    german: "Alman",
    korean: "Korece",
    italian: "İtalyanca",
    french: "Fransızca",
    serbian: "Sırpça",
    turkish: "Çince",
    chinese: "Kineski",
    app_language: "Dil seçin",
    profile_preview: "Profil Önizlemesi",
    edit_profile: "Profili Düzenle",
    login: "Giriş yap",
    referral_reach:
      "Bize kendiniz ve erişiminiz hakkında daha fazla bilgi verin.",
    referral_apply_placeholder: "Tapni'yi nasıl tanıtmayı planlıyorsunuz?",
    referral_apply_note:
      "Hesabınıza mümkün olduğunca çok bağlantı ekleyin, <br>\nböylece kim olduğunuzu öğrenebiliriz.                  <br><br>Özel ol.\n                  <br>Herkes kabul edilmez.😉",
    referral_apply_now: "Şimdi başvur",
    referral_apply_thanks:
      "Başvurduğunuz için teşekkürler. Başvurunuzu yakında gözden geçireceğiz!",
    connect: "Bağlan",
    with: "ile",
    exchange_contact: "Değişim İletişim",
    add_details: "Daha fazlasını ekle",
    show_details: "Daha fazlasını göster",
    download_contact: "İletişimi İndir",
    stay_connected: "Bağlantıda kalın",
    no_thanks: "Hayır, Teşekkürler",
    connect_join: "Tapni'ye katıl",
    connect_with: "ile bağlanın",
    connect_required_fields: "İki iletişim alanından biri sağlanmalıdır",
    name: "İsim",
    first_name: "İlk isim",
    last_name: "Soyadı",
    email: "E posta",
    phone: "Telefon numarası",
    phone_p: "Telefon",
    company: "Şirket",
    address: "Adres",
    website: "Web sitesi",
    open: "Aç",
    delete: "sil",
    confirm: "Onayla",
    cancel: "Iptal",
    save: "Kaydet",
    code: "Kod",
    note: "Not",
    note_p: "Bir not ekleyin",
    contact_card_icon: "Kişi kartı simgesinin altındaki metni ayarlayın",
    contact_card_name: "Kişi kartı için isim girin",
    contact_card_name_p: "Kişi kartı adı",
    contact_card_email: "Kişi kartı için e-posta girin",
    contact_card_email_p: "Kişi kartı e-postası",
    contact_card_phone: "Kişi kartı için telefonu girin",
    contact_card_phone_p: "Kişi kartı telefonu",
    contact_card_website: "Kişi kartı için web sitesini girin",
    contact_card_website_p: "Kişi kartı web sitesi",
    contact_card_company_name: "Kişi kartı için şirket adını girin",
    contact_card_company_name_p: "İletişim kartı şirket adı",
    contact_card_job_title: "İş unvanı",
    contact_card_job_title_p: "İş unvanını girin",
    contact_card_company_website: "Şirket web sitesi",
    contact_card_company_website_p: "Şirket web sitesini girin",
    contact_card_bio: "Kişi kartı için biyografiyi girin",
    contact_card_bio_p: "Kişi kartı için biyografiyi girin",
    contact_card_home_address: "Kişi kartı için ev adresini girin",
    contact_card_home_address_p: "Kişi kartı ev adresi",
    contact_card_work_address: "Kişi kartı için iş adresini girin",
    contact_card_work_address_p: "Kişi kartı iş adresi",
    contact_card_social:
      "Kişi kartına eklemek için  <br> Tapni profilinize sosyal medya bağlantılarını girin\n",
    contact_card_social_p: "Kişi kartı için sosyal medya bağlantılarını girin",
    link_text_p: "Bağlantı metni",
    link_icon: "Bağlantı simgesinin altındaki metni ayarla",
    direct_pick: "Doğrudan bir bağlantı seçin",
    direct_p:
      "Birinin telefonuna dokunduğunuzda, <br> profiliniz yerine bu bağlantı açılacaktır.\n",
    password_change: "Parolayı değiştir",
    password_repeat: "Parolayı Tekrarla",
    password_reset_t: "Parolayı sıfırla",
    password_current: "Mevcut parolanızı girin",
    password_current_p: "Mevcut Parolanız",
    password_new: "Yeni parolanızı giriniz",
    password_new_p: "Yeni parolanız",
    password_new_repeat: "Yeni parolanızı tekrar girin",
    password_new_repeat_p: "Yeni parolanızı tekrar girin",
    password_update: "Parolayı güncelle",
    password_reset:
      "Mevcut şifrenizi hatırlamıyorsanız, <br>\n                  parolanızı sıfırlayabilirsiniz",
    delete_account_permanently:
      "Tapni hesabınızı kalıcı olarak silmek istediğinizden emin misiniz?",
    by_clicking_here: "buraya tıklayarak",
    password_8_chars: "Parolanız en az 8 karakterden oluşmalıdır",
    password_least_one_lowercase: "Parola en az bir küçük harf içermelidir",
    password_least_one_uppercase: "Şifre en az bir büyük harf içermelidir",
    password_least_one_number: "Şifre en az bir sayı içermelidir",
    password_not_match: "Parolalar uyuşmuyor",
    password_change_success: "Parolanız başarıyla değiştirildi",
    or: "ya da",
    profile: "Profil",
    account_settings: 'Hesap Ayarları',
    profile_public: "Herkese açık profil",
    profile_public_p: "Profiliniz şu yollarla herkese açık:",
    profile_active: "Aktif profil",
    profile_active_p:
      "Profilinizi devre dışı bırakırsanız, profilinize kimse erişemez ve bağlı tag'ler artık çalışmaz",
    profile_change_password: "Şifreyi değiştir",
    profile_change_username: "Kullanıcı adını değiştir",
    profile_change_language: "Dil seçenekleri",
    profile_delete_account: "Hesabı Sil",
    profile_confirm_delete_account: "Hesabı Sil",
    profile_cancel_delete_account: "İptal",
    profile_delete_account_success: "Hesap başarıyla silindi",
    profile_remove_p:
      "Hesabınızı kalıcı olarak silmek isterseniz,<br> lütfen bize yazın",
    profile_contact_support:
      "Ayarlarla ilgili sorun yaşarsanız,<br> lütfen bize şu adresten yazın",
    profile_email_subject: "Tapni Uygulama Desteği",
    profile_remove_subject: "Hesap Silme Talebi",
    profile_update_success: "Profiliniz başarıyla güncellendi",
    profile_complete: "profilinizi tamamlayın",
    completed: "Tamamlandı",
    uncompleted: "Tamamlanamadı",
    contact_us: "Bize ulaşın",
    contact_us_verify: "E-posta adresinizi doğrulamak için.",
    verify_email: "E-posta Adresini doğrulayın",
    verify_email_subject: "Eposta Doğrulama",
    verify_email_inbox: "Gelen kutunuzu kontrol edin",
    add_first_link: "İlk bağlantınızı ekleyin",
    add_first_link_p:
      "Sosyal medya kanallarınızı veya<br>\n                          iletişim bilgilerinizi Tapni profilinize eklemek için buraya dokunun",
    add_picture: "Profil fotoğrafı ekle",
    add_picture_p: "Profil resminizi yüklemek için buraya dokunun.",
    add_tag: "Tapni'nizi etkinleştirin",
    add_tag_p: "Tapni NFC Tag'inizi etkinleştirmek için buraya dokunun.",
    completing_profile:
      "Profilinizi tamamlamada sorun mu yaşıyorsunuz? <br>\nBize yazmaktan çekinmeyin",
    completing_profile_subject: "Profilinizi Tamamlayın",
    share: "Paylaşın",
    share_profile: "Profilinizi paylaşın",
    share_profile_p:
      "İnsanlar profilinizi görmek için <br> bu QR kodu tarayabilir.",
    share_profile_change_username:
      "Bağlantınızı değiştrimek istiyorsanız,<br> <u>kullanıcı adını değiştirmek için</u> buraya tıklayın",
    share_profile_qr: "Profili Paylaş",
    share_contactcard_qr: "İletişim Kartı",
    scan_qr_code: "QR kodu tarayın",
    enter_activation_code: "Aktivasyon kodunu girin",
    activate_tag: "Tag'inizi aktive edin",
    order_tapni:
      "Henüz Tapni sipariş etmediyseniz, <br>\n        hemen kendinize bir tane ayırın",
    activation_success: "Tag'e başarıyla bağlanıldı. <br> Hazırsınız!🥳",
    activation_success_message: "Tag'e başarıyla bağlanıldı. Hazırsınız!🥳",
    activation_error: "Bu geçerli bir Tag değil. <br> Tekrar deneyiniz.",
    activation_error_message: "Bu geçerli Tag değil. <br> Tekrar deneyiniz.",
    activation_progress: "Tag kaydınız devam ediyor...",
    activation_invalid_code: "Geçersiz aktivasyon kodu.",
    activation_qr_failed: "QR Kod Taraması başarısız oldu",
    activation_qr_area: "Tarama alanının içine bir QR Kodu yerleştirin",
    activation_code: "Aktivasyon kodu",
    invalid_code: "Geçersiz kod",
    total_taps: "Toplam etkileşimler",
    active: "Aktif",
    tag_active_p:
      "Eğer devre dışı bırakırsanız birinin telefonuna dokunduğunuzda <br> profiliniz açılmaz",
    tag_delete_confirm:
      "Tag bağlantısını kaldırmadan önce etkinleştirme kodunu kaydedin, <br> aksi takdirde yeniden etkinleştirilemez.",
    upload_profile_picture: "Profil Profil fotoğrafı Yükleyiniz",
    upload_cover_picture: "Kapak Fotoğrafı Yükleyiniz",
    upload_link_picture: "Bağlantı Resmini Yükleyiniz",
    select_from_device: "Cihazdan seç",
    select_from_mobile: "Mobil cihazınızdan fotoğraf seçiniz",
    select_from_computer:
      "veya bilgisayar kullanıyorsanız sürükleyip bırakabilirsiniz.",
    crop: "Kırp",
    crop_upload: "Kırp ve Yükle",
    crop_image: "Görüntüyü kırp",
    drag_and_drop: "Dosyaları buraya sürükleyip bırakınız",
    upload_success: "Medya başarıyla yüklendi",
    browser_not_supported: "Tarayıcınız desteklemiyor",
    change_username: "Kullanıcı adını değiştir",
    change_username_success: "Kullanıcı adı başarıyla değiştirildi",
    update_username: "Kullanıcı adını güncelle",
    new_username: "Yeni kullanıcı adınızı girin",
    username_is_free: "kullanıcı adı müsait",
    username_check: "Lütfen uygun bir kullanıcı adı girin",
    anonymous: "Anonim",
    connected: "Bağlı",
    exchanged: "Değiş tokuş",
    accept: "Kabul edildi",
    view: "İzle",
    join: "Katıl",
    add_account: "Hesap ekleyin",
    complete_profile: "Profili Tamamlayın",
    my_profile: "Profilim",
    my_qr: "QR'ım",
    my_connections: "Bağlantılarım",
    contacts: "Kişiler",
    my_contacts: "Kişilerim",
    contact_description: "Önemsediğiniz tüm bağlantılar için kişisel CRM'niz",
    my_tags: "Ürünlerim",
    my_tags_description: "Hesabınızla bağlantılı Akıllı İş Kartlarını yönetin",
    buy_tapni: "Tapni satın al",
    referral_program: "İşbirliği Programı",
    device_compatibility: "Cihaz uyumluluğu",
    help: "Yardım",
    settings: "Ayarlar",
    logout: "Çıkış yap",
    version: "Sürüm",
    configure: "Ayarlayın",
    unexpected_err:
      "Beklenmeyen bir hata oluştu, lütfen support@tapni.com adresinden bizimle iletişime geçin.",
    success_login: "Başka bir hesapla başarıyla giriş yapıldı.",
    password_reset_success: "Parola sıfırlama kodu e-postanıza gönderildi.",
    password_reseted_success:
      "Şifre başarıyla değiştirildi. Şimdi giriş yapabilirsiniz.",
    success_remove_link: "Link başarıyla kaldırıldı",
    success_remove_picture: "Başarıyla resim kaldırıldı.",
    profile_active_message: "Profiliniz artık aktif",
    profile_inactive_message: "Profiliniz artık devre dışı",
    profile_public_message: "Profiliniz artık herkese açık",
    profile_private_message: "Profiliniz artık gizli",
    redirect_enabled: "Otomatik yönlendirme Bağlantısı etkin",
    redirect_disabled: "Otomatik Yönlendirme Bağlantısı devre dışı",
    tag_active: "Tag şimdi aktif. Hadi başlayalım!",
    tag_inactive: "Tag şimdi devre dışı. Ziyaretçileriniz tapni.co'ya gelecek",
    tag_removed:
      "Tag kaldırıldı. Lütfen bize support@tapni.com adresinden yazın.",
    connection_accepted: "Bağlantı isteği kabul edildi.",
    connection_added: "Yeni bağlantı başarıyla eklendi",
    connection_exist: "Bu kullanıcıya zaten bağlısınız",
    not_found: "Bulunamadı",
    not_found_p: "Kaybolmuşsunuz gibi görünüyor",
    go_back: "Geri dön",
    referral_p1: "Satış ortağımız olun <br> %20 komisyon kazanın💰",
    referral_p2:
      "Kampanya kodunuzla <br> arkadaşlarınıza %20 indirim sağlayın 🎁",
    referral_p3: "Bu bir kazan-kazan 🤝",
    referral_p4:
      "Referans kodunuzu değiştirmek istiyorsanız,<br>\n veya herhangi bir sorunuz varsa,<br>\n bize yazın",
    referral_p5: "Şimdi para kazanmaya başlayın!",
    referral_p66: "Ortaklık linkinizi paylaşın ve para kazanın!",
    referral_p6:
      "Bir blog yazarı, influencer veya sosyal medya meraklısıysanız, İş birliği Programımız için mükemmel bir seçimsiniz.\n Markamızı tanıtın ve indirim kodunuz veya referans linkinizle yaptılan her alışverişte %20 komisyon kazanın.",
    referral_p7:
      "Tapni İşbirliği Programına kaydolduktan sonra kişisel bir referans linki ve indirim kodu alacaksınız.\n Bunları web sitenizde ve sosyal medyada paylaşın ve biz insanlara harika bir deneyim sunarken siz de para kazanın.",
    referral_p8: "Ödeme Notu",
    referral_p9: "Banka transfer ödemeleri için <br> minimum tutar 25€.",
    referral_p10: "Ödeme İsteği",
    referral_p11: "Ödeme isteği gerçekleştirildi",
    your_connections: "Bağlantılarınız",
    connections_p1: "Tanıştığınız insanlarla iletişimde kalın",
    connections_p2: "<br><br>Şu ana kadar bağlantı yok<br> Hadi başlayalım💥",
    connections_p3: "Bağlantı bulunamadı.",
    connection_success: "Bağlantı isteği başarıyla gönderildi",
    exchange_success: "Kişiler başarıyla değiştirildi.",
    create_free_account:
      "Ücretsiz bir Tapni hesabı oluşturun ve kişilerinizi güncel tutun!",
    connection_edit: "Bağlantıyı düzenle",
    connection_edit_success: "Bağlantı başarıyla güncellendi",
    connection_delete_success: "Bağlantı başarıyla silindi",
    search: "Arama",
    devices_p1: "Uyumlu cihazlar listesi",
    devices_p2:
      "Tapni, çoğu iPhone ve Android cihazıyla uyumlu teknolojiyi kullanır.",
    devices_p3:
      "Bazı Android telefonlarda NFC kapalı olabilir. Tapni'niz bir Android cihazda çalışmıyorsa, ayarlarda NFC'yi arayın ve açık olduğundan emin olun.",
    devices_p4: "kontrol merkezinde iOS 14 NFC widget'i ile",
    download_p1: "Tapni Uygulamasını İndirin",
    download_p2: "Apple ve Android cihazlar için kullanılabilir",
    user_not_found: "Kullanıcı bulunamadı",
    user_not_found_p: "Kullanıcıyı veritabanımızda bulamadık🤷",
    taps: "etkileşimler",
    private_profile: "Gizli profil",
    private_profile_p:
      "Gizli bir profildir.<br> Profil yalnızca Tapni Tag'leri kullanılarak görünür ",
    profile_bio_p: "Kendiniz veya markanız hakkında bir şeyler yazın",
    add_to_contacts: "Kişilere ekle",
    drag_and_drop_links: "Linkleri istediğiniz sırayla Sürükleyin ve Bırakın",
    no_links: "Profilinizde link yok.",
    add_link: "Link ekle",
    direct_link_p1:
      "Ziyaretçiler otomatik olarak şu adrese yönlendirilecektir:",
    profile_not_active:
      "Profiliniz şuanda aktif değil. <br> ve giriş yaptığınızda sadece siz görebilirsiniz.",
    profile_not_public:
      "Profiliniz şu anda herkese açık değil <br> yalnızca Tapni ile iletişim kurduklarınız görebilir.",
    activate_profile_now: "Profilinizi şimdi etkinleştirin",
    publish_profile_now: "Profilinizi şimdi herkese açık yapın",
    link_add_p1: "Bir link tipi seçin",
    link_add_not_found: "Link bulunamadı🤷",
    link_add_not_found_p2:
      "Eğer favori platformunuzu kaçırdıysak lütfen bize e mail gönderin (<b>support@tapni.com</b>) ya da sosyal medya üzerinden meesaj atabilirsiniz (<b>@tapni</b>)",
    link_add_not_found_p3:
      "Tüm platformları desteklemek için elimizden geleni yapıyoruz 😉",
    featured: "Önce çıkanlar",
    social_media: "Sosyal medya",
    contact: "İletişim",
    finance: "Finans",
    portfolio: "Portfolyo",
    music: "Müzik",
    business: "İş",
    personal: "Kişisel",
    fun: "Eğlence",
    ecommerce: "E Ticaret",
    more: "Daha fazla",
    email_username: "E posta / Kullanıcı adı",
    username: "Kullanıcı adı",
    password: "Parola",
    sign_in: "Oturum aç",
    sign_in_with: "ile oturum aç",
    sign_in_with_new: "Yeni hesapla oturum açın",
    sign_up_with: "ile kaydol",
    select_sign_method: "Bir Oturum Açma yöntemi seçin",
    verify_account: "Hesabı Doğrula",
    terms_by_signing_in: "Giriş yaparak, kabul etmiş olursunuz",
    terms_by_registering: "Kayıt olarak, kabul etmiş olursunuz",
    terms_by_submitting:
      "Bu formu göndererek Hizmet Şartlarımızı kabul etmiş olursunuz",
    terms_of_service: "Kullanım Şartları",
    and: "ve",
    refresh: "yenile",
    privacy_policy: "Gizlilik Politikası",
    create_account: "Hesap oluştur",
    reset_password: "Parolayı yenile",
    reset_password_p1:
      "Şifrenizi yenileyemediyseniz <br> bizimle iletişime geçmekten çekinmeyin",
    reset_password_p2:
      "Şifrenizi yenileyemediyseniz <br> bizimle iletişime geçmekten çekinmeyin",
    create_new_account: "Yeni hesap oluştur",
    maintenance_p1: "yakında dönüş yapacağız",
    maintenance_p2: " Tapni koleksiyonlarını keşfedin",
    maintenance_p3:
      "Tapni planlı bir bakım için kapalı  <br> ve birkaç dakika içinde tekrar çevrimiçi olması bekleniyor.",
    offline_p1: "İnternet bağlantısı yok",
    offline_p2: "Bağlantınızı kontrol edin, ardından sayfayı yenileyin",
    offline_p3_1: "Çevrimdışıyken,",
    offline_p3_2: "bu QR kodunu kullanarak kişi kartınızı paylaşabilirsiniz",
    products_p1:
      "\nTanıştığınız herkesi etkileyin 😎 <br> Kendinize bir tane ayırın 👇",
    cards: "Kartlar",
    stickers: "Stickerlar",
    keychains: "Anahtarlıklar",
    explore_collection: "Koleksiyonu keşfedin",
    register: "Kayıt ol",
    register_success: "Neredeyse tamamladınız. Lütfen e-postanızı onaylayın.",
    register_success_modal: "Başka bir hesapla başarıyla giriş yapıldı.",
    check_your_inbox: "Check your inbox",
    tapni_tags: "Tapni tagler",
    tapni_tags_p1: "Profilinize bağlı Tag'leri yapılandırın",
    tapni_tags_p2: "Profilinize bağlı Tag yok. <br> Şimdi bir tane bağlayın!",
    verify_account_p1:
      "Bir kod almadıysanız, spam kutunuzu <br> kontrol edin veya bize ulaşın",
    verify_account_success:
      "Hesap başarıyla doğrulandı. Lütfen şimdi şifrenizi değiştirin.",
    verify_account_success2: "Hesap başarıyla doğrulandı. Hazırsınız!🥳",
    welcome: "Hoşgeldiniz",
    welcome_p1: "kartvizitlerin geleceğine",
    how_it_works: "Nasıl çalışır?",
    already_registered: "Zaten kayıtlı",
    error: "Hata",
    success: "Başarı",
    copied: "Panoya kopyalandı",
    connect_validate: "İki iletişim alanından biri sağlanmalıdır",
    connect_validate_name: "Lütfen adınızı giriniz",
    connect_validate_email: "Lütfen geçerli bir e-posta girin",
    link_validate: "Geçersiz bağlantı verileri.",
    link_text_contact_card: "Kişi kartı",
    link_text_custom_link: "Özel bağlantı",
    link_text_menu: "Menü",
    link_text_phone: "Telefon",
    save_to_apple_wallet: "Apple Cüzdan'a Ekle",
    save_to_google_wallet: "Google cuzdanına ekle",
    card: "Kart",
    sticker: "Sticker",
    keychain: "Anahtarlık",
    band: "Band",
    case: "Vaka",
    standee: "Standee",
    decoded: "Decoded",
    wristbanditz: "Wristbanditz",
    update_p1: "Güncel olmayan uygulama sürümü algılandı",
    update_p2: "Güncelle",
    update_p3: "Lütfen uygulamayı en yeni sürüme güncelleyin",
    company_domain: "Şirketinizin alan adı",
    search_company_domain: "Şirket alan adınızı arayın",
    enter_company_email: "Şirket e-postasını girin",
    company_not_found: "Şirket bulunamadı",
    ik_company_domain: "Şirket alan adını biliyorum",
    idk_company_domain: "Şirketin alan adını bilmiyorum",
    continue: "Devam et",
    forbidden_rule: "Bu seçenek devre dışıdır, şirket yöneticinize başvurun.",
    companyName: "Şirket Adı",
    createMarkerPlaceholder: "Bir işaretleyici oluşturma veya seçme",
    clickToCreateMarker: "İşaretleyici oluşturmak için buraya tıklayın",
    pleaseInviteUser:
      "Lütfen kullanıcıyı sizinle senkronize edilmiş verilere sahip olması için Tapni'ye katılmaya davet edin.",
    connectionSynchronized:
      "Bağlantı verileri otomatik olarak senkronize edilir.",
    viewProfile: "Profili Görüntüle",
    createConnectionHeader: "Yeni Bağlantı Ekle",
    createConnectionButton: "Ekle",
    createConnectionSuccess: "Bağlantı oluşturuldu.",
    allow_company_management: "Şirket yönetimine izin verin",
    company_join_request:
      "profilinizi yönetmek ve Enterprise hesabıyla ilişkilendirmek istiyor.",
    account_ownership_transfer:
      "Talebi kabul etmeniz halinde hesabınızın mülkiyeti şirkete devredilecektir. Şirket profilinizi değiştirebilir, bağlantıları düzenleyebilir ve ekleyebilir, bağlantılarınızı görebilir, şifrenizi değiştirebilir, hesabınızı silebilir ve daha fazlasını yapabilir. Talebi kabul etmeden önce şirketinizle görüştüğünüzden emin olun.",
    request_expired:
      "Talebin süresi doldu, şirketinizden yeni bir talep göndermesini isteyin.",
    request_expiration_desc:
      "Daha fazla güvenlik için, talep gönderildikten sonra yalnızca 14 gün boyunca çalışacaktır.",
    invitation_link_not_valid: "Davetiye bağlantısı geçerli değil!",
    invitation_link_not_valid2:
      "Davet e-postasındaki düğmeye tekrar tıklayın veya davet bağlantısını kopyalayın.",
    accept_request: "İsteği kabul et",
    close: "Kapat",
    request_accepted: "İstek başarıyla kabul edildi",
    companyEmail: "Şirket E-postası",
    companyPhone: "Şirket Telefonu",
    addBusinessInformation: "İşletme bilgilerini ekle",
    resetFilter: "Sıfırla",
    selectMarkers: "İşaretleyicileri Seçin",
    filterConnectionsHeader: "Filtre Bağlantıları",
    selectConnectionType: "Bağlantı Türünü Seçin",
    all: "Tümü",
    direct: "Doğrudan",
    form: "Form",
    manually: "Manuel olarak",
    scan: "Tarama",
    sortOptions: "Sıralama Seçenekleri",
    date: "Oluşturulma Tarihi",
    fullName: "Ad Soyad",
    descending: "Alçalma",
    ascending: "Yükselen",
    selectDateRange: "Tarih Aralığı Seçin",
    follow_up_reminder: "Takip hatırlatması",
    now: "Şimdi",
    one_hour: "1 saat",
    one_day: "1 Gün",
    three_days: "3 Gün",
    one_week: "1 Hafta",
    two_weeks: "2 Hafta",
    one_month: "1 Ay",
    custom: "Zaman seçin",
    set_reminder: "Hatırlatma ayarla",
    select_date_time: "Tarih ve saati seçin",
    enter_reminder_text: "Hatırlatma metnini girin",
    reminder_created: "Hatırlatma başarıyla oluşturuldu",
    connectionNameLabel: "Bağlantı adını girin",
    connectionEmailLabel: "Özel e-posta adresinizi girin",
    privatePhoneLabel: "Özel telefon numarasını girin",
    companyNameLabel: "Şirket adını girin",
    businessEmail: "İş e-posta adresi",
    businessEmailLabel: "İş e-posta adresini girin",
    businessPhone: "İş telefon numarası",
    businessPhoneLabel: "İş telefon numarasını girin",
    businessFax: "İş faksı",
    businessFaxLabel: "İş faks numarasını girin",
    noteLabel: "Not girin",
    markersLabel: "Bağlantı işaretleri ekleyin",
    showScan: "Taramayı Göster",
    hideScan: "Taramayı Gizle",
    create_connection: "Bağlantı Ekleme",
    create_manually: "Manuel Olarak Ekle",
    create_using_scanner: "Kart Tarayıcı Kullanarak Ekleme",
    companyTitle: "Şirket Unvanı",
    companyTitleLabel: "Şirket Unvanını Girin",
    allConnectionsLabel: "Tüm bağlantı türleri",
    directConnectionsLabel:
      "Tapni Kullanıcıları ile doğrudan uygulama içi bağlantı",
    formConnectionsLabel: "Bağlantı formunda doldurulan bağlantılar",
    manualConnectionsLabel: "Manuel olarak oluşturulan bağlantılar",
    scanConnectionsLabel: "Kart tarayıcı bağlantıları oluşturdu",
    analyticsHeader: "Analitik",
    analyticsProfileViews: "Profil Görünümleri",
    analyticsLinkClicks: "Bağlantı Tıklamaları",
    analyticsClickTroughRatio: "Tıklama Çukuru Oranı",
    analyticsTotalConnections: "Toplam Bağlantılar",
    analyticsTopLinks: "En Popüler Bağlantılar",
    analyticsProfileVisits: "Profil Ziyaretleri",
    analyticsLinkVisits: "Bağlantı Ziyaretleri",
    analyticsNewConnections: "Yeni Bağlantılar",
    analyticsTopCountries: "En İyi Ülkeler",
    analyticsTopReferers: "En İyi Referanslar",

    tapni_for_business: "İş için Tapni",
    supercharge_your_business: "İşletmeler ve Takımlar için yapılmış 1 numaralı Dijital İş Kartı Platformu ile işinizi güçlendirin.",

    for_teams_and_businesses: "Takımlar & İşletmeler İçin",
    number_one_digital_business_card_platform: "#1 Dijital İş Kartı Platformu",
    manage_teams_collect_leads_automate_workflows: "Takımları Yönet, Müşteri Adayları Topla & İş Akışlarını Otomatikleştir",
    read_more: "Daha Fazla Oku",
    navigate_to_business_tapni_com: "1) business.tapni.com adresine gidin",
    start_qr_scanner_via_button_below: "2) Aşağıdaki buton aracılığıyla QR Tarayıcıyı başlatın",
    scan_the_qr_code_from_the_screen: "3) Ekrandaki QR kodunu tarayın",
    enjoy_using_our_platform: "4) Platformumuzu kullanmaktan keyif alın",
    start_qr_scanner: "QR Tarayıcıyı Başlat",

    change_language: "Dili Değiştir",
    terms_and_privacy: "Şartlar & Gizlilik",

    lead_capture_mode: "Potansiyel Müşteri Yakalama Modu",
    lead_capture_mode_description: "Potansiyel müşteri yakalama formu profilinizde görüntülenecek",
    public_profile_mode: "Halka Açık Profil Modu",
    public_profile_mode_description: "Profilinizi",
    public_profile_mode_available: "herkese açık hale getirin",

    link_a_device: "Bir cihazı bağla",

    streetName: "Sokak adı",
    streetNr: "Numara",
    plz: "Posta Kodu",
    city: "Şehir",
    country: "Ülke",

    choose_template: "Şablon seç",
    contactcard: "İletişim kartı",
    customlink: "Özel link",
    onboarding: "Yerleştirme",
    tag_text_p: "Etiket adı",
    tag_text_p2: "Ürün için özel bir isim belirleyin",
    connect_validate_consent: "Lütfen gizlilik onayını kabul edin",
    links: "Bağlantılar",
    manage: "Yönet",
    buy_domain: "Alan Adı Satın Al",
    buy_domain_p: "Özel Bir Alan Adı ile Çevrimiçi Kimliğinizi Özelleştirin.",

    enable_lead_button: "'İletişim Takası' Butonunu Etkinleştir",
    enable_lead_button_p: "Profilinizde iletişim takası butonu görüntülenecek",
    form_auto: 'Profili ziyarette formu otomatik aç',
    form_auto_p: 'Birisi profilinizi açtığında iletişim takası formu otomatik olarak açılacak',
    form_required: 'Form doldurma zorunlu',
    form_required_p: 'Kapatılmadan önce formun doldurulması zorunlu hale getirilecek.',
    enter_code_manually: 'Kodu manuel olarak gir',
    activate_via_nfc: 'NFC aracılığıyla etkinleştir',
    qr_code: 'QR Kod',
    nfc: 'NFC',
    nfc_not_available: 'Bu cihazda NFC mevcut değil',
    nfc_not_enabled: 'NFC etkin değil. Lütfen ayarlarınızı kontrol edin',
    nfc_no_link_found: 'NFC bağlantısı bulunamadı. Lütfen destekle iletişime geçin.',
    activation_second_step_needed: 'Bir adım daha gerekli. <br> Şimdi ürünü NFC aracılığıyla tarayın',

    scanner_card_p: 'Kamerayı kağıt karta tutun <br> ve düğmeye dokunun.',
    scanner_qr_p: 'Kamerayı QR’a tutun <br> otomatik algılayın.',
    scanner_badge_p: 'Kamerayı rozete tutun <br> ve düğmeye dokunun.',
    paper_card: 'Kağıt Kart',
    event_badge: 'Etkinlik Rozeti'
  },
};
