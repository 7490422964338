<template>
  <!-- Header -->
  <div
    style="
      position: fixed;
      margin-top: 5px;
      width: 100%;
      max-width: 677px !important;
      z-index: 4;
    "
  >
    <!--
    <a
        v-if="isAppHeader && route.name && route.name.includes('Home')"
        @click="toggleSidebar"
        class="header-icon header-icon-1 font-25 hamburger-animated no-border pointer vibrate"
        style="left: 4%;"

    >
      <img
          v-if="isCompletedProfileSetup || !isLoggedInUser"
          src="https://cdn.tapni.co/icons/burger-menu-tiny.png"
          class="responsive-image burger-icon"
          alt=""
      />
      <img
          v-else-if="isLoggedInUser"
          src="https://cdn.tapni.co/icons/burger-menu-tiny-red-dott.png"
          class="responsive-image burger-icon"
          alt=""
      />
    </a>
    -->

    <!-- Back -->
    <!--
    <router-link
        v-else-if="isAppHeader && route.name && !route.name.includes('Home')"
        :to="home"
        tag="a"
        class="header-icon font-25 no-border"
        style="left: 4%;"
    ><i class="fa fa-angle-left" style="position: relative; bottom: 2px; margin-right: 3px;"></i
    ></router-link>
    -->

    <!-- Spinner Icon
    <router-link
      v-if="loadingHeader || showSuccessSave"
      to=""
      tag="a"
      :event="''"
      class="header-icon font-18 no-border"
      style="right: 4%"
    >
      <i
        v-if="!showSuccessSave"
        class="fa fa-circle-o-notch fa-spin load-social-icon"
        style="position: relative"
      ></i>
      <img
        v-else
        src="https://cdn.tapni.co/icons/check.png"
        class="responsive-image check-icon"
        alt=""
      />
    </router-link>
  -->
    <a
      v-if="!isLoggedIn && route.name && route.name.startsWith('Auth')"
      @click="toggleLanguageModal"
      class="header-icon font-18 no-border pointer"
      style="right: 10px; top: 7px"
      ><span class="lang-icon">{{ appLang.toUpperCase() }}</span></a
    >

    <!-- QR Icon -->
    <!--
    <a
        v-else-if="isLoggedIn && isLoggedInUser && user.is_public && isAppHeader"
        @click="toggleQR"
        class="header-icon font-25 no-border pointer vibrate"
        style="right: 4%;"
    ><img
        src="https://cdn.tapni.co/icons/share.png"
        class="responsive-image share-icon"
        alt=""
    /></a>
    -->

    <!-- User Icon
	  <router-link v-else-if="isLoggedIn && showUserIcon" :to="home" exact class="header-icon font-20 no-border" :class="{'header-icon-4': isAppHeader || isLogoCenter, 'header-icon-1': !isAppHeader && !isLogoCenter }">
	    <img src="https://cdn.tapni.co/icons/User.png" class="responsive-image user-icon">
    </router-link>
    -->
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { EventBus } from "../store/event-bus";

export default {
  name: "Header",
  data() {
    return {
      activeMenu: false,
      loadingHeader: false,
      showSuccessSave: false,
      blurredMenu: false,
    };
  },
  mounted() {
    EventBus.$on("loadingHeader", (nv) => {
      if (this.loadingHeader === true && nv === false) {
        this.showSuccessSave = true;
        setTimeout(() => {
          this.showSuccessSave = false;
        }, 2000);
      }
      this.loadingHeader = nv;
    });
  },
  methods: {
    ...mapActions([]),
    selectLang() {
      this.activeMenu = false;
    },
    toggleSidebar() {
      EventBus.$emit("toggleSidebar");
    },
    toggleLanguageModal() {
      EventBus.$emit("toggleLanguageModal");
    },
    toggleQR() {
      EventBus.$emit("toggleQRModal");
    },
  },
  computed: {
    ...mapState(["initialized", "isMaintenance"]),
    isLoggedInUser() {
      return this.user && this.storage &&
          this.user.id === this.storage.UserId &&
          this.user.username === this.storage.username &&
          this.isLoggedIn
    },
    showUserIcon() {
      return (
        this.route.name &&
        !this.route.name.includes("Auth") &&
        !this.route.name.includes("Error")
      );
    },
    isLogoCenter() {
      return false;
    },
    isAppHeader() {
      if (
        this.isLoggedIn &&
        ((this.route.name && this.route.name.includes("Home")) ||
          [
            "Tags",
            "LinkAdding",
            "Connections",
            "Devices",
            "Referral",
            "Products",
            "Settings",
            "Analytics",
            "More",
          ].includes(this.route.name))
      )
        return true;
      return false;
    },
  },
};
</script>

<style scoped>
.menu-scroll a {
  padding: 0 auto;
}
.menu-scroll a:hover {
  transition: ease 0.5s;
  background-color: #33435b;
}
strong {
  color: #eee;
  margin-top: 0px !important;
}

.header-icon {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 13px;
  position: absolute;
  background: rgba(238, 238, 238, 0.7);
  border-radius: 50px;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(25px);
  color: #232323;
}
#header * {
  transition: all 250ms ease;
}

#header * {
  transition: all 250ms ease;
}

.header-dark em {
  background-color: rgba(255, 255, 255, 0.7) !important;
}
.share-icon {
  padding: 25%;
}
.check-icon {
  padding: 30%;
  margin-top: 3px;
}
.burger-icon {
  padding: 30%;
}
.lang-icon {
  position: relative;
}
</style>
