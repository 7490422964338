<template>
  <div
    class="menu-wrapper menu-light menu-modal center-text activate-page"
    :class="{ 'active-menu': toggle }"
    style="
      height: auto;
      max-height: 93%;
      margin-top: -172.5px;
      box-shadow: 0px 2px 20px 0px #0000004f;
    "
  >
    <a
      @click="close"
      class="color-black pull-right pointer"
      style="margin-top: 2px; margin-right: 5px; position: absolute; right: 5px"
    >
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_b_2_32)">
          <rect
            width="30"
            height="30"
            rx="15"
            fill="white"
            fill-opacity="0.08"
          />
          <path
            d="M8.49065 19.937C8.08166 20.346 8.07332 21.0722 8.499 21.4979C8.93302 21.9235 9.65918 21.9152 10.0598 21.5146L15.001 16.5733L19.9339 21.5062C20.3513 21.9235 21.0691 21.9235 21.4948 21.4979C21.9204 21.0638 21.9204 20.3544 21.5031 19.937L16.5702 15.0042L21.5031 10.063C21.9204 9.64562 21.9288 8.92781 21.4948 8.50213C21.0691 8.07645 20.3513 8.07645 19.9339 8.49378L15.001 13.4267L10.0598 8.49378C9.65918 8.08479 8.92468 8.0681 8.499 8.50213C8.07332 8.92781 8.08166 9.66231 8.49065 10.063L13.4235 15.0042L8.49065 19.937Z"
            fill="#1D1D1D"
          />
        </g>
        <defs>
          <filter
            id="filter0_b_2_32"
            x="-54.3656"
            y="-54.3656"
            width="138.731"
            height="138.731"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="27.1828" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_2_32"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_2_32"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </a>

    <h3 v-if="!showPolicyDetails" class="modal-title policy-title">
      We use cookies
    </h3>
    <h3 v-else class="modal-title policy-title">Cookie Policy</h3>

    <div v-if="!showPolicyDetails" class="policy-details">
      <p style="text-align: left; font-size: 16px">
        We use cookies to enhance your experience and to understand how you use
        our site.
      </p>
      <a
        @click="showPolicyDetails = true"
        class="color-blue-light pointer learn-more"
        >Learn more</a
      >
    </div>

    <div
      v-if="!showPolicyDetails"
      style="
        width: 95%;
        display: inline-block;
        margin: 0 auto;
        margin-top: 10px;
      "
    >
      <a @click="decline" class="color-black pointer decline">Decline</a>
      <button
        @click="accept"
        class="button black-button half-top"
        style="float: right; width: 65%; margin: 0"
      >
        Accept and close
      </button>
    </div>

    <div v-if="showPolicyDetails" class="policy-details">
      <p style="text-align: left; font-size: 15px">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Id leo in vitae
        turpis massa. Dui id ornare arcu odio. Volutpat maecenas volutpat
        blandit aliquam etiam erat. Nibh tellus molestie nunc non. Volutpat
        maecenas volutpat blandit aliquam. Eu turpis egestas pretium aenean
        pharetra magna ac placerat. Risus sed vulputate odio ut enim blandit
        volutpat maecenas. Vel turpis nunc eget lorem dolor sed viverra.
        Faucibus et molestie ac feugiat sed lectus. Nulla malesuada pellentesque
        elit eget. Ipsum dolor sit amet consectetur adipiscing elit. Nulla
        posuere sollicitudin aliquam ultrices sagittis orci a. Feugiat nisl
        pretium fusce id velit ut tortor. Sit amet est placerat in egestas erat
        imperdiet sed euismod. Tincidunt praesent semper feugiat nibh sed. Dolor
        sit amet consectetur adipiscing elit duis tristique sollicitudin. Quis
        auctor elit sed vulputate mi sit. Augue eget arcu dictum varius duis at
        consectetur lorem. Pulvinar mattis nunc sed blandit. Eros in cursus
        turpis massa tincidunt dui ut ornare lectus. Sit amet luctus venenatis
        lectus magna. Quis risus sed vulputate odio. Quam lacus suspendisse
        faucibus interdum posuere lorem ipsum dolor. Ornare massa eget egestas
        purus viverra accumsan in. Proin fermentum leo vel orci. Arcu non odio
        euismod lacinia. Penatibus et magnis dis parturient montes nascetur. Et
        malesuada fames ac turpis egestas maecenas pharetra convallis.
        Pellentesque elit ullamcorper dignissim cras tincidunt lobortis feugiat.
        Mollis aliquam ut porttitor leo a diam sollicitudin tempor id. Enim
        nulla aliquet porttitor lacus. Aliquet bibendum enim facilisis gravida.
        Elementum facilisis leo vel fringilla est. Leo in vitae turpis massa sed
        elementum tempus egestas sed. Semper quis lectus nulla at volutpat. Vel
        fringilla est ullamcorper eget. Duis tristique sollicitudin nibh sit
        amet commodo. Nibh venenatis cras sed felis eget velit aliquet. Viverra
        tellus in hac habitasse platea dictumst vestibulum. Felis bibendum ut
        tristique et egestas quis. Id volutpat lacus laoreet non curabitur
        gravida arcu ac. Pellentesque massa placerat duis ultricies lacus sed
        turpis tincidunt id.
      </p>
      <a
        @click="showPolicyDetails = false"
        class="color-blue-light pointer close"
        >Close</a
      >
    </div>
  </div>
</template>


<script>
import { EventBus } from "../../store/event-bus";
import { mapState } from "vuex";
import { acceptAnalyticsCookies } from "../../plugins/analytics";

export default {
  name: "CookiePolicy",
  data() {
    return {
      toggle: false,
      showPolicyDetails: false,
    };
  },
  computed: {
    ...mapState(["analytics"]),
  },
  mounted() {
    if (this.isPreview) return;

    EventBus.$on("toggleCookieModal", this.toggleModal);
    EventBus.$on("closeModal", () => {
      this.toggle = false;
    });
  },
  beforeDestroy() {
    EventBus["_events"]["toggleCookieModal"].pop();
  },
  methods: {
    close() {
      //EventBus.$emit('closeModal')
      this.toggle = false;
    },
    toggleModal() {
      this.toggle = !this.toggle;
    },
    decline() {
      let tmpValue = "declined-" + Math.round(Date.now() / 1000);
      localStorage.setItem("analyticsCookie", tmpValue);
      // EventBus.$emit('closeModal')
      this.toggle = false;
    },
    accept() {
      acceptAnalyticsCookies(this.analytics);
      let tmpValue = "accepted-" + Math.round(Date.now() / 1000);
      localStorage.setItem("analyticsCookie", tmpValue);
      this.toggle = false;
      //EventBus.$emit('closeModal')
    },
  },
};
</script>

<style scoped>
.decline {
  margin-top: 30px;
  margin-left: 8px;
  float: left;
  text-decoration: underline;
  text-underline-position: under;
  display: inline;
}

.close {
  margin: 0 auto;
  text-decoration: underline;
  text-underline-position: under;
  display: inline;
}

.learn-more {
  margin-top: 10px;
  margin-left: 0px;
  float: left;
  text-underline-position: under;
  display: inline;
}

.policy-title {
  margin-left: 5px !important;
  margin-top: 5px !important;
  font-weight: 600;
}

.policy-details {
  width: 95%;
  display: inline-block;
  margin: 0 auto;
  margin-top: 5px;
  overflow-y: scroll;
}
</style>
