import Api from './Api'

export default {
  activate (data) {
    return Api().put(`/tags/activate/` + data.serial, data)
  },
  get (data) {
    return Api().put(`/tags/` + data.serial, data)
  },
  edit (data) {
    return Api().put(`/tags/` + data.id, data)
  },
  remove (data) {
    return Api().delete(`/tags/` + data.id)
  }
}
