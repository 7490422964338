/* eslint-disable */
import {mapActions, mapGetters, mapState} from 'vuex'
import {Browser} from '@capacitor/browser';
import {Share} from '@capacitor/share';
import {Capacitor} from '@capacitor/core';
import {Haptics, ImpactStyle} from '@capacitor/haptics';
import { WidgetsBridgePlugin } from 'capacitor-widgetsbridge-plugin';
import DeepLinkService from '@/services/DeepLinkService';
import { sanitizeUrl } from "@braintree/sanitize-url";
import { sleep, asyncForEach } from '@tapni/js-utils'
import { saveAs } from 'file-saver';
export default {
  data() {
    return {
      allowedOrigins: [
          'localhost',
          't.link',
          'dev.t.link',
          'my.tapni.co',
          'my.tapni.com',
          'preview.tapni.com',
          'tapni.com',
          'business.tapni.com',
          'mailsign.link',
      ],
      initialStorageValues: {
        'realm': '',
        'token': '',
        'username': '',
        'UserId': '',
        'verifyEmail': '',
        'subdomain': ''
      }
    }
  },
  computed: {
    ...mapState(['lang', 'route', 'user', 'linkTypes', 'company', 'storage']),
    ...mapGetters(['isLoggedIn', 'appLang']),
    mfaReady () { return !!this.$route.query.mfaReady || true; },
    proReady () { return !!this.$route.query.proReady; },
    teamAndFollowUpReady () { return !!this.$route.query.teamAndFollowUpReady; },
    roadmapReady () { return !!this.$route.query.roadmapReady; },
    onboardingReady () { return !!this.$route.query.onboardingReady; },
    analyticsReady () { return this.route.query.analyticsReady !== undefined; },
    upgradeReady () { return !!this.$route.query.upgradeReady || this.user.email?.includes('@tapni.co'); },
    isPreview() {
      return location.hostname.includes('preview') || !!this.$route.query.dontTap
    },
    hostname() {
      return this.isCustomDomain ? location.hostname : 't.link'
    },
    isCustomDomain() {
      return !this.allowedOrigins.includes(location.hostname) && !location.hostname.includes('192.168')
    },
    home() {
      if (this.storage && this.storage.token && this.storage.username) {
        return '/' + this.storage.username
      } else return '/welcome'
    },
    isCompletedProfileSetup() {
      if (this.user) {
        // Check if verified email
        if (this.user.status !== 'VERIFIED') return false
        if (this.user.links.length === 0) return false
        if (this.user.tags.length === 0) return false
        if (this.user.photo === 'https://cdn.tapni.co/images/user.png') return false
        return true
      }
      return null
    },
    cdnHost() {
      return import.meta.env.VITE_APP_CDN_HOST
    },
    isB2B() {
      return !!this.user?.CompanyId;
    },
    isAndroid(){
      return /Android/i.test(navigator.userAgent) || Capacitor.getPlatform() === 'android'
    },
    isiOS() {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
        // iPad on iOisNativeS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
        || Capacitor.getPlatform() === 'ios'
    },
    isNative() {
      return Capacitor.isNativePlatform()
    },
    webShareApiSupported() {
      return navigator.share;
    },
  },
  methods: {
    ...mapActions(["eventLog"]),
    getIcon(icon) {
      if (!this.isNative) {
        return 'https://cdn.tapni.co/icons/' + icon;
      } else {
        return `/icons/${icon}`;
      }
    },
    errorHandler(error, log = false) {
      if (log) console.error(log);
      if (error && error.response && error.response.data && error.response.data.error) {
        if (error.response.data.error === 'ACCESS_DENIED') {
          if (this.$store) this.$store.dispatch('logout');
          return error;
        }

        this.errorSnack(error.response.data.error)
      }
      return error
    },
    errorSnack(message) {
      if (!message.includes('Axios') && !message.includes('TOKEN_EXPIRED') && !message.includes('Network Error') && !message.includes('Cannot read properties')) {
        let snackbar = document.getElementById('snackbarApp')
        let errMessage = document.getElementById('errorMessageApp')
        let errorSnack = document.getElementById('errorSnackApp')
        errMessage.innerHTML = message
        snackbar.classList.add('show-snack')
        errorSnack.classList.add('active-snack')

        setTimeout(function () { errorSnack.classList.remove('active-snack'); snackbar.classList.remove('show-snack')  }, 3000)
      }
    },
    successSnack(message) {
      let snackbar = document.getElementById('snackbarApp')
      let successMessage = document.getElementById('successMessageApp')
      let successSnack = document.getElementById('successSnackApp')
      successMessage.innerHTML = message
      snackbar.classList.add('show-snack')
      successSnack.classList.add('active-snack')
      setTimeout(function () { successSnack.classList.remove('active-snack'); snackbar.classList.remove('show-snack') }, 3000)
    },
    closeSnacks() {
      document.getElementById('snackbarApp').classList.remove('show-snack')
      document.getElementById('successSnackApp').classList.remove('active-snack')
      document.getElementById('errorSnackApp').classList.remove('active-snack')
    },
    tapsEmoji(count) {
      if (count < 10) return ''
      else if (count <= 50) return '🔥'
      else if (count <= 100) return '💥'
      else if (count <= 300) return '💎'
      else if (count > 300) return '🚀'
      return ''
    },
    redirectLink(data, openNative = false, returnLink = false, directLink = false) {
      if (!this.linkTypes.length) return;

      let url = ''
      const linkType = this.linkTypes.filter(e => e.type === data.type)[0]
      if (!linkType.prefill_url) {
        url = linkType.base_url + data.url
      } else {
        url = data.url
      }

      //url sanitizer - prevent xss
      url = sanitizeUrl(url);

      /*
      if (openNative && Capacitor.isNativePlatform()) {
        return Browser.open({ url });
      }
       */

      if (!returnLink) {
        //window.open(url);

        /*
        if(directLink) window.location.replace(url);
        else window.open(url, "_blank");
         */
        DeepLinkService.openDeepLink(linkType.type, url, (openNative && Capacitor.isNativePlatform()) , directLink );

        //return window.location.href = url;
      }
      else return url
    },
    async downloadContent(url, openNative = false) {

      // ako ne radi na telefonu, skidaj url direktno, bez axiosa
      //let response = await UserService.downloadFile(url)
      //let _url = window.URL.createObjectURL(response.data);
      if (Capacitor.isNativePlatform() && openNative) {
        return Browser.open({ url });
      }

      try {
          const response = await fetch(url, {
              method: 'GET',
          });

          if (!response.ok) {
              throw new Error('Network response was not ok');
          }

          const blob = await response.blob();
          const contentDisposition = response.headers.get('content-disposition');

          let filename = 'file.vcf'; // default filename
          if (contentDisposition) {
              const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              const matches = filenameRegex.exec(contentDisposition);
              if (matches != null && matches[1]) {
                  filename = matches[1].replace(/['"]/g, '');
              }
          }

          // Append a timestamp to the filename
          filename = filename.replace('.vcf', '') + '_' + new Date().getTime() + '.vcf';

          // Use FileSaver.js to handle the download
          saveAs(blob, filename);
      } catch (error) {
          console.error('Failed to download file:', error);
      }
    },
    getMinutes(hours, minutes) {
      return parseInt(hours) * 60 + parseInt(minutes)
    },
    /** Function which checks if provided object is empty  */
    isEmpty(obj) {
      if (!obj) return true
      for (let prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          return false
        }
      }
      return JSON.stringify(obj) === JSON.stringify({})
    },
    /** Copy string to clipboard */
    copy(str = '') {
      const el = document.createElement('textarea')
      el.value = str
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      this.successSnack(this.lang[this.appLang].copied)
    },
    share(url, eventLog = 'qr') {

      if (this.isNative) {

        if(this.isLoggedIn) this.eventLog({type: eventLog + '_native_share'})

        Share.share({
          title: '',
          text: '',
          url,
          dialogTitle: ''
        });
      } else {

        if(this.isLoggedIn)  this.eventLog({type: eventLog + '_web_share'})

        navigator.share({
          title: '',
          text: '',
          url
        })
      }
    },
    async vibrate(style='high') {

      if(!Capacitor.isNativePlatform()) return;
      if(style==='light') await Haptics.impact({ style: ImpactStyle.Light });
      if(style==='medium') await Haptics.impact({ style: ImpactStyle.Light });
      if(style==='high')await Haptics.vibrate();
    },
    async getCaptchaToken(action){
      try {
       return await window.grecaptcha.execute(import.meta.env.VITE_APP_GOOGLE_RECAPTCHA_SITE_KEY, {action: action});
      } catch (err){
        console.log(err)
        return null
      }
    },
    async setWidgetUserData (data) {
      if(!this.isNative) return;
      await WidgetsBridgePlugin.setItem({key: data.key, value: data.value, group: 'group.co.tapni.app'});
      await WidgetsBridgePlugin.reloadAllTimelines();

    },
    replaceCompanyVariables (s, company) {
      if (!s) return '';
      return s
          .replace('[companyName]', company.name)
          ;
    },
    capitalize([first, ...rest]) {
      return first.toUpperCase() + rest.join("");
    },
    formatAndTranslateLinkType (link) {
      if (!link || !link.type) return '';
      // TODO: Write this function properly (Contact card example)
      if (link.type === 'contactcard') return this.lang[this.appLang].contactcard;
      if (link.type === 'customlink') return link.text;
      return this.capitalize(link.type);
    },
    sleep,
    asyncForEach
  }
}
