<template>
  <vue-bottom-sheet ref="connectionsFilterModal" @closed="close" :transition-duration="0.4">
    <h1
      class="left-text bold small-top half-bottom headerText"
      style="padding-left: 5px"
    >
      Upgrade Modal
    </h1>

    <div style="overflow-y: scroll; max-height: calc(92vh - 170px)">

    </div>

    <div style="display: flex; justify-content: space-between">
      <!-- Reset -->
      <button
        @click="close"
        class="button white-button half-bottom small-top"
        style="margin: 0; width: auto; margin-left: 10px"
      >
        {{lang[appLang].cancel }}
      </button>

      <!-- Save -->
      <button
        @click="upgrade"
        class="button black-button half-bottom small-top"
        style="margin: 0; width: 62%; margin-right: 10px"
      >
        Upgrade
      </button>
    </div>
  </vue-bottom-sheet>
</template>

<script>
import { EventBus } from "../../store/event-bus";
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
import  "@webzlodimir/vue-bottom-sheet/dist/style.css";
import to from "await-to-js";
import { Purchases, LOG_LEVEL, PURCHASES_ERROR_CODE } from "@revenuecat/purchases-capacitor";

export default {
  components: {
    VueBottomSheet,
  },
  data() {
    return {

    };
  },
  computed: {
  },
  mounted() {
    EventBus.$on("toggleUpgradeModal", this.toggleModal);
  },
  beforeDestroy() {
    EventBus["_events"]["toggleUpgradeModal"].pop();
  },
  methods: {
    async upgrade() {
      if (this.isNative) {
        try {
          // Fetch the offering from RevenueCat
          const offerings = await Purchases.getOfferings();

          console.log('Offerings', JSON.stringify(offerings));
          const currentOffering = offerings.current;

          if (!currentOffering) {
            throw new Error('No offerings available');
          }

          // Get the specific package you want to purchase
          const packageToPurchase = currentOffering.monthly; // Adjust based on your plan type
          if (!packageToPurchase) {
            throw new Error('No package available for monthly plan');
          }

          // Make the purchase
          const { purchaserInfo, productIdentifier } = await Purchases.purchasePackage({ aPackage: packageToPurchase });

          console.log('Transaction after purchase');
          console.log(productIdentifier);

          // Check permissions (entitlements) after purchase
          const proAccess = purchaserInfo.entitlements.active["tapni_pro_permissions"];
          console.log('Entitlement after purchase');
          console.log(proAccess);

          if (proAccess) {
            console.log('Tapni PRO Unlocked');
            // Unlock a feature
          }

          // Connect custom subscriber ID if needed
          // await Purchases.setAttributes({ subscriber_id: 'my_custom_identifier' });

        } catch (error) {
          console.error('Purchase failed', error);
          if (error.code === PURCHASES_ERROR_CODE.PURCHASE_CANCELLED_ERROR) {
            console.error('Purchase cancelled');
          } else {
            console.error('Error making purchase');
          }
        }
      } else {

      }
    },
    close() {
      EventBus.$emit("closeModal");
      document.body.classList.remove('modal-open');
    },
    async toggleModal() {

      this.$refs.connectionsFilterModal.open();
      document.body.classList.add('modal-open');

    },
  },
};
</script>

<style>

</style>
