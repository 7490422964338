/* eslint-disable no-unused-vars */
import router from "../router";
import lang from "./modules/lang";

import to from "await-to-js";

import UserService from "../services/UserService";
import DeviceService from "../services/DeviceService";
import LinkService from "../services/LinkService";
import TagService from "../services/TagService";
import CompanyService from "../services/CompanyService";
import ScanService from "../services/ScanService";
import LeadFormService from "../services/LeadFormService";

import { EventBus } from "./event-bus";
import axios from "axios";
import { Capacitor } from "@capacitor/core";
import CONSTANTS from "../constants";
import { checkAnalyticsCookies } from "../plugins/analytics";
import { initPushNotifications } from "../plugins/pushNotifications";
import { Device } from "@capacitor/device";
import { createStore } from 'vuex';

export default createStore({
  modules: {
    lang,
  },
  state: {
    appLanguage: "en",
    demo: false,
    isMaintenance: false,
    isOutdatedVersion: true,
    requestedPayout: false,
    authUrl: "",
    token: "",
    refreshToken: "",
    storage: {
      token: '',
      username: '',
      UserId: '',
      verifyEmail: '',
    },
    user: {},
    company: {},
    device: {},
    affiliate: {},
    linkTypes: [],
    isCompanyLoaded: false,
    initialized: false,
    initializing: '',
    loading: true,
    loggedInAccounts: {},
    analytics: {},
  },
  getters: {
    accessToken(state) {
      return state.token;
    },
    appLang(state) {
      return state.appLanguage;
    },
    isLoggedIn(state) {
      return !!state.token && state.token !== "" && state.token !== "null";
    },
    appVersion() {
      switch (Capacitor.getPlatform()) {
        case "android":
          return CONSTANTS.ANDROID_VERSION;
        case "ios":
          return CONSTANTS.IOS_VERSION;
        case "web":
          return CONSTANTS.WEB_VERSION;
      }
      return CONSTANTS.WEB_VERSION;
    },
    bundleVersion() {
      return CONSTANTS.WEB_VERSION || "0.0.0";
    },
    subdomain(state) {
      let subdomain = null;
      if (state.company && state.company.subdomain)
        subdomain = state.company.subdomain;
      if (state.route.query && state.route.query.s) subdomain = state.route.query.s;
      return subdomain;
    },
    rules(state) {
      return (
        state.user?.group?.rules || {
          editName: true,
          editBio: true,
          editUsername: true,
          activeProfile: true,
          publicProfile: true,
          changePassword: true,
          editContactCard: true,
          editProfilePicture: true,
          addTags: true,
          editCompanyTags: true,
          addLinks: true,
          editCompanyLinks: true,
        }
      );
    },
    forbiddenLinks(state) {
      return state.user?.group?.forbidden_links || [];
    },
  },
  actions: {
    async linkClick({}, data) {
      const [err, response] = await to(LinkService.linkClick(data));
      if (err) return EventBus.$emit('errorHandler', err);
      return response;
    },
    async eventLog({}, data) {
      const [err, response] = await to(UserService.eventLog(data));
      if (err) return EventBus.$emit('errorHandler', err);
      return response;
    },
    async getLinkTypes({ commit }) {

      const [err, response] = await to(LinkService.getLinkTypes());

      if (err) return EventBus.$emit('errorHandler', err);
      if (response.data.success) {
        commit("setLinkTypes", response.data.types);
      }
    },
    async maintenance({ commit, getters }) {
      const [err, response] = await to(
        axios.get("https://status.tapni.co/data/maintenance.json")
      );
      if (err) return console.error(err);
      if (response) {
        let maintenanceState = response.data;
        if (typeof response.data === "string") {
          maintenanceState = JSON.parse(response.data);
        }

        if (maintenanceState.api && maintenanceState.api.maintenanceActive) {
          commit("isMaintenance", true);
          router.push("/maintenance?msg=" + maintenanceState.api.msg);
        }

        if (
          maintenanceState.version &&
          maintenanceState.version.minimumVersion
        ) {
          if (
            parseFloat(maintenanceState.version.minimumVersion) >
            parseFloat(getters.appVersion)
          ) {
            commit("isOutdatedVersion", true);
            router.push("/update?v=" + maintenanceState.version.minimumVersion);
          }
        }
      }
    },
    async getCompanyBySubdomain({ state, commit, rootState, dispatch }, data) {
      const [err, response] = await to(
        CompanyService.getBySubdomain(data.subdomain)
      );
      if (err) EventBus.$emit('errorHandler', err);
      if (response && response.data) {
        commit("setCompany", response.data);
        return response.data;
      }
      return null;
    },
    async getUser({ state, getters, commit, dispatch, rootState }, data) {
      if (data) {
        if (state.initializing === JSON.stringify(data)) return;
        state.initializing = JSON.stringify(data);

        let err, response;
        if (data.username) {
          [err, response] = await to(UserService.getByUsername(data));
        } else if (data.serial) {
          [err, response] = await to(UserService.getByNumber(data));
        } else if (data.domain) {
          [err, response] = await to(UserService.getByDomain(data));
        }
        if (err) return EventBus.$emit('errorHandler', err);
        if (response && response.data.success) {
          if (!response.data.user && response.data.showDemoProfile) {
            if (getters.isLoggedIn) {
              router.push("/tags#activate");
            } else {
              return router.push("/" + response.data.showDemoProfile);
            }
          }

          let metapixelAnalytics = "";
          let googleAnalytics = "";

          // if user belongs to  company
          if (
            response.data?.company &&
            Object.keys(response.data.company).length > 0
          ) {
            commit("setCompany", response.data.company);

            const { Integrations } = response.data.company;
            if (Integrations?.length) {
              Integrations.forEach((integration) => {
                if (integration.analytic === "metapixel-analytics")
                  metapixelAnalytics = integration.key;
                if (integration.analytic === "google-analytics")
                  googleAnalytics = integration.key;
              });
            }
          } else {
            commit("setCompany", {});
            // metapixelAnalytics = CONSTANTS.METAPIXEL_ID;
            // googleAnalytics = CONSTANTS.GOOGLE_ANALYTICS_ID;
          }

          commit("setAnalytics", { metapixelAnalytics, googleAnalytics });

          // show cookie policy only on web app
          if (
            !Capacitor.isNativePlatform() &&
            (metapixelAnalytics || googleAnalytics)
          )
            checkAnalyticsCookies({ metapixelAnalytics, googleAnalytics });

          if (!response.data.user && !data.homeUser) {
            dispatch("logout");
          }

          // dispatch("lang/registerLang", response.data.user ? response.data.user.lang : "en");

          if (!data.homeUser) {
            commit("setUser", response.data.user);
          }

          // if user has custom form
          if (response.data.user && response.data.user.lead_form.FormTemplateId) {
            commit("setFormStyle", response.data.user.lead_form.form)
          }

          // Fetch affiliate
          if (response.data.user && response.data.user.id && getters.isLoggedIn) {
            dispatch("getAffiliate", response.data.user.id);
          }
        }

        if (!data.homeUser) {
          EventBus.$emit('loggedInUserLoaded')
        }

        state.initializing = '';
        return response ? response.data.user : null;
      }
    },
    async sleep({}, ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async refreshTokenAction({ state, commit, rootState, dispatch }, data) {
      EventBus.$emit('ssoLibraryEvent', { name: 'refreshTokenAction', data: {...data, refreshToken: state.refreshToken } })
      await dispatch('sleep', 3333);
    },
    async newPassword({ state, commit, rootState }, data) {
      const [err, response] = await to(UserService.newPassword(data));
      if (err) return EventBus.$emit('errorHandler', err);
      return response.data.success;
    },
    async deleteAccount({ state, commit, rootState }, data) {
      const [err, response] = await to(UserService.deleteAccount(data));
      if (err) return EventBus.$emit('errorHandler', err);
      return response.data.success;
    },
    async registerDevice({ state, commit, rootState }) {
      const deviceID = await Device.getId();
      const deviceInfo = await Device.getInfo();
      let deviceData = {
        device_id: deviceID.uuid,
        platform: deviceInfo.platform,
        device_info: {
          model: deviceInfo.model,
          manufacturer: deviceInfo.manufacturer,
          operatingSystem: deviceInfo.operatingSystem,
          osVersion: deviceInfo.osVersion,
          isVirtual: deviceInfo.isVirtual,
          webViewVersion: deviceInfo.webViewVersion,
        },
      };
      const [err, response] = await to(
        DeviceService.registerDevice(deviceData)
      );
      if (err) return EventBus.$emit('errorHandler', err);
      return response;
    },
    async addFcmToken({ state, commit, rootState }, data) {
      const [err, response] = await to(DeviceService.addFcmToken(data));
      if (err) return EventBus.$emit('errorHandler', err);
      return response;
    },
    async affiliatePayout({ state }) {
      const [err, response] = await to(UserService.affiliatePayout());
      if (err) return EventBus.$emit('errorHandler', err);
      state.requestedPayout = true;
      return response;
    },
    async acceptCompanyInvitation({ commit }, code) {
      const [err, response] = await to(
        CompanyService.acceptCompanyInvitation(code)
      );
      if (err) return EventBus.$emit('errorHandler', err);
      return response;
    },
    async getAffiliate({ commit }, UserId) {
      const [err, response] = await to(UserService.getAffiliate(UserId));
      if (err) return EventBus.$emit('errorHandler', err);
      if (response.data.success) {
        commit("setAffiliate", response.data.affiliate);
      }
    },
    async referralApply({ state, commit, rootState }, data) {
      const [err, response] = await to(UserService.referralApply(data));
      if (err) return EventBus.$emit('errorHandler', err);
      return response.data.success;
    },
    async logout({ state, commit, dispatch }, sendRequest = true) {
      if (sendRequest) {
        EventBus.$emit('ssoLibraryEvent', { name: 'ssoLogout', data: { refreshToken: state.refreshToken }});
      }
      commit("setCompany", null);
      commit("setUser", null);
    },
    async saveProfile({ state, commit, dispatch }, data) {
      const [err, response] = await to(UserService.save(data));
      EventBus.$emit("loadingHeader", false);
      if (err) return EventBus.$emit('errorHandler', err);
      if (response.data.success) {
        if (!data.preventTopOver) {
          router.push("/" + data.username + "#edit");
        }
        return response.data.success;
      }
    },
    async deletePicture({ state, commit, dispatch, rootState, getters }, data) {
      const [err, response] = await to(UserService.deletePicture(data));
      if (err) return EventBus.$emit('errorHandler', err);
      if (response.data.success) {
        dispatch("getUser", state.user);
        EventBus.$emit('successSnack',
          rootState.lang[getters.appLang].success_remove_picture
        );
      }
    },
    async removeLink({ state, commit, dispatch, rootState, getters }, data) {
      const [err, response] = await to(LinkService.remove(data));
      if (err) return EventBus.$emit('errorHandler', err);
      if (response.data.success) {
        dispatch("getUser", state.user);
        EventBus.$emit('successSnack',
          rootState.lang[getters.appLang].success_remove_link
        );
      }
    },
    async addOrUpdateLink({ state, commit, dispatch }, data) {
      EventBus.$emit("loadingHeader", true);
      let err, response;
      if (data.id) {
        [err, response] = await to(LinkService.edit(data));
      } else {
        [err, response] = await to(LinkService.add(data));
      }
      setTimeout(() => {
        EventBus.$emit("loadingHeader", false);
      }, 1000);
      if (err) return EventBus.$emit('errorHandler', err);
      if (response.data.success) {
        dispatch("getUser", state.user);
      }
    },
    async updateVCard({ state, commit, dispatch }, data) {
      EventBus.$emit("loadingHeader", true);
      let [err, response] = await to(UserService.updateVCard(data));
      setTimeout(() => {
        EventBus.$emit("loadingHeader", false);
      }, 1000);
      if (err) return EventBus.$emit('errorHandler', err);
      if (response.data.success) {
        dispatch("getUser", state.user);
      }
    },
    async updateLinkStyle({ state, commit }, link_style) {
      EventBus.$emit("loadingHeader", true);
      const [err, response] = await to(
        UserService.updateLinkStyle({ link_style })
      );
      if (err) return EventBus.$emit('errorHandler', err);
      if (response.data.success) {
        setTimeout(() => {
          EventBus.$emit("loadingHeader", false);
        }, 1000);
        return true;
      }
    },
    async updateIsActive({ state, commit, rootState, getters }, isActive) {
      const [err, response] = await to(
        UserService.updateIsActive({ isActive })
      );
      if (err) return EventBus.$emit('errorHandler', err);
      if (response.data.success) {
        if (isActive) {
          EventBus.$emit('successSnack',
            rootState.lang[getters.appLang].profile_active_message
          );
        } else {
          EventBus.$emit('successSnack',
            rootState.lang[getters.appLang].profile_inactive_message
          );
        }
      }
    },
    async updateIsPublic({ state, commit, rootState, getters }, isPublic) {
      const [err, response] = await to(
        UserService.updateIsPublic({ isPublic })
      );
      if (err) return EventBus.$emit('errorHandler', err);
      if (response.data.success) {
        if (isPublic) {
          EventBus.$emit('successSnack',
            rootState.lang[getters.appLang].profile_public_message
          );
        } else {
          EventBus.$emit('successSnack',
            rootState.lang[getters.appLang].profile_private_message
          );
        }
      }
    },
    async updateIsDirect({ state, commit, rootState, getters }, data) {
      const [err, response] = await to(LinkService.updateIsDirect(data));
      if (err) return EventBus.$emit('errorHandler', err);
      if (response.data.success) {
        if (data.is_direct) {
          EventBus.$emit('successSnack',
            rootState.lang[getters.appLang].redirect_enabled
          );
        } else {
          EventBus.$emit('successSnack',
            rootState.lang[getters.appLang].redirect_disabled
          );
        }
      }
    },
    async updateTag({ state, commit, dispatch, rootState, getters }, data) {
      const [err, response] = await to(TagService.edit(data));
      if (err) return EventBus.$emit('errorHandler', err);
      if (response.data.success) {
        if (data.is_active) {
          EventBus.$emit('successSnack', rootState.lang[getters.appLang].tag_active);
        } else {
          EventBus.$emit('successSnack', rootState.lang[getters.appLang].tag_inactive);
        }
        dispatch("getUser", state.user);
      }
    },
    async removeTag({ state, commit, dispatch, rootState, getters }, data) {
      const [err, response] = await to(TagService.remove(data));
      if (err) return EventBus.$emit('errorHandler', err);
      if (response.data.success) {
        dispatch("getUser", state.user);
        EventBus.$emit('successSnack', rootState.lang[getters.appLang].tag_removed);
      }
    },
    async activate({ state, commit, rootState }, data) {
      if (data.serial) {
        data.serial = data.serial
          .replace(/:/g, "")
          .replace(/ /g, "")
          .toLowerCase();
        const [err, response] = await to(TagService.activate(data));
        if (err) return EventBus.$emit('errorHandler', err);
        return response;
      }
    },
    async connect({ state, commit, rootState }, data) {
      const [err, response] = await to(UserService.connect(data));
      if (err) return EventBus.$emit('errorHandler', err);
      return response;
    },
    async saveConnection({ state, commit, rootState }, data) {
      const [err, response] = await to(UserService.saveConnection(data));
      if (err) return EventBus.$emit('errorHandler', err);
      return response;
    },
    async deleteConnection({ state, commit, rootState }, data) {
      const [err, response] = await to(UserService.deleteConnection(data));
      if (err) return EventBus.$emit('errorHandler', err);
      return response;
    },
    async createConnection(context, data) {
      const [err, response] = await to(UserService.createConnection(data));
      if (err) return EventBus.$emit('errorHandler', err);
      return response;
    },
    async accept({ state, commit, rootState, getters }, data) {
      const [err, response] = await to(UserService.accept(data));
      if (err) return EventBus.$emit('errorHandler', err);
      if (response.data.success) {
        EventBus.$emit('successSnack',
          rootState.lang[getters.appLang].connection_accepted
        );
      }
    },
    async getConnections(context, params) {
      const [err, response] = await to(UserService.getConnections(params, context.getters.accessToken));
      if (err) return EventBus.$emit('errorHandler', err);
      return response;
    },
    async createCardScan({ state, commit, rootState }, data) {
      const [err, response] = await to(ScanService.createCardScan(data));
      if (err) return EventBus.$emit('errorHandler', err);
      return response;
    },
    async setLeadForm({ state, commit, rootState }, data) {
      const [err, response] = await to(LeadFormService.setLeadForm(data));
      if (err) return EventBus.$emit('errorHandler', err);
      return response;
    },
    changeStyle({ state }, style) {
      state.user.customStyle = style;
    },
    async loginUsingQR({ state, commit, rootState }, data) {
      const [err, response] = await to(UserService.loginUsingQR(data));
      if (err) return EventBus.$emit('errorHandler', err);
      return response.data.success;
    },
  },
  mutations: {
    setStorage(state, storage) {
      state.storage = storage;
      state.token = storage.token;
      state.refreshToken = storage.refreshTokens.split(',')[0];
    },
    setLoggedInAccounts(state, accounts) {
      state.loggedInAccounts = {};
      if (accounts && accounts.length) {
        accounts.forEach((account) => {
          state.loggedInAccounts[account.username] = account;
        });
      }
    },
    setAffiliate(state, affiliate) {
      state.affiliate = affiliate;
    },
    setLinkTypes(state, linkTypes) {
      state.linkTypes = linkTypes;
    },
    async updateLinks(state, links) {
      if (links) {
        links.forEach((link, i) => {
          link.order = i + 1;
        });
        const currentLinks = state.user.links;
        state.user.links = links;
        if (JSON.stringify(currentLinks) !== JSON.stringify(links)) {
          EventBus.$emit("loadingHeader", true);
          const [err, response] = await to(LinkService.updateLinksOrder(links));
          if (err) return EventBus.$emit('errorHandler', err);
          if (response.data.success) {
            setTimeout(() => {
              EventBus.$emit("loadingHeader", false);
            }, 1000);
          }
        }
      }
    },
    isMaintenance(state, isMaintenance) {
      state.isMaintenance = isMaintenance;
    },
    isOutdatedVersion(state, isOutdatedVersion) {
      state.isOutdatedVersion = isOutdatedVersion;
    },
    setUser(state, user) {
      state.user = user;
      state.appLanguage = user?.lang || "en";
    },
    setUserOffline (state) {
      state.user = state.storage.localUser;
    },
    setCompany(state, company) {
      state.company = company;
      state.isCompanyLoaded = true;
    },
    setRefreshToken(state, token) {
      state.refreshToken = token;
    },
    setToken(state, token) {
      state.token = token
    },
    setInitialize(state, initialized) {
      state.initialized = initialized;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setLanguage(state, lang) {
      state.appLanguage = lang;
    },
    setAnalytics(state, data) {
      state.analytics.googleAnalytics = data.googleAnalytics;
      state.analytics.metapixelAnalytics = data.metapixelAnalytics;
    },
    setCustomStyle(state, data) {
      state.user.customStyle = data;
    },
    setFormStyle(state, data) {
      if (state.user.lead_form) {
        state.user.lead_form.form = {
          ...data
        };
      }
    },
  },
});
