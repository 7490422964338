<template>
  <div
    class="menu-wrapper menu-light menu-modal center-text activate-page"
    :class="{ 'active-menu': toggle }"
    style="height: auto; max-height: 92vh; margin-top: -172.5px"
  >
    <a
      @click="close"
      class="color-black pull-right pointer"
      style="
        margin-top: 5px;
        position: absolute;
        right: 15px;
        text-align: right;
      "
    >
      <i class="font-17 fa color-black">
        <img
          src="https://cdn.tapni.co/icons/down-arrow.png"
          class="fa responsive-image"
          style="width: 45%"
        />
      </i>
    </a>

    <!--
    <img :src="'https://cdn.tapni.co/icons/reminder.png'" class="connections-img" :alt="lang[appLang].my_connections"/>
    <h4 class="small-bottom">{{lang[appLang].follow_up_reminder }}</h4>
    -->

    <h1
      class="left-text bold small-top half-bottom headerText"
      style="padding-left: 5px"
    >
      {{lang[appLang].follow_up_reminder }}
    </h1>

    <div style="display: flex; flex-wrap: wrap; justify-content: space-between">
      <div class="half-top" style="width: 48%">
        <button
          @click="minutes = 0"
          class="button white-button button-full pointer"
          :class="{ 'active-button': minutes === 0 }"
        >
          {{lang[appLang].now }}
        </button>
      </div>
      <div class="half-top" style="width: 48%">
        <button
          @click="minutes = 60"
          class="button white-button button-full pointer"
          :class="{ 'active-button': minutes === 60 }"
        >
          {{lang[appLang].one_hour }}
        </button>
      </div>
      <div class="half-top" style="width: 48%">
        <button
          @click="minutes = 1440"
          class="button white-button button-full pointer"
          :class="{ 'active-button': minutes === 1440 }"
        >
          {{lang[appLang].one_day }}
        </button>
      </div>
      <div class="half-top" style="width: 48%">
        <button
          @click="minutes = 4320"
          class="button white-button button-full pointer"
          :class="{ 'active-button': minutes === 4320 }"
        >
          {{lang[appLang].three_days }}
        </button>
      </div>
      <div class="half-top" style="width: 48%">
        <button
          @click="minutes = 10080"
          class="button white-button button-full pointer"
          :class="{ 'active-button': minutes === 10080 }"
        >
          {{lang[appLang].one_week }}
        </button>
      </div>
      <div class="half-top" style="width: 48%">
        <button
          @click="minutes = 20160"
          class="button white-button button-full pointer"
          :class="{ 'active-button': minutes === 20160 }"
        >
          {{lang[appLang].two_weeks }}
        </button>
      </div>
      <div class="half-top" style="width: 48%">
        <button
          @click="minutes = 43200"
          class="button white-button button-full pointer"
          :class="{ 'active-button': minutes === 43200 }"
        >
          {{lang[appLang].one_month }}
        </button>
      </div>
      <div class="half-top" style="width: 48%">
        <button
          @click="minutes = 'custom'"
          class="button white-button button-full pointer"
          :class="{ 'active-button': minutes === 'custom' }"
        >
          {{lang[appLang].custom }}
        </button>
      </div>
    </div>

    <VueDatePicker
        style="width: 100%"
        v-show="minutes === 'custom'"
        v-model="customTime"
        class="edit-input full-top half-bottom"
        color="#000000"
        format="dd.MM.yyyy HH:mm"
        model-type="yyyy-MM-dd HH:mm"
        :placeholder="lang[appLang].select_date_time"
        :minutes-increment="5"
        :no-minutes-overlay="true"
        :min-date="minDate"
    />

    <textarea
      v-model="reminderText"
      :placeholder="lang[appLang].enter_reminder_text"
      class="edit-input full-bottom half-top"
      rows="4"
      wrap="hard"
      maxlength="150"
      style="
        width: 100%;
        margin: 0 auto;
        min-height: 65px;
        resize: none;
        line-height: 20px;
      "
      @keydown="$event.keyCode === 13 ? $event.preventDefault() : false"
    />
    <div class="small-top half-bottom" style="width: 100%">
      <button
        class="button black-button button-full pointer"
        @click="setReminder"
      >
        {{lang[appLang].set_reminder }}
      </button>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../../store/event-bus";
import { LocalNotifications } from "@capacitor/local-notifications";
import VueDatePicker from '@vuepic/vue-datepicker';
import UserService from "../../services/UserService";
import { Filesystem, Directory } from "@capacitor/filesystem";
import { Capacitor } from "@capacitor/core";
import moment from "moment";

export default {
  components: {
    VueDatePicker
  },
  data() {
    return {
      toggle: false,
      minutes: 0,
      reminderText: "",
      name: "",
      username: "",
      photo: null,
      email: "",
      customTime: [],
      minDate: moment().format("yyyy/MM/DD HH:mm"),
    };
  },
  async mounted() {
    EventBus.$on("toggleFollowUpScheduleModal", (data) => {
      LocalNotifications.requestPermissions();
      this.toggle = true;
      this.minutes = 0;
      let conn = data;
      this.customTime = moment().add(1, "days").format("yyyy/MM/DD HH:mm");

      if (conn.status === "connected") {
        if (conn.receiver && this.storage && conn.receiver.id !== this.storage.UserId) {
          this.name = conn.receiver.name || "@" + conn.receiver.username;
          this.username = conn.receiver.username;
          this.photo = conn.receiver.photo;
        }

        if (conn.requester && this.storage && conn.requester.id !== this.storage.UserId) {
          this.name = conn.requester.name || "@" + conn.requester.username;
          this.username = conn.requester.username;
          this.photo = conn.requester.photo;
        }
      } else {
        this.name = conn.payload.name || conn.payload.email;
        this.username = null;
        this.email = conn.payload.email || "";
        this.photo = null;
      }
      this.reminderText = "This is a reminder to follow up with " + this.name;
    });
    EventBus.$on("closeModal", () => {
      this.toggle = false;
    });
  },
  beforeDestroy() {
    EventBus["_events"]["toggleFollowUpScheduleModal"].pop();
  },
  methods: {
    close() {
      EventBus.$emit("closeModal");
    },
    async setReminder() {
      //add one more second more, for case when 0 minutes are. schedule time must be now + 2 sec minimum
      let at;
      if (this.minutes === "custom" && this.customTime)
        at = new Date(moment(this.customTime, "yyyy/MM/DD HH:mm").valueOf());
      else at = new Date(new Date().getTime() + this.minutes * 60000 + 2000);

      let id;
      if (Capacitor.getPlatform() === "ios")
        id = parseInt(new Date().getTime());
      else id = parseInt(new Date().getTime() / 1000);

      let data = {
        notifications: [
          {
            id,
            title: "Follow Up Reminder",
            body: this.reminderText,
            sound: null,
            data: {
              username: this.username,
              name: this.name,
              email: this.email,
              photo: this.photo,
            },
            extra: {
              username: this.username,
              name: this.name,
              email: this.email,
              photo: this.photo,
            },
            schedule: { at: at },
          },
        ],
      };

      if (this.photo && Capacitor.getPlatform() === "ios") {
        let img = await UserService.getFile(this.photo);
        let imgBase64 = Buffer.from(img.data, "binary").toString("base64");
        const fileName = id.toString() + ".jpeg";

        const savedFile = await Filesystem.writeFile({
          path: fileName,
          data: imgBase64,
          directory: Directory.Data,
        });

        data.notifications[0].attachments = [
          { id: id.toString(), url: savedFile.uri },
        ];
      }

      LocalNotifications.schedule(data);

      this.successSnack(this.lang[this.appLang].reminder_created);
      this.close();
    },
  },
};
</script>

<style>
.connections-img {
  margin: 0 auto;
  margin-top: 2px;
  width: 65px;
}

.active-button {
  border: 1px solid #000000;
}

.vpd-content {
  border-radius: 20px;
}

.vpd-header {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.vpd-actions button:nth-child(3) {
  display: none;
}
</style>
