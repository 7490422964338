<template>
  <form
      v-if="!isEmpty(user) && user.lead_form"
      @submit.prevent="save"
      class="menu-wrapper menu-light menu-modal activate-page"
      :class="{ 'active-menu': toggle }"
      style="height: auto; max-height: 90vh; margin-top: -172.5px"
  >
    <a
        @click="close"
        class="color-black pull-right pointer"
        style="margin-top: 5px; position: absolute; right: 5px"
    >
      <i class="font-17 fa color-black">
        <img
            src="https://cdn.tapni.co/icons/down-arrow.png"
            class="fa responsive-image"
            style="
            width: 45%;
            display: block !important;
            margin: 0 auto !important;
          "
        />
      </i>
    </a>

    <img :src="'https://i.ibb.co/nL4Gc9B/exchnage-contant-form.png'" class="lead-capture-img"/>

    <div style="overflow-y: scroll; max-height: calc(92vh - 98px)">
      <!-- Lead Capture enable/disable Section -->
      <div id="lead-capture-show-section" class="content-boxed switch-section bg-white tiny-top" style="height: auto"
      >
        <div
            class="ios-switch"
            style="display: inline-block; margin: -2px 0 7px 10px; float: right"
        >
          <input
              v-model="user.lead_form.show"
              type="checkbox"
              name="ios-switch"
              class="ios-switch-checkbox"
              :id="'lead-capture-show-switch'"
          />
          <label class="ios-switch-label" :for="'lead-capture-show-switch'"></label>
        </div>
        <h5 class="pull-left regularbold">
          {{lang[appLang].enable_lead_button }}
        </h5>
        <p class="pull-left">
          {{lang[appLang].enable_lead_button_p }}
        </p>
      </div>

      <!-- Exchange(lead capture) Form  auto-open -->
      <div id="lead-capture-form-open-section" class="content-boxed switch-section bg-white" style="height: auto; margin-bottom: 0">
        <div class="ios-switch" style="display: inline-block; margin: -2px 0 7px 10px; float: right">
          <input
              v-model="user.lead_form.openAuto"
              type="checkbox"
              name="ios-switch"
              class="ios-switch-checkbox"
              :id="'lead-capture-form-open-switch'"
              :disabled="!user.lead_form.show || user.lead_form.forceFilling"
          />
          <label class="ios-switch-label" :class="{ 'switch-disabled': !user.lead_form.show || user.lead_form.forceFilling}"  :for="'lead-capture-form-open-switch'"></label>
        </div>
        <h5 class="pull-left regularbold">
          {{lang[appLang].form_auto }}
        </h5>
        <p class="pull-left">{{lang[appLang].form_auto_p }}</p>
      </div>

      <!-- Force Exchange(lead capture) Form filling -->
      <div id="lead-capture-form-force-section" class="content-boxed switch-section bg-white" style="height: auto; margin-bottom: 20px;">
        <div class="ios-switch" style="display: inline-block; margin: -2px 0 7px 10px; float: right">
          <input
              v-model="user.lead_form.forceFilling"
              type="checkbox"
              name="ios-switch"
              class="ios-switch-checkbox"
              :id="'lead-capture-form-force-switch'"
              :disabled="!user.lead_form.show"
          />
          <label class="ios-switch-label" :class="{ 'switch-disabled': !user.lead_form.show}" :for="'lead-capture-form-force-switch'"></label>
        </div>
        <h5 class="pull-left regularbold">
          {{lang[appLang].form_required }}
        </h5>
        <p class="pull-left">{{lang[appLang].form_required_p }}</p>
      </div>
    </div>
  </form>
</template>

<script>
import { EventBus } from "../../store/event-bus";
import { mapState, mapActions } from "vuex";

export default {
  name: "LeadCaptureSetup",
  data() {
    return {
      toggle: false,
      saveTimeout: null,
    };
  },
  computed: {
    rules() {
      return this.$store.getters.rules;
    },
  },
  mounted() {
    EventBus.$on("toggleLeadCaptureSetupModal", this.toggleModal);
    EventBus.$on("closeModal", () => {
      this.toggle = false;
    });
  },
  beforeDestroy() {
    EventBus['_events']['toggleLeadCaptureSetupModal'].pop()
  },
  methods: {
   ...mapActions(["setLeadForm"]),
    close() {
      EventBus.$emit("closeModal");
    },
    toggleModal() {
      this.toggle = !this.toggle;
    },
    timeoutSave() {
      clearInterval(this.saveTimeout);
      this.saveTimeout = setTimeout(() => {
        if (this.isEmpty(this.user)) return;
        let data = {
          show: this.user.lead_form.show,
          openAuto: this.user.lead_form.openAuto,
          forceFilling: this.user.lead_form.forceFilling,
        }
        this.setLeadForm(data);
      }, 2000);
    },
  },
  watch: {
    'user.lead_form.show'(nv, ov) {
      if (nv === undefined || ov === undefined || !this.toggle) return;
      if(!nv && !this.isEmpty(this.user)) {
        this.user.lead_form.openAuto = false;
        this.user.lead_form.forceFilling = false;
      }
      this.timeoutSave();
    },
    'user.lead_form.openAuto'(nv, ov){
      if (nv === undefined || ov === undefined || !this.toggle) return;
      this.timeoutSave();
    },
    'user.lead_form.forceFilling' (nv, ov) {
      if (nv === undefined || ov === undefined || !this.toggle) return;
      if(nv) this.openAuto = true;
      this.timeoutSave();
    },
  },
};
</script>

<style scoped>
  .lead-capture-img {
    margin: 0 auto;
    margin-top: 2px;
    margin-bottom: 10px;
    border: 1px #dedede solid;
    border-radius: 8px;
    width: 200px;
  }

  .form-fields {
    margin-left: 10px;
    display:flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  .checkboxes {
    display: flex;
    align-items: center;
    vertical-align: middle;
    word-wrap: break-word;
    margin-right: 7px;
    font-size: 15px;
  }

  input.checkboxes {
    width: 22px;
    height: 22px;
    accent-color: black;
  }
</style>
