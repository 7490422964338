<template>
  <div v-if="user"
    class="menu-wrapper menu-white menu-modal activate-page center-text"
    :class="{ 'active-menu': toggle }"
    style="height: auto; max-height: 90vh; margin-top: -172.5px"
    ref="bottomSheetDraggableArea"
  >
    <span>
      <a
                  @click="close"
                  class="color-black pull-right pointer"
                  style="margin-top: 5px; position: absolute; right: 5px"
              >
        <i class="font-17 fa color-black">
          <img src="https://cdn.tapni.co/icons/down-arrow.png" class="fa responsive-image" style="width: 45%" alt=""/>
        </i>
      </a>
      <h1 class="bold center-text small-top full-bottom">
        {{lang[appLang].share }} {{shareText}}
      </h1>


      <!-- Swiper -->
      <swiper
          :modules="swiperModules"
          ref="swiper"
          class="swiper"
          :speed="500"
          :navigation="{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }"
          :pagination="swiperOption.pagination"
          :initial-slide="0"
          @slideChange="onSlideChange"
      >
        <swiper-slide v-show="user.is_public">
          <div id="qr_code_profile" class="center-text small-top small-bottom"></div>
        </swiper-slide>
        <swiper-slide v-show="vcardExist">
          <div id="qr_code_vcard" class="center-text"></div>
        </swiper-slide>
        <swiper-slide v-for="link in qrLinks" :key="link.id" v-show="link && link.type !== 'contactcard'">
          <div :id="'qr_code_link_' + link.id" class="center-text small-top small-bottom"></div>
        </swiper-slide>

            <!-- Navigation Buttons -->
        <div v-show="false" class="swiper-button-prev"></div>
        <div v-show="false" class="swiper-button-next"></div>

            <!-- Pagination -->
        <div v-show="user.is_public" class="swiper-pagination" @touchstart.stop></div>
      </swiper>

      <!--
      <a style="text-align: center; display: block;" class="pointer" @click="downloadQR">{{lang[appLang].save}} QR Code</a>
      -->
      <button v-if="shareLink" @click="copy('https://' + shareLink)" class="button white-button font-12 pointer small-top" style="width: 100%;"> <div style="display: flex; align-items: center;"><img src="https://cdn.tapni.co/icons/copy.png" style="width: 30px; height: 30px; margin-right: 10px;"> <span style="width: 75%; font-size: 14px;">{{'https://' + shareLink}} </span> </div></button>
      <button v-if="webShareApiSupported || isNative" @click="() => share('https://' + shareLink, 'qr')" class="button white-button font-12 pointer small-top" style="width: 100%;"> <div style="display: flex; align-items: center;"><img src="https://cdn.tapni.co/icons/share.png" style="width: 30px; height: 30px; margin-right: 10px; padding: 4px;"> <span style="width: 75%; font-size: 16px;">{{lang[appLang].share_profile}}</span> </div></button>
      <!--<button v-if="isiOS" @click="saveToAppleWallet" class="button white-button font-12 pointer small-top" style="width: 100%;"> <div style="display: flex; align-items: center;"><img src="https://cdn.tapni.co/icons/apple-wallet.png" style="width: 30px; height: 30px; margin-right: 10px;"> <span style="width: 75%; font-size: 16px;">{{lang[appLang].save_to_apple_wallet}}</span> </div></button>-->
      <button v-if="isiOS" @click="saveToAppleWallet()" class="button white-button font-12 pointer small-top" style="width: 100%; background: #1f1f1f !important; color: white"> <div style="display: flex; align-items: center;justify-content: center;"><img src="https://cdn.tapni.co/icons/apple-wallet.png" style="width: 30px; height: 30px; margin-right: 10px;"> <span style="font-size: 16px;">{{lang[appLang].save_to_apple_wallet}}</span> </div></button>
      <button v-if="isAndroid" @click="saveToGoogleWallet()" class="button white-button font-12 pointer small-top" style="width: 100%; background: #1f1f1f !important; color: white"> <div style="display: flex; align-items: center;justify-content: center;"><img src="https://cdn.tapni.co/icons/google-wallet.png" style="width: 30px; height: 30px; margin-right: 10px;"> <span style="font-size: 16px;">{{lang[appLang].save_to_google_wallet}}</span> </div></button>
      <p v-if="false"
          @click="toggleUsernameModal()"
          class="
          color-black
          center-text
          pointer
          half-top
          half-bottom
          tiny-top-padding
          font-13
        "
          v-html="lang[appLang].share_profile_change_username"
      />
    </span>
  </div>
</template>

<script>
import { Filesystem, Directory } from '@capacitor/filesystem';
import { EventBus } from "@/store/event-bus.js";
import { mapActions } from "vuex";
import QRCodeStyling from "qr-code-styling";
import LinkIcon from "../LinkIcon";
import UtilService from '@/services/UtilService';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { generateVcard } from "@tapni/js-utils";
import to from "await-to-js";
import UserService from "@/services/UserService.js";
import { ScreenBrightness } from '@capacitor-community/screen-brightness';

export default {
  name: "Share",
  components: {
    Swiper,
    SwiperSlide,
    LinkIcon
  },
  data() {
    return {
      toggle: false,
      rendered: false,
      qrType: 'profileShare',
      vcardExist: false,
      template: {},
      qrCodes: [],
      step: 0,
      swiperModules: [Pagination],
      swiperOption: {
        speed: 500,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          renderBullet: this.renderBullet
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      currentBrightness: null,
    };
  },
  computed: {
    qrLinks() {
      return this.user.links ? this.user.links.filter(l => l.type !== 'contactcard') : [];
    },
    shareLink() {
      if (this.step === 0 && this.user.is_public) {
        return this.hostname + "/" + this.user.username
      }
      if (this.step === 1) {
        const contactCardLink = this.user.links?.find(l => l.type === 'contactcard');
        return contactCardLink ? `${this.hostname}/${contactCardLink.hash}` : '';
      }
      else if (this.qrLinks[this.step - 2]) return this.hostname + "/" + this.qrLinks[this.step - 2].hash
      return '';
    },
    shareText() {
      if (this.step === 0 && this.user.is_public) {
        return this.lang[this.appLang].profile
      }
      if (this.step === 1 || (!this.user.is_public && this.step === 0)) {
        return this.lang[this.appLang].contactcard
      }
      else return this.formatAndTranslateLinkType(this.qrLinks[this.step - 2])
    },
    swiper() {
      return this.$refs.swiper.$swiper
    }
  },
  mounted() {
    EventBus.$on("closeModal", () => {
      this.toggle = false;
    });
    EventBus.$on("toggleQRModal", this.toggleModal);
    EventBus.$on('saveToAppleWallet', this.saveToAppleWallet);

    if (!this.isEmpty(this.user)) {
      this.init();
    }
  },
  beforeDestroy() {
    EventBus['_events']['toggleQRModal'].pop()
    EventBus['_events']['saveToAppleWallet'].pop()
  },
  methods: {
    ...mapActions(["eventLog"]),
    onSlideChange(swiper) {
      this.$nextTick(() => {
        this.step = swiper.realIndex;
        this.$forceUpdate();
      });
    },
    renderBullet(index, className) {
      let imageSrc = 'https://cdn.tapni.co/icons/customlink.png';

      if (index === 0) {
        imageSrc = this.user.photo;
        if (!this.user.is_public) {
          imageSrc = '';
          return `<span class="${className}" style="display: none!important;"></span>`;
        }
      } else if (index === 1) {
        imageSrc = 'https://cdn.tapni.co/icons/contactcard.png';
      } else {
        const link = this.qrLinks[index - 2];
        if (link && link.type !== 'contactcard') {
          if (link.style.custom_icon) {
            imageSrc = link.style.custom_icon;
          } else {
            imageSrc = 'https://cdn.tapni.co/icons/' + link.type + '.png';
          }
        }
      }

      return `<span class="${className}" style="background-image: url('${imageSrc}'); background-size: contain;"></span>`;
    },
    next () {
      this.step = this.step + 1
      this.swiper.slideNext()
    },
    async init() {
      if (!this.isEmpty(this.user) && !this.rendered && this.isEmpty(this.template)) {
        this.rendered = true;

        const [err, response] = await to(UserService.getAllTemplates('qr'));
        if (err) return this.errorHandler(err);

        this.template = response.data.filter(t => t.id === this.user.QrTemplateId)[0];

        let qrData = "https://" + this.hostname + "/" + this.user.username + "?utm_source=tapni-app&utm_medium=qr"
        let qrSize = 320;
        if (window.innerWidth < 360) {
          qrSize = 280;
        }

        if (this.user.is_public) {
          this.generateQRCode(qrData, qrSize, 'qr_code_profile', { type: 'profile' })
        } else this.step = 1;

        if(!this.isEmpty(this.user.vcard)) {
          this.vcardExist = true
          let vcardString = generateVcard(this.user.vcard)
          await this.sleep(1500);
          this.generateQRCode(vcardString, qrSize, 'qr_code_vcard', { type: 'contactcard' })
        }

        await this.asyncForEach(this.qrLinks, async (link) => {
          await this.sleep(1500);
          this.generateQRCode("https://" + this.hostname + "/" + link.hash + "?utm_source=tapni-app&utm_medium=qr", qrSize, 'qr_code_link_' + link.id, link)
        })
      }
    },
    generateQRCode(data, qrSize, qrElementId = 'qr_code_profile', link){

      let linkImg = null;

      if (link) {
        if (link.type === 'profile') {
          // linkImg = this.user.photo;
        } else if (link.type === 'contactcard') {
          // linkImg = 'https://cdn.tapni.co/icons/contactcard.png';
        } else {
          // linkImg = 'https://cdn.tapni.co/icons/' + link.type + '.png';
        }
      }

      this.qrCodes.push(new QRCodeStyling({
        ...this.template?.config,
        type: 'canvas',
        width: qrSize,
        height: qrSize,
        margin: 0,
        data,
        ...(this.template && this.template.media.photo && this.template.media.photo !== '[photo]' ? {
          image: this.template.media.photo
        } : {
        ...(linkImg ? { image: linkImg } : {})
        })
      }));

      let divQR = document.getElementById(qrElementId);
      if (divQR) {
        let element = divQR.getElementsByTagName("canvas"), index;
        for (index = element.length - 1; index >= 0; index--) {
          element[index].parentNode.removeChild(element[index]);
        }

        this.qrCodes[this.qrCodes.length - 1].append(document.getElementById(qrElementId));
      }
    },
    async downloadQR(){
      let linkEl= document.createElement('a');
      linkEl.download = 'qr.png';
      let qrDiv = document.getElementById('qr-code');
      let base64 = qrDiv.getElementsByTagName("canvas")[0].toDataURL();
      linkEl.href = base64;
      if (this.isNative) await Filesystem.writeFile({path: 'qr.png', data: base64, directory: Directory.Data});
      else linkEl.click();
      linkEl.remove();
    },
    close() {
      EventBus.$emit("toggleQRModal");
    },
    async toggleModal() {
      this.toggle = !this.toggle;

      if (this.toggle) {

        if (this.isNative) {
          const {brightness: currentBrightness} = await ScreenBrightness.getBrightness();
          this.currentBrightness = currentBrightness;
          const brightness = 1;
          await ScreenBrightness.setBrightness({ brightness });
        }

        try {
          this.eventLog({
            type: "qr_modal",
          });
        } catch (e) {
          console.log('catch err share', e)
        }
      } else {
        if (this.isNative && this.currentBrightness) {
          await ScreenBrightness.setBrightness({ brightness: this.currentBrightness });
        }
      }
    },
    changeQRType(type) {
      if (!type) return;

      if(type === 'profileShare') {
        this.qrType = 'profileShare'
      }
      if(type === 'contactCard') {
        this.qrType = 'contactCard'
      }
      this.$forceUpdate();
    },
    toggleUsernameModal() {
      EventBus.$emit("toggleUsernameModal");
      this.toggleModal();
    },
    saveToAppleWallet(templateId = null) {
      if (!this.isEmpty(this.company) && !templateId) {
        EventBus.$emit('toggleTemplatesModal', 'wallet');
        this.close();
        return EventBus.$emit("openModalOverlay");
      }
      window.open(import.meta.env.VITE_APP_API_ROOT + "/v1/users/" + this.user.id + "/passkit" + (templateId ? '?templateId=' + templateId : ''), '_blank');
    },
    saveToGoogleWallet(templateId = null){
      if (!this.isEmpty(this.company) && !templateId) {
        EventBus.$emit('toggleTemplatesModal', 'wallet');
        this.close();
        return EventBus.$emit("openModalOverlay");
      }
      window.open(import.meta.env.VITE_APP_API_ROOT + "/v1/users/" + this.user.id + "/googlepass" + (templateId ? '?templateId=' + templateId : ''), '_blank');
    },
  },
  watch: {
    user() {
      this.init();
    },
    "user.username"() {
      this.init();
    },
  },
};
</script>

<style>
.link-copy:hover {
  color: #2d2d2d;
}
.qr-img {
  margin: 0 auto;
  margin-top: 2px;
  width: 65px;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #373737;
}
.swiper-button-prev {
  left: 0px;

}
.swiper-button-next {
  right: 0px;
}
.swiper-button-prev, .swiper-button-next {
  color: #373737;
}
.swiper-pagination-bullet {
  width: 40px;
  height: 40px;
  margin: 0 5px;
  background: none;
}
.swiper-pagination-bullet {
  opacity: 0.9;
  border-radius: 25%;
  display: inline-block; /* Align bullets in a row */
}
.swiper-pagination-bullet-active {
  border: 1px solid #0a99d2;
  transition: 1s;
}
.swiper-pagination {
  margin-top: 10px;
  position: relative;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch; /* for smooth scrolling on iOS */
}
</style>
