<template>
  <form
    @submit.prevent="saveLink"
    class="menu-wrapper menu-light menu-modal center-text activate-page no-padding"
    :class="{ 'active-menu': toggle }"
    :style="`height: auto; margin-top: -172.5px;`"
  >
    <!-- Close Modal Icon -->
    <a
      @click="close"
      class="color-black pull-right pointer"
      style="margin-top: 20px; position: absolute; right: 5px"
    >
      <i class="font-17 fa color-black">
        <img src="https://cdn.tapni.co/icons/down-arrow.png" alt="down-arrow" class="fa responsive-image" style="width: 45%;"/>
      </i>
    </a>

    <!-- Modal Title -->
    <h3 class="modal-title">{{lang[appLang].link_text_contact_card }}</h3>

    <!-- Modal Body -->
    <div
      class="contactcard"
      style="overflow-y: scroll; max-height: calc(92vh - 140px); width: 100%"
    >
      <!-- Link Icon & Text -->
      <div style="display: flex; width: 90%; margin: 0 auto">
        <div v-if="data">
          <a @click="uploadLinkIcon" class="pointer edit-shadow" style="width: 30px; left:60%; top: 0%; z-index: 999; position: absolute; border-radius: 40px; overflow: hidden">
            <img :src="'https://cdn.tapni.co/icons/edit.png'" class="responsive-image"/>
          </a>
          <img v-if="!linkImageBase64"
              :src="data.custom_icon ? data.style.custom_icon : 'https://cdn.tapni.co/icons/contactcard.png'"
              class="link-img"
              :alt="data.name"
          />
          <img v-else
               :src="linkImageBase64"
               class="link-img"
               :alt="data.name"
          />
        </div>

        <div style="display: flex; flex-direction: column; width: 100%">
          <input
            v-if="data && data.desc"
            id="linkText"
            type="text"
            v-model="data.desc[appLang].short"
            :placeholder="lang[appLang].link_text_p"
            style="width: 100%; margin-bottom: 5px;"
            class="edit-input h-50 lh-50"
          />
          <p
            class="close-text close-menu"
            style="text-align: left !important; margin-left: 5px"
          >
            {{lang[appLang].link_icon }}
          </p>
        </div>
      </div>

      <!-- First Name & Last Name -->
      <div
        style="
          width: 90%;
          display: flex;
          flex-direction: column;
          margin: 0 auto;
        "
      >
        <input
          v-if="data"
          id="vcardFirstName"
          type="text"
          v-model="vcardFirstName"
          :placeholder="lang[appLang].first_name"
          class="edit-input h-50 lh-50 half-bottom"
          style="width: 100%"
        />
        <input
          v-if="data"
          id="vcardLastName"
          type="text"
          v-model="vcardLastName"
          :placeholder="lang[appLang].last_name"
          class="edit-input h-50 lh-50 half-bottom"
          style="width: 100%"
        />
      </div>

      <!-- Bio -->
      <textarea
        v-if="data"
        class="edit-input margin-center h-65 lh-20 half-bottom"
        :placeholder="lang[appLang].contact_card_bio_p"
        maxlength="300"
        rows="2"
        v-model="vcardBio"
        style="resize: none; color: #1d1d1d; width: 90%"
      />

      <!-- Business / Private Switch Tabs -->
      <div v-if="!isB2B || (company && company.config?.includePrivateSectionInContactCard)" class="pills-wrapper">
        <div
          class="pill left-pill pointer"
          @click="activePill = 'business'"
          :class="{ 'pill-active': activePill === 'business' }"
        >
          <span>{{lang[appLang].business}}</span>
        </div>
        <div
            class="pill right-pill pointer"
            style="float: left"
            @click="activePill = 'personal'"
            :class="{ 'pill-active': activePill === 'personal' }"
        >
          <span>{{lang[appLang].personal}}</span>
        </div>
      </div>

      <!-- Business / Private Inputs -->
      <div class="contact-input-wrapper">
        <div style="width: 100%; display: flex;" v-if="data && activePill === 'personal'">
          <input
            id="vcardPhone"
            type="tel"
            v-model="vcardPhone"
            :placeholder="lang[appLang].contact_card_phone_p"
            class="edit-input margin-center h-50 lh-50 half-top half-bottom"
          />
          <span @click="phoneInputCnt++" v-show="phoneInputCnt !== 3" class="addRemoveInput"><i style="margin-left: 12px;">+</i></span>
          <span v-show="phoneInputCnt === 3" class="addRemoveInput"><i style="margin-left: 12px; color:#d7d7d7;">+</i></span>
        </div>

        <div style="width: 100%; display: flex;" v-if="data && activePill === 'personal'" v-show="phoneInputCnt > 1">
          <input
              id="vcardPhone2"
              type="tel"
              v-model="vcardPhone2"
              :placeholder="lang[appLang].contact_card_phone_p + ' 2'"
              class="edit-input h-50 lh-50 half-top half-bottom"
              style="width: 95%"
          />
          <span @click="phoneInputCnt--; vcardPhone2 = ''" class="addRemoveInput"><i style="margin-left: 17px; color: red;"> - </i></span>
        </div>

        <div style="width: 100%; display: flex;" v-if="data && activePill === 'personal'" v-show="phoneInputCnt > 2">
          <input
              id="vcardPhone3"
              type="tel"
              v-model="vcardPhone3"
              :placeholder="lang[appLang].contact_card_phone_p + ' 3'"
              class="edit-input h-50 lh-50 half-top half-bottom"
              style="width: 95%"
          />
          <span @click="phoneInputCnt--; vcardPhone3 = ''" class="addRemoveInput"><i style="margin-left: 17px; color: red;"> - </i></span>
        </div>

        <div style="width: 100%; display: flex;" v-if="data && activePill === 'personal'">
          <input
              id="vcardEmail"
              type="email"
              v-model="vcardEmail"
              :placeholder="lang[appLang].contact_card_email_p"
              class="edit-input h-50 lh-50 half-top half-bottom"
              style="width: 95%"
          />
          <span @click="emailInputCnt++" v-show="emailInputCnt !== 3" class="addRemoveInput"><i style="margin-left: 12px;">+</i></span>
          <span v-show="emailInputCnt === 3" class="addRemoveInput"><i style="margin-left: 12px; color:#d7d7d7;">+</i></span>        </div>

        <div style="width: 100%; display: flex;" v-if="data && activePill === 'personal'" v-show="emailInputCnt > 1">
          <input
              id="vcardEmail2"
              type="email"
              v-model="vcardEmail2"
              :placeholder="lang[appLang].contact_card_email_p + ' 2'"
              class="edit-input h-50 lh-50 half-top half-bottom"
              style="width: 95%"
          />
          <span @click="emailInputCnt--; vcardEmail2 = ''" class="addRemoveInput"><i style="margin-left: 17px; color: red;"> - </i></span>
        </div>

        <div style="width: 100%; display: flex;" v-if="data && activePill === 'personal'" v-show="emailInputCnt > 2">
          <input
              id="vcardEmail3"
              type="email"
              v-model="vcardEmail3"
              :placeholder="lang[appLang].contact_card_email_p + ' 3'"
              class="edit-input h-50 lh-50 half-top half-bottom"
              style="width: 95%"
          />
          <span @click="emailInputCnt--; vcardEmail3 = ''" class="addRemoveInput"><i style="margin-left: 17px; color: red;"> - </i></span>
        </div>

        <div style="width: 100%; display: flex;" v-if="data && activePill === 'personal'">
          <input
            id="vcardWebsite"
            type="text"
            v-model="vcardWebsite"
            :placeholder="lang[appLang].contact_card_website_p"
            class="edit-input h-50 lh-50 half-top half-bottom"
            style="width: 95%"
          />
          <span @click="urlInputCnt++" v-show="urlInputCnt !== 3" class="addRemoveInput"><i style="margin-left: 12px;">+</i></span>
          <span v-show="urlInputCnt === 3" class="addRemoveInput"><i style="margin-left: 12px; color:#d7d7d7;">+</i></span>
        </div>

        <div style="width: 100%; display: flex;" v-if="data && activePill === 'personal'" v-show="urlInputCnt > 1">
          <input
              id="vcardWebsite2"
              type="text"
              v-model="vcardWebsite2"
              :placeholder="lang[appLang].contact_card_website_p + ' 2'"
              class="edit-input h-50 lh-50 half-top half-bottom"
              style="width: 95%"
          />
          <span @click="urlInputCnt--; vcardWebsite2 = ''" class="addRemoveInput"><i style="margin-left: 17px; color: red;"> - </i></span>
        </div>

        <div style="width: 100%; display: flex;" v-if="data && activePill === 'personal'" v-show="urlInputCnt > 2">
          <input
              id="vcardWebsite3"
              type="text"
              v-model="vcardWebsite3"
              :placeholder="lang[appLang].contact_card_website_p + ' 3'"
              class="edit-input h-50 lh-50 half-top half-bottom"
              style="width: 95%"
          />
          <span @click="urlInputCnt--; vcardWebsite3 = ''" class="addRemoveInput"><i style="margin-left: 17px; color: red;"> - </i></span>
        </div>

        <div style="width: 100%; display: flex;" v-if="data && activePill === 'business'">
          <input
            id="vcardCompanyPhone"
            type="text"
            v-model="vcardWorkPhone"
            :placeholder="lang[appLang].businessPhone"
            class="edit-input h-50 lh-50 half-top half-bottom"
            style="width: 95%"
          />
          <span @click="workPhoneInputCnt++" v-show="workPhoneInputCnt !== 3" class="addRemoveInput"><i style="margin-left: 12px;">+</i></span>
          <span v-show="workPhoneInputCnt === 3" class="addRemoveInput"><i style="margin-left: 12px; color:#d7d7d7;">+</i></span>
        </div>

        <div style="width: 100%; display: flex;" v-if="data && activePill === 'business'" v-show="workPhoneInputCnt > 1">
          <input
              id="vcardCompanyPhone2"
              type="text"
              v-model="vcardWorkPhone2"
              :placeholder="lang[appLang].businessPhone + ' 2'"
              class="edit-input h-50 lh-50 half-top half-bottom"
              style="width: 95%"
          />
          <span @click="workPhoneInputCnt--; vcardWorkPhone2 = ''" class="addRemoveInput"><i style="margin-left: 17px; color: red;"> - </i></span>
        </div>

        <div style="width: 100%; display: flex;" v-if="data && activePill === 'business'" v-show="workPhoneInputCnt > 2">
          <input
              id="vcardCompanyPhone3"
              type="text"
              v-model="vcardWorkPhone3"
              :placeholder="lang[appLang].businessPhone + ' 3'"
              class="edit-input h-50 lh-50 half-top half-bottom"
              style="width: 95%"
          />
          <span @click="workPhoneInputCnt--; vcardWorkPhone3 = ''" class="addRemoveInput"><i style="margin-left: 17px; color: red;"> - </i></span>
        </div>

        <div style="width: 100%; display: flex;" v-if="data && activePill === 'business'">
          <input
              id="vcardCompanyEmail"
              type="text"
              v-model="vcardWorkEmail"
              :placeholder="lang[appLang].businessEmail"
              class="edit-input h-50 lh-50 half-top half-bottom"
              style="width: 95%"
          />
          <span @click="workEmailInputCnt++" v-show="workEmailInputCnt !== 3" class="addRemoveInput"><i style="margin-left: 12px;">+</i></span>
          <span v-show="workEmailInputCnt === 3" class="addRemoveInput"><i style="margin-left: 12px; color:#d7d7d7;">+</i></span>
        </div>

        <div style="width: 100%; display: flex;" v-if="data && activePill === 'business'" v-show="workEmailInputCnt > 1">
          <input
            id="vcardCompanyEmail2"
            type="text"
            v-model="vcardWorkEmail2"
            :placeholder="lang[appLang].businessEmail + ' 2'"
            class="edit-input h-50 lh-50 half-top half-bottom"
            style="width: 95%"
          />
          <span @click="workEmailInputCnt--; vcardWorkEmail2 = ''" class="addRemoveInput"><i style="margin-left: 17px; color: red;"> - </i></span>
        </div>

        <div style="width: 100%; display: flex;" v-if="data && activePill === 'business'" v-show="workEmailInputCnt > 2">
          <input
              id="vcardCompanyEmail3"
              type="text"
              v-model="vcardWorkEmail3"
              :placeholder="lang[appLang].businessEmail + ' 3'"
              class="edit-input h-50 lh-50 half-top half-bottom"
              style="width: 95%"
          />
          <span @click="workEmailInputCnt--; vcardWorkEmail3 = ''" class="addRemoveInput"><i style="margin-left: 17px; color: red;"> - </i></span>
        </div>

        <input
          v-if="data && activePill === 'business'"
          id="vcardJobTitle"
          type="text"
          v-model="vcardTitle"
          :placeholder="lang[appLang].contact_card_job_title"
          class="edit-input margin-center h-50 lh-50 half-top half-bottom"
        />

        <input
          v-if="data && activePill === 'business'"
          id="vcardCompanyName"
          type="text"
          v-model="vcardCompanyName"
          :placeholder="lang[appLang].contact_card_company_name_p"
          class="edit-input margin-center h-50 lh-50 half-top half-bottom"
        />


        <input v-if="data && activePill === 'business'"
                id="vcardCompanyFax"
                type="text"
                v-model="vcardWorkFax"
                :placeholder="lang[appLang].businessFax"
                class="edit-input h-50 lh-50 small-top half-bottom"
        />

        <div style="width: 100%; display: flex;" v-if="data && activePill === 'business'">
          <input
            id="vcardWorkUrl"
            type="text"
            v-model="vcardWorkUrl"
            :placeholder="lang[appLang].contact_card_company_website"
            class="edit-input h-50 lh-50 half-top half-bottom"
            style="width: 95%"
          />
          <span @click="workUrlInputCnt++" v-show="workUrlInputCnt !== 3" class="addRemoveInput"><i style="margin-left: 12px;">+</i></span>
          <span v-show="workUrlInputCnt === 3" class="addRemoveInput"><i style="margin-left: 12px; color:#d7d7d7;">+</i></span>
        </div>

        <div style="width: 100%; display: flex;" v-if="data && activePill === 'business'" v-show="workUrlInputCnt > 1">
          <input
              id="vcardWorkUrl2"
              type="text"
              v-model="vcardWorkUrl2"
              :placeholder="lang[appLang].contact_card_company_website + ' 2'"
              class="edit-input h-50 lh-50 half-top half-bottom"
              style="width: 95%"
          />
          <span @click="workUrlInputCnt--; vcardWorkUrl2 = ''" class="addRemoveInput"><i style="margin-left: 17px; color: red;"> - </i></span>
        </div>

        <div style="width: 100%; display: flex;" v-if="data && activePill === 'business'" v-show="workUrlInputCnt > 2">
          <input
              id="vcardWorkUrl3"
              type="text"
              v-model="vcardWorkUrl3"
              :placeholder="lang[appLang].contact_card_company_website + ' 3'"
              class="edit-input h-50 lh-50 half-top half-bottom"
              style="width: 95%"
          />
          <span @click="workUrlInputCnt--; vcardWorkUrl3 = ''" class="addRemoveInput"><i style="margin-left: 17px; color: red;"> - </i></span>
        </div>

        <div style="width: 100%; display: flex; position: relative;">
          <vue-google-autocomplete v-show="toggle"
              :id="'googleAddressContactCard'"
              ref="googleAddressContactCard"
              :placeholder="activePill === 'personal' ? lang[appLang].contact_card_home_address_p : lang[appLang].contact_card_work_address_p"
              types="address"
              @placechanged="getAddressData"
              @inputChange="addressChange"
              @keypress="(e) => movePacContainer(e, 'googleAddressContactCard')"
              @focus="(e) => movePacContainer(e, 'googleAddressContactCard')"
              classname="edit-input h-50 lh-50 half-top half-bottom"
              style="width: 95%"
              v-model="formattedAddress"
              :fields="['address_components', 'adr_address', 'alt_id',  'formatted_address', 'geometry', 'icon', 'id', 'name', 'scope', 'type', 'vicinity']"
          />
          <span @click="vcardAddressEdit = !vcardAddressEdit" class="addRemoveInput">
            <img
                :alt="lang[appLang].more"
                :class="{ 'rotate-icon': !!vcardAddressEdit }"
                src="https://cdn.tapni.co/icons/down-arrow.png"
                class="cursor-pointer transition-icon half-top tiny-top-padding"
                style="width: 20px; margin-left: 12px;"
            />
          </span>
        </div>
        <div style="width: 100%; display: flex; flex-wrap: wrap; justify-content: space-between;" v-show="vcardAddressEdit && activePill === 'personal'">
          <input type="text" v-model="vcardAddress.home.address.route"
                 :placeholder="lang[appLang].streetName"
                 class="edit-input h-50 lh-50 small-top half-bottom"
                 style="width: 70%;"
          />
          <input type="text" v-model="vcardAddress.home.address.street_number"
                 :placeholder="lang[appLang].streetNr"
                 class="edit-input h-50 lh-50 small-top half-bottom"
                 style="width: 25%; float: right;"
          />
          <div class="break-flex"></div>
          <input type="text" v-model="vcardAddress.home.address.locality"
                 :placeholder="lang[appLang].city"
                 class="edit-input h-50 lh-50 small-top half-bottom"
                 style="width: 70%;"
          />
          <input type="text" v-model="vcardAddress.home.address.postal_code"
                 :placeholder="lang[appLang].plz"
                 class="edit-input h-50 lh-50 small-top half-bottom"
                 style="width: 25%;"
          />
          <div class="break-flex"></div>
          <input type="text" v-model="vcardAddress.home.address.country"
                 :placeholder="lang[appLang].country"
                 class="edit-input h-50 lh-50 small-top half-bottom"
          />
        </div>

        <div style="width: 100%; display: flex; flex-wrap: wrap; justify-content: space-between;" v-show="vcardAddressEdit && activePill === 'business'">
          <input type="text" v-model="vcardAddress.work.address.route"
                 :placeholder="lang[appLang].streetName"
                 class="edit-input h-50 lh-50 small-top half-bottom"
                 style="width: 70%;"
          />
          <input type="text" v-model="vcardAddress.work.address.street_number"
                 :placeholder="lang[appLang].streetNr"
                 class="edit-input h-50 lh-50 small-top half-bottom"
                 style="width: 25%; float: right;"
          />
          <div class="break-flex"></div>
          <input type="text" v-model="vcardAddress.work.address.locality"
                 :placeholder="lang[appLang].city"
                 class="edit-input h-50 lh-50 small-top half-bottom"
                 style="width: 70%;"
          />
          <input type="text" v-model="vcardAddress.work.address.postal_code"
                 :placeholder="lang[appLang].plz"
                 class="edit-input h-50 lh-50 small-top half-bottom"
                 style="width: 25%;"
          />
          <div class="break-flex"></div>
          <input type="text" v-model="vcardAddress.work.address.country"
                 :placeholder="lang[appLang].country"
                 class="edit-input h-50 lh-50 small-top half-bottom"
          />
        </div>

      </div>

      <!-- Contact Card Links -->
      <div
        v-if="data && !isEmpty(user) && contactCardLinks.length > 0"
        class="links-wrapper"
      >
        <LinkIcon
          v-for="link in contactCardLinks"
          :key="link.id"
          :data="link"
          :link-style="'link-list'"
          :switching="true"
          :editing="false"
          class="link-transition tiny-top"
        />
      </div>

      <a
        v-if="false"
        @click="redirectLink(data, true)"
        class="button white-button small-top pointer"
        style="margin: 0 5px"
      >
        {{lang[appLang].open }}
      </a>
    </div>

    <!-- Link Footer -->
    <div class="sticky-footer">
      <!-- Delete -->
      <a
        v-if="data && data.id && !confirmDelete"
        @click="confirmDelete = true"
        class="button white-button pointer small-top half-bottom"
        style="
          margin: 0;
          width: 60px;
          border-radius: 8px;
          padding-left: 7px;
          padding-right: 7px;
        "
      >
        <img src="https://cdn.tapni.co/icons/delete.png" alt="delete" height="25" width="25" class="btn-icon" style="margin-right: 0;" />
      </a>
      <a
        v-if="data && data.id && confirmDelete"
        @click="deleteLink(data.id)"
        class="button white-button pointer small-top half-bottom"
        style="margin: 0; color: #fc1414; width: auto; max-width: 43%"
      >
        {{lang[appLang].confirm }}
      </a>

      <button
        type="submit"
        class="button black-button pointer small-top half-bottom"
        style="margin: 0; width: 55%"
      >

        <span v-if="!submitted">{{lang[appLang].save }}</span>
        <span v-else class="button--loading button__loader"></span>
      </button>
    </div>
  </form>
</template>

<script>
import { EventBus } from '@/store/event-bus';
import { mapActions } from "vuex";
import GoogleMapsMixin from "@/mixins/google-maps.mixin.js";
import LinkIcon from "../LinkIcon";
export default {
  name: "ContactCard",
  mixins: [GoogleMapsMixin],
  components: { LinkIcon },
  data() {
    return {
      data: null,
      confirmDelete: false,
      toggle: false,
      vcardEmail: "",
      vcardEmail2: "",
      vcardEmail3: "",
      vcardPhone: "",
      vcardPhone2: "",
      vcardPhone3: "",
      vcardWebsite: "",
      vcardWebsite2: "",
      vcardWebsite3: "",
      vcardFirstName: "",
      vcardLastName: "",
      vcardBio: "",
      vcardWorkPhone: "",
      vcardWorkPhone2: "",
      vcardWorkPhone3: "",
      vcardWorkFax: "",
      vcardWorkEmail: "",
      vcardWorkEmail2: "",
      vcardWorkEmail3: "",
      vcardTitle: "",
      vcardCompanyName: "",
      vcardWorkUrl: "",
      vcardWorkUrl2: "",
      vcardWorkUrl3: "",
      vcardAddress: {
        work: {
          place: {
            formatted_address: ''
          },
          address: {
            route: '',
            street_number: '',
            postal_code: '',
            locality: '',
            country: '',
          }
        },
        home: {
          place: {
            formatted_address: ''
          },
          address: {
            route: '',
            street_number: '',
            postal_code: '',
            locality: '',
            country: '',
          }
        }
      },
      activePill: "business",
      phoneInputCnt: 1,
      emailInputCnt: 1,
      urlInputCnt: 1,
      workPhoneInputCnt: 1,
      workEmailInputCnt: 1,
      workUrlInputCnt: 1,
      submitted: false,
      vcardAddressEdit: false,
      vcardAddressWorkEdit: false,
      linkImageBase64: '',
      formattedAddress: '',
    };
  },
  computed: {
    contactCardLinks() {
      if (!this.user || !this.user.links || this.user.links.length === 0)
        return [];
      const links = this.user.links.filter((l) =>
        this.linkTypes.find((e) => e.type === l.type && !!e.vcardLink)
      );
      links.unshift({
        id:'tapniLink',
        text: this.user.name,
        subtext: 'https://' + this.hostname + '/' + this.user.username,
        type: 'customlink',
        switching: true,
        custom_icon: true,
        style: {
          custom_icon: this.user.photo
        }
      })

      links.forEach((l) => (l.switching = true));

      return links;
    },
  },
  mounted() {
    EventBus.$on('contactCardImageBase64', (base64) => {
      EventBus.$emit('toggleContactCardModal', false)
      this.linkImageBase64 = base64
    })
    EventBus.$on("toggleContactCardModal", this.toggleModal);
    EventBus.$on("closeModal", () => {
      setTimeout(() => {
        this.confirmDelete = false;
        this.linkImageBase64 = ''
      }, 1000);
      this.toggle = false;
    });
  },
  beforeDestroy() {
    EventBus['_events']['toggleContactCardModal'].pop()
  },
  methods: {
    ...mapActions(["updateVCard", "removeLink", "getUser"]),
    close() {
      EventBus.$emit("closeModal");
    },
    deleteLink(id) {
      this.removeLink({ id });
      EventBus.$emit("closeModal");
      setTimeout(() => {
        this.confirmDelete = false;
        this.linkImageBase64 = '';
      }, 1000);
    },
    async saveLink() {
      if (this.submitted) return;
      this.submitted = true;

      let data = {
        // email: this.contactCardEmail.switching,
        // phone: !this.isEmpty(phone),
        vcardEmail: this.vcardEmail,
        vcardEmail2: this.vcardEmail2,
        vcardEmail3: this.vcardEmail3,
        vcardWorkEmail: this.vcardWorkEmail,
        vcardWorkEmail2: this.vcardWorkEmail2,
        vcardWorkEmail3: this.vcardWorkEmail3,
        vcardPhone: this.vcardPhone,
        vcardPhone2: this.vcardPhone2,
        vcardPhone3: this.vcardPhone3,
        vcardWorkPhone: this.vcardWorkPhone,
        vcardWorkPhone2: this.vcardWorkPhone2,
        vcardWorkPhone3: this.vcardWorkPhone3,
        vcardWorkFax: this.vcardWorkFax,
        vcardWebsite: this.vcardWebsite,
        vcardWebsite2: this.vcardWebsite2,
        vcardWebsite3: this.vcardWebsite3,
        vcardFirstName: this.vcardFirstName,
        vcardLastName: this.vcardLastName,
        vcardBio: this.vcardBio,
        vcardCompanyName: this.vcardCompanyName,
        vcardTitle: this.vcardTitle,
        vcardWorkUrl: this.vcardWorkUrl,
        vcardWorkUrl2: this.vcardWorkUrl2,
        vcardWorkUrl3: this.vcardWorkUrl3,
        vcardAddress: this.vcardAddress,
        socialUrls: {},
        custom_icon: this.linkImageBase64 ? true : undefined,
        linkImageBase64: this.linkImageBase64 ? this.linkImageBase64 : undefined
      };

      this.contactCardLinks.forEach((l) => {
        if (l.switching) {
          let link = l.type;
          if (link === "customlink") link = "website";
          data.socialUrls[l.id] = {
            url: this.redirectLink(l, false, true),
            type: link,
          };
        }
      });
      await this.updateVCard({vcard: data, text: this.data.desc[this.appLang].short});
      if (this.storage) await this.getUser({ username: this.storage.username });
      EventBus.$emit("toggleContactCardModal");
      await this.$router.push(this.home);
      this.confirmDelete = false;
      this.submitted = false;
      this.activePill = 'personal';
      this.vcardAddressEdit = false;
    },
    toggleModal(data) {
      this.toggle = !this.toggle;
      this.confirmDelete = false
      let tempArr;
      if (data) {
        this.data = data;
        const linkType = this.linkTypes.filter((e) => e.type === data.type)[0];

        if (linkType) {
          this.data.desc = linkType.desc;
        } else console.error('No linkType in toggleModal ContactCard')

        if (!this.isEmpty(this.user.vcard)) {
          this.data.desc.short = this.user.links.filter(
            (l) => l.type === "contactcard"
          )[0]
            ? this.user.links.filter((l) => l.type === "contactcard")[0].text
            : data.text;
        }

        if (this.$refs.googleAddressContactCard) {
          if (this.activePill === 'personal') {
            this.$refs.googleAddressContactCard.update(
                !this.isEmpty(this.user.vcard.vcardAddress) &&
                !this.isEmpty(this.user.vcard.vcardAddress.home)
                    ? this.user.vcard.vcardAddress.home.place.formatted_address
                    : ""
            );
          } else {
            this.$refs.googleAddressContactCard.update(
                !this.isEmpty(this.user.vcard.vcardAddress) &&
                !this.isEmpty(this.user.vcard.vcardAddress.work)
                    ? this.user.vcard.vcardAddress.work.place.formatted_address
                    : ""
            );
          }
        }

        this.vcardBio = this.user.bio;
        if (this.user.name) {
          this.vcardFirstName = this.user.name.split(" ")[0];
          this.vcardLastName = this.user.name.split(" ")[1] || "";
        }

        if (linkType && linkType.prefill_url) {
          if (!data.id) {
            this.data.url = linkType.base_url;
          }
        } else {
          if (data.id && this.data.url) {
            this.data.url = this.data.url.replace(linkType.base_url, "");
          }
        }
      }

      if (!this.isEmpty(this.user.vcard)) {
        // Old name as first name
        if (this.user.vcard.vcardName) this.vcardFirstName = this.user.vcard.vcardName;

        // New name split - first and last name
        if (this.user.vcard.vcardFirstName) this.vcardFirstName = this.user.vcard.vcardFirstName;
        if (this.user.vcard.vcardLastName) this.vcardLastName = this.user.vcard.vcardLastName;
        if (this.user.vcard.vcardEmail) {
          this.emailInputCnt = 1;
          this.vcardEmail = this.user.vcard.vcardEmail;
        }
        if (this.user.vcard.vcardEmail2) {
          this.emailInputCnt = 2;
          this.vcardEmail2 = this.user.vcard.vcardEmail2;
        }
        if (this.user.vcard.vcardEmail3) {
          this.emailInputCnt = 3;
          this.vcardEmail3 = this.user.vcard.vcardEmail3;
        }
        if (this.user.vcard.vcardWorkEmail) {
          this.workEmailInputCnt = 1;
          this.vcardWorkEmail = this.user.vcard.vcardWorkEmail;
        }
        if (this.user.vcard.vcardWorkEmail2) {
          this.workEmailInputCnt = 2;
          this.vcardWorkEmail2 = this.user.vcard.vcardWorkEmail2;
        }
        if (this.user.vcard.vcardWorkEmail3) {
          this.workEmailInputCnt = 3;
          this.vcardWorkEmail3 = this.user.vcard.vcardWorkEmail3;
        }

        if (this.user.vcard) {
          if(this.user.vcard.vcardPhone && this.user.vcard.vcardPhone.includes(',')) {
            tempArr = this.user.vcard.vcardPhone.split(',');
            if(tempArr[0]) this.vcardPhone = tempArr[0];
            if(tempArr[1]) this.vcardPhone2 = tempArr[1];
            if(tempArr[2]) this.vcardPhone3 = tempArr[2];
          }
          else {
            if (this.user.vcard.vcardPhone) this.vcardPhone = this.user.vcard.vcardPhone;
            if (this.user.vcard.vcardPhone2) this.vcardPhone2 = this.user.vcard.vcardPhone2;
            if (this.user.vcard.vcardPhone3) this.vcardPhone3 = this.user.vcard.vcardPhone3;
          }
        }

        if(this.vcardPhone) this.phoneInputCnt = 1;
        if(this.vcardPhone2) this.phoneInputCnt = 2;
        if(this.vcardPhone3) this.phoneInputCnt = 3;

        if(this.user.vcard.vcardWorkPhone && this.user.vcard.vcardWorkPhone.includes(',')) {
          tempArr = this.user.vcard.vcardWorkPhone.split(',');
          if(tempArr[0]) this.vcardWorkPhone = tempArr[0];
          if(tempArr[1]) this.vcardWorkPhone = tempArr[1];
          if(tempArr[2]) this.vcardWorkPhone = tempArr[2];
        }
        else {
          if (this.user.vcard.vcardWorkPhone) this.vcardWorkPhone = this.user.vcard.vcardWorkPhone;
          if (this.user.vcard.vcardWorkPhone2) this.vcardWorkPhone2 = this.user.vcard.vcardWorkPhone2;
          if (this.user.vcard.vcardWorkPhone3) this.vcardWorkPhone3 = this.user.vcard.vcardWorkPhone3;
        }

        if(this.vcardWorkPhone) this.workPhoneInputCnt = 1;
        if(this.vcardWorkPhone2) this.workPhoneInputCnt = 2;
        if(this.vcardWorkPhone3) this.workPhoneInputCnt = 3;

        if (this.user.vcard.vcardWebsite) {
          this.urlInputCnt =  1;
          this.vcardWebsite = this.user.vcard.vcardWebsite;
        }
        if (this.user.vcard.vcardWebsite2) {
          this.urlInputCnt =  2;
          this.vcardWebsite2 = this.user.vcard.vcardWebsite2;
        }
        if (this.user.vcard.vcardWebsite3) {
          this.urlInputCnt =  3;
          this.vcardWebsite3 = this.user.vcard.vcardWebsite3;
        }
        if (this.user.vcard.vcardCompanyName) this.vcardCompanyName = this.user.vcard.vcardCompanyName;
        if (this.user.vcard.vcardWorkFax) this.vcardWorkFax = this.user.vcard.vcardWorkFax;
        if (this.user.vcard.vcardTitle) this.vcardTitle = this.user.vcard.vcardTitle;
        if (this.user.vcard.vcardBio) this.vcardBio = this.user.vcard.vcardBio;
        if (this.user.vcard.vcardWorkUrl) {
          this.workUrlInputCnt = 1;
          this.vcardWorkUrl = this.user.vcard.vcardWorkUrl;
        }
        if (this.user.vcard.vcardWorkUrl2) {
          this.workUrlInputCnt = 2;
          this.vcardWorkUrl2 = this.user.vcard.vcardWorkUrl2;
        }
        if (this.user.vcard.vcardWorkUrl3){
          this.workUrlInputCnt = 3;
          this.vcardWorkUrl3 = this.user.vcard.vcardWorkUrl3;
        }
        if (this.user.vcard.vcardAddress) {
          if (this.user.vcard.vcardAddress.home) this.vcardAddress.home = this.user.vcard.vcardAddress.home;
          if (this.user.vcard.vcardAddress.work) this.vcardAddress.work = this.user.vcard.vcardAddress.work;
        }

        if (this.user.vcard.socialUrls) {
          this.contactCardLinks.forEach((l) => {
            l.switching = this.user.vcard.socialUrls[l.id]
                ? true
                : !!this.user.vcard.socialUrls[l.type];
          });
        }
      }
    },
    addressChange(e) {
      if (e.newVal === "") {
        this.vcardAddress.home = {
          place: {
            formatted_address: ''
          },
          address: {
            route: '',
            street_number: '',
            postal_code: '',
            locality: '',
            country: '',
          }
        };
      }
    },
    getAddressData(address, place) {
      if (address && !this.isEmpty(address)) {
        Object.keys(address).forEach(key => {
          if (this.activePill === 'personal') this.vcardAddress.home.address[key] = address[key];
          else this.vcardAddress.work.address[key] = address[key];
        })
      }
      if (place && !this.isEmpty(place)) {
        if (this.activePill === 'personal') this.vcardAddress.home.place.formatted_address = place.formatted_address;
        else this.vcardAddress.work.place.formatted_address = place.formatted_address;
      }
      this.vcardAddressEdit = false;
      this.$forceUpdate();
    },
    uploadLinkIcon () {
      EventBus.$emit('toggleUploadModal', false, 'contactcard')
      this.toggleModal()
    },
    formatAddress(streetAddress, number, postalCode, city, country) {
      let parts = [];

      if (streetAddress || number) {
        parts.push(`${streetAddress}${number ? ' ' + number : ''}`);
      }
      if (postalCode || city) {
        parts.push(`${postalCode}${city ? ' ' + city : ''}`);
      }
      if (country) {
        parts.push(country);
      }

      return parts.join(', ');
    }
},
  watch: {
    activePill(nv) {
      if (nv === 'personal') {
        this.formattedAddress = this.vcardAddress.home.place.formatted_address;
      } else {
        this.formattedAddress = this.vcardAddress.work.place.formatted_address;
      }
    },
    formattedAddress(nv) {
      if (this.$refs.googleAddressContactCard) {
        this.$refs.googleAddressContactCard.update(nv);
      }
    },
    'vcardAddress.home.address': {
      handler: function handler() {
          this.vcardAddress.home.place.formatted_address = this.formatAddress(this.vcardAddress.home.address.route, this.vcardAddress.home.address.street_number, this.vcardAddress.home.address.postal_code, this.vcardAddress.home.address.city, this.vcardAddress.home.address.country);
      },
      deep: true
    },
    'vcardAddress.work.address': {
      handler: function handler() {
        this.vcardAddress.work.place.formatted_address = this.formatAddress(this.vcardAddress.work.address.route, this.vcardAddress.work.address.street_number, this.vcardAddress.work.address.postal_code, this.vcardAddress.work.address.city, this.vcardAddress.work.address.country);
      },
      deep: true
    },
  }
};
</script>

<style scoped>
  .link-img {
    width: 80px;
    margin-right: 15px;
    border-radius: 22.46%;
  }
  #linkText {
    font-size: 17px;
    margin: 0 auto;
    width: 100%;
  }

  .contactcard p {
    margin-bottom: 15px !important;
  }

  .contact-input-wrapper {
    max-width: 90%;
    margin: 0 auto;
  }

  .pills-wrapper {
    width: 100%;
    max-width: 90%;
    display: flex;
    border-radius: 50px;
    height: 50px;
    margin: 0 auto;
    background: rgb(233, 233, 233) !important;
    padding: 2px;
    line-height: 48px;
  }

  .pill {
    width: 50%;
  }

  .pill-active {
    background-color: #ffffff;
    border-radius: 50px;
  }

  .left-pill {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }
  .right-pill {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }

  .contact-input-wrapper input {
    width: 100%;
  }

  .links-wrapper {
    max-height: 50vh;
    max-width: 90%;
    margin: 0 auto;
    margin-top: 15px;
    overflow-y: scroll;
    border-radius: 15px !important;
  }

  .sticky-footer {
    height: 90px;
    width: 100%;
    position: sticky;
    bottom: 0;
    z-index: 999;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 15px !important;
  }

  .addRemoveInput{
    font-size: 32px;
    font-weight: 400;
    line-height: 50px;
    height: 50px;
    padding-bottom:10px;
    position: relative;
    margin-top: 10px;
  }

  .addRemoveInput i {
    font-style: normal;
  }

  .transition-icon {
    transition: 0.5s;
  }
  .rotate-icon {
    transform: rotate(180deg);
  }
</style>
