import { reactive, readonly } from 'vue';

const listeners = reactive(new Map());

export const EventBus = {
    $on(event, listener) {
        let eventListeners = listeners.get(event);
        if (!eventListeners) {
            eventListeners = new Set();
            listeners.set(event, eventListeners);
        }
        eventListeners.add(listener);
    },
    $emit(event, ...args) {
        const eventListeners = listeners.get(event);
        if (eventListeners) {
            for (const listener of eventListeners) {
                listener(...args);
            }
        }
    },
    $off(event, listener) {
        const eventListeners = listeners.get(event);
        if (eventListeners) {
            eventListeners.delete(listener);
        }
    }
};

export default readonly(EventBus);
