import vCardJS from '@tapni/vcards-js';
import Compressor from 'compressorjs';
import enState from '../store/modules/en';
import deState from '../store/modules/de';
import esState from '../store/modules/es';
import krState from '../store/modules/kr';
import itState from '../store/modules/it';
import frState from '../store/modules/fr';
import srState from '../store/modules/sr';
import trState from '../store/modules/tr';

export default {
    getFirstBrowserLanguage() {
        let nav = window.navigator
        let browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage']
        let i
        let language

        // support for HTML 5.1 "navigator.languages"
        if (Array.isArray(nav.languages)) {
            for (i = 0; i < nav.languages.length; i++) {
                language = nav.languages[i]
                if (language && language.length) {
                    return language.slice(0, 2)
                }
            }
        }

        // support for other well known properties in browsers
        for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
            language = nav[browserLanguagePropertyKeys[i]]
            if (language && language.length) {
                return language.slice(0, 2)
            }
        }

        return null
    },
    compareLangKeys() {
        const en = Object.keys(enState.state);
        const de = Object.keys(deState.state);
        const es = Object.keys(esState.state);
        const kr = Object.keys(krState.state);
        const it = Object.keys(itState.state);
        const fr = Object.keys(frState.state);
        const sr = Object.keys(srState.state);
        const tr = Object.keys(trState.state);


        // Compare en and de
        console.log('In en but not in de', en.filter(x => !de.includes(x)));
        console.log('In de but not in en', de.filter(x => !en.includes(x)));
        console.log();

        // Compare en and es
        console.log('In en but not in es', en.filter(x => !es.includes(x)));
        console.log('In es but not in en', es.filter(x => !en.includes(x)));
        console.log();

        // Compare en and kr
        console.log('In en but not in kr', en.filter(x => !kr.includes(x)));
        console.log('In kr but not in en', kr.filter(x => !en.includes(x)));
        console.log();

        // Compare en and it
        console.log('In en but not in it', en.filter(x => !it.includes(x)));
        console.log('In it but not in en', it.filter(x => !en.includes(x)));
        console.log();

        // Compare en and fr
        console.log('In en but not in fr', en.filter(x => !fr.includes(x)));
        console.log('In fr but not in en', fr.filter(x => !en.includes(x)));
        console.log();

        // Compare en and sr
        console.log('In en but not in sr', en.filter(x => !sr.includes(x)));
        console.log('In sr but not in en', sr.filter(x => !en.includes(x)));
        console.log();

        // Compare en and tr
        console.log('In en but not in tr', en.filter(x => !tr.includes(x)));
        console.log('In tr but not in en', tr.filter(x => !en.includes(x)));
        console.log();
    },
    base64ImageToBlob(base64Image) {
        // Split into two parts
        const parts = base64Image.split(';base64,');

        // Hold the content type
        const imageType = parts[0].split(':')[1];

        // Decode Base64 string
        const decodedData = window.atob(parts[1]);

        // Create UNIT8ARRAY of size same as row data length
        const uInt8Array = new Uint8Array(decodedData.length);

        // Insert all character code into uInt8Array
        for (let i = 0; i < decodedData.length; ++i) {
            uInt8Array[i] = decodedData.charCodeAt(i);
        }

        // Return BLOB image after conversion
        return new Blob([uInt8Array], {type: imageType});
    },
    blobToBase64(blob) {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
            reader.onloadend = () => {
                resolve(reader.result);
            };
        });
    },
    base64toFile(base64, filename) {

        var arr = base64.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {type: mime});
    },
    fileToBase64(file) {
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    },
    compressImage(file) {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: .8,
                maxWidth: 1024,
                maxHeight: 1024,
                success: resolve,
                error: reject
            });
        });
    },
    getUTMParams(data) {
        let utm = {}
        if (data.utm_source) utm.utm_source = data.utm_source;
        if (data.utm_medium) utm.utm_medium = data.utm_medium;
        if (data.utm_campaign) utm.utm_campaign = data.utm_campaign;
        if (data.utm_term) utm.utm_term = data.utm_term;
        if (data.utm_content) utm.utm_content = data.utm_content;
        return utm;
    },

    cropCardScan(base64PictureData, type = 'card', callback) {

        // image will contain ORIGINAL image
        const image = new window.Image();

        // image will contain CROPPED image
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Load original image into image element
        image.src = 'data:image/jpeg;base64,' + base64PictureData;
        image.onload = () => {
            let sw = image.width * 0.9;
            let sh = image.width / 1.65;
            let sx = image.width / 20;
            let sy = (image.height / 2) - (sh / 2);

            if (type === 'badge') {
                sw = image.width * 0.45;
                sh = sw * 1.20;
                sx = (image.width / 2) - (sw / 2);
                sy = (image.height / 2) - (sh / 2) - (image.height * 0.05);
            }

            const dw = sw;
            const dh = sh;
            canvas.width = dw;
            canvas.height = dh
            // https://i.stack.imgur.com/USDAX.png
            ctx.drawImage(image, sx, sy, sw, sh, 0, 0, dw, dh); // Crop interpolated rectangle
            const croppedBase64Image = canvas.toDataURL();

            callback(croppedBase64Image);

            return croppedBase64Image;
        };
    },
    generateRandomString(length) {
        let array = new Uint32Array(length);
        window.crypto.getRandomValues(array);
        return Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');
    },
    async pkceChallengeFromVerifier(v) {
        const encoder = new TextEncoder();
        const data = encoder.encode(v);
        const hashed =  await window.crypto.subtle.digest('SHA-256', data);
        // base64 url encode
        return btoa(String.fromCharCode.apply(null, new Uint8Array(hashed))).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    },
    encryptAES(key, iv, data) {
        let encoder = new TextEncoder();
        let encoded = encoder.encode(data);
        return window.crypto.subtle.encrypt(
          {
              name: "AES-CBC",
              iv: iv,
          },
          key,
          encoded,
        );
    },
    async decryptAES(key, iv, data) {
        try {
            const fromBase64 = base64String => Uint8Array.from(atob(base64String), c => c.charCodeAt(0));

            data = data.replace(/ /g, '+');

            const encoder = new TextEncoder();
            const decoder = new TextDecoder();

            key = encoder.encode(key);
            iv = encoder.encode(iv);
            data = fromBase64(data);


            const secretKey = await window.crypto.subtle.importKey('raw', key, 'AES-CBC', true, ['encrypt', 'decrypt']);


            let decoded = await window.crypto.subtle.decrypt({name: "AES-CBC", iv}, secretKey, data);

            decoded = decoder.decode(decoded);
            decoded = decoded.replace(/ /g, '+');
            decoded = atob(decoded);

            return decoded;
        } catch (err) {
            console.log(err)
        }
    }
}
