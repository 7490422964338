<template>
    <TapniAuth ref="authLibrary"
               :class="{
                  'menu-wrapper menu-light menu-modal activate-page no-bottom': toggle,
                  'page-content page-content-scroll content-padding page-content-light header-clear-larger no-top': !isModal,
                  'active-menu': toggle
               }"
               @ssoEvent="ssoIncomingEvent"
               @ssoLibraryReady="ssoLibraryReady"
               :viewProp="view || route.name" :isModal="isModal" :appLang="appLang" />
</template>

<script>
    import {EventBus} from "@/store/event-bus";

    export default {
        name: "AuthView",
        props: {
            view: {
                type: String,
                default: null
            },
            isModal: {
                type: Boolean,
                default: false
            },
            toggle: {
              type: Boolean,
              default: false
            }
        },
        methods: {
            ssoLibraryReady(storage) {
                this.$emit('ssoLibraryReady', storage)
                EventBus.$on('ssoLibraryEvent', this.ssoLibraryEvent)
            },
            ssoIncomingEvent(data) {
                EventBus.$emit(data.name, data.data)
            },
            ssoLibraryEvent(data) {
              this.$refs.authLibrary.ssoIncomingEvent(data)
            },
        },
        mounted() {
            // Listen on events that can come from the auth library
            EventBus.$on('saveProfile', (data) => this.$store.dispatch('saveProfile', data))
            EventBus.$on('setLoading', (data) => this.$store.commit('setLoading', data))
            EventBus.$on('getUser', (data) => this.$store.dispatch('getUser', data))
            EventBus.$on('setStorage', (data) => this.$store.commit('setStorage', data))
            EventBus.$on('redirect', (data) => this.$router.push(data))
            EventBus.$on('switchAccount', (data) => {
              this.$router.push('/' + data.username + '#refresh');
              this.$store.commit("setLoading", true);
              setTimeout(() => {
                location.reload();
              }, 1000)
            })
            EventBus.$on('setLoggedInAccounts', (data) => this.$store.commit('setLoggedInAccounts', data))
        },
        beforeDestroy() {
          EventBus.$off("saveProfile");
          EventBus.$off("setLoading");
          EventBus.$off("getUser");
          EventBus.$off("setStorage");
          EventBus.$off("setLoggedInAccounts");
        },
    }
</script>

<style scoped>

</style>
