import { createApp } from 'vue';
import RootApp from './App.vue';
import router from './router';
import store from './store/store';
import { sync } from 'vuex-router-sync';
import GlobalMixin from './mixins/global.mixin';
import VueCookies from 'vue-cookies';
import { App } from '@capacitor/app';
import VueTextareaAutogrowDirective from './plugins/vue-textarea-autogrow-directive';
// import VueMeta from 'vue-meta';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import * as Sentry from "@sentry/vue";
import { Browser } from '@capacitor/browser';
import TapniAuth from '@tapni/auth';

const app = createApp(RootApp);

// Vue Meta
// app.use(VueMeta, { refreshOnceOnNavigation: true });

// Vuex Router Sync
sync(store, router);

// Use router and store
app.use(router);
app.use(store);

app.use(TapniAuth, {store, apiRoot: import.meta.env.VITE_APP_API_ROOT });

// Textarea Autogrow
app.use(VueTextareaAutogrowDirective);

// Cookies
app.use(VueCookies);

// Global Mixin
app.mixin(GlobalMixin);


// Sentry Configuration
if (import.meta.env.NODE_ENV !== "development") {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_APP_SENTRY_DSN,
        integrations: [
            // ... Sentry integrations
        ],
        // ... other Sentry configurations
    });
}

// Mount Vue instance
app.mount('#app');


defineCustomElements(window);

function hasQueryParams(route) {
    return !!Object.keys(route.query).length
}

/*
router.beforeEach((to, from, next) => {
    setTimeout(() => {
        window.scrollTo(0, 0)
    }, 100)
    next()
})
 */

/**
 * Redirect users to desired profile - DEEP LINKING
 * @param data
 */
function redirectToProfile(data) {
    if (data && data.url) {
        let url = new URL(data.url)
        let pathname = url.pathname || '';
        let queryParams = url.search || ''
        if(queryParams) queryParams = Object.fromEntries(new URLSearchParams(queryParams));
        else queryParams = {};
        let subdomain = '';
        //['my', 'tapni', 'co']
        let tempArr = url.hostname.split('.');
        if(tempArr.length === 3) subdomain = tempArr[0];
        if(subdomain) queryParams.s = subdomain;

        // open t.link shorter links in browser
        if(pathname && /[A-Z]/.test(pathname) && !pathname.startsWith('/t/')) {
            Browser.open({url: url.toString()});
            return router.push('/');
        }

        if (pathname) {
            router.push({
                path: pathname,
                query: queryParams
            })
        }
    }
}
/**
 * CAPACITOR_CONFIG
 * Deep linking configuration
 * Users who have an app should get the profiles opened in the app ie: tapni.co/tapni
 */
App.addListener('appUrlOpen', async function (data) {
    if(data.url && data.url.startsWith('tapni://')) data.url = data.url.replace('tapni://', 'https://')
    let url = new URL(data.url);
    if(url.pathname.includes('/callback/')) {
        await router.push({path: url.pathname, query:  Object.fromEntries(new URLSearchParams(url.search))});
    }
    else redirectToProfile(data);
})

// When the app is open and you trigger NFC event to open the app
// App.addListener('appStateChange', async function (l) {
// 	const data = await App.getLaunchUrl()
// })

// When the app is closed and NFC event opens the app
App.getLaunchUrl().then((data) => {
    redirectToProfile(data)
});
