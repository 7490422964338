const Home = () => import(/* webpackChunkName: "Home" */ "./views/Home.vue");
const NotFound = () => import(/* webpackChunkName: "404" */ "./views/404.vue");
const DownloadApp = () =>
  import(/* webpackChunkName: "404" */ "./views/DownloadApp.vue");
const Maintenance = () =>
  import(/* webpackChunkName: "Maintenance" */ "./views/Maintenance.vue");
const Update = () =>
  import(/* webpackChunkName: "Update" */ "./views/Update.vue");
const LinkAdding = () =>
  import(/* webpackChunkName: "LinkAdding" */ "./views/LinkAdding.vue");
const Tags = () => import(/* webpackChunkName: "Tags" */ "./views/Tags.vue");
const Connections = () =>
  import(/* webpackChunkName: "Connections" */ "./views/Contacts.vue");
const Links = () =>
    import(/* webpackChunkName: "Links" */ "./views/Links.vue");
const Settings = () =>
  import(/* webpackChunkName: "Settings" */ "./views/Settings.vue");

// const Terms = () => import(/* webpackChunkName: "Terms" */ './views/Terms.vue')
const Help = () => import(/* webpackChunkName: "Help" */ './views/Help.vue')
const Devices = () =>
  import(/* webpackChunkName: "Devices" */ "./views/Devices.vue");
const Referral = () =>
  import(/* webpackChunkName: "Referral" */ "./views/Referral.vue");
const Products = () =>
  import(/* webpackChunkName: "Products" */ "./views/Products.vue");
const Welcome = () =>
  import(/* webpackChunkName: "AuthView" */ "./views/AuthView.vue");
const Login = () => import(/* webpackChunkName: "AuthView" */ "./views/AuthView.vue");
const Register = () =>
  import(/* webpackChunkName: "AuthView" */ "./views/AuthView.vue");
const Reset = () => import(/* webpackChunkName: "Reset" */ "./views/AuthView.vue");
const Verify = () =>
  import(/* webpackChunkName: "AuthView" */ "./views/AuthView.vue");
const Callback = () =>
  import(/* webpackChunkName: "AuthView" */ "./views/AuthView.vue");
const CompanyInvitation = () =>
  import(/* webpackChunkName: "Verify" */ "./views/CompanyInvitation.vue");

const ForBusiness = () =>
  import(/* webpackChunkName: "Verify" */ "./views/ForBusiness.vue");

const Analytics = () =>
  import(/* webpackChunkName: "Analytics" */ "./views/Analytics.vue");

const More = () => import(/* webpackChunkName: "More" */ "./views/More.vue");

export default [
  {
    path: "/404",
    name: "Error404",
    component: NotFound,
  },
  {
    path: "/app",
    name: "DownloadApp",
    component: DownloadApp,
  },
  {
    path: "/maintenance",
    name: "ErrorMaintenance",
    component: Maintenance,
  },
  {
    path: "/update",
    name: "ErrorUpdate",
    component: Update,
  },
  {
    path: "/welcome",
    name: "AuthWelcome",
    component: Welcome,
  },
  {
    path: "/login",
    name: "AuthLogin",
    component: Login,
  },
  {
    path: "/callback/saml",
    name: "AuthCallback",
    alias: ['/callback/okta', '/callback/auth'],
    component: Callback,
  },
  {
    path: "/reset",
    name: "AuthReset",
    component: Reset,
  },
  {
    path: "/register",
    name: "AuthRegister",
    component: Register,
  },
  {
    path: "/verify",
    name: "AuthVerify",
    component: Verify,
  },
  {
    path: "/link/add",
    name: "LinkAdding",
    component: LinkAdding,
  },

  {
    path: "/analytics",
    name: "Analytics",
    component: Analytics,
  },
  {
    path: "/links",
    name: "Links",
    alias: "/qrs",
    component: Links,
  },
  {
    path: "/connections",
    name: "Connections",
    alias: "/contacts",
    component: Connections,
  },
  {
    path: "/more",
    name: "More",
    component: More,
  },
  {
    path: "/tags",
    name: "Tags",
    component: Tags,
  },
  {
    path: "/t/:serial",
    name: "HomeSerial",
    component: Home,
    alias: ['/f/:serial', '/w/:serial'],
  },
  /*{
    path: '/terms/:lang?',
    name: 'Terms',
    component: Terms
  },*/
  {
    path: "/devices/:lang?",
    name: "Devices",
    component: Devices,
  },
  {
    path: "/referral",
    name: "Referral",
    component: Referral,
  },
  {
    path: "/products",
    name: "Products",
    component: Products,
  },
  {
    path: "/for-business",
    name: "ForBusiness",
    component: ForBusiness,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: '/help',
    name: 'AuthHelp',
    component: Help
  },
  {
    path: "/:username?",
    name: "HomeUsername",
    component: Home,
  },
  {
    path: "/users/invitation",
    name: "CompanyInvitation",
    component: CompanyInvitation,
  },
  {
    path: "/call/:tel",
    beforeEnter(to) {
      location.href = "tel:" + to.params.tel;
    },
  }
];
