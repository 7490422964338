<template>
  <div
    id="qrScannerBindId"
    class="menu-wrapper menu-light menu-modal activate-page center-text"
    :class="{ 'active-menu': toggle }"
    :style="`overflow-y: hidden; height: ${
      !scanning || readingNFC && isiOS ? 'auto' : '80vh'
    }; max-height: 90vh`"
  >

    <!-- Close Modal Buttons -->
    <a
      v-if="scanning"
      @click="close"
      class="color-orange-dark pull-right pointer"
      style="margin-top: 10px; position: absolute; right: 15px; z-index: 3000; font-size: 20px;"
      >✖</a
    >
    <a
      v-else
      @click="close"
      class="color-black pull-right pointer"
      style="margin-top: 10px; position: absolute; right: 0px"
    >
      <i class="font-17 fa color-black">
        <img
          src="https://cdn.tapni.co/icons/down-arrow.png"
          class="fa responsive-image"
          style="width: 45%"
        />
      </i>
    </a>

    <!-- QR / NFC Switch Tabs -->
    <div v-if="!scanning && nfcActivationReady && !secondStepMessage" class="pills-wrapper half-top">
      <div
          class="pill left-pill pointer"
          style="float: left"
          @click="activePill = 'QR'"
          :class="{ 'pill-active': activePill === 'QR' }"
      >
        <span>{{lang[appLang].qr_code}}</span>
      </div>
      <div
          class="pill right-pill pointer"
          @click="activePill = 'NFC'"
          :class="{ 'pill-active': activePill === 'NFC' }"
      >
        <span>{{lang[appLang].nfc}}</span>
      </div>
    </div>

    <h3 class="half-top center-text bold" v-if="secondStepMessage && !scanning" v-html="secondStepMessage + ' ' + activePill"></h3>

    <div v-show="!scanning || (activePill === 'NFC' && !isiOS)" class="full-top">

      <!-- GIF Image -->
      <img
        src="https://cdn.tapni.co/images/qr-scan-animation.gif"
        class="scan-gif"
        v-if="route.name && route.name.includes('Tags') && activePill === 'QR'"
      />
      <img
          :src="scanning ? 'https://cdn.tapni.co/images/nfc-tap-animation.gif' : 'https://cdn.tapni.co/images/nfc-scan-animationn.gif'"
          class="scan-gif"
          v-if="route.name && route.name.includes('Tags') && activePill === 'NFC'"
      />


      <!-- Activate Button -->
      <button
        v-if="activePill === 'QR'"
        class="button white-button small-top pointer scan-button"
        @click="turnCameraOn"
      >
        {{lang[appLang].scan_qr_code }}
      </button>

      <button
          v-if="nfcActivationReady && activePill === 'NFC' && !scanning && !enterManually"
          class="button white-button small-top pointer scan-button"
          @click="turnNFCOn"
      >
        {{lang[appLang].activate_via_nfc }}
      </button>

      <button
          v-if="nfcActivationReady && activePill === 'NFC' && !scanning && !!enterManually"
          class="button white-button small-top pointer scan-button"
          @click="writeNFC"
      >
        Write NFC
      </button>

      <button
          v-if="nfcActivationReady && activePill === 'NFC' && scanning"
          class="button white-button small-top pointer scan-button"
          @click="close"
      >
        {{lang[appLang].cancel }} NFC
      </button>

      <!-- Manual Activation paragraph -->
      <p
        v-if="!enterManually && !scanning"
        class="
          center-text
          small-top
          half-bottom
          font-15
          underline
          pointer
          color-black
        "
        @click="enterManually = true"
      >
        {{lang[appLang].enter_code_manually }}
      </p>

      <!-- Activation code forma -->
      <form @submit.prevent="activateTag(code, true)" v-if="enterManually">
        <input
          id="activationCode"
          type="text"
          v-model="code"
          :placeholder="lang[appLang].activation_code"
          class="edit-input full-top h-40 lh-40"
          style="margin: 0 auto; width: 70%; font-size: 15px"
        />
        <p
          class="
            close-text close-menu
            center-text
            small-top small-bottom
            font-14
          "
        >
          {{lang[appLang].enter_activation_code }}
        </p>
        <button
            v-if="!loadingActivate"
            type="submit"
            class="button white-button small-top pointer"
            style="margin: 0 auto; width: 70%"
        >
          {{lang[appLang].activate_tag }}
        </button>
        <button
            v-else
            class="button white-button small-top pointer button--loading"
            style="margin: 0 auto; width: 70%; position: relative"
            @click.stop.prevent
        ><span class="button__loader"></span>
        </button>
      </form>


      <p v-if="!scanning" class="center-text font-13 half-top full-bottom">
        <span v-html="lang[appLang].order_tapni" />
        <router-link
          to="/products"
          exact
          class="color-black bold"
          @click.native="close"
          >{{lang[appLang].by_clicking_here }}</router-link>
      </p>
    </div>


    <!-- Scanning QR Code -->
    <qrcode-stream
      v-if="scanning && activePill === 'QR'"
      :camera="camera"
      @decode="onDecodeQr"
      @init="onInit"
    >
      <div
        v-if="validationSuccess"
        class="validation-success"
        v-html="lang[appLang].activation_success"
      />

      <div
        v-if="validationFailure"
        class="validation-failure"
        v-html="lang[appLang].activation_error"
      />

      <div
        v-if="validationPending"
        class="validation-pending"
        v-html="lang[appLang].activation_progress"
      />
    </qrcode-stream>
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import { EventBus } from "../../store/event-bus";
import { mapActions } from "vuex";
import NFCMixin from '../../mixins/nfc.mixin';
import to from 'await-to-js';
import {Capacitor} from "@capacitor/core";

export default {
  name: "ProductActivation",
  components: { QrcodeStream },
  mixins: [NFCMixin],
  data() {
    return {
      activePill: 'QR',
      secondStepFirstParameter:  undefined,
      secondStepMessage: undefined,
      isValid: undefined,
      camera: "off",
      result: null,
      toggle: false,
      loadingActivate: false,
      code: "",
      scanning: false,
      enterManually: false,
    };
  },
  computed: {
    validationPending() {
      return this.isValid === null && this.camera === "off";
    },
    validationSuccess() {
      return this.isValid === true;
    },
    validationFailure() {
      return this.isValid === false;
    },
  },
  mounted() {
    EventBus.$on("closeModal", () => {
      this.toggle = false;
      this.reset();
    });
    EventBus.$on("toggleQRScanModal", this.toggleModal);
    EventBus.$on("qrDecoded", (data) => {
      if(data.usage === "tagActivation") this.onDecodeQr(data.result)
    });
    EventBus.$on("closeQRScanner", () => {
      this.close();
    });
  },
  beforeDestroy() {
    EventBus['_events']['toggleQRScanModal'].pop()
    EventBus['_events']['qrDecoded'].pop()
    EventBus['_events']['closeQRScanner'].pop()
  },
  methods: {
    ...mapActions(["getUser", "activate"]),
    toggleModal() {
      this.toggle = !this.toggle;
    },
    reset() {
      this.enterManually = false;
      this.code = "";
      this.secondStepFirstParameter = undefined;
      this.secondStepMessage = undefined;
      this.activePill = 'QR';

      this.resetValidationState();
      this.removeAllNFCListeners();
      this.camera = "off";
      this.scanning = false;
    },
    close() {
      this.reset();
      if (!this.scanning) EventBus.$emit("closeModal");
    },
    onInit(promise) {
      promise.catch(console.error).then(this.resetValidationState);
    },

    resetValidationState() {
      this.isValid = undefined;
    },

    async onDecodeQr(content) {
      this.result = content;
      this.isValid = null;

      if (!content.includes("t/")) {
        setTimeout(this.close, 2000);
      }
      console.log('content', content)
      await this.activateTag(content.split("t/")[1]);
    },

    async activateTag(serial, formSubmitted = false, state = null) {
      if (!serial) return this.errorSnack(this.lang[this.appLang].activation_invalid_code);

      this.loadingActivate = true;

      // Swap parameters
      if (!!this.secondStepFirstParameter && this.secondStepFirstParameter !== serial) {
        const helper = serial;
        serial = this.secondStepFirstParameter;
        this.secondStepFirstParameter = helper;
      }

      const response = await this.activate({
        serial,
        // captchaToken: await this.getCaptchaToken(),
        secondParameter: this.secondStepFirstParameter,
        state
      });
      this.loadingActivate = false;

      this.isValid = response && response.data ? response.data.success : false;

      // Second step needed exception
      if (this.isValid && response.data.message === 'activation_second_step_needed') {
        this.scanning = false
        this.enterManually = false
        this.secondStepFirstParameter = serial;
        this.secondStepMessage = this.lang[this.appLang].activation_second_step_needed;
        if (this.activePill === 'QR') {
          this.activePill = 'NFC';
        } else if (this.activePill === 'NFC') {
          this.activePill = 'QR';
        }
        return;
      }

      if (this.isValid === true) {
        setTimeout(() => {
          this.successSnack(this.lang[this.appLang].activation_success_message);
        }, 1500);
      }

      // Refresh user data
      await this.getUser({ username: this.storage.username });

      this.scanning = false;
      if (this.validationSuccess) {
        this.close();
        this.$forceUpdate();
      }
      this.turnCameraOff();
      this.resetValidationState();
    },
    async turnNFCOn() {
      this.$emit('nfcActivationInProgress', true);
      this.scanning = true;
      if (!this.scanning) return;

      const [errSupported, isSupported] = await to(this.isSupported());
      if (errSupported) return this.errorSnack(this.lang[this.appLang].unexpected_err)
      if (!isSupported) return this.errorSnack(this.lang[this.appLang].nfc_not_available)

      if (Capacitor.getPlatform() === 'android') {
        const [errEnabled, isEnabled] = await to(this.isEnabled());
        if (errEnabled) return this.errorSnack(this.lang[this.appLang].unexpected_err)
        if (!isEnabled) return this.errorSnack(this.lang[this.appLang].nfc_not_enabled)
      }

      const [err, result] = await to(this.readNFC());
      this.scanning = false;
      setTimeout(() => { this.$emit('nfcActivationInProgress', false); }, 5000);
      if (err) {
        if (err.message.includes('not available')) {
          return this.errorSnack(this.lang[this.appLang].nfc_not_available)
        } else return this.errorSnack(this.lang[this.appLang].unexpected_err)
      }
      if (result && result.message && result.message.records.length) {
        if (!result.message.records[0].payload) {
          return this.errorSnack(this.lang[this.appLang].nfc_no_link_found);
        }
        this.serial = this.convertBytesToString(result.message.records[0].payload);
        if (!this.serial) return this.errorSnack(this.lang[this.appLang].nfc_no_link_found);

        if (this.serial.includes('t/')) {
          await this.activateTag(this.serial.split("t/")[1]);
        } else if (this.serial.includes('f/')) {
          await this.activateTag(this.serial.split("f/")[1], true, 'fidesmo');
        } else {
          this.errorSnack(this.lang[this.appLang].unexpected_err);
        }
      } else return setTimeout(() => { this.errorSnack(this.lang[this.appLang].nfc_no_link_found); }, 2000);
    },
    async turnCameraOn() {
      // eslint-disable-next-line no-undef
      if (this.isNative) {
        EventBus.$emit("startScanner", "QR", "tagActivation");
      } else {
        this.scanning = true;
        this.camera = "auto";
      }
    },
    turnCameraOff() {
      this.camera = "off";
    },

    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },
  },
};
</script>

<style scoped>
button {
  margin-bottom: 20px;
}

.validation-success,
.validation-failure,
.validation-pending {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.82);
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.validation-success {
  color: #0576a3;
}
.validation-failure {
  color: red;
}
.menu-wrapper {
  margin-top: -172.5px;
  padding: 0;
}

.scan-gif {
  width: 70%;
  margin: 0 auto;
  border-radius: 20px;
}

.scan-button {
  display: block;
  width: 70%;
  margin: 0 auto;
  margin-bottom: 20px;
}

.button--loading::after {
  border-top-color: #000000;
}

.pills-wrapper {
  width: 100%;
  max-width: 70%;
  display: flex;
  border-radius: 50px;
  height: 50px;
  margin: 0 auto;
  background: rgb(233, 233, 233) !important;
  padding: 2px;
  line-height: 48px;
}

.pill {
  width: 50%;
}

.pill-active {
  background-color: #ffffff;
  border-radius: 50px;
}

.left-pill {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}
.right-pill {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}
</style>
