<template>
  <div
    :style="`padding: 3% 1%; border-bottom: ${
      showBorder ? '0.5' : '0'
    }px solid #eee; border-radius: 0px!important;`"
    class="content-boxed"
  >
    <div class="pointer connectionContainer">
      <div @click="viewProfile">
        <img
          :src="photo"
          class="edit-shadow connection-img responsive-image"
          :alt="source.text"
          :class="{ 'connection-img-big': isBigger }"
        />
      </div>

      <div class="connectionInfo">
        <h4 v-if="name" style="margin-bottom: 5px">
          <b>{{ name }}</b>
        </h4>

        <div class="emailContainer">
          <span v-if="username">{{
            source.vcard && source.vcard.vcardEmail
              ? source.vcard.vcardEmail
              : "@" + username
          }}</span>
          <span class="emailSpan" v-else-if="source.payload">{{ source.payload.email }}</span>
        </div>

        <div class="dateOfCreation">{{ dateOfCreation }}</div>

        <div class="accordionContainer">
          <div v-if="source.vcard" class="bio">
            {{ source.vcard.vcardBio }}
          </div>
          <div class="markersContainer">
            <span
              class="marker"
              v-for="marker in source.markers"
              :key="marker.id"
              >{{ marker.name }}</span
            >
          </div>
          <div>{{ source.note }}</div>
        </div>
      </div>
      <div v-if="showActionButton" @click="editConnection">
        <button
          class="button white-button small-top pointer no-border normal-text"
        >
          • • •
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { EventBus } from "../store/event-bus";
import UserService from "../services/UserService";
import moment from "moment";

export default {
  name: "Connection",
  props: {
    isBigger: {
      type: Boolean,
      required: false,
      default: false,
    },
    showActionButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    showBorder: {
      type: Boolean,
      required: false,
      default: true,
    },
    source: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    photo() {
      const userId = this.storage ? this.storage.UserId : null;
      const { status, receiver, requester } = this.source;

      if (status === "connected") {
        if (receiver && receiver.id !== userId) return receiver.photo;
        if (requester && requester.id !== userId) return requester.photo;
      }

      return "https://cdn.tapni.co/images/user.png";
    },
    name() {
      const userId = this.storage ? this.storage.UserId : null;
      const { status, receiver, requester, payload } = this.source;

      if (status === "connected") {
        if (receiver && receiver.id !== userId) return receiver.name;
        if (requester && requester.id !== userId) return requester.name;
      }

      return payload && payload.name ? payload.name : "Anonymous";
    },
    username() {
      const userId = this.storage ? this.storage.UserId :null;
      const { receiver, requester } = this.source;

      if (receiver && receiver.id !== userId) return receiver.username;
      if (requester && requester.id !== userId) return requester.username;

      return "";
    },
    bio() {
      const userId = this.storage ? this.storage.UserId : null;
      const { status, receiver, requester, payload } = this.source;

      if (status === "connected") {
        if (receiver && receiver.id !== userId) return receiver.bio;
        if (requester && requester.id !== userId) return requester.bio;
      }

      return payload && payload.note ? payload.note : false;
    },
    dateOfCreation() {
      return moment(this.source.createdAt).locale('en').format("DD MMM YYYY");
    },
  },
  methods: {
    ...mapActions([]),
    contact(type, data) {
      this.redirectLink({
        type,
        url: source.payload[type],
      });
    },
    accept() {
      UserService.accept(this.source.id);
    },
    viewProfile() {
      this.$router.push(this.username)
      // if (this.username) location.href = 'https://t.link/' + this.username + '#preview';
    },
    editConnection() {
      this.source.showDetails = true;
      EventBus.$emit("toggleConnectionModal", this.source);
    },
    click() {
      EventBus.$emit("toggleTagModal", this.source);
    },
    toggleTagModal(tag) {
      EventBus.$emit("toggleTagModal", tag);
    },
    toggleNoteModal(connection) {
      EventBus.$emit("toggleNoteModal", connection);
    },
  },
};
</script>

<style scoped>
.connectionContainer {
  display: flex;
}
.connectionInfo {
  flex: 1;
  padding-left: 20px;
  word-break: break-word;
}
.connectionInfo * {
  text-align: left;
}
.connection-img {
  width: 50px;
  height: 50px;
  border-radius: 100px;
}
.connection-img-big {
  width: 70px;
  height: 70px;
  border-radius: 100px;
}
.contact-icon {
  margin: 0 auto;
  margin-bottom: 5px;
}

.marker {
  margin-right: 10px;
  border-radius: 5px;
  padding: 3px;
  border: 1px solid black;
  font-size: 10px;
}

img {
  transition: all 0.5s ease-in-out;
}

.withAccordion img {
  width: 90px;
  height: 90px;
  transition: all 0.5s ease-in-out;
}

.accordionContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.accordionContainer {
  height: 0;
  width: 0;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  visibility: hidden;
  transition-delay: 0.5s;
}

.withAccordion .accordionContainer {
  height: unset;
  width: unset;
  opacity: 1;
  transition: all 0.5s ease-in-out;
  visibility: unset;
  transition-delay: 0.5s;
}

.markersContainer {
  overflow-x: scroll;
  white-space: nowrap;
  text-align: left;
  width: 0;
  min-width: 100%;
}

.dateOfCreation {
  font-size: 10px;
  line-height: 10px;
  color: gray;
}

h4 {
  margin-bottom: 0;
}

.emailContainer {
  margin-top: -10px;
  display: grid;
  grid-template-columns: auto 1fr;
}

.emailContainer > span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
