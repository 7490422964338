<script setup>
import { RouterLink } from 'vue-router'
</script>

<template>
  <div
    v-if="isLoggedIn && showNav"
    class="navbar"
    id="myNavbar"
  >
    <!-- home -->
    <RouterLink :to="home.replace('#edit', '#refresh')" exact class="vibrate">
      <!-- Spinner Icon -->
      <div
          v-if="loadingHeader || showSuccessSave"
          class="header-icon font-20 no-border"
          style="position: relative; height: 31px;"
      >
        <i
            v-if="!showSuccessSave"
            class="fa fa-circle-o-notch fa-spin load-social-icon"
            style="position: absolute; bottom: 1px; right: 0; left: 0; width: 50px;"
        ></i>
        <img
            v-else
            src="https://cdn.tapni.co/icons/checkedIcon.svg"
            style="width: 45px; margin-top: 0px;"
        />
      </div>
      <img v-else-if="isLoggedInUser" :src="user.photo" alt="" class="responsive-image rounded-image" :class="{ 'border-picture': route.name.includes('Home') }" />
      <img v-else-if="loggedInAccounts[storage.username]" :src="loggedInAccounts[storage.username].photo" alt="" class="responsive-image rounded-image" />
      <span :class="{ 'bold-icon': route.name.includes('Home') }">{{lang[appLang].profile}}</span>
    </RouterLink>

    <!-- contacts -->
    <RouterLink to="/connections" exact class="vibrate">
      <img v-show="!route.name.includes('Connections')"
          :src="getIcon('newConnections.svg')"
          class="responsive-image"
          :alt="lang[appLang].contacts"
      />
      <img v-show="route.name.includes('Connections')"
          :src="getIcon('newConnections-black.svg')"
           class="responsive-image"
           :alt="lang[appLang].contacts"
      />
      <span :class="{ 'bold-icon': route.name.includes('Connections') }">{{lang[appLang].contacts}}</span>
    </RouterLink>

    <!-- Links -->
    <RouterLink v-if="false && isLoggedIn" to="/links" exact class="vibrate">
      <img
              src="https://cdn.tapni.co/icons/linkIconGray.svg"
              class="responsive-image"
              :alt="lang[appLang].links"
      />
      <span :class="{ 'bold-icon': route.name.includes('Links') }">{{lang[appLang].links}}</span>
    </RouterLink>


    <!--direct link -->
    <a class="direct vibrate">
      <div v-if="user !== null" class="ios-switch" style="display: inline-block">
        <input
          v-model="user.is_direct"
          type="checkbox"
          name="ios-switch"
          class="ios-switch-checkbox"
          :id="'direct-switch'"
        />
        <label
          @click="updateDirectLink(!user.is_direct)"
          class="ios-switch-label"
          :class="{ 'direct-image': user.is_direct }"
          :for="'direct-switch'"
        ></label>
        <span>{{lang[appLang].direct}}</span>
      </div>
    </a>


    <!--add/edit connection
    <a
      v-if="user.connected && $route.name && $route.name.includes('Home')"
      @click="editConnection"
      class="direct pointer vibrate"
    >
       <img
           src="https://cdn.tapni.co/icons/newArrowRight.svg"
           class="responsive-image"
           alt=""
       />
      <span>{{lang[appLang].manage}}</span>
    </a>  -->

    <!-- Share -->
    <a class="pointer vibrate" @click="toggleQR">
      <img
          :src="getIcon('share.svg')"
          class="responsive-image"
          :alt="lang[appLang].share"
      />
      <span>{{lang[appLang].share}}</span>
    </a>

    <!-- analytics
    <RouterLink v-if="isLoggedIn" to="/analytics" exact class="vibrate">
      <img
              src="https://cdn.tapni.co/icons/newAnalytics.svg"
              class="responsive-image"
              alt=""
      />
      <span>{{lang[appLang].analyticsHeader}}</span>
    </RouterLink>
    -->

    <!-- more -->
    <RouterLink to="/more" exact class="vibrate">
      <a>
        <img v-show="!route.name.includes('More')"
          :src="getIcon('newHamburger.svg')"
          class="responsive-image"
          :alt="lang[appLang].more"
        />
        <img v-show="route.name.includes('More')"
             :src="getIcon('newHamburger-black.svg')"
             class="responsive-image"
             :alt="lang[appLang].more"
        />
        <span :class="{ 'bold-icon': route.name.includes('More') }">{{lang[appLang].more}}</span>
      </a>
    </RouterLink>
  </div>
</template>

<script>
import { EventBus } from "../store/event-bus";
import { mapGetters, mapActions, mapState } from "vuex";
import UserService from "../services/UserService";

export default {
  name: "FooterNav",
  data () {
    return {
      loadingHeader: false,
      showSuccessSave: false,
    }
  },
  computed: {
    ...mapState(["loading", "affiliate", "loggedInAccounts"]),
    ...mapGetters(["isLoggedIn"]),
    isLoggedInUser() {
      return this.user && this.storage &&
          this.user.id === this.storage.UserId &&
          this.user.username === this.storage.username &&
          this.isLoggedIn
    },
    directLink() {
      return this.user && this.user.links ? this.user.links.filter((l) => l.is_direct)[0] : null;
    },
    showNav() {
      if (
        this.isLoggedIn &&
        ((this.route.name && this.route.name.includes("Home")) ||
          [
            "Tags",
            "LinkAdding",
            "Connections",
            "Links",
            "Devices",
            "Ambassador",
            "Products",
            "Settings",
            "Analytics",
            "ForBusiness",
            "Referral",
            "More",
          ].includes(this.route.name))
      )
        return true;
      return false;
    },
  },
  mounted() {
    this.applyCSS();
    EventBus.$on("closeModal", (picked) => {
      if (!this.directLink && !picked) {
        if (!this.isEmpty(this.user)) {
          this.user.is_direct = false;
        }
      }
    });

    EventBus.$on("loadingHeader", (nv) => {
      if (this.loadingHeader === true && nv === false) {
        this.showSuccessSave = true;
        setTimeout(() => {
          this.showSuccessSave = false;
        }, 2000);
      }
      this.loadingHeader = nv;
    });
  },
  beforeUpdate: function () {
    this.applyCSS();
  },
  methods: {
    ...mapActions(["addOrUpdateLink", "connect", "getUser", "eventLog"]),
    /*
    openAddConnectionModal() {
      EventBus.$emit("toggleConnectionModal", {
        addConnection: true,
        manualInput: true
      });
    },
     */
    affiliateFooterClick() {
      this.eventLog({
        type: "affiliate_buy_click",
        affiliateId: this.affiliate.id,
      });
      location.href = "https://tapni.com?ref=" + this.affiliate.ref_code;
    },
    applyCSS() {
      if (!this.loading) {
        this.$nextTick(() => {
          const myNavbar = document.getElementById("myNavbar");

          let iconUrl = "https://cdn.tapni.co/icons/direct.png";
          if (this.directLink) {
            if (this.directLink.custom_icon) {
              iconUrl = this.directLink.style.custom_icon;
            } else {
              iconUrl = `https://cdn.tapni.co/icons/${
                  this.directLink ? this.directLink.type : "direct"
              }.png`;
            }
          }

          let cssRule = `.direct-image:before {background-image: url("${iconUrl}")!important;`;
          let style = document.createElement("style");
          style.type = "text/css";
          style.className = "directLinkNavStyle";
          if (myNavbar) {
            const elements =
                document.getElementsByClassName("directLinkNavStyle");
            while (elements.length > 0) {
              elements[0].parentNode.removeChild(elements[0]);
            }

            myNavbar.appendChild(style);
            style.innerHTML = cssRule;
          }
        })
      }
    },
    /*
    toggleConnectModal() {
      EventBus.$emit("toggleConnectModal", true, {profile: this.user);
    },
    async connectHandler() {
      if (!this.isLoggedIn) {
        this.toggleConnectModal();
      } else if (!this.user.connected) {
        EventBus.$emit("loadingHeader", true);
        await this.connect({
          UserId: this.user.id,
        });
        await this.getUser({ username: this.user.username });
        this.$forceUpdate();
        setTimeout(() => {
          EventBus.$emit("loadingHeader", false);
        }, 1000);
      } else {
        const response = await UserService.getConnection(
          this.user.connectionId
        );
        EventBus.$emit("toggleConnectionModal", response.data.connection);
      }
    },
    */
    async editConnection() {
      const response = await UserService.getConnection(this.user.connectionId);
      let data = response.data.connection;
      data.showDetails = true;
      EventBus.$emit("toggleConnectionModal", data);
    },
    updateDirectLink(nv) {
      if (nv === true && !this.loading && this.isLoggedInUser) {
        EventBus.$emit("toggleDirectModal");
      }
      if (nv === false && this.directLink && !this.loading) {
        this.addOrUpdateLink({
          id: this.directLink.id,
          is_direct: false,
        });
      }
    },
    toggleQR() {
      if (!this.isLoggedInUser) {
        this.copy(this.hostname +'/' + this.user ? this.user?.username : this.storage?.username)
      } else {
        EventBus.$emit("toggleQRModal");
      }
    },
  },
  watch: {
    loading() {
      this.applyCSS();
      this.$forceUpdate();
    },
    "user.is_direct"() {
      this.applyCSS();
      this.$forceUpdate();
    },
  },
};
</script>

<style scoped>
/* Place the navbar at the bottom of the page, and make it stick */
.navbar {
  background-color: #ffffff;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 677px;
  padding-bottom: env(safe-area-inset-bottom);
  z-index: 655;
  -webkit-backface-visibility: hidden;
  border: 1px solid #e8e8e8;
  /*height: 62px;*/
  display: flex;
  align-items: center;
}

/* Style the links inside the navigation bar */
.navbar a {
  display: block;
  color: #999999;
  text-align: center;
  padding: 3px 11px 0px 11px;
  text-decoration: none;
  font-size: 10px;
  flex: 1;
}

.navbar img {
  width: 25px;
  margin: 0 auto;
  margin-top: 6px;
  aspect-ratio: 1;
}

.direct {
  float: left;
  width: 30% !important;
  margin: 0 auto;
}

.ios-switch {
  background-color: #ffffff;
  position: relative;
  width: 60px;
  padding-top: 4px;
  padding-right: 4px;
  margin-left: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.ios-switch-checkbox {
  display: none;
}
.ios-switch-label {
  background-color: #e3e3e3 !important;
  display: block;
  overflow: initial;
  cursor: pointer;
  height: 30px;
  padding: 0;
  line-height: 20px;
  border: 1px solid #e3e3e3;
  border-radius: 25px;
  transition: all 250ms ease;
}
.ios-switch-label:before {
  content: "";
  display: block;
  width: 30px;
  margin: 0px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 24px;
  background-image: url("https://cdn.tapni.co/icons/direct-link.svg");
  background-size: 17px;
  background-repeat: no-repeat;
  background-position: center;
  border: 2px solid #e3e3e3;
  border-radius: 25px;
  transition: all 250ms ease;
}

.ios-switch-checkbox:checked + .ios-switch-label {
  background-color: #e3e3e3;
}
.ios-switch-checkbox:checked + .ios-switch-label,
.ios-switch-checkbox:checked + .ios-switch-label:before {
  border-color: #e3e3e3;
}
.ios-switch-checkbox:checked + .ios-switch-label:before {
  right: -1px;
}
.bold-icon{
  font-weight: bold;
  stroke-width: 2px;
  color: #3b3b3b;
}
.border-picture{
  border: 1px solid #3b3b3b;
}
</style>
