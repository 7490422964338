<template>
  <vue-bottom-sheet ref="connectionsFilterModal" @closed="close" :transition-duration="0.4">
    <h1
      class="left-text bold small-top half-bottom headerText"
      style="padding-left: 5px"
    >
      {{lang[appLang].filterConnectionsHeader }}
    </h1>

    <div style="overflow-y: scroll; max-height: calc(92vh - 170px)">
      <h5 class="half-top">
        {{lang[appLang].selectConnectionType }}
      </h5>

      <div class="connectionType">
        <span
          :class="{ active: connectionType === 'all' }"
          @click="connectionType = 'all'"
        >
          {{lang[appLang].all }}
        </span>
        <span
          :class="{ active: connectionType === 'connected' }"
          @click="connectionType = 'connected'"
        >
          {{lang[appLang].direct }}
        </span>
        <span
          :class="{ active: connectionType === 'anonymous' }"
          @click="connectionType = 'anonymous'"
        >
          {{lang[appLang].form }}
        </span>
        <span
          :class="{ active: connectionType === 'manually' }"
          @click="connectionType = 'manually'"
        >
          {{lang[appLang].manually }}
        </span>
        <span
          :class="{ active: connectionType === 'scan' }"
          @click="connectionType = 'scan'"
        >
          {{lang[appLang].scan }}
        </span>
      </div>
      <p class="small-top">{{ connectionTypeLabel[connectionType] }}</p>

      <h5 class="full-top">
        {{lang[appLang].sortOptions }}
      </h5>

      <div class="connectionType sortOptions">
        <span
          :class="{ active: sortBy === 'createdAt' }"
          @click="sortBy = 'createdAt'"
        >
          {{lang[appLang].date }}
        </span>
        <span :class="{ active: sortBy === 'name' }" @click="sortBy = 'name'">
          {{lang[appLang].fullName }}
        </span>
      </div>

      <div class="connectionType sortOptions">
        <span
          :class="{ active: sortDirection === 'desc' }"
          @click="sortDirection = 'desc'"
        >
          {{lang[appLang].descending }}
        </span>
        <span
          :class="{ active: sortDirection === 'asc' }"
          @click="sortDirection = 'asc'"
        >
          {{lang[appLang].ascending }}
        </span>
      </div>

      <h5 class="full-top">
        {{lang[appLang].selectDateRange }}
      </h5>
      <VueDatePicker
        v-model="rangeDate"
        range
        :enable-time-picker="false"
        class="rangeDatePicker edit-input"
        color="#000000"
        format="dd.MM.yyyy"
        model-type="yyyy-MM-dd"
        :placeholder="lang[appLang].selectDateRange"
      />

      <h5 class="full-top">
        {{lang[appLang].selectMarkers }}
      </h5>

      <multiselect
        v-model="markers"
        :options="options"
        :multiple="true"
        :close-on-select="false"
        :clear-on-select="false"
        :preserve-search="true"
        :placeholder="lang[appLang].selectMarkers"
        label="name"
        track-by="name"
        :preselect-first="true"
        selectLabel=""
        deselectLabel=""
        tagPlaceholder=""
        :taggable="true"
        style="width: 95%; margin: 0 auto; border-radius: 8px"
        class="full-bottom markerFilter"
        selectedLabel=""
      />
    </div>

    <div style="display: flex; justify-content: space-between">
      <!-- Reset -->
      <button
        @click="resetFilter"
        class="button white-button half-bottom small-top"
        style="margin: 0; width: auto; margin-left: 10px"
      >
        {{lang[appLang].resetFilter }}
      </button>

      <!-- Save -->
      <button
        @click="saveFilter"
        class="button black-button half-bottom small-top"
        style="margin: 0; width: 62%; margin-right: 10px"
      >
        {{lang[appLang].save }}
      </button>
    </div>
  </vue-bottom-sheet>
</template>

<script>
import { EventBus } from "../../store/event-bus";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import Multiselect from "vue-multiselect";
import UserService from "@/services/UserService";
import moment from "moment";
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
import  "@webzlodimir/vue-bottom-sheet/dist/style.css";
export default {
  components: {
    VueBottomSheet,
    VueDatePicker,
    Multiselect,
  },
  data() {
    return {
      connectionType: "all",
      sortBy: "createdAt",
      sortDirection: "desc",
      rangeDate: this.createRangeDate(),
      markers: [],
      options: [],
    };
  },
  computed: {
    connectionTypeLabel() {
      return {
        all: this.lang[this.appLang].allConnectionsLabel,
        connected: this.lang[this.appLang].directConnectionsLabel,
        anonymous: this.lang[this.appLang].formConnectionsLabel,
        manually: this.lang[this.appLang].manualConnectionsLabel,
        scan: this.lang[this.appLang].scanConnectionsLabel,
      };
    },
  },
  mounted() {
    EventBus.$on("toggleFilterModal", this.toggleModal);
  },
  beforeDestroy() {
    EventBus["_events"]["toggleFilterModal"].pop();
  },
  methods: {
    saveFilter() {
      EventBus.$emit("closeModal");
      EventBus.$emit("refreshConnections", {
        dateFrom: this.rangeDate[0],
        dateTo: this.rangeDate[1],
        type: this.connectionType,
        markers: this.markers.map((marker) => marker.id),
        sortBy: this.sortBy,
        sortDirection: this.sortDirection,
      });
      this.$refs.connectionsFilterModal.close();
    },
    resetFilter() {
      this.connectionType = "all";
      this.sortBy = "createdAt";
      this.sortDirection = "desc";
      this.rangeDate = this.createRangeDate();
      this.markers = [];
    },
    createRangeDate() {
      const start = moment()
        .startOf("year")
        .subtract(3, "year")
        .format("YYYY-MM-DD");
      const end = moment().format("YYYY-MM-DD");

      return [
        start,
        end,
      ];
    },
    close() {
      EventBus.$emit("closeModal");
      document.body.classList.remove('modal-open');
    },
    async toggleModal() {
      const {
        data: { markers },
      } = await UserService.getAllMarkers();

      this.options = markers;
      this.$refs.connectionsFilterModal.open();
      document.body.classList.add('modal-open');

    },
  },
};
</script>

<style>
.dp__input_icon_pad {
  padding-inline-start: 40px;
}
.dp__input_icon {
  margin-top: -18px;
  color: #0c1117;
}
.dp__input {
  background-color: #fff0;
  border: none;
}
.rangeDatePicker {
  margin-top: 15px;
  border-radius: 50px !important;
}
.rangeDatePicker .vd-picker__input {
  justify-content: center;
}
.vd-picker__input input {
  flex: unset;
  width: 220px;
  line-height: 30px;
}
.vd-picker__input-icon {
  margin-right: unset;
}

.sortBy,
.sortDirection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.connectionType {
  display: flex;
  justify-content: center;
  padding: 10px 10px 0;
}

.connectionType .active {
  background: black;
  color: white;
}

.connectionType span {
  border: 1px solid #e8e8e8;
  padding: 10px;
  cursor: pointer;
  flex: 1;
  background: white;
}

.connectionType span:not(:last-child) {
  border-right: none;
}

.connectionType span:first-child {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding-left: 15px;
}
.connectionType span:last-child {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  padding-right: 15px;
}
.sortOptions span {
  padding-top: 5px;
  padding-bottom: 5px;
}
.multiselect {
  width: auto !important;
  margin-top: 15px !important;
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.markerFilter input {
  margin-left: 0 !important;
}
</style>
