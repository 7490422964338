<template>
  <form
    @submit.prevent="submit"
    class="menu-wrapper menu-light menu-modal center-text activate-page"
    :class="{ 'active-menu': toggle }"
    :style="{
      height: 'auto',
      'margin-top': '-172.5px',
      'max-height': '90vh',
      ...customStyle?.style?.formBackground,
    }"
    autocomplete="on"
  >
    <a
      @click="close"
      class="color-black pull-right pointer"
      style="margin-top: 5px; position: absolute; right: 5px"
    >
      <i class="font-17 fa color-black">
        <img
            v-if="profile && !profile?.lead_form?.forceFilling"
            src="https://cdn.tapni.co/icons/down-arrow.png"
            class="fa responsive-image"
            style="width: 45%"
            alt="down-arrow"
        />
      </i>
    </a>
    <h3 v-if="profile" class="center-text bold small-top half-bottom connect-title"
    :style="{...customStyle?.style?.formTitle}" v-text="replaceProfileVariables(customStyle?.config?.formTitleText || strings.formTitle, profile)"></h3>
    <div v-if="profile && !showDetails && (customStyle?.config?.fieldShowProfilePicture ?? true)" class="user-image half-bottom"
         :style="{
          ...customStyle?.style?.formProfilePhoto
         }">
        <img class="responsive-image user-photo"
             :src="profile.photo" alt="user-image"
             :style="customStyle?.style?.formProfileImg"
        />
    </div>

    <input
      type="text"
      v-model="name"
      name="name"
      autocomplete="name"
      :placeholder="lang[appLang].name"
      class="edit-input half-bottom"
      style="margin: 0 auto; width: 90%;"
    />

    <input
        type="email"
        name="email"
        autocomplete="email"
        v-model="email"
        :placeholder="lang[appLang].email"
        class="edit-input half-bottom" style="margin: 0 auto; width: 90%;"
    />
    <div
      style="width: 65%; display: inline-block; margin: 0 auto"
      v-if="!showDetails && ((this.customStyle?.config?.fieldMore ?? true) || customStyle?.config?.fieldPhone || customStyle?.config?.fieldJobTitle || customStyle?.config?.fieldCompany || customStyle?.config?.fieldWebsite || customStyle?.config?.fieldNote)"
    >
      <p @click="showMore = true" class="center-text small-bottom pointer underline bold" :style="{...customStyle?.style?.formTitle}">{{
        lang[appLang].add_details
      }}</p>
    </div>

    <input
      type="text"
      name="phone"
      autocomplete="phone"
      v-model="phone"
      v-show="showDetails && (this.customStyle?.config?.fieldPhone ?? true)"
      :placeholder="lang[appLang].phone"
      class="edit-input half-bottom"
      style="margin: 0 auto; width: 90%;"
    />

    <input
      type="text"
      name="jobTitle"
      autocomplete="jobTitle"
      v-model="title"
      v-show="showDetails && (this.customStyle?.config?.fieldJobTitle ?? true)"
      :placeholder="lang[appLang].contact_card_job_title"
      class="edit-input half-bottom" style="margin: 0 auto; width: 90%;" />

    <input
      type="text"
      name="companyName"
      autocomplete="companyName"
      v-model="companyName"
      v-show="showDetails && (this.customStyle?.config?.fieldCompany ?? true)"
      :placeholder="lang[appLang].company"
      class="edit-input half-bottom"
      style="margin: 0 auto; width: 90%;"
    />

    <input
        type="text"
        name="website"
        autocomplete="website"
        v-model="website"
        v-show="showDetails && (this.customStyle?.config?.fieldWebsite ?? true)"
        :placeholder="'Website'"
        class="edit-input half-bottom"
        style="margin: 0 auto; width: 90%;"
    />

    <textarea
      v-autogrow
      v-model="note"
      v-show="showDetails && (this.customStyle?.config?.fieldNote ?? true)"
      :placeholder="lang[appLang].note_p"
      class="edit-input half-bottom"
      rows="2"
      style="width: 90%; margin: 0 auto; min-height: 80px; resize: none"
    />

    <input v-for="field in company?.objects?.connections" :key="field.id"
        type="text"
        :name="field.value"
        v-model="metadata[field.value]"
        v-show="showDetails && (this.customStyle?.config?.[field.value] ?? false)"
        :placeholder="field.label"
        class="edit-input half-bottom"
        style="margin: 0 auto; width: 90%;"
    />

    <div v-if="customStyle?.config?.explicitConsent" class="form-fields">
      <div style="width:100%">
        <div style="display: flex; margin-top: 10px; margin-left: 5px;">
          <label for="formfieldConsent" class="checkboxes" style="margin-top: 15px;">
            <input v-model="explicitConsent" type="checkbox" id="formfieldName" name="checked" class="checkboxes" />
          </label>
          <p class="close-text left-text half-top color-black" :style="{...customStyle?.style?.consent}">
            You agree with our <br />
            <a :style="`color:${customStyle?.style?.consent?.link}`" href="https://tapni.com/policies/terms-of-service">{{
                lang[appLang].terms_of_service
              }}</a>
            {{lang[appLang].and }}
            <a :style="`color:${customStyle?.style?.consent?.link}`" href="https://tapni.com/policies/privacy-policy">{{
                lang[appLang].privacy_policy
              }}</a>
          </p>
        </div>

        <div v-if="customStyle?.config?.customExplicitConsent" style="display: flex; margin-top: 10px; margin-left: 5px;">
          <label for="formfieldCustomConsent" class="checkboxes" style="margin-top: 15px;">
            <input v-model="explicitCustomConsent" type="checkbox" id="formfieldCustomConsent" name="checked" class="checkboxes" />
          </label>
          <p class="close-text left-text half-top color-black" :style="`color:${customStyle?.config?.customConsentTextColor}`">
            {{ replaceCompanyVariables(customStyle?.config?.customConsentText, company) }}
            <a :style="`color:${customStyle?.config?.customConsentLinkColor}`" :href="customStyle?.config?.customConsentLinkUrl">{{
                customStyle?.config?.customConsentLinkText
              }}</a>
          </p>
        </div>
      </div>
    </div>

    <button
        v-if="!loading"
        type="submit"
        :disabled="profile && profile.connected"
        class="button black-button half-top pointer"
        :style="{
          margin: '0 auto',
          width: '90%',
          ...customStyle?.style?.actionButton,
        }"
    >
      {{ replaceProfileVariables(customStyle?.config?.actionButtonText || lang[appLang].exchange_contact, profile) }}
    </button>

    <button
        v-if="loading"
        class="button black-button half-top pointer button--loading"
        style="margin: 0 auto; width: 90%"
        @click.stop.prevent

    ><span class="button__loader"></span>
    </button>

    <div v-if="!customStyle?.config?.explicitConsent" class="decoration decoration-lines-thin no-bottom"></div>

    <p v-if="!customStyle?.config?.explicitConsent" class="close-text center-text half-top color-black" :style="{...customStyle?.style?.consent}">
      {{lang[appLang].terms_by_submitting }} <br />
      <a :style="`color:${customStyle?.style?.consent?.link}`" href="https://tapni.co/policies/terms-of-service">{{
        lang[appLang].terms_of_service
      }}</a>
      {{lang[appLang].and }}
      <a :style="`color:${customStyle?.style?.consent?.link}`" href="https://tapni.com/policies/privacy-policy">{{
        lang[appLang].privacy_policy
      }}</a>
    </p>
  </form>
</template>

<script>
import {EventBus} from '../../store/event-bus'
import {mapState, mapActions, mapGetters} from 'vuex'
import {replaceProfileVariables} from "@tapni/js-utils";
export default {
  name: "Connect",
  data() {
    return {
      profile : {},
      name: '',
      email: '',
      phone: '',
      companyName: '',
      title: '',
      website: '',
      note: '',
      metadata: {},
      toggle: false,
      showMore: false,
      explicitConsent: false,
      explicitCustomConsent: false,
      loading: false,
    };
  },
  computed: {
    customStyle() {
      return this.profile?.lead_form?.form || {};
    },
    strings() {
      return {
        formTitle: `${this.lang[this.appLang].connect_with} [name]`,
      }
    },
    showDetails() {
      return this.customStyle?.config?.fieldMore || this.showMore;
    }
  },
  mounted() {
    EventBus.$on("toggleConnectModal", this.toggleModal);
    EventBus.$on("closeModal", (force = false) => {
      if(!force && this.profile?.lead_form?.forceFilling) return;
      this.toggle = false;
    });
  },
  beforeDestroy() {
    EventBus['_events']['toggleConnectModal'].pop()
  },
  methods: {
    ...mapActions(["connect", "getUser"]),
    replaceProfileVariables (data, profile) {
      return replaceProfileVariables(data, profile)
    },
    join() {
      this.close();
      this.$router.push("/register");
    },
    close() {
      this.showMore = false;
      EventBus.$emit("closeModal", true);
    },
    async submit() {
      // TODO: Check required fields
      if (!this.name) {
        return this.errorSnack(this.lang[this.appLang].connect_validate_name);
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!this.email || !emailRegex.test(this.email)) {
        return this.errorSnack(this.lang[this.appLang].connect_validate_email);
      }

      if (this.customStyle?.config?.explicitConsent && !this.explicitConsent) {
        return this.errorSnack(this.lang[this.appLang].connect_validate_consent);
      }

      if (this.customStyle?.config?.customExplicitConsent && !this.explicitCustomConsent) {
        return this.errorSnack(this.lang[this.appLang].connect_validate_consent);
      }

      try {
        this.loading = true;
        let captchaToken = await this.getCaptchaToken('form');
        await this.connect({
          name: this.name,
          email: this.email,
          phone: this.phone,
          company: this.companyName,
          title: this.title,
          website: this.website,
          note: this.note,
          metadata: this.metadata,
          UserId: this.profile.id,
          captchaToken
        });
        this.profile.connected = true;
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }

      //this.successSnack(this.lang[this.appLang].exchange_success)
      this.close();
      EventBus.$emit("toggleAfterConnectModal");
      /*
      if (Object.keys(this.user.vcard).length > 0) {
        const url =
          import.meta.env.VITE_APP_API_ROOT + "/v1/users/" + this.user.id + "/vcard";
        this.downloadContent(url);
      }
       */
      this.$forceUpdate();
    },
    downloadContact() {
      const url =
        import.meta.env.VITE_APP_API_ROOT + "/v1/users/" + this.profile.id + "/vcard";
      this.close();
      EventBus.$emit("toggleAfterConnectModal");
      this.downloadContent(url, true);
    },
    toggleModal(shouldToggle = true, {profile}) {
      this.profile = profile;

      if (shouldToggle) {
        this.toggle = !this.toggle;
      }
    },
  },
};
</script>

<style scoped>
  .link-img {
    margin: 0 auto;
    width: 55px;
  }
  #linkText {
    font-size: 22px;
  }

  #linkUrl {
    font-size: 15px;
  }
  input {
    margin: 7px 0;
    margin: 0 auto;
    height: 40px;
  }

  .form-fields {
    margin-left: 10px;
    display:flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  .checkboxes {
    display: flex;
    align-items: center;
    vertical-align: middle;
    word-wrap: break-word;
    margin-right: 7px;
    font-size: 15px;
  }

  input.checkboxes {
    width: 100%;
    height: auto;
    accent-color: black;
  }
  .connect-title {
    width: 80%;
    margin: 0 auto;
  }
</style>
