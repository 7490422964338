<template>
  <form
    @submit.prevent
    class="menu-wrapper menu-light menu-modal center-text activate-page left-text"
    :class="{ 'active-menu': toggle }"
    style="height: auto; margin-top: -172.5px"
  >
    <a
      @click="close"
      class="color-black pull-right pointer"
      style="margin-top: 5px; position: absolute; right: 5px"
    >
      <i class="font-17 fa color-black">
        <img
          src="https://cdn.tapni.co/icons/down-arrow.png"
          class="fa responsive-image"
          style="width: 45%"
        />
      </i>
    </a>
    <img
      :src="'https://cdn.tapni.co/icons/settings.png'"
      class="profile-img small-bottom"
    />

    <!-- Active Profile Section -->
    <div
      v-if="!isEmpty(user)"
      id="deactivate-section"
      class="content-boxed switch-section bg-white"
      style="height: auto; margin-bottom: 0"
    >
      <div
        class="ios-switch"
        style="display: inline-block; margin: -2px 0 7px 10px; float: right"
      >
        <input
          v-model="user.is_active"
          @input="toggleActiveProfile"
          type="checkbox"
          name="ios-switch"
          class="ios-switch-checkbox"
          :id="'deactivate-switch'"
          :disabled="!rules.activeProfile"
        />
        <label class="ios-switch-label" :for="'deactivate-switch'"></label>
      </div>
      <h5 class="pull-left regularbold" style="min-width: 60%">
        {{lang[appLang].profile_active }}
      </h5>
      <p class="pull-left">{{lang[appLang].profile_active_p }}</p>
      <p v-if="!rules.activeProfile" class="pull-left" style="min-width: 60%">
        <b>{{lang[appLang].forbidden_rule }} </b>
      </p>
    </div>

    <div class="center-text full-top">
      <button
        @click="toggleUsernameModal"
        type="button"
        class="button white-button full-top button-full pointer"
        style="margin: 0 auto"
      >
        {{lang[appLang].profile_change_username }}
      </button>
    </div>
    <div v-if="isNative" class="center-text half-top">
      <button
              @click="startScanner"
              type="button"
              class="button white-button small-top button-full pointer"
              style="margin: 0 auto"
      >
        {{lang[appLang].link_a_device }}
      </button>
    </div>
    <div class="center-text half-top">
      <button
        @click="togglePasswordModal"
        type="button"
        class="button white-button small-top button-full pointer"
        style="margin: 0 auto"
      >
        {{lang[appLang].profile_change_password }}
      </button>
    </div>
    <div class="center-text half-top">
      <button
          @click="toggleMFAModal"
          type="button"
          class="button white-button small-top button-full pointer"
          style="margin: 0 auto"
      >
        Enable 2-step verification
      </button>
    </div>
    <div class="center-text half-top">
      <button
        @click="toggleDeleteAccountModal"
        type="button"
        class="button white-button small-top full-bottom button-full pointer"
        style="margin: 0 auto"
      >
        {{lang[appLang].profile_delete_account }}
      </button>
    </div>
  </form>
</template>

<script>
import { EventBus } from "../../store/event-bus";
import UserService from "@/services/UserService";
import {mapActions} from "vuex";

export default {
  name: "AccountSettings",
  data() {
    return {
      toggle: false,
    };
  },
  computed: {
    rules() {
      return this.$store.getters.rules;
    },
  },
  mounted() {
    EventBus.$on("toggleAccountSettingsModal", this.toggleModal);
    EventBus.$on("closeModal", () => {
      this.toggle = false;
    });

    EventBus.$on("qrDecoded", async (data) => {
      if(data.usage === "qrLogin") {
        let response = await this.loginUsingQR({qrToken: data.result, /*captchaToken: await this.getCaptchaToken()*/ });
        if(response === true) this.successSnack('You will be logged in on the web in a few moments')
      }
      this.vibrate('high');
    });
  },
  beforeDestroy() {
    EventBus["_events"]["toggleAccountSettingsModal"].pop();
    EventBus['_events']['qrDecoded'].pop()
  },
  methods: {
    ...mapActions(["loginUsingQR"]),
    startScanner() {
      EventBus.$emit("closeModal");
      EventBus.$emit("startScanner", "QR", "qrLogin");
    },
    close() {
      EventBus.$emit("closeModal");
    },
    toggleUsernameModal() {
      if (!this.rules.editUsername)
        EventBus.$emit("toggleForbiddenRuleModal", false);
      else EventBus.$emit("toggleUsernameModal");
      this.toggleModal();
    },
    toggleMFAModal() {
      EventBus.$emit("toggleAuthModal", { view: 'AuthMFA' });
      EventBus.$emit("toggleAccountSettingsModal", true);
    },
    togglePasswordModal() {
      if (!this.rules.changePassword)
        EventBus.$emit("toggleForbiddenRuleModal", false);
      else EventBus.$emit("togglePasswordModal");
      this.toggleModal();
    },
    toggleDeleteAccountModal() {
      EventBus.$emit("toggleDeleteAccountModal", false);
      this.toggleModal();
    },
    toggleModal() {
      this.toggle = !this.toggle;
    },
    async toggleActiveProfile() {
      await UserService.toggleActiveProfile({ isActive: !this.user.is_active });
    },
  },
};
</script>

<style scoped>
.profile-img {
  margin: 0 auto;
  margin-top: 2px;
  width: 65px;
}
</style>
