<template>
    <form @submit.prevent="submit" class="menu-wrapper menu-light menu-modal center-text activate-page" :class="{'active-menu': toggle}" style="height: auto; margin-top: -172.5px;">
	  <a @click="close" class="color-black pull-right pointer" style="margin-top: 5px; position: absolute; right: 5px;">
		  <i class="font-17 fa color-black">
			  <img src="https://cdn.tapni.co/icons/down-arrow.png" class="fa responsive-image" style="width: 45%;"/>
		  </i>
	  </a>

	  <img :src="'https://cdn.tapni.co/icons/amba.png'" class="password-img half-bottom" alt="Ambassador"/>

      <textarea v-autogrow v-model="comment" :placeholder="lang[appLang].referral_apply_placeholder"
             class="" required rows="1" style="width: 75%; margin: 0 auto; min-height: 80px; resize: none; background: rgb(244, 244, 244) !important; border-radius: 12px !important; padding: 8px; "/>
	  <p class="font-13 small-top-padding small-bottom">
		  {{lang[appLang].referral_reach}}
	  </p>
	  <p class="font-14 bold small-top-padding full-bottom" v-html="lang[appLang].referral_apply_note" />

      <button type="submit" class="button white-button button-full half-bottom" style="margin: 0 auto; margin-top: 10px;">
		  {{lang[appLang].referral_apply_now}}
      </button>
    </form>
</template>

<script>
import {EventBus} from '../../store/event-bus'
import {mapState, mapActions} from 'vuex'
export default {
  name: 'Ambassador',
  data () {
    return {
      comment: '',
      toggle: false
    }
  },
  mounted () {
    EventBus.$on('toggleAmbassadorModal', this.toggleModal)
    EventBus.$on('closeModal', () => { this.toggle = false })
  },
  beforeDestroy() {
    EventBus['_events']['toggleAmbassadorModal'].pop()
  },
  methods: {
    ...mapActions(['referralApply', 'getAffiliate']),
    forceClose () {
      EventBus.$emit('closeModal')
    },
    close () {
      EventBus.$emit('toggleAmbassadorModal')
    },
    async submit () {
      const result = await this.referralApply({
        comment: this.comment
      })
      if (result === true) {
      	await this.getAffiliate(this.user.id)
        this.successSnack(this.lang[this.appLang].referral_apply_thanks)
        EventBus.$emit('toggleAmbassadorModal', true)
      }
    },
    toggleModal () {
      this.toggle = !this.toggle
    }
  }
}
</script>

<style scoped>
  .password-img {
    margin: 0 auto;
    margin-top: 2px;
    width: 65px;
  }
  textarea, input {
	margin: 0 auto;
	height: 20px;
	color: #1d1d1d!important;
	opacity: 1;
	-webkit-text-fill-color: #1d1d1d;
  }
</style>
