import Api from "./Api";

export default {
  getMe() {
    return Api().get(`/users/me`);
  },
  getByUsername(data) {
    let referrer = document.referrer || "";
    let params = data.utmParams || {};
    if (data.dontTap) params.dontTap = true;
    return Api().get(`/users/${data.username}`, {
      params,
      headers: { "X-Referer": referrer },
    });
  },
  getByNumber(data) {
    let referrer = document.referrer || "";
    let params = data.utmParams || {};
    if (data.dontTap) params.dontTap = true;
    return Api().get(`/users/tag/${data.serial}`, {
      params,
      headers: { "X-Referer": referrer },
    });
  },
  getByDomain(data) {
    let referrer = document.referrer || "";
    let params = data.utmParams || {};
    if (data.dontTap) params.dontTap = true;
    return Api().get(`/users/domain/${data.domain}`, {
      params,
      headers: { "X-Referer": referrer },
    });
  },
  save(data) {
    return Api().put(`/users`, data);
  },
  deletePicture(data) {
    return Api().delete(`/media/` + data.type);
  },
  updateLinkStyle(data) {
    return Api().put(`/users/style`, data);
  },
  updateIsPublic(data) {
    return Api().put(`/users/public`, data);
  },
  updateIsActive(data) {
    return Api().put(`/users/active`, data);
  },
  updateIsDirect(data) {
    return Api().put(`/users/direct`, data);
  },
  getAffiliate(UserId) {
    return Api().get(`/affiliate/` + UserId);
  },
  affiliatePayout() {
    return Api().post(`/affiliate/payout`);
  },
  referralApply(data) {
    return Api().post(`/affiliate/apply`, data);
  },
  newPassword(data) {
    return Api().put(`/users/new-password`, data);
  },
  connect(data) {
    return Api().post(`/users/connect`, data);
  },
  saveConnection(data) {
    return Api().put(`/users/connection/` + data.id, data);
  },
  deleteConnection(id) {
    return Api().delete(`/users/connection/` + id);
  },
  createConnection(data) {
    return Api().post("users/connection", data);
  },
  accept(data) {
    return Api().post(`/users/accept`, data);
  },
  getConnection(connectionId) {
    return Api().get(`/users/connection/${connectionId}`);
  },
  getConnections(params, token) {
    if (params.markers) params.markers = params.markers.join(",");
    return Api().get(`/users/connections`, {
      params,
    });
  },
  eventLog(data) {
    return Api().post(`/users/log`, data);
  },
  updateVCard(data) {
    return Api().put(`/users/vcard`, data);
  },
  deleteAccount(data) {
    return Api().post(`/users/profile/delete`, data);
  },
  getAllMarkers() {
    return Api().get("/users/markers");
  },
  createMarker(data) {
    return Api().post("/users/marker", data);
  },
  downloadFile(url) {
    return Api().get(url, { responseType: "blob" });
  },
  registerDevice(data) {
    return Api().post(`/users/device/register`, data);
  },
  addFcmToken(data) {
    return Api().post(`/users/device/fcm`, data);
  },
  getFile(url) {
    return Api().get(url, {
      responseType: "arraybuffer",
      transformRequest: (data, headers) => {
        delete headers["X-Client-Version"];
        delete headers["X-Client-Name"];
        delete headers["Authorization"];
        return data;
      },
    });
  },
  getAllTemplates(type = 'profile') {
    return Api().get("/users/templates?type=" + type);
  },
  applyTemplate(id) {
    return Api().put("/users/template", { templateID: id });
  },
  getAnalytics(params) {
    return Api().get("/users/analytics", { params });
  },
  loginUsingQR(data) {
    return Api().post("/users/qr/login", data);
  },
  togglePublicProfile(payload) {
    return Api().put("/users/public", payload);
  },
  changeLanguage(payload) {
    return Api().put("/users/language", payload);
  },
  toggleActiveProfile(payload) {
    return Api().put(`/users/active`, payload);
  },
};
