export default {
  state: {
    english: "영어",
    spanish: "스페인어",
    german: "독일어",
    korean: "한국인",
    italian: "이탈리아 사람",
    french: "프랑스 국민",
    serbian: "세르비아 사람",
    turkish: "터키인",
    chinese: "중국어",
    app_language: "언어",
    profile_preview: "프로필 미리보기",
    edit_profile: "프로필 수정",
    login: "로그인",
    referral_reach:
      "귀하와 귀하가 찾는 것에 대한 자세한 정보를 제공해 주십시오.",
    referral_apply_placeholder: "탭니를 홍보하기 위해 어떤 방법을 갖고있나요?",
    referral_apply_note:
      "가능한 많은 계정의 링크를 입력해주세요. <br>\n\t\t 당신이 누구인지 우리에게 보여주세요.\n\t\t  <br><br>독점을 위해서.\n\t\t  <br>모든 사람을 승낙할 순 없습니다.😉",
    referral_apply_now: "신정하기",
    referral_apply_thanks:
      "신청해주셔서 감사합니다. 빠른 시일 내에 어플로 알려드리겠습니다!",
    connect: "연결",
    with: "와 함께",
    exchange_contact: "연락처 교환",
    add_details: "더 추가",
    show_details: "더 보기",
    download_contact: "연락처 다운로드",
    stay_connected: "연결 유지",
    no_thanks: "고맙지 만 사양 할게",
    connect_join: "탭니 가입",
    connect_with: "연결",
    connect_required_fields: "두 개의 연락처 공간 중 하나는 작성해야 합니다.",
    name: "이름",
    first_name: "이름",
    last_name: "성",
    email: "이메일",
    phone: "휴대폰",
    phone_p: "휴대폰",
    company: "회사",
    address: "주소",
    website: "웹사이트",
    open: "열기",
    delete: "삭제",
    confirm: "확정하다",
    cancel: "취소",
    save: "저장",
    code: "코드",
    note: "참고",
    note_p: "메모 추가",
    contact_card_icon: "연락처 카드 아이콘 아래에 텍스트 저장",
    contact_card_name: "연락처 카드의 이름을 입력하세요",
    contact_card_name_p: "연락처 카드 이름",
    contact_card_email: "연락처 카드의 이메일 입력",
    contact_card_email_p: "연락처 카드 이메일",
    contact_card_phone: "연락처 카드의 전화번호 입력",
    contact_card_phone_p: "연락처 카드 전화번호",
    contact_card_website: "연락처 카드의 웹사이트 입력",
    contact_card_website_p: "연락처 카드 웹사이트",
    contact_card_company_name: "연락처 카드의 회사 이름 입력",
    contact_card_company_name_p: "연락처 카드 회사 이름",
    contact_card_job_title: "직위",
    contact_card_job_title_p: "직함을 입력하세요",
    contact_card_company_website: "C회사 웹 사이트",
    contact_card_company_website_p: "회사 웹사이트 입력",
    contact_card_bio: "연락처 카드의 소개글 입력",
    contact_card_bio_p: "연락처 카드의 소개글 입력",
    contact_card_home_address: "연락처 카드의 집 주로 입력",
    contact_card_home_address_p: "연락처 카드 집 주소",
    contact_card_work_address: "연락처 카드의 회사 주소 입력",
    contact_card_work_address_p: "연락처 카드 회사 주소",
    contact_card_social:
      "탭니 프로필에 쇼셜 미디어 링크 입력 <br> 연락처 카드에 추가하기 위하여",
    contact_card_social_p: "연락처 카드에 입력할 소셜 미디아 링크 입력",
    link_text_p: "링크 주소",
    link_icon: "링크 아이콘의 이름 입력",
    direct_pick: "메인 링크를 선택",
    direct_p:
      "누군가의 폰에 탭 했을때, <br> 나의 전체 프로필 대신에 링크를 열어줍니다.",
    password_change: "비밀번호 변경",
    password_repeat: "비밀번호 확인",
    password_reset_t: "비밀번호 재설정",
    password_current: "현재 비밀번호 입력",
    password_current_p: "현재 비밀번호",
    password_new: "새로운 비밀번호 입력",
    password_new_p: "새로운 비밀번호",
    password_new_repeat: "새로운 비밀번호 확인",
    password_new_repeat_p: "새로운 비밀번호 확인",
    password_update: "비밀번호 업데이트",
    password_reset:
      "만약 현재 비밀번호를 잊어버리셨을 경우,<br>\n\t\t  여기를 클릭하여",
    delete_account_permanently: "Tapni 계정을 영구적으로 삭제하시겠습니까?",
    by_clicking_here: "쇼핑하기",
    password_8_chars: "최소한 8개 이상의 비밀번호를 입력 해야합니다.",
    password_least_one_lowercase: "암호에는 소문자가 하나 이상 포함되어야 합니다.",
    password_least_one_uppercase: "비밀번호는 적어도 하나의 대문자를 포함해야 합니다.",
    password_least_one_number: "비밀번호는 적어도 하나의 숫자를 포함해야합니다",
    password_not_match: "비밀번호가 같지 않습니다",
    password_change_success: "비밀번호 변경이 완료되었습니다.",
    or: "또는",
    profile: "프로필",
    account_settings: '계정 설정',
    profile_public: "프로필 공개",
    profile_public_p: "우측 링크를 통해 프로필을 공개적으로 사용 가능합니다 :",
    profile_active: "프로필 활성화",
    profile_active_p:
      "만약 프로필을 비활성화하면, 아무도 나의 프로필에 액세스할 수 없으며 연결된 태그는 더 이상 작동하지 않습니다.",
    profile_change_password: "비밀번호 변경",
    profile_change_username: "사용자 이름 변경",
    profile_change_language: "언어 변경",
    profile_delete_account: "계정 삭제",
    profile_confirm_delete_account: "계정 삭제",
    profile_cancel_delete_account: "취소",
    profile_delete_account_success: "계정이 성공적으로 삭제되었습니다",
    profile_remove_p:
      "만약 계정 삭제를 원하시면, 저희에게<br>\n\t\t    계정 제거 요청을 해주세요.",
    profile_contact_support: "계정을 제거하려면 다음 주소로 문의하십시오.",
    profile_email_subject: "Tapni App Support",
    profile_remove_subject: "보내주세요.",
    profile_update_success: "프로필 업데이트 완료.",
    profile_complete: "프로필 작성",
    completed: "완료",
    uncompleted: "미완성",
    contact_us: "우리에게 연락 해 주세요.",
    contact_us_verify:
      "\n음성듣기번역하기언어전환한국어\n당신의 이메일 주소를 확인하기 위해.",
    verify_email: "이메일 주소 확인",
    verify_email_subject: "이메일 검증",
    verify_email_inbox: "편지함을 확인 해 주세요.",
    add_first_link: "편지함 확인해주세요.",
    add_first_link_p:
      "여기를 클릭하여 소셜 미디어 채널을 추가 해 주세요. <br>\n\t\t\t  또는 탭니 프로필에 연락처 정보를 추가 해 주세요.",
    add_picture: "프로필 사진 추가",
    add_picture_p: "클릭하여 프로필 사진을 추가 해 주세요.",
    add_tag: "나의 탭니 활성화",
    add_tag_p: "클릭하여 탭니 NFC 태그 활성화를 해 주세요.",
    completing_profile:
      "프로필 완성을 하는데 문제가 있나요?<br>\n\t\t    여기로 편하게 연락주세요",
    completing_profile_subject: "프로필완성",
    share: "공유하다",
    share_profile: "공유하다",
    share_profile_p: "QR 코드를 스캔하여 <br> 나의 프로필을 볼 수 있습니다.",
    share_profile_change_username:
      "만약 링크 변경을 원하신다면, <br> <u>여기를 클릭</u>하여 사용자 이름을 변경하세요.",
    share_profile_qr: "프로필 공유",
    share_contactcard_qr: "연락처 카드",
    scan_qr_code: "스캔 QR 코드",
    enter_activation_code: ".",
    activate_tag: "태그 활성화",
    order_tapni:
      "아직 탭니를 주문하지 않으셨나요?<br>\n          나만의 탭니를 주문하세요.",
    activation_success: "태그 연결 성공. <br> 세팅 준비 완료!🥳",
    activation_success_message: "태그 연결 성공. 세팅 준비 완료!🥳",
    activation_error: "올바른 태그가 아닙니다. <br> 다시 해주세요.",
    activation_error_message: "올바른 태그가 아닙니다. <br> 다시 해주세요.",
    activation_progress: "태그 등록 진행 중...",
    activation_invalid_code: "잘못된 활성화 코드입니다.",
    activation_qr_failed: "QR 코드 스캔 실패",
    activation_qr_area: "QR 코드를 스캔 영역 안에 넣어주세요.",
    activation_code: "활성화 코드",
    invalid_code: "잘못된 코드",
    total_taps: "총 탭 횟수",
    active: "활성",
    tag_active_p:
      "다른 사람의 폰에 탭을 하였을 때 <br> 비활성화된 경우 프로필이 열리지 않습니다.",
    tag_delete_confirm:
      "태그 연결을 해제하기 전에 활성화 코드 저장하세요,<br> 그렇지 않으면 다시 활성화될 수 없습니다.",
    upload_profile_picture: "프로필 사진 업로드",
    upload_cover_picture: "커버 사진 업로드",
    upload_link_picture: "링크 사진 업로드",
    select_from_device: "장치에서 선택",
    select_from_mobile: "모바일에서 사진 선택",
    select_from_computer: "또는 컴퓨터를 사용하여 끌어다 놓을 수 있습니다.",
    crop: "자르기",
    crop_upload: "자르고 업로드",
    crop_image: "이미지 자르기",
    drag_and_drop: "파일을 끌어다 여기 놓으세요.",
    upload_success: "미디어 업로드 완료",
    browser_not_supported: "브라우저가 지원하지 않습니다.",
    change_username: "사용자 이름 변경",
    change_username_success: "사용자 이름을 성공적으로 변경했습니다.",
    update_username: "사용자 이름 업데이트",
    new_username: "새 사용자 이름 입력",
    username_is_free: "사용자 이름이 사용 가능합니다",
    username_check: "올바른 사용자 이름을 제공하십시오.",
    anonymous: "익명",
    connected: "연결된",
    exchanged: "교환",
    accept: "수락하다",
    view: "보기",
    join: "가입",
    add_account: "계정 추가",
    complete_profile: "프로필 완성하기",
    my_profile: "내 프로필",
    my_qr: "나의 QR",
    my_connections: "나의 연결 기록",
    contacts: "연락처",
    my_contacts: "내 연락처",
    contact_description: "중요하게 생각하는 모든 연결을 위한 개인 CRM",
    my_tags: "내 제품",
    my_tags_description: "계정과 연결된 스마트 비즈니스 카드 관리",
    buy_tapni: "탭니 구매",
    referral_program: "엠버서더 프로그램",
    device_compatibility: "장치 호환성",
    help: "도움말",
    settings: "세팅",
    logout: "로그아웃",
    version: "버젼",
    configure: "설정하다",
    unexpected_err:
      "예기치 않은 오류가 발생했습니다. 지원 센터에 문의하십시오.@tapni.co",
    success_login: "다른 계정으로 로그인했습니다.",
    password_reset_success: "비밀번호 재설정 코드가 이메일로 전송되었습니다.",
    password_reseted_success:
      "암호를 성공적으로 변경했습니다. 다시 로그인 해주세요.",
    success_remove_link: "링크를 제거했습니다.",
    success_remove_picture: "사진이 성공적으로 제거되었습니다.",
    profile_active_message: "프로필이 이제 활성화되었습니다.",
    profile_inactive_message: "이제 프로필이 비활성화되었습니다.",
    profile_public_message: "프로필이 공개되었습니다.",
    profile_private_message: "프로필을 비공개로 전환하였습니다.",
    redirect_enabled: "링크 사이트로 자동 설정되었습니다.",
    redirect_disabled: "자동-회선 링크 사용",
    tag_active: "자동-회선 링크 사용 안함",
    tag_inactive:
      "이제 태그가 비활성화되었습니다. 당신의 방문자들은 tapni.co사이트에 들어가집니다.",
    tag_removed: "태그가 제거되었습니다. 도움이 필요하시면 연락주세요@tapni.co",
    connection_accepted: "연결 요청을 수락했습니다.",
    connection_added: "새 연결이 성공적으로 추가되었습니다",
    connection_exist: "이 사용자와 이미 연결되어 있습니다",
    not_found: "찾을 수 없습니다.",
    not_found_p: "길을 잃은 것 같군요",
    go_back: "돌아가세요",
    referral_p1: "제휴 판매로 인하여<br> 20%의 커미션을 얻습니다 💰",
    referral_p2:
      "당신의 할인 코드로 다른 사<br> 람들에게 20% 할인해 주세요. 🎁",
    referral_p3: "서로가 좋은 이득을 갖습니다. 🤝",
    referral_p4:
      "만약 당신이 당신의 추천인을 바꾸고 싶다면,<br>\n\t\t\t\t  또는 궁금한 게 있으면,<br>\n\t\t\t\t  저희에게 연락주세요",
    referral_p5: "지금부터 돈을 벌기 시작하세요!",
    referral_p66: "제휴링크를 공유하고 돈을 벌어보세요!",
    referral_p6:
      "블로거, 인플루언서 또는 소셜 미디어 매니아라면 당사의 엠버서더 프로그램 에 완벽하게 적합합니다.\n\t\t\t\t브랜드를 홍보하고 할인 코드 또는 추천 링크를 사용하여 구입한 경우 20%의 수수료를 받으십시오.",
    referral_p7:
      "탭니 엠버서더 프로그램에 등록하면 개인 추천 링크와 할인 코드가 발급됩니다.\n\t\t\t\t웹 사이트 및 소셜 미디어에 공유하여 돈을 버는 동시에 최고의 연결 경험을 제공하십시오.",
    referral_p8: "지불 메모",
    referral_p9:
      "Paypal (세계적인) 또는 계좌이체 (EU) <br> 최소 지급액은 25€입니다.",
    referral_p10: "지급 요청",
    referral_p11: "요청된 지불",
    your_connections: "나의 연결 기록",
    connections_p1: "사람들과 만나서 탭을 하세요.",
    connections_p2:
      "<br><br>지금까지 연결이 없습니다. <br> 탭을 시작해볼까요💥",
    connections_p3: "연결을 찾을 수 없습니다.",
    connection_success: "연결 요청을 보냈습니다.",
    exchange_success: "연락처를 교환했습니다.",
    create_free_account:
      "무료 Tapni 계정을 만들고 연락처를 최신 상태로 유지하십시오!",
    connection_edit: "연결 편집",
    connection_edit_success: "성공적으로 업데이트된 연결",
    connection_delete_success: "연결을 삭제했습니다.",
    search: "검색",
    devices_p1: "장치 호환성 목록",
    devices_p2:
      "탭니는 대부분의 최신 아이폰 및 안드로이드와 호환이 가능합니다.",
    devices_p3:
      "일부 안드로이드 폰은 NFC가 꺼져 있을 수 있습니다. 탭니가 안드로이드 장치에서 작동하지 않는 경우 설정에서 NFC를 검색하고 켜져 있는지 확인합니다.",
    devices_p4: "제어 센터에 iOS 14 NFC 위젯 포함",
    download_p1: "탭니 앱 다운로드",
    download_p2: "애플 및 안드로이드 기기에서 사용 가능",
    user_not_found: "사용자를 찾을 수 없음",
    user_not_found_p: "데이터베이스에서 사용자를 찾을 수 없습니다.🤷",
    taps: "탭",
    private_profile: "개인 프로필",
    private_profile_p:
      "은 개인 프로필입니다. <br> 프로필은 탭니 태그에서만 사용하며 표시됩니다.",
    profile_bio_p: "자기 소개를 해주세요.",
    add_to_contacts: "연락처에 추가",
    drag_and_drop_links: "링크를 원하는 순서대로 끌어서 놓기",
    no_links: "프로필에 링크가 없습니다.",
    add_link: "링크 추가",
    direct_link_p1: "방문자는 다음으로 자동 이동됩니다:",
    profile_not_active:
      "프로필이 현재 활성화되지 않았습니다. <br> 로그인을 해야 볼 수 있어요.",
    profile_not_public:
      "프로필이 현재 공개되지 않았습니다. <br> 누르신 분들만 볼 수 있습니다.",
    activate_profile_now: "프로필 활성화 완료",
    publish_profile_now: "지금 프로필을 공개하십시오.",
    link_add_p1: "링크 유형 선택",
    link_add_not_found: "링크를 찾을 수 없습니다. 🤷",
    link_add_not_found_p2:
      "즐겨찾는 플랫폼을 놓치셨다면, 저희에게 이메일을 보내주시기 바랍니다. (<b>support@tapni.com</b>) 아니면 소셜 미디어로 DM을 해주세요.(<b>@tapni</b>)",
    link_add_not_found_p3:
      "모든 플랫폼을 지원할 수 있도록 최선을 다하겠습니다. 😉",
    featured: "피처링",
    social_media: "소셜 미디어",
    contact: "연락처",
    finance: "금융",
    portfolio: "포트폴리오",
    music: "음악",
    business: "비지니스",
    personal: "개인적인",
    fun: "재미",
    ecommerce: "전자상거래",
    more: "더 보기",
    email_username: "이메일 / 사용자 이름",
    username: "사용자 이름",
    password: "비밀번호",
    sign_in: "로그인",
    sign_in_with: "로그인",
    sign_in_with_new: "새 계정으로 로그인",
    sign_up_with: "등록하기",
    select_sign_method: "로그인 방법 선택",
    verify_account: "계정 확인",
    terms_by_signing_in: "회원님의 동의로 회원가입을 합니다.",
    terms_by_registering: "동의하시면 등록하여 주십시오.",
    terms_by_submitting: "이 양식을 제출하면 서비스 약관에 동의하는 것입니다",
    terms_of_service: "서비스 약관",
    and: "그리고",
    refresh: "새로 고침",
    privacy_policy: "개인 정보 보호 정책",
    create_account: "계정 만들기",
    reset_password: "비밀번호 재설정",
    reset_password_p1:
      "암호를 재설정할 수 없으면, <br> 언제든지 다음 주소로 연락하십시오.",
    reset_password_p2:
      "암호를 재설정할 수 없으면, <br> 언제든지 다음 주소로 연락하십시오.",
    create_new_account: "새 계정 만들기",
    maintenance_p1: "우리는 곧 돌아올 것이다.",
    maintenance_p2: "그동안 탭니 컬렉션 살펴보기",
    maintenance_p3:
      "탭니는 예정된  점검으로 인하여 잠시 중단되었습니다. <br> 몇 분 내로 다시 온라인 상태가 될 것입니다.",
    offline_p1: "인터넷에 연결되지 않음",
    offline_p2: "연결을 확인한 다음 페이지를 새로 고치십시오.",
    offline_p3_1:
      "오프라인일 때 이 QR 코드를 사용하여 연락처 카드를 공유할 수 있습니다.",
    offline_p3_2: "",
    products_p1:
      "만나는 모든 사람에게 깊은 인상을 남깁니다. 😎 <br> 지금 바로 구입하십시오 👇",
    cards: "카드",
    stickers: "스티커",
    keychains: "키 체인",
    explore_collection: "컬렉션 탐색",
    register: "등록",
    register_success: "거의 다 완료하였습니다. 이메일을 확인해 주세요.",
    register_success_modal: "다른 계정으로 로그인했습니다.",
    check_your_inbox: "첫 번째 링크를 추가 해 주세요.",
    tapni_tags: "탭니 태그",
    tapni_tags_p1: "프로필에 연결된 태그 구성",
    tapni_tags_p2: "프로필에 연결된 태그가 없습니다. <br> 지금 연결하세요!",
    verify_account_p1:
      "코드를 받지 못한 경우 다음을 확인하십시오. <br> 스팸 폴더 또는 연락주세요:",
    verify_account_success:
      "계정을 확인했습니다. 지금 비밀번호를 변경해 주세요.",
    verify_account_success2: "계정을 확인했습니다. 모두 완료 되었습니다.!🥳",
    welcome: "반갑습니다.",
    welcome_p1: "비지니스 카드의 미래까지",
    how_it_works: "작동 방식",
    already_registered: "이미 등록되어있습니다",
    error: "오류",
    success: "성공",
    copied: "클립보드에 복사됨",
    connect_validate: "두 개의 연락처 필드 중 하나를 제공해야 합니다.",
    connect_validate_name: "이름을 입력하십시오.",
    connect_validate_email: "유효한 이메일을 입력하세요",
    link_validate: "잘못된 링크 데이터입니다.",
    link_text_contact_card: "연락처 카드",
    link_text_custom_link: "사용자 지정 링크",
    link_text_menu: "메뉴",
    link_text_phone: "휴대폰",
    save_to_apple_wallet: "Apple 지갑에 저장",
    save_to_google_wallet: "Google 지갑에 저장",
    card: "카드",
    sticker: "상표",
    keychain: "키체인",
    band: "밴드",
    case: "사례",
    standee: "서다",
    decoded: "Decoded",
    wristbanditz: "Wristbanditz",
    update_p1: "오래된 앱 버전이 감지되었습니다",
    update_p2: "업데이트",
    update_p3: "앱을 최신 버전으로 업데이트하세요",

    company_domain: "귀하의 회사 도메인",
    search_company_domain: "회사 도메인 검색",
    enter_company_email: "회사 이메일을 입력하세요",
    company_not_found: "회사를 찾을 수 없음",
    ik_company_domain: "회사 도메인을 알고 있습니다",
    idk_company_domain: "회사 도메인을 모릅니다",
    continue: "계속",
    forbidden_rule:
      "이 옵션은 비활성화되어 있습니다. 회사 관리자에게 문의하십시오.",
    companyName: "회사 이름",
    createMarkerPlaceholder: "마커 생성 또는 선택",
    clickToCreateMarker: "마커를 만들려면 여기를 클릭하세요.",
    pleaseInviteUser:
      "데이터를 동기화하려면 사용자를 Tapni에 가입하도록 초대하세요.",
    connectionSynchronized: "연결 데이터가 자동으로 동기화됩니다.",
    viewProfile: "프로필보기",
    createConnectionHeader: "새 연결 추가",
    createConnectionButton: "추가하다",
    createConnectionSuccess: "연결이 생성되었습니다.",
    allow_company_management: "회사 관리 허용",
    company_join_request:
      "이 귀하의 프로필을 관리하고 이를 기업 계정에 연결하도록 요청하고 있습니다.",
    account_ownership_transfer:
      "귀하가 요청을 수락하면 귀하의 계정 소유권이 회사로 이전됩니다. 회사는 귀하의 프로필을 수정하고, 링크를 편집 및 추가하고, 연결을 확인하고, 비밀번호를 수정하고, 계정을 삭제하는 등의 작업을 수행할 수 있습니다. 요청을 수락하기 전에 회사에 확인하십시오.",
    request_expired:
      "요청이 만료되었습니다. 회사에 새 요청을 보내도록 요청하십시오.",
    request_expiration_desc:
      "보안 강화를 위해 요청은 전송된 후 14일 동안만 작동합니다.",
    invitation_link_not_valid: "초대 링크가 유효하지 않습니다!",
    invitation_link_not_valid2:
      "초대 이메일에서 버튼을 다시 클릭하거나 초대 링크를 복사합니다.",
    accept_request: "요청 수락",
    close: "닫다",
    request_accepted: "요청이 성공적으로 수락되었습니다.",
    companyEmail: "회사 이메일",
    companyPhone: "회사 전화",
    addBusinessInformation: "비즈니스 정보 추가",
    resetFilter: "초기화",
    selectMarkers: "마커 선택",
    filterConnectionsHeader: "필터 연결",
    selectConnectionType: "연결 유형 선택",
    all: "모두",
    direct: "직접",
    form: "형태",
    manually: "수동으로",
    scan: "주사",
    sortOptions: "정렬 옵션",
    date: "제작 일",
    fullName: "성명",
    descending: "내림차순",
    ascending: "오름차순",
    selectDateRange: "날짜 범위 선택",
    follow_up_reminder: "후속 알림",
    now: "지금",
    one_hour: "1 토",
    one_day: "1 일",
    three_days: "3 일",
    one_week: "일주",
    two_weeks: "이주",
    one_month: "개월",
    custom: "시간 선택",
    set_reminder: "알림 설정",
    select_date_time: "날짜 및 시간 선택",
    enter_reminder_text: "알림 텍스트 입력",
    reminder_created: "알림이 생성되었습니다.",
    connectionNameLabel: "연결 이름 입력",
    connectionEmailLabel: "개인 이메일 주소 입력",
    privatePhoneLabel: "개인 전화번호 입력",
    companyNameLabel: "회사 이름 입력",
    businessEmail: "비즈니스 이메일 주소",
    businessEmailLabel: "비즈니스 이메일 주소 입력",
    businessPhone: "회사 전화번호",
    businessPhoneLabel: "회사 전화번호 입력",
    businessFax: "비즈니스 팩스",
    businessFaxLabel: "비즈니스 팩스 번호 입력",
    noteLabel: "메모 입력",
    markersLabel: "연결 마커 추가",
    showScan: "스캔 표시",
    hideScan: "스캔 숨기기",
    create_connection: "연결 추가",
    create_manually: "수동으로 추가",
    create_using_scanner: "카드 스캐너를 사용하여 추가",
    companyTitle: "회사명",
    companyTitleLabel: "회사명 입력",
    allConnectionsLabel: "모든 연결 유형",
    directConnectionsLabel: "Tapni 사용자와 직접 앱 내 연결",
    formConnectionsLabel: "연결 양식에 채워진 연결",
    manualConnectionsLabel: "수동으로 생성된 연결",
    scanConnectionsLabel: "카드 스캐너가 연결을 생성했습니다.",
    analyticsHeader: "분석",
    analyticsProfileViews: "프로필 보기",
    analyticsLinkClicks: "링크 클릭 수",
    analyticsClickTroughRatio: "트로프 비율 클릭",
    analyticsTotalConnections: "총 연결 수",
    analyticsTopLinks: "가장 인기 있는 링크",
    analyticsProfileVisits: "프로필 방문",
    analyticsLinkVisits: "링크 방문",
    analyticsNewConnections: "새로운 연결",
    analyticsTopCountries: "상위 국가",
    analyticsTopReferers: "상위 추천인",

    tapni_for_business: "비즈니스용 탭니",
    supercharge_your_business: "기업 및 팀을 위해 만들어진 1등 디지털 명함 플랫폼으로 비즈니스를 강화하세요.",

    for_teams_and_businesses: "팀 및 비즈니스를 위하여",
    number_one_digital_business_card_platform: "1위 디지털 명함 플랫폼",
    manage_teams_collect_leads_automate_workflows: "팀 관리, 리드 수집 및 워크플로우 자동화",
    read_more: "더 읽기",
    navigate_to_business_tapni_com: "1) business.tapni.com으로 이동하기",
    start_qr_scanner_via_button_below: "2) 아래 버튼을 통해 QR 스캐너 시작",
    scan_the_qr_code_from_the_screen: "3) 화면의 QR 코드 스캔",
    enjoy_using_our_platform: "4) 우리 플랫폼 사용 즐기기",
    start_qr_scanner: "QR 스캐너 시작",

    change_language: "언어 변경",
    terms_and_privacy: "약관 및 개인정보처리방침",

    lead_capture_mode: "리드 캡처 모드",
    lead_capture_mode_description: "리드 캡처 양식이 귀하의 프로필에 표시됩니다",
    public_profile_mode: "공개 프로필 모드",
    public_profile_mode_description: "프로필을",
    public_profile_mode_available: "공개로 설정",

    link_a_device: "장치 연결하기",

    streetName: "거리 이름",
    streetNr: "번호",
    plz: "우편 번호",
    city: "도시",
    country: "국가",

    choose_template: "템플릿 선택",
    contactcard: "연락처 카드",
    customlink: "맞춤 링크",
    onboarding: "온보딩",
    tag_text_p: "태그 이름",
    tag_text_p2: "제품에 대한 사용자 정의 이름 설정",
    connect_validate_consent: "개인정보 동의를 수락해주세요",
    links: "링크",
    manage: "관리",
    buy_domain: "도메인 구입",
    buy_domain_p: "맞춤 도메인으로 온라인 정체성을 맞춤 설정하세요.",

    enable_lead_button: "'연락처 교환' 버튼 활성화",
    enable_lead_button_p: "당신의 프로필에 연락처 교환 버튼이 표시됩니다",
    form_auto: '프로필 방문시 양식 자동 열기',
    form_auto_p: '누군가 당신의 프로필을 열 때 연락처 교환 양식이 자동으로 열립니다',
    form_required: '양식 작성 필수',
    form_required_p: '닫기 전에 양식을 작성해야만 하는 것으로 설정하세요.',
    enter_code_manually: '코드 수동 입력',
    activate_via_nfc: 'NFC를 통해 활성화',
    qr_code: 'QR 코드',
    nfc: 'NFC',
    nfc_not_available: '이 기기에서는 NFC를 사용할 수 없습니다',
    nfc_not_enabled: 'NFC가 활성화되어 있지 않습니다',
    nfc_no_link_found: 'NFC 링크를 찾을 수 없습니다. 지원팀에 문의하세요.',
    activation_second_step_needed: '한 단계 더 필요합니다. <br> 이제 NFC를 통해 제품을 스캔하세요',

    scanner_card_p: '카메라를 종이 카드에 대고 <br> 버튼을 누르세요.',
    scanner_qr_p: '카메라를 QR 코드에 대고 <br> 자동 감지하세요.',
    scanner_badge_p: '카메라를 배지에 대고 <br> 버튼을 누르세요.',
    paper_card: '종이 카드',
    event_badge: '행사 배지'
  },
};
