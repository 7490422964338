export default {
  state: {
    english: "Anglais",
    spanish: "Espagnol",
    german: "Allemand",
    italian: "Italien",
    korean: "Coréen",
    french: "Français",
    serbian: "Serbe",
    turkish: "Turc",
    chinese: "Chinois",
    app_language: "Langue",
    profile_preview: "Aperçu du profil",
    edit_profile: "Editer le profil",
    login: "Connexion",
    referral_reach:
      "Fournissez-nous plus d'informations sur vous et votre portée.",
    referral_apply_placeholder: "Comment envisagez-vous de promouvoir Tapni?",
    referral_apply_note:
      "Ajouter autant de liens que possible sur votre compte, <br>\n                  afin que nous puissions savoir qui vous êtes.\n                  <br><br> Soyez exclusif.\n                  <br>Tout le monde ne peut pas être accepté.😉",
    referral_apply_now: "Postulez maintenant",
    referral_apply_thanks:
      "Merci d'avoir postulé. Nous allons étudier votre candidature très bientôt!",
    connect: "Connecter",
    with: "avec",
    exchange_contact: "Échanger mes coordonnées",
    add_details: "Ajouter plus",
    show_details: "Montrer plus",
    download_contact: "télécharger le contact",
    stay_connected: "Restez connecté",
    no_thanks: "Non merci",
    connect_join: "Rejoindre Tapni",
    connect_with: "Se connecter à",
    connect_required_fields: "L'un des deux champs de contact doit être fourni",
    name: "Nom",
    first_name: "Prénom",
    last_name: "Nom de famille",
    email: "E-mail",
    phone: "Numéro de téléphone",
    phone_p: "Téléphone",
    company: "Société",
    address: "Adresse",
    website: "Site web",
    open: "Ouvrir",
    delete: "Supprimer",
    confirm: "Confirmer",
    cancel: "Annuler",
    save: "Sauvegarder",
    code: "Code",
    note: "Note",
    note_p: "Ajouter une note",
    contact_card_icon: "Définir le texte sous l'icône de la fiche contact",
    contact_card_name: "Entrez le nom de la fiche contact",
    contact_card_name_p: "Nom de la fiche contact",
    contact_card_email: "Entrez l'e-mail pour la fiche contact",
    contact_card_email_p: "Email de la fiche contact",
    contact_card_phone: "Entrez le téléphone pour la fiche contact",
    contact_card_phone_p: "Téléphone de la fiche contact",
    contact_card_website: "Entrez le site Web pour la fiche contact",
    contact_card_website_p: "Site Web de la fiche contact",
    contact_card_company_name:
      "Entrez le nom de l'entreprise pour la fiche contact",
    contact_card_company_name_p: "Nom de l'entreprise pour la fiche contact",
    contact_card_job_title: "Titre du poste",
    contact_card_job_title_p: "Entrez le titre du poste",
    contact_card_company_website: "Site web de l'entreprise",
    contact_card_company_website_p: "Entrez le site web de l'entreprise",
    contact_card_bio: "Entrez la bio pour la fiche contact",
    contact_card_bio_p: "Entrez la bio pour la fiche contact",
    contact_card_home_address:
      "Entrez l'adresse du domicile pour la fiche contact",
    contact_card_home_address_p: "Adresse du domicile de la fiche contact",
    contact_card_work_address:
      "Entrez l'adresse de travail de la fiche contact",
    contact_card_work_address_p: "Adresse de travail de la fiche contact",
    contact_card_social:
      "Entrez les liens de vos réseaux sociaux sur votre profil Tapni <br>afin de les ajouter dans la fiche contact",
    contact_card_social_p:
      "Entrez les liens de vos réseaux sociaux pour la fiche contact",
    link_text_p: "Texte du lien",
    link_icon: "Définir le texte sous l'icône du lien",
    direct_pick: "Choisissez un lien direct",
    direct_p:
      "Lorsque vous tapez sur le téléphone de quelqu'un, <br> ce lien s'ouvrira au lieu de votre profil complet.",
    password_change: "Changez le mot de passe",
    password_repeat: "Répétez le mot de passe",
    password_reset_t: "Réinitialisez le mot de passe",
    password_current: "Entrez votre mot de passe actuel",
    password_current_p: "Mot de passe actuel",
    password_new: "Entrez votre nouveau mot de passe",
    password_new_p: "nouveau mot de passe",
    password_new_repeat: "Entrez à nouveau votre nouveau mot de passe",
    password_new_repeat_p: "Entrez à nouveau votre nouveau mot de passe",
    password_update: "Mise à jour du mot de passe",
    password_reset:
      "Si vous ne vous souvenez plus de votre mot de passe actuel,<br>\n                 vous pouvez le réinitialiser",
    delete_account_permanently:
      "Êtes-vous sûr(e) de vouloir supprimer définitivement votre compte Tapni ?",
    by_clicking_here: "En cliquant ici",
    password_8_chars: "Le mot de passe doit contenir au moins 8 caractères",
    password_least_one_lowercase: "Le mot de passe doit contenir au moins une lettre minuscule",
    password_least_one_uppercase: "Le mot de passe doit contenir au moins une lettre majuscule",
    password_least_one_number: "le mot de passe doit contenir au moins un chiffre",
    password_not_match: "Les mots de passe ne correspondent pas",
    password_change_success: "Mot de passe modifié avec succès",
    or: "ou",
    profile: "Profil",
    account_settings: 'Paramètres du compte',
    profile_public: "Profil public",
    profile_public_p: "Votre profil est disponible publiquement via:",
    profile_active: "Profil actif",
    profile_active_p:
      "Si vous désactivez votre profil, personne n'aura accès à votre profil et les produits tapni connectés ne fonctionneront plus",
    profile_change_password: "Changez le mot de passe",
    profile_change_username: "Changez le nom d'utilisateur",
    profile_change_language: "Changez de langue",
    profile_delete_account: "Supprimer le compte",
    profile_confirm_delete_account: "Supprimer le compte",
    profile_cancel_delete_account: "Annuler",
    profile_delete_account_success: "Le compte a été supprimé avec succès",
    profile_remove_p:
      "Si vous souhaitez supprimer votre compte,<br>\n                    écrivez-nous à",
    profile_contact_support:
      "Si vous souhaitez supprimer votre compte,<br>\n" +
      "\t\t    veuillez nous écrire à l'adresse suivante",
    profile_email_subject: "Tapni App Support",
    profile_remove_subject: "Demande de suppression de compte",
    profile_update_success: "Profil mis à jour avec succès",
    profile_complete: "Complètez votre profil",
    completed: "Complété",
    uncompleted: "Inachevé",
    contact_us: "Nous contacter",
    contact_us_verify: "afin de vérifier votre adresse e-mail.",
    verify_email: "Vérifier l'adresse e-mail",
    verify_email_subject: "Vérification de l'e-mail",
    verify_email_inbox: "Vérifiez votre boîte de réception pour",
    add_first_link: "Ajoutez votre premier lien",
    add_first_link_p:
      "Cliquez ici pour ajouter vos réseaux sociaux <br>\n                          ou coordonées sur votre profil Tapni.",
    add_picture: "Ajoutez une photo de profil",
    add_picture_p: "Cliquez ici pour télécharger votre photo de profil.",
    add_tag: "Activez votre tapni",
    add_tag_p: "Cliquez ici pour activer votre produit Tapni.",
    completing_profile:
      "Des problèmes à completer votre profil?<br>\n                    N'hésitez pas à nous écrire à",
    completing_profile_subject: "Completez votre profil",
    share: "Partagez",
    share_profile: "Partagez le profil",
    share_profile_p:
      "D'autres peuvent scaner ce code QR <br> afin de voir votre profil",
    share_profile_change_username:
      "Si vous souhaitez modifier votre lien, <br> <u>cliquez ici </u> pour changer votre nom d'utilisateur.",
    share_profile_qr: "Partager mon profil",
    share_contactcard_qr: "Fiche contact",
    scan_qr_code: "Scannez le code QR",
    enter_activation_code: "Entrez le code d'activation",
    activate_tag: "Activez votre produit",
    order_tapni:
      "Si vous n'avez pas encore commandé votre Tapni,<br>\n          vous pouvez choisir le vôtre",
    activation_success:
      "Produit connecté avec succès. <br> Vous êtes prêt ! 🥳",
    activation_success_message:
      "Produit connecté avec succès. Vous êtes prêt ! 🥳",
    activation_error: "Ceci n'est pas un tag valide. <br> Essayez à nouveau.",
    activation_error_message:
      "Ceci n'est pas un tag valide. <br> Essayez à nouveau.",
    activation_progress: "Enregistrement du produit en cours ...",
    activation_invalid_code: "Code d'activation invalide.",
    activation_qr_failed: "La numérisation du code QR a échoué",
    activation_qr_area: "Placez un code QR dans la zone de numérisation",
    activation_code: "Code d'activation",
    invalid_code: "Code invalide",
    total_taps: "Total de Tapes",
    active: "Actif",
    tag_active_p:
      "S'il est désactivé, il n'ouvrira pas votre profil <br> lorsque vous taperez sur le téléphone de quelqu'un.",
    tag_delete_confirm:
      "Enregistrez le code d'activation avant de dissocier votre produit,<br> sinon il ne pourra pas être réactivé.",
    upload_profile_picture: "Téléchargez une photo de profil",
    upload_cover_picture: "Téléchargez l'image de couverture",
    upload_link_picture: "Téléchargez l'image du lien",
    select_from_device: "Sélectionnez à partir de l'appareil",
    select_from_mobile:
      "Sélectionnez l'image à partir de votre appareil mobile",
    select_from_computer: "\nou glissez-déposez si vous utilisez un ordinateur",
    crop: "Recadrez",
    crop_upload: "Recadrez et téléchargez",
    crop_image: "Recadrage de l'image",
    drag_and_drop: "Glissez et déposez des fichiers ici",
    upload_success: "Les médias ont été téléchargés avec succès",
    browser_not_supported: "Votre navigateur ne supporte pas",
    change_username: "Changez le nom d'utilisateur",
    change_username_success: "Nom d'utilisateur modifié avec succès",
    update_username: "Mettre à jour le nom d'utilisateur",
    new_username: "Entrez votre nouveau nom d'utilisateur",
    username_is_free: "le nom d'utilisateur est libre",
    username_check: "Veuillez fournir un nom d'utilisateur approprié",
    anonymous: "Anonyme",
    connected: "Connecté",
    exchanged: "Échangé",
    accept: "J'accepte",
    view: "Voir",
    join: "Rejoindre",
    add_account: "Ajoutez un compte",
    complete_profile: "Profil completé",
    my_profile: "Mon profil",
    my_qr: "Mon QR",
    my_connections: "Mes connexions",
    contacts: "Contacts",
    my_contacts: "Mes Contacts",
    contact_description: "Votre CRM personnel pour toutes les connexions qui vous tiennent à cœur",
    my_tags: "Mes produits",
    my_tags_description: "Gérez les Cartes de Visite Intelligentes associées à votre compte",
    buy_tapni: "Achetez Tapni",
    referral_program: "Programme d'ambassadeur",
    device_compatibility: "Compatibilité des appareils",
    help: "Aide",
    settings: "Réglages",
    logout: "Se déconnecter",
    version: "Version",
    configure: "Configurez",
    unexpected_err:
      "Une erreur inattendue s'est produite, veuillez nous contacter à support@tapni.com",
    success_login: "Connecté avec succès avec un autre compte.",
    password_reset_success:
      "Le code de réinitialisation du mot de passe a été envoyé à votre email.",
    password_reseted_success:
      "Mot de passe modifié avec succès. Vous pouvez vous connecter maintenant.",
    success_remove_link: "Lien supprimé avec succès",
    success_remove_picture: "Image supprimée avec succès.",
    profile_active_message: "Votre profil est maintenant actif",
    profile_inactive_message: "Votre profil est maintenant désactivé",
    profile_public_message: "Votre profil est maintenant public",
    profile_private_message: "Votre profil est maintenant privé",
    redirect_enabled: "Lien de redirection automatique activé",
    redirect_disabled: "Lien de redirection automatique désactivé",
    tag_active: "Produit maintenant actif.",
    tag_inactive:
      "Produit maintenant désactivé. Vos visiteurs vont atterrir sur tapni.co",
    tag_removed: "Produit enlevé. Veuillez nous écrire à support@tapni.com",
    connection_accepted: "Demande de connexion acceptée.",
    connection_added: "Nouvelle connexion ajoutée avec succès",
    connection_exist: "Vous êtes déjà connecté à cet utilisateur",
    not_found: "Non trouvé",
    not_found_p: "Il semble que vous soyez perdus",
    go_back: "Retournez",
    referral_p1: "Gagnez 20% de commission<br> sur les ventes d'affiliés 💰",
    referral_p2:
      "Donnez aux autres 20% de rabais<br> grâce à votre code de réduction 🎁",
    referral_p3: "C'est gagnant-gagnant",
    referral_p4:
      "Si vous voulez changer vos références,<br>\n                                 ou si vous avez des questions, <br>\n                                 écrivez-nous sur",
    referral_p5: "Commencez à gagner de l'argent maintenant!",
    referral_p66: "Partagez votre lien d'affiliation et gagnez de l'argent!",
    referral_p6:
      "Si vous êtes blogueur, influenceur ou un passionné des réseaux sociaux, vous êtes parfaitement adapté à notre programme d'ambassadeurs.\n                                Faites la promotion de notre Marque et gagnez une commission de 20% sur les achats effectués avec votre code de réduction ou votre lien de parrainage.",
    referral_p7:
      "Après vous être inscrit au programme d'ambassadeurs Tapni, vous recevrez un lien de parrainage personnel et un code de réduction.\n                                Partagez-les sur votre site web et vos réseaux sociaux, et gagnez de l'argent pendant que nous vous offrons une expérience de connexion exceptionnelle.",
    referral_p8: "Note de paiement",
    referral_p9:
      "PayPal (Dans le monde entier) et Transferts bancaires (UE) <br> Le montant minimum de paiement est de 25 €.",
    referral_p10: "Demande de paiement",
    referral_p11: "Paiement demandé",
    your_connections: "Vos connexions",
    connections_p1:
      "Entrez en contact avec les personnes que vous avez rencontrées",
    connections_p2:
      "<br><br>Pas de connexions jusqu'à présent. <br> Commencez à taper💥",
    connections_p3: "Aucune connexion trouvée.",
    connection_success: "Demande de connexion envoyée avec succès",
    exchange_success: "Les contacts ont été échangés avec succès.",
    create_free_account:
      "Créez un compte Tapni gratuit et tenez vos contacts à jour!",
    connection_edit: "Modifier la connexion",
    connection_edit_success: "Mise à jour réussie de la connexion",
    connection_delete_success: "Succès de la suppression de la connexion",
    search: "Rechercher",
    devices_p1: "Liste des appareils compatibles",
    devices_p2:
      "Tapni utilise une technologie qui est compatible avec la plupart des nouveaux appareils iPhone et Android.",
    devices_p3:
      "Sur certains téléphones Android, la fonction NFC peut être désactivée. Si votre Tapni ne fonctionne pas sur un appareil Android, recherchez NFC dans les paramètres et assurez-vous qu'il est activé.",
    devices_p4: "avec le widget NFC d'iOS 14 dans le centre de contrôle",
    download_p1: "Téléchargez l'application Tapni",
    download_p2: "Disponible pour les appareils Apple et Android",
    user_not_found: "Utilisateur non trouvé",
    user_not_found_p:
      "Nous n'avons pas pu trouver l'utilisateur dans notre base de données🤷",
    taps: "Tapes",
    private_profile: "Profil privé",
    private_profile_p:
      "est un profil privé. <br> Le profil est visible uniquement en utilisant les produits Tapni",
    profile_bio_p: "Écrivez quelque chose à propos de vous ou de votre marque",
    add_to_contacts: "Ajoutez aux contacts",
    drag_and_drop_links: "Glissez-déposez les liens dans l'ordre souhaité",
    no_links: "\nIl n'y a aucun lien dans votre profil.",
    add_link: "Ajoutez un lien",
    direct_link_p1: "Les visiteurs seront automatiquement redirigés vers:",
    profile_not_active:
      "Votre profil n'est actuellement pas actif <br> et vous seul pouvez le voir lorsque vous êtes connecté.",
    profile_not_public:
      "Votre profil n'est actuellement pas public <br> et seuls ceux qui tape avec vous peuvent le voir.",
    activate_profile_now: "Activez votre profil maintenant",
    publish_profile_now: "Rendez votre profil public maintenant",
    link_add_p1: "Choisissez un type de lien",
    link_add_not_found: "Aucun lien trouvé 🤷",
    link_add_not_found_p2:
      "Si nous avons manqué votre plate-forme préférée, veuillez nous envoyer un courriel (<b> support@tapni.com </b>) ou un MP sur les réseaux sociaux (<b> @tapni </b>)",
    link_add_not_found_p3:
      "Nous ferons de notre mieux pour prendre en charge toutes les plateformes  😉",
    featured: "Les vedettes",
    social_media: "Réseaux sociaux",
    contact: "Contact",
    finance: "Finance",
    portfolio: "Portefeuille",
    music: "Musique",
    business: "Entreprise",
    personal: "Personnel",
    fun: "Amusement",
    ecommerce: "Ecommerce",
    more: "Autre",
    email_username: "Email / Nom d'utilisateur",
    username: "Nom d'utilisateur",
    password: "Mot de passe",
    sign_in: "Se connecter",
    sign_in_with: "Se connecter avec",
    sign_in_with_new: "Connectez-vous avec un nouveau compte",
    sign_up_with: "Se connecter avec",
    select_sign_method: "Sélectionnez une méthode de connexion",
    verify_account: "Vérifier le compte",
    terms_by_signing_in: "En vous connectant, vous acceptez notre",
    terms_by_registering: "En vous inscrivant, vous acceptez notre",
    terms_by_submitting:
      "En soumettant ce formulaire, vous acceptez nos conditions d'utilisation.",
    terms_of_service: "Conditions d'utilisation",
    and: "et",
    refresh: "Rafraîchir",
    privacy_policy: "Politique de confidentialité",
    create_account: "Créer un compte",
    reset_password: "Réinitialiser le mot de passe",
    reset_password_p1:
      "Si vous ne pouvez pas réinitialiser votre mot de passe,<br> n'hésitez pas à nous contacter à",
    reset_password_p2:
      "Si vous ne pouvez pas réinitialiser votre mot de passe,<br> n'hésitez pas à nous contacter à",
    create_new_account: "Créer un nouveau compte",
    maintenance_p1: "Nous serons bientôt de retour ",
    maintenance_p2: "En attendant, explorez les collections de tapni",
    maintenance_p3:
      "Tapni est en panne pour un entretien programmé <br> et devrait être de retour en ligne dans quelques minutes.",
    offline_p1: "Pas de connexion Internet",
    offline_p2: "Vérifiez votre connexion, puis rafraîchissez la page.",
    offline_p3_1: "Lorsque vous êtes hors ligne,",
    offline_p3_2:
      "vous pouvez partager votre carte de contact en utilisant ce code QR.",
    products_p1:
      "Impressionnez tout le monde que vous rencontrez 😎 <br> Obtenez le vôtre maintenant 👇",
    cards: "Cartes",
    stickers: "Stickers",
    keychains: "Porte-clés",
    explore_collection: "Explorez la collection",
    register: "S'inscrire",
    register_success:
      "Vous avez presque terminé. Veuillez confirmer votre courriel.",
    register_success_modal: "Connecté avec succès avec un autre compte.",
    check_your_inbox: "Vérifiez votre boîte de réception",
    tapni_tags: "Tags Tapni",
    tapni_tags_p1: "Configurez les Tags connectées à votre profil",
    tapni_tags_p2:
      "Il n'y a aucun tag connecté à votre profil.  <br> Connectez-en un maintenant !",
    verify_account_p1:
      "Si vous n'avez pas reçu de code, vérifiez votre <br> dossier Spam ou contactez-nous à",
    verify_account_success:
      "Compte vérifié avec succès. Veuillez changer votre mot de passe maintenant.",
    verify_account_success2: "Compte vérifié avec succès. Vous êtes prêt! 🥳",
    welcome: "Bienvenue",
    welcome_p1: "à l'avenir des cartes de visite",
    how_it_works: "Comment ça fonctionne",
    already_registered: "Déjà enregistré",
    error: "Erreur",
    success: "Succès",
    copied: "Copié dans le presse-papier",
    connect_validate: "L'un des deux champs de contact doit être fourni",
    connect_validate_name: "Veuillez entrez votre nom",
    connect_validate_email: "Veuillez entrer une adresse électronique valide",
    link_validate: "Données de liaison non valides.",
    link_text_contact_card: "Fiche contact",
    link_text_custom_link: "Lien personnalisé",
    link_text_menu: "Menu",
    link_text_phone: "Téléphone",
    save_to_apple_wallet: "Ajouter à l'app Cartes Apple",
    save_to_google_wallet: "Ajouter a Google Wallet",
    card: "Carte",
    sticker: "Sticker",
    keychain: "Trousseau",
    band: "Band",
    case: "Case",
    standee: "Standee",
    decoded: "Decoded",
    wristbanditz: "Wristbanditz",
    update_p1: "Version d'application obsolète détectée",
    update_p2: "Update",
    update_p3:
      "Veuillez mettre à jour l'application avec la version la plus récente",
    company_domain: "Le domaine de votre entreprise",
    search_company_domain: "Recherchez le domaine de votre entreprise",
    enter_company_email: "Entrez l'adresse e-mail de l'entreprise",
    company_not_found: "Entreprise non trouvée",
    ik_company_domain: "Je connais le domaine de l'entreprise",
    idk_company_domain: "Je ne connais pas le domaine de l'entreprise",
    continue: "Continuer",
    forbidden_rule:
      "Cette option est désactivée, contactez l'administrateur de votre entreprise.",
    companyName: "Nom de la société",
    createMarkerPlaceholder: "Créer ou sélectionner un marqueur",
    clickToCreateMarker: "Cliquez ici pour créer un marqueur",
    pleaseInviteUser:
      "Invite al usuario a unirse a Tapni para tener los datos sincronizados con usted.",
    connectionSynchronized:
      "Les données de connexion sont automatiquement synchronisées.",
    viewProfile: "Voir le profil",
    createConnectionHeader: "Ajouter une nouvelle connexion",
    createConnectionButton: "Ajouter",
    createConnectionSuccess: "La connexion a été créée.",
    allow_company_management: "Permettre la gestion de l'entreprise",
    company_join_request:
      "demande à gérer votre profil et à l'associer à son compte Entreprise.",
    account_ownership_transfer:
      "La propriété de votre compte sera transférée à l'entreprise si vous acceptez la demande. L'entreprise pourra modifier votre profil, éditer et ajouter des liens, voir vos connexions, modifier votre mot de passe, supprimer votre compte, etc. Vérifiez auprès de votre entreprise avant d'accepter la demande.",
    request_expired:
      "La demande a expiré, demandez à votre entreprise d'envoyer une nouvelle demande.",
    request_expiration_desc:
      "Pour plus de sécurité, la demande ne fonctionnera que pendant 14 jours après son envoi.",
    invitation_link_not_valid: "Le lien d'invitation n'est pas valide !",
    invitation_link_not_valid2:
      "Cliquez à nouveau sur le bouton de l'e-mail d'invitation ou copiez le lien d'invitation.",
    accept_request: "Accepter la demande",
    close: "Fermer",
    request_accepted: "La demande a été acceptée avec succès",
    companyEmail: "Courriel de l'entreprise",
    companyPhone: "Téléphone de l'entreprise",
    addBusinessInformation: "Ajouter des informations sur les busines",
    resetFilter: "Réinitialiser",
    selectMarkers: "Sélectionner les marqueurs",
    filterConnectionsHeader: "Filtrer mes connexions",
    selectConnectionType: "Sélectionnez le type de connexion",
    all: "Tous",
    direct: "Direct",
    form: "Formulaire",
    manually: "Manuellement",
    scan: "Scanner",
    sortOptions: "Options de tri",
    date: "Date de création",
    fullName: "Nom complet",
    descending: "Descendant",
    ascending: "Ascendant",
    selectDateRange: "Sélectionnez la plage de dates",
    follow_up_reminder: "Rappel de suivi",
    now: "Maintenant",
    one_hour: "1 heure",
    one_day: "1 Jour",
    three_days: "3 Jours",
    one_week: "1 Semaine",
    two_weeks: "2 Semaines",
    one_month: "1 Mois",
    custom: "Choisir l'heure",
    set_reminder: "Fixer un rappel",
    select_date_time: "Sélectionnez la date et l'heure",
    enter_reminder_text: "Saisir le texte du rappel",
    reminder_created: "Rappel créé avec succès",
    connectionNameLabel: "Entrez le nom de la connexion",
    connectionEmailLabel: "Entrez l'adresse e-mail privée",
    privatePhoneLabel: "Entrez le numéro de téléphone privé",
    companyNameLabel: "Entrez le nom de l'entreprise",
    businessEmail: "Adresse électronique professionnelle",
    businessEmailLabel: "Entrez l'adresse électronique professionnelle",
    businessPhone: "Numéro de téléphone professionnel",
    businessPhoneLabel: "Entrez le numéro de téléphone de l'entreprise",
    businessFax: "Fax professionnel",
    businessFaxLabel: "Entrez le numéro de fax de l'entreprise",
    noteLabel: "Entrez la note",
    markersLabel: "Ajouter des marqueurs de connexion",
    showScan: "Afficher le scan",
    hideScan: "Masquer le scan",
    create_connection: "Ajouter des connexions",
    create_manually: "Ajouter manuellement",
    create_using_scanner: "Ajouter en utilisant le scanner de cartes",
    companyTitle: "Título de la empresa",
    companyTitleLabel: "Introduzca el título de la empresa",
    allConnectionsLabel: "Tous les types de connexion",
    directConnectionsLabel:
      "Connexion directe in-app avec les utilisateurs de Tapni",
    formConnectionsLabel: "Connexions remplies sur le formulaire de connexion",
    manualConnectionsLabel: "Connexions créées manuellement",
    scanConnectionsLabel: "Le scanner de cartes a créé des connexions",
    analyticsHeader: "Analyse",
    analyticsProfileViews: "Vues du profil",
    analyticsLinkClicks: "Clics sur les liens",
    analyticsClickTroughRatio: "Rapport entre le clic et le creux",
    analyticsTotalConnections: "Total des connexions",
    analyticsTopLinks: "Liens les plus populaires",
    analyticsProfileVisits: "Visites de profil",
    analyticsLinkVisits: "Visites de liens",
    analyticsNewConnections: "Nouvelles connexions",
    analyticsTopCountries: "Principaux pays",
    analyticsTopReferers: "Principaux référents",

    tapni_for_business: "Tapni pour les Affaires",
    supercharge_your_business: "Dynamisez votre entreprise avec la plateforme de carte de visite numérique n°1 conçue pour les entreprises et les équipes.",

    for_teams_and_businesses: "Pour les Équipes & les Entreprises",
    number_one_digital_business_card_platform: "Plateforme de Carte de Visite Numérique N°1",
    manage_teams_collect_leads_automate_workflows: "Gérer les Équipes, Collecter des Leads & Automatiser les Flux de Travail",
    read_more: "En savoir plus",
    navigate_to_business_tapni_com: "1) Naviguer vers business.tapni.com",
    start_qr_scanner_via_button_below: "2) Démarrer le Scanner QR via le bouton ci-dessous",
    scan_the_qr_code_from_the_screen: "3) Scanner le code QR depuis l'écran",
    enjoy_using_our_platform: "4) Profitez de l'utilisation de notre plateforme",
    start_qr_scanner: "Démarrer le Scanner QR",

    change_language: "Changer de Langue",
    terms_and_privacy: "Conditions & Confidentialité",

    lead_capture_mode: "Formulaire de capture de leads",
    lead_capture_mode_description: "Le formulaire de capture de leads sera affiché sur votre profil",
    public_profile_mode: "Mode Profil Public",
    public_profile_mode_description: "Rendez votre profil",
    public_profile_mode_available: "publiquement disponible",

    link_a_device: "Lier un appareil",

    streetName: "Nom de la rue",
    streetNr: "Numéro",
    plz: "Code postal",
    city: "Ville",
    country: "Pays",

    choose_template: "Choisir un modèle",
    contactcard: "Carte de contact",
    customlink: "Lien personnalisé",
    onboarding: "Intégration",
    tag_text_p: "Nom du tag",
    tag_text_p2: "Définir un nom personnalisé pour le produit",
    connect_validate_consent: "Veuillez accepter le consentement à la vie privée",
    links: "Liens",
    manage: "Gérer",
    buy_domain: "Acheter un Domaine",
    buy_domain_p: "Personnalisez votre Identité en Ligne avec un Domaine sur Mesure.",

    enable_lead_button: "Activer le bouton 'Échanger Contact'",
    enable_lead_button_p: "Le bouton d'échange de contact sera affiché sur votre profil",
    form_auto: 'Ouvrir le formulaire lors de la visite du profil',
    form_auto_p: "Le formulaire d'échange de contact s'ouvrira automatiquement lorsque quelqu'un ouvrira votre profil",
    form_required: 'Remplissage du formulaire requis',
    form_required_p: "Rendre le formulaire obligatoire à remplir avant qu'il puisse être fermé.",
    enter_code_manually: 'Entrer le code manuellement',
    activate_via_nfc: 'Activer via NFC',
    qr_code: 'Code QR',
    nfc: 'NFC',
    nfc_not_available: 'Le NFC n’est pas disponible sur cet appareil',
    nfc_not_enabled: 'Le NFC n’est pas activé sur cet appareil. Veuillez activer le NFC dans les paramètres de votre appareil.',
    nfc_no_link_found: 'Aucun lien NFC trouvé. Veuillez contacter le support.',
    activation_second_step_needed: 'Une étape supplémentaire nécessaire. <br> Scannez le produit maintenant via',

    scanner_card_p: 'Pointez la caméra vers la carte <br> et appuyez sur le bouton.',
    scanner_qr_p: 'Pointez la caméra sur le QR <br> pour détection auto.',
    scanner_badge_p: 'Pointez la caméra vers le badge <br> et appuyez sur le bouton.',
    paper_card: 'Carte en papier',
    event_badge: 'Badge d\'événement'
  },
};
