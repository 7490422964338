import { Nfc, NfcUtils, NfcTagTechType, PollingOption } from '@tapni/capacitor-nfc';

export default {
    data() {
        return {
            nfcActivationReady: true,
            serial: '',
            readingNFC: false,
            nfcUtils: null,
        }
    },
    methods: {
        convertBytesToString (bytes) {
            this.nfcUtils = new NfcUtils();
            return this.nfcUtils.convertBytesToString({bytes}).text;
        },
        async readNFC ()  {
            return new Promise(async (resolve, reject) => {
                if (this.readingNFC) return resolve(false);
                this.readingNFC = true;
                Nfc.addListener('nfcTagScanned', async (event) => {
                    console.log(JSON.stringify(event.nfcTag));
                    await Nfc.stopScanSession();
                    this.readingNFC = false;
                    resolve(event.nfcTag);
                });

                try {
                    await Nfc.startScanSession({
                        pollingOptions: [PollingOption.iso14443]
                    });
                } catch (err) {
                    this.readingNFC = false;
                    reject(err);
                }
            });
        },
        createNdefTextRecord(text) {
            const utils = new NfcUtils();
            const { record } = utils.createNdefTextRecord({ text });
            return record;
        },

        convertDecimalArrayToSerial(decimalArray) {
            // Convert each decimal number to a two-digit hexadecimal string
            let hexArray = decimalArray.map(num => {
                // Convert to hexadecimal and pad with a leading zero if necessary
                let hex = num.toString(16).toLowerCase();
                return hex.length === 1 ? '0' + hex : hex;
            });

            // Join the hexadecimal strings
            return hexArray.join('');
        },

        async writeNFC () {
            return new Promise(async (resolve, reject) => {
                if (this.readingNFC) return resolve(false);
                this.readingNFC = true;
                Nfc.addListener('nfcTagScanned', async (event) => {
                    console.log(JSON.stringify(event.nfcTag))
                    const text = 'https://t.link/t/' + this.convertDecimalArrayToSerial(event.nfcTag.id);
                    const record = this.createNdefTextRecord(text);

                    await Nfc.write({ message: { records: [record] } });
                    await Nfc.stopScanSession();
                    resolve();
                });

                try {
                    await Nfc.startScanSession();
                } catch (err) {
                    this.readingNFC = false;
                    reject(err);
                }
            });
        },

        async makeReadOnly() {
            return new Promise((resolve) => {
                Nfc.addListener('nfcTagScanned', async (event) => {
                    await Nfc.makeReadOnly();
                    await Nfc.stopScanSession();
                    resolve();
                });

                Nfc.startScanSession();
            });
        },

        async readSignature() {
            return new Promise((resolve) => {
                Nfc.addListener('nfcTagScanned', async (event) => {
                    const { response } = await Nfc.transceive({ techType: NfcTagTechType.NfcA, data: [60, 0] });
                    await Nfc.stopScanSession();
                    resolve(response);
                });

                Nfc.startScanSession();
            });
        },

        async erase() {
            return new Promise((resolve) => {
                Nfc.addListener('nfcTagScanned', async (event) => {
                    await Nfc.erase();
                    await Nfc.stopScanSession();
                    resolve();
                });

                Nfc.startScanSession();
            });
        },

        async format() {
            return new Promise((resolve) => {
                Nfc.addListener('nfcTagScanned', async (event) => {
                    await Nfc.format();
                    await Nfc.stopScanSession();
                    resolve();
                });

                Nfc.startScanSession();
            });
        },

        async isSupported() {
            const { isSupported } = await Nfc.isSupported();
            return isSupported;
        },

        async isEnabled() {
            const { isEnabled } = await Nfc.isEnabled();
            return isEnabled;
        },

        async openSettings() {
            await Nfc.openSettings();
        },

        async checkPermissions() {
            const { nfc } = await Nfc.checkPermissions();
            return nfc;
        },

        async requestPermissions() {
            const { nfc } = await Nfc.requestPermissions();
            return nfc;
        },

        async removeAllNFCListeners() {
            this.readingNFC = false;
            await Nfc.removeAllListeners();
        }
    },
    beforeDestroy() {
        Nfc.removeAllListeners();
    }
}
