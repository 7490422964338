const TextareaAutogrowDirective = {
    name: 'autogrow',
    mounted(el) {
        let observe, minHeight;

        // Adjust for potential component wrappers around the textarea
        el = el.tagName.toLowerCase() === 'textarea' ? el : el.querySelector('textarea')

        minHeight = parseFloat(getComputedStyle(el).getPropertyValue("min-height"));

        // Use modern event listener syntax as attachEvent is deprecated
        observe = function (element, event, handler) {
            element.addEventListener(event, handler, false);
        };

        const resize = () => {
            el.style.height = 'auto';
            let border = parseFloat(getComputedStyle(el).borderTopWidth) * 2;
            el.style.height = `${Math.max(el.scrollHeight + border, minHeight)}px`;
        };

        const delayedResize = () => {
            window.setTimeout(resize, 0);
        };

        const respondToVisibility = (element, callback) => {
            const intersectionObserver = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.intersectionRatio > 0) callback();
                });
            }, {
                root: document.documentElement
            });

            intersectionObserver.observe(element);
        };

        respondToVisibility(el, resize);
        observe(el, 'change', resize);
        observe(el, 'cut', delayedResize);
        observe(el, 'paste', delayedResize);
        observe(el, 'drop', delayedResize);
        observe(el, 'input', resize);

        resize(); // Initialize size
    }
};

const VueTextareaAutogrowPlugin = {
    install(app) {
        app.directive(TextareaAutogrowDirective.name, TextareaAutogrowDirective);
    }
};

// Automatic installation if Vue is available globally
if (typeof window !== 'undefined' && window.Vue) {
    window.Vue.use(VueTextareaAutogrowPlugin);
}

export default VueTextareaAutogrowPlugin;
export { TextareaAutogrowDirective };
