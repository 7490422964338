<script setup>
import { RouterLink, RouterView } from 'vue-router'
</script>

<template>
  <div>
    <div id="rootAppId" style="position: relative">
      <!-- Loader with spinner -->
      <Loader :loading="loading" />

      <ModalOverlay />
      <Direct v-if="isLoggedIn" />
      <ProfileSetup v-if="isLoggedIn" />
      <LeadCaptureSetup
          v-if="isLoggedIn && route.name && (route.name.includes('More') || route.name.includes('Home'))"
      />
      <AccountSettings
          v-if="isLoggedIn && route.name && route.name.includes('More')"
      />
      <Password
          v-if="isLoggedIn && route.name && route.name.includes('More')"
      />
      <Ambassador
          v-if="isLoggedIn && route.name && route.name.includes('Referral')"
      />
      <Username
          v-if="
          isLoggedIn &&
          route.name &&
          (route.name.includes('Home') || route.name.includes('More'))
        "
      />
      <Language />
      <DeleteAccount
          v-if="isLoggedIn && route.name && route.name.includes('More')"
      />
      <ContactCard
          v-if="
          isLoggedIn &&
          route.name &&
          (route.name.includes('Home') || route.name.includes('Link'))
        "
      />
      <Link
          v-if="
          isLoggedIn &&
          route.name &&
          (route.name.includes('Home') || route.name.includes('Link'))
        "
      />
      <Upload v-if="isLoggedIn" />
      <Share v-if="isLoggedIn" />
      <ProductActivation v-if="isLoggedIn && route.name && route.name.includes('Tags')" @nfcActivationInProgress="(b) => nfcActivationInProgress = b" />
      <GoogleReview
          v-if="
          isLoggedIn &&
          route.name &&
          (route.name.includes('Home') || route.name.includes('Link'))
        "
      />
      <Tag v-if="isLoggedIn && route.name && route.name.includes('Tags')" />
      <Connect
          v-if="route.name && route.name.includes('Home')"
      />
      <AfterConnect
          v-if="!isLoggedIn && route.name && route.name.includes('Home')"
      />
      <Connection
          v-if="
          isLoggedIn &&
          route.name &&
          (route.name.includes('Home') || route.name.includes('Connections'))
        "
      />
      <AddAccount v-if="isLoggedIn && route.name && route.name.includes('More') && false" />
      <ForbiddenRule v-if="isLoggedIn" />
      <Header />
      <Sidebar v-if="isLoggedIn && false" />
      <ScanCropper
          v-if="isLoggedIn && route.name && route.name.includes('Connections')"
      />
      <ScanLoading
          v-if="isLoggedIn && route.name && route.name.includes('Connections')"
      />
      <ConnectionsFilter
          v-if="isLoggedIn && route.name && route.name.includes('Connections')"
      />
      <Upgrade
          v-if="isLoggedIn"
      />
      <FollowUpSchedule
          v-if="isLoggedIn && route.name && route.name.includes('Connections')"
      />
      <Templates v-if="isLoggedIn" />
      <CookiePolicy
          v-if="!isLoggedIn && route.name && route.name.includes('Home')"
      />

      <v-offline
          online-class="online"
          offline-class="offline"
          @detected-condition="onNetworkChange"
      >
        <RouterView
            v-if="!isMaintenance && isOnline && !isAuthPage && initialized"
            id="page-content"
            class="page-content page-content-scroll content-padding page-content-light header-clear-larger no-top"
        />
        <ErrorMaintenance
            v-else-if="isOnline && isMaintenance && !isAuthPage"
            id="page-content"
            class="page-content page-content-scroll content-padding page-content-light header-clear-larger no-top"
        />
        <ErrorOffline
            v-else-if="initialized && !isOnline && !isAuthPage"
            id="page-content"
            class="page-content page-content-scroll content-padding page-content-light header-clear-larger no-top"
        />

        <AuthView
            v-show="isAuthPage || toggleAuth"
            id="page-content"
            :toggle="toggleAuth"
            :view="authView"
            :isModal="toggleAuth"
            @ssoLibraryReady="init"
        />
      </v-offline>


      <FooterNav v-if="isLoggedIn" />
      <!-- Snackbars Success / Error-->
      <div class="snackbar snackbar-boxed" id="snackbarApp">
        <p class="snack-body" id="errorSnackApp">
          <span id="errorMessageApp"> {{lang[appLang].error }} </span>
          <a
              @click="closeSnacks"
              style="
              position: absolute;
              right: 10px;
              top: 10px;
              width: 50px;
              height: 50px;
            "
          >
            <img
                src="https://cdn.tapni.co/icons/notification-close.png"
                style="width: 100%;:"
            />
          </a>
          <!--<a @click="closeSnacks" class="pointer"><i class="fa fa-close font-15"></i></a>-->
        </p>
        <p class="snack-body" id="successSnackApp">
          <span id="successMessageApp"> {{lang[appLang].success }} </span>
          <a
              @click="closeSnacks"
              style="
              position: absolute;
              right: 10px;
              top: 10px;
              width: 50px;
              height: 50px;
            "
          >
            <img
                :src="getIcon('notification-close.png')"
                style="width: 100%"
            />
          </a>
          <!--<a @click="closeSnacks" class="pointer"><i class="fa fa-close font-15"></i></a>-->
        </p>
      </div>
    </div>
    <SmartScannerOverlay
        v-if="
        isLoggedIn &&
        route.name &&
        (route.name.includes('Tags') ||
          route.name.includes('Connections') ||
          route.name.includes('More') ||
          route.name.includes('ForBusiness'))
      "
    />
  </div>
</template>

<script>
import '@tapni/styles/dist/index.css';
import Loader from "@/components/Loader";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import { Capacitor } from "@capacitor/core";
import Password from "@/components/modals/Password";
import ProductActivation from "@/components/modals/ProductActivation.vue";
import Tag from "@/components/modals/Tag";
import Link from "@/components/modals/Link";
import Upload from "@/components/modals/Upload";
import Header from "@/components/Header";
import ModalOverlay from "@/components/modals/ModalOverlay";
import Share from "@/components/modals/Share.vue";
import Profile from "@/components/modals/Profile";
import LeadCaptureSetup from "@/components/modals/LeadCaptureSetup";
import AccountSettings from "@/components/modals/AccountSettings";
import ProfileSetup from "@/components/modals/ProfileSetup";
import Sidebar from "@/components/Sidebar";
import Connect from "@/components/modals/Connect";
import AfterConnect from "@/components/modals/AfterConnect";
import Connection from "@/components/modals/Connection";
import ErrorMaintenance from "@/views/Maintenance";
import ErrorOffline from "@/views/Offline";
import Username from "@/components/modals/Username";
import Ambassador from "@/components/modals/Referral";
import { StatusBar, Style } from "@capacitor/status-bar";
import FooterNav from "@/components/FooterNav";
import Direct from "@/components/modals/Direct";
import AddAccount from "@/components/modals/AddAccount";
import { EventBus } from "./store/event-bus";
import ContactCard from "@/components/modals/ContactCard";
import Language from "@/components/modals/Language";
import DeleteAccount from "@/components/modals/DeleteAccount";
import GoogleReview from "@/components/modals/GoogleReview";
import UtilService from "./services/UtilService";
import DeviceService from "./services/DeviceService";
import ForbiddenRule from "@/components/modals/ForbiddenRule";
import ScanCropper from "@/components/modals/ScanCropper";
import ScanLoading from "@/components/modals/ScanLoading";
import ConnectionsFilter from "@/components/modals/ConnectionsFilter";
import Upgrade from "@/components/modals/Upgrade";
import FollowUpSchedule from "@/components/modals/FollowUpSchedule";
import Templates from "@/components/modals/Templates.vue";
import CookiePolicy from "@/components/modals/CookiePolicy";
import SmartScannerOverlay from "@/components/SmartScannerOverlay";
import to from 'await-to-js'

import { LocalNotifications } from "@capacitor/local-notifications";
import { Browser } from "@capacitor/browser";
import { VOffline } from 'v-offline';

import { App } from "@capacitor/app";
import { CapacitorUpdater } from '@capgo/capacitor-updater'
import { Purchases, LOG_LEVEL } from "@revenuecat/purchases-capacitor";

import CONSTANTS from "@/constants";
import AuthView from "@/views/AuthView.vue";
window.events = EventBus["_events"];
export default {
  name: "App",
  components: {
    AuthView,
    VOffline,
    GoogleReview,
    Language,
    ContactCard,
    AddAccount,
    Direct,
    FooterNav,
    Ambassador,
    Username,
    ErrorOffline,
    ErrorMaintenance,
    Connect,
    AfterConnect,
    Connection,
    Sidebar,
    Profile,
    LeadCaptureSetup,
    AccountSettings,
    ProfileSetup,
    Password,
    DeleteAccount,
    Tag,
    Link,
    ProductActivation,
    Upload,
    Header,
    Loader,
    ModalOverlay,
    Share,
    ForbiddenRule,
    ScanCropper,
    ScanLoading,
    ConnectionsFilter,
    Upgrade,
    FollowUpSchedule,
    Templates,
    CookiePolicy,
    SmartScannerOverlay,
  },
  data () {
    return {
      isOnline: true,
      nfcActivationInProgress: false,
      toggleAuth: false,
      authView: null,
      version: null,
    }
  },
  computed: {
    ...mapState([
      "loading",
      "initialized",
      "isMaintenance",
      "loggedInAccounts",
    ]),
    ...mapGetters(["isLoggedIn"]),
    isAuthPage() {
      return this.route.name && this.route.name.includes('Auth');
    },
  },
  methods: {
    ...mapActions([
      "getUser",
      "getLinkTypes",
      "maintenance",
      "refreshTokenAction",
      "getCompanyBySubdomain",
    ]),
    ...mapMutations(["setToken", "setRefreshToken", "setInitialize"]),
    toggleAuthModal (data) {
      this.toggleAuth = !this.toggleAuth;
      if (data.view) this.authView = data.view;
    },
    onNetworkChange (status) {
      this.isOnline = status;
    },
    async init(storage) {
      this.$store.commit('setStorage', JSON.parse(JSON.stringify(storage)));

      // Cookies config
      this.$cookies.config(60 * 60 * 24 * 365); // 1 year

      // Fetch User
      if (this.storage && this.storage.username) {
        this.$store.commit("setLoading", true);
        if (this.isOnline) {
          await this.getUser({ username: this.storage.username });
          this.setWidgetUserData({ key: "username", value: this.storage.username });
        } else {
          // this.$store.commit("setUserOffline");
        }
      }

      // NFC event when the app is turned on
      document.addEventListener("onNewIntentNFC", (data) => {
        // TODO: Prevent if NFC Activation in progress
        if (data.url && !this.nfcActivationInProgress) {
          /*
          const slug = data.url.split("tapni.co")[1];
          if (slug) {
            this.$router.push(slug + "#refresh");
          }
           */
          let url = new URL(data.url);
          if (url.pathname) {
            this.$router.push(url.pathname + "#refresh");
          }
        }
      });

      if (this.isNative) {
        const statusInfo = await StatusBar.getInfo();
        if (statusInfo.color === "#000000") {
          await StatusBar.setStyle({ style: Style.Dark });
        } else {
          await StatusBar.setStyle({ style: Style.Light });
        }
      }

      if (import.meta.env.VITE_APP_COMPARE_LANGUAGE_KEYS === "true") {
        UtilService.compareLangKeys();
      }

      this.$nextTick(() => {
        this.$store.commit('setInitialize', true)
        this.$forceUpdate();
      })
    },
  },
  created() {
    if (!this.linkTypes.length) {
      this.getLinkTypes();
    }

    LocalNotifications.addListener(
        "localNotificationActionPerformed",
        (payload) => {
          let data = null;
          let path;
          if (payload.notification.extra) data = payload.notification.extra;
          if (payload.notification.data) data = payload.notification.data;
          if (data.url) return Browser.open({ url: data.url });
          if (this.isiOS) path = "/";
          else path = "https://t.link/";
          if (data.username) return (window.location.href = path + data.username);
          if (data.email)
            return (window.location.href =
                path + "connections?search=" + data.email);
          else return (window.location.href = path + "connections");
        }
    );

    var self = this;
    window.addEventListener("touchstart", (event) => {
      const nodeName = event.target;
      const parentNodeName = event.target.parentNode;
      const parentParentNodeName = event.target.parentNode.parentNode;
      // vibrate
      if (
          (nodeName && nodeName.classList && nodeName.classList.contains("vibrate")) ||
          (parentNodeName && parentNodeName.classList && parentNodeName.classList.contains("vibrate")) ||
          (parentParentNodeName && parentParentNodeName.classList && parentParentNodeName.classList.contains("vibrate"))
      ) {
        self.vibrate("light");
      }
    });

    this.maintenance();
  },
  async mounted() {
    if (this.isNative) {
      await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG });
      if (Capacitor.getPlatform() === 'ios') {
        await Purchases.configure({ apiKey: "appl_ObbjgiphOwZEwRMONoGVwSVxqQy" });
      } else if (Capacitor.getPlatform() === 'android') {
        await Purchases.configure({ apiKey: "goog_oDekqKsvACWYmeXBkGffQErjZVb" });
      }

      let offerings = await Purchases.getOfferings();
      console.log('RevenueCat offerings');
      console.log(offerings);
    }
    EventBus.$on('toggleAuthModal', this.toggleAuthModal)
    EventBus.$on("errorHandler", this.errorHandler);
    EventBus.$on("errorSnack", this.errorSnack);
    EventBus.$on("successSnack", this.successSnack);
  }
};


CapacitorUpdater.notifyAppReady();
let newAppVersion = null;
App.addListener("appStateChange", async (state) => {
  if (!Capacitor.isNativePlatform()) return;

  if (!import.meta.env.PROD) return console.log("App update skipped.");
  else console.log("App update started with, state: " + state.isActive);

  if (state.isActive) {
    // Fetch latest version from API
    const [err, response] = await to(DeviceService.latestAppVersion())
    if (err) return console.error(err);

    if (response.data && response.data.success) {
      // Do the download during user active app time to prevent failed download
      newAppVersion = await CapacitorUpdater.download({
        url: import.meta.env.VITE_APP_API_ROOT + "/v1/updater/app?version=" + (CONSTANTS.WEB_VERSION || "0.0.0"),
        version: response.data.version
      });
    }

  }

  if (!state.isActive && newAppVersion !== null) {
    // Activate the update when the application is sent to background
    try {
      await CapacitorUpdater.set(newAppVersion);
      // At this point, the new version should be active, and will need to hide the splash screen
    } catch (err) {
      console.log(err);
    }
  }
});

</script>

<style>
.pac-container {
  z-index: 100000;
  top: 65px!important;
  left:0px!important;
  border-radius: 5px;
}
.bottom-sheet__main {
  padding: 0 20px;
}
.modal-open {
  overflow: hidden!important;
  height: 100%!important;
}
</style>
