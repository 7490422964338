import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
    components: {VueGoogleAutocomplete},
    methods: {
        movePacContainer (e, ref='googleAddressContactCard') {
            let pacContainersInput = document.querySelectorAll('.pac-target-input');
            let pacContainers = document.querySelectorAll('.pac-container');

            Array.from(pacContainersInput).forEach((element, index) => {
                element.dataset.addressIndex = 'address-' + index;
            });

            Array.from(pacContainers).forEach((element, index) => {
                let containerClass = element.getAttribute('class');
                if (!containerClass.includes('address') && containerClass.includes('pac-container')) {
                    element.classList.add('address-'+index);
                }
            });

            let searchBox = this.$refs[ref];
            let searchBoxId = searchBox.$el?.dataset?.addressIndex;
            let pacContainer = document.querySelector('.'+ searchBoxId);

            let searchBoxElement = searchBox.$el;
            let parentElement = searchBoxElement?.parentNode;
            let nextSibling = searchBoxElement?.nextSibling;

            parentElement.insertBefore(pacContainer, nextSibling);
        },
    }
}
