<template>
    <AuthView v-if="page === 'login'" @ssoEvent="ssoEvent" class="menu-wrapper menu-light menu-modal activate-page no-bottom" :isModal="true"
               :class="{'active-menu': toggle}" style="height: 85vh; margin-top: -172.5px; border-bottom-right-radius: 0px; border-bottom-left-radius: 0px;" :view="'AuthLogin'" />
    <TapniAuth v-else-if="page === 'register'" @ssoEvent="ssoEvent" class="menu-wrapper menu-light menu-modal activate-page no-bottom" :isModal="true"
               :class="{'active-menu': toggle}" style="height: 85vh; margin-top: -172.5px; border-bottom-right-radius: 0px; border-bottom-left-radius: 0px;" :view="'AuthRegister'" />
    <TapniAuth v-else-if="page === 'verify'" @ssoEvent="ssoEvent" class="menu-wrapper menu-light menu-modal activate-page no-bottom" :isModal="true"
               :class="{'active-menu': toggle}" style="height: 85vh; margin-top: -172.5px; border-bottom-right-radius: 0px; border-bottom-left-radius: 0px;" :view="'AuthVerify'" />
</template>

<script>
import {EventBus} from '../../store/event-bus'
import AuthView from '../../views/AuthView.vue';

export default {
    name: 'AddAccount',
    components: {AuthView},
    data () {
        return {
            toggle: false,
            page: 'login'
        }
    },
    mounted () {
        EventBus.$on('toggleAddAccountModalRegister', () => {
            this.page = 'register'
        })
        EventBus.$on('toggleAddAccountModalLogin', () => {
            this.page = 'login'
        })
        EventBus.$on('toggleAddAccountModalVerify', () => {
            this.page = 'verify'
	        this.toggle = !this.toggle
        })
        EventBus.$on('toggleAddAccountModal', this.toggleModal)
        EventBus.$on('closeModal', () => {
            this.toggle = false
            if (this.page !== 'verify') {
	            setTimeout(() => {
		            this.page = 'login'
	            }, 1500)
            }
        })
    },
    beforeDestroy() {
        EventBus['_events']['toggleAddAccountModalRegister'].pop()
        EventBus['_events']['toggleAddAccountModalLogin'].pop()
        EventBus['_events']['toggleAddAccountModalVerify'].pop()
        EventBus['_events']['toggleAddAccountModal'].pop()
    },
    methods: {
        ssoEvent(data) {
            EventBus.$emit(data.name, data.data)
        },
        close () {
            EventBus.$emit('closeModal')
        },
        async submit () {},
        toggleModal () {
            this.toggle = !this.toggle
            if (!this.toggle) {
                this.page = 'login'
            }
        }
    }
}
</script>

<style scoped>
.link-img {
    margin: 0 auto;
    width: 55px;
}
#linkText {
    font-size:22px;
}

#linkUrl {
    font-size:15px;
}
input {
    margin: 7px 0;
    margin: 0 auto;
    height: 25px;
}
</style>
