<template>
  <div class="wallet-card" :style="`background-color: ${templateData.template.backgroundOptions.color};`">
    <div class="full-width" style="padding: 20px;">
      <div class="wallet-card-header">
        <div style="">
          <div class="google-logo" v-if="walletType === 'google'">
            <img :src="templateData.template.showPhoto && templateData.media.photo === '[photo]' ? (profile ? profile.photo : 'https://cdn.tapni.co/images/user.png') : 'https://cdn.tapni.co/images/tapni_logo_google_pass.png'" :style="`border-radius: ${templateData.template.photoCrop}%;`" alt="Logo">
            <span v-show="templateData.template.showPrimary" :style="`color: ${templateData.template.textOptions.color};`">{{ replaceProfileVariables(templateData.template.title, profile) }}</span>
          </div>
          <hr v-if="walletType === 'google'" style="width: 280px; left:0; color: #c2c2c2; position: absolute; border-width: 0.001rem;">
          <div class="logo" v-if="walletType === 'apple'">
            <img v-show="templateData.template.showLogo" :src="templateData.media.logo" alt="Logo">
          </div>
          <div class="primary">
            <span v-show="templateData.template.showPrimary" class="info-label" :style="`color: ${templateData.template.labelOptions.color};`">{{ replaceProfileVariables(templateData.template.primaryLabel1, profile) }}</span>
            <span v-show="templateData.template.showPrimary" :style="`color: ${templateData.template.textOptions.color};`">{{ replaceProfileVariables(templateData.template.primaryText1, profile) }}</span>
          </div>
        </div>
        <div v-show="templateData.template.showPhoto && walletType === 'apple'" class="photo-container-apple" :style="`border-radius: ${templateData.template.photoCrop}%;`">
          <img v-if="templateData.media" :src="templateData.media.photo === '[photo]' ? (profile ? profile.photo : 'https://cdn.tapni.co/images/user.png') : ''" class="photo">
        </div>
      </div>
      <div class="info">
        <div class="info-item">
          <span v-show="templateData.template.showSecondary" class="info-label" :style="`color: ${templateData.template.labelOptions.color};`">{{ replaceProfileVariables(templateData.template.secondaryLabel1, profile) }}</span>
          <span v-show="templateData.template.showSecondary" class="info-content" :style="`color: ${templateData.template.textOptions.color};`">{{ replaceProfileVariables(templateData.template.secondaryText1, profile) }}</span>
        </div>
        <div class="info-item">
          <span v-show="templateData.template.showAuxiliary" class="info-label" :style="`color: ${templateData.template.labelOptions.color};`">{{ replaceProfileVariables(templateData.template.auxiliaryLabel1, profile) }}</span>
          <span v-show="templateData.template.showAuxiliary" class="info-content" :style="`color: ${templateData.template.textOptions.color};`">{{ replaceProfileVariables(templateData.template.auxiliaryText1, profile) }}</span>
        </div>
      </div>
    </div>
    <div class="qr-code">
      <img v-show="templateData.template.showQR" :src="templateData.template.qrCodeUrl" alt="QR Code">
    </div>
    <div v-show="templateData.template.showLogo && walletType === 'google'" class="photo-container-google">
      <img v-if="templateData.media" :src="templateData.media.logo" class="photo">
    </div>
  </div>
</template>

<script>
export default {
  name: 'WalletPreview',
  props: {
    profile: {
      required: false,
      type: Object,
      default: null
    },
    templateData: {
      type: Object,
      required: true
    },
    walletType: {
      type: String,
      required: false,
      default: 'apple'
    }
  },
  methods: {
    replaceProfileVariables (s, user) {
      if (!user) return s;
      if (!s) return '';
      return s
          .replace('[name]', user.name)
          .replace('[bio]', user.bio)
          .replace('[photo]', user.photo)
          .replace('[username]', user.username)
          ;
    },
  }
}
</script>

<style scoped>
.wallet-card {
  position: relative;
  width: 280px;
  min-height: 380px;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.wallet-card-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0!important;
  margin-bottom: 10%;
}

.logo {
  height: 30px;
}

.logo img {
  height: 30px;
  float: left;
}

.google-logo {
  height: 30px;
  overflow: hidden;
}

.google-logo span {
  font-weight: bold;
  font-size: 13px;
  margin-left: 10px;
  line-height: 30px;
}

.google-logo img {
  height: 30px;
  float: left;
}

.title {
  font-size: 12px;
  font-weight: bold;
  margin-top: 10px;
}

.primary {
  font-size: 22px;
  font-weight: normal;
  margin: 15px 0;
  height: 40px;
  margin-top: 25px;
  text-align: left;
}

.info {
  font-size: 14px;
  text-align: left;
}

.info-item:not(:last-child) {
  margin-bottom: 10px;
}

.info-label {
  display: block;
  font-weight: bold;
  font-size: 10px;
  text-align: left;
}

.info-content {
  display: block;
  font-weight: normal;
}

.photo-container-apple {
  margin-top: 25px;
  margin-bottom: 25px;
  width: 60px;
  height: 60px;
  overflow: hidden;
  margin-left: auto; /* This pushes the photo to the right */
}

.photo-container-google {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-left: auto; /* This pushes the photo to the right */
}

.photo {
  width: 100%;
  height: 100%;
  display: block;
  vertical-align: bottom;
}

.qr-code {
  width: 100%;
  margin-bottom: 15px;
}

.qr-code img {
  width: 100px;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
