<template>
  <div
    class="menu-wrapper menu-light menu-white menu-modal center-text activate-page"
    :class="{ 'active-menu': toggle }"
    style="height: auto; max-height: 92vh; margin-top: -172.5px"
  >
    <a
      @click="close"
      class="color-black pull-right pointer"
      style="margin-top: 5px; position: absolute; right: 5px"
    >
      <i class="font-17 fa color-black">
        <img
          src="https://cdn.tapni.co/icons/down-arrow.png"
          class="fa responsive-image"
          style="width: 45%"
        />
      </i>
    </a>
    <h3 class="bold center-text color-black small-bottom">
      {{lang[appLang].app_language }}
    </h3>

    <LinkIcon
      @click.native="updateLanguage('en')"
      :link-style="'link-list'"
      :data="{ type: 'lang/en', text: lang[appLang].english }"
      :editing="false"
      :class="{ 'add-button': true }"
      :customLinkStyle="{ linkIcon: { display: 'none' }}"
      style="margin-top:7px;"
    />

    <LinkIcon
      @click.native="updateLanguage('es')"
      :link-style="'link-list'"
      :data="{ type: 'lang/es', text: lang[appLang].spanish }"
      :editing="false"
      :class="{ 'add-button': true }"
      :customLinkStyle="{ linkIcon: { display: 'none' }}"
      style="margin-top:7px;"
    />

    <LinkIcon
      @click.native="updateLanguage('de')"
      :link-style="'link-list'"
      :data="{ type: 'lang/de', text: lang[appLang].german }"
      :editing="false"
      :class="{ 'add-button': true }"
      :customLinkStyle="{ linkIcon: { display: 'none' }}"
      style="margin-top:7px;"
    />

    <LinkIcon
      @click.native="updateLanguage('it')"
      :link-style="'link-list'"
      :data="{ type: 'lang/it', text: lang[appLang].italian }"
      :editing="false"
      :class="{ 'add-button': true }"
      :customLinkStyle="{ linkIcon: { display: 'none' }}"
      style="margin-top:7px;"
    />

    <LinkIcon
      @click.native="updateLanguage('fr')"
      :link-style="'link-list'"
      :data="{ type: 'lang/fr', text: lang[appLang].french }"
      :editing="false"
      :class="{ 'add-button': true }"
      :customLinkStyle="{ linkIcon: { display: 'none' }}"
      style="margin-top:7px;"
    />

    <LinkIcon
      @click.native="updateLanguage('kr')"
      :link-style="'link-list'"
      :data="{ type: 'lang/kr', text: lang[appLang].korean }"
      :editing="false"
      :class="{ 'add-button': true }"
      :customLinkStyle="{ linkIcon: { display: 'none' }}"
      style="margin-top:7px;"
    />

    <LinkIcon
      @click.native="updateLanguage('sr')"
      :link-style="'link-list'"
      :data="{ type: 'lang/sr', text: lang[appLang].serbian }"
      :editing="false"
      :class="{ 'add-button': true }"
      :customLinkStyle="{ linkIcon: { display: 'none' }}"
      style="margin-top:7px;"
    />

    <LinkIcon
      @click.native="updateLanguage('tr')"
      :link-style="'link-list'"
      :data="{ type: 'lang/tr', text: lang[appLang].turkish }"
      :editing="false"
      :class="{ 'add-button': true }"
      :customLinkStyle="{ linkIcon: { display: 'none' }}"
      style="margin-top:7px;"
    />

    <LinkIcon
      @click.native="updateLanguage('cn')"
      :link-style="'link-list'"
      :data="{ type: 'lang/cn', text: lang[appLang].chinese }"
      :editing="false"
      :class="{ 'add-button': true }"
      :customLinkStyle="{ linkIcon: { display: 'none' }}"
      style="margin-top:7px;"
    />
  </div>
</template>

<script>
import { EventBus } from "../../store/event-bus";
import { mapMutations } from "vuex";
import LinkIcon from "../LinkIcon";
import UtilService from "../../services/UtilService";
import CONSTANTS from "../../constants";
import { Device } from "@capacitor/device";
import UserService from "@/services/UserService";

export default {
  name: "Language",
  components: { LinkIcon },
  data() {
    return {
      toggle: false,
    };
  },
  async mounted() {
    EventBus.$on("toggleLanguageModal", this.toggleModal);
    EventBus.$on("closeModal", () => {
      this.toggle = false;
    });

    let lang = this.isNative
      ? await Device.getLanguageCode()
      : UtilService.getFirstBrowserLanguage();
    if (lang && CONSTANTS.SUPPORTED_LANGUAGES.includes(lang)) {
      this.updateLanguage(lang);
    }
  },
  beforeDestroy() {
    EventBus["_events"]["toggleLanguageModal"].pop();
  },
  methods: {
    ...mapMutations(["setLanguage"]),
    forceClose() {
      EventBus.$emit("closeModal");
    },
    close() {
      this.forceClose();
    },
    async updateLanguage(lang) {
      // this.$store.dispatch("lang/registerLang", lang);
      this.setLanguage(lang);
      if (this.isLoggedIn && !this.isEmpty(this.user) && this.user.username) {
        await UserService.changeLanguage({
          lang,
        });
      }
      EventBus.$emit("closeModal");
      this.$forceUpdate();
    },
    toggleModal() {
      this.toggle = !this.toggle;
    },
  },
};
</script>

<style scoped>
.user-img {
  margin: 0 auto;
  margin-top: 2px;
  width: 65px;
}
input {
  margin: 0 auto;
  height: 25px;
}
</style>
