<template>
  <form
    @submit.prevent
    class="menu-wrapper menu-light menu-modal center-text activate-page"
    :class="{ 'active-menu': toggle }"
    style="
      height: auto;
      margin-top: -172.5px;
      padding-bottom: 30px !important;
      max-height: 90vh;
    "
  >
    <a
      @click="close"
      class="color-black pull-right pointer"
      style="margin-top: 5px; position: absolute; right: 5px"
    >
      <i class="font-17 fa color-black">
        <img
          src="https://cdn.tapni.co/icons/down-arrow.png"
          class="fa responsive-image"
          style="width: 45%"
          alt="down-arrow"
        />
      </i>
    </a>
    <h1
      v-if="user"
      class="bold small-top left-text headerText"
      style="padding-left: 5px"
    >
      {{
        lang[appLang][
          addConnection ? "createConnectionHeader" : "connection_edit"
        ]
      }}
    </h1>

    <div
      v-if="scanImage"
      @click="resizeScanImage"
      style="display: flex; justify-content: center; margin-bottom: 15px"
    >
      <img class="scan-image" :src="scanImage" />
    </div>

    <div class="formContainer">
      <Connection
        v-if="!isEmpty(data) && !isAnonymous"
        :source="data"
        ref="connectionsRef"
        :showActionButton="false"
        isBigger
        style="min-height: 85px"
      />

      <div v-if="isAnonymous">
        <input
          type="text"
          v-model="name"
          :placeholder="lang[appLang].name"
          class="edit-input half-bottom"
          style="margin: 0 auto; width: 100%"
          :class="{ disabledField: !isAnonymous }"
          :disabled="!isAnonymous"
        />
      </div>

      <div v-if="isAnonymous" v-show="!showDetails">
        <input
          type="text"
          v-model="email"
          :placeholder="lang[appLang].email"
          class="edit-input half-bottom"
          style="margin: 0 auto; width: 100%"
          :class="{ disabledField: !isAnonymous }"
          :disabled="!isAnonymous"
        />
      </div>

      <div
        v-if="isAnonymous"
        v-show="showDetails"
        style="width: 100%; display: flex"
      >
        <input
          type="text"
          v-model="email"
          :placeholder="lang[appLang].email"
          class="edit-input half-bottom"
          style="width: 90%"
          :class="{ disabledField: !isAnonymous }"
          :disabled="!isAnonymous"
        />
        <span
          @click="emailInputCnt++"
          v-show="emailInputCnt !== 3"
          class="addRemoveInput"
          ><i style="margin-left: 12px">+</i></span
        >
        <span v-show="emailInputCnt === 3" class="addRemoveInput"
          ><i style="margin-left: 12px; color: #d7d7d7">+</i></span
        >
      </div>

      <div
        v-if="isAnonymous"
        v-show="showDetails && emailInputCnt > 1"
        style="width: 100%; display: flex"
      >
        <input
          type="text"
          v-model="email2"
          :placeholder="lang[appLang].email + ' 2'"
          class="edit-input half-bottom"
          style="width: 90%"
          :class="{ disabledField: !isAnonymous }"
          :disabled="!isAnonymous"
        />
        <span
          @click="
            emailInputCnt--;
            email2 = '';
          "
          class="addRemoveInput"
          ><i style="margin-left: 17px; color: red"> - </i></span
        >
      </div>

      <div
        v-if="isAnonymous"
        v-show="showDetails && emailInputCnt > 2"
        style="width: 100%; display: flex"
      >
        <input
          type="text"
          v-model="email3"
          :placeholder="lang[appLang].email + ' 3'"
          class="edit-input half-bottom"
          style="width: 90%"
          :class="{ disabledField: !isAnonymous }"
          :disabled="!isAnonymous"
        />
        <span
          @click="
            emailInputCnt--;
            email3 = '';
          "
          class="addRemoveInput"
          ><i style="margin-left: 17px; color: red"> - </i></span
        >
      </div>

      <div
        v-if="!showDetails"
        style="width: 65%; display: inline-block; margin: 0 auto"
      >
        <p
          @click="showDetails = true"
          class="center-text small-bottom pointer underline color-black"
          style="font-weight: 500"
        >
          {{
            addConnection
              ? lang[appLang].add_details
              : lang[appLang].show_details
          }}
        </p>
      </div>

      <div
        v-if="isAnonymous"
        v-show="showDetails"
        style="width: 100%; display: flex"
      >
        <input
          type="text"
          v-model="phone"
          :placeholder="lang[appLang].phone"
          class="edit-input half-bottom"
          style="width: 90%"
          :class="{ disabledField: !isAnonymous }"
          :disabled="!isAnonymous"
        />
        <span
          @click="phoneInputCnt++"
          v-show="phoneInputCnt !== 3"
          class="addRemoveInput"
          ><i style="margin-left: 12px">+</i></span
        >
        <span v-show="phoneInputCnt === 3" class="addRemoveInput"
          ><i style="margin-left: 12px; color: #d7d7d7">+</i></span
        >
      </div>

      <div
        v-if="isAnonymous"
        v-show="showDetails && phoneInputCnt > 1"
        style="width: 100%; display: flex"
      >
        <input
          type="text"
          v-model="phone2"
          :placeholder="lang[appLang].phone + ' 2'"
          class="edit-input half-bottom"
          style="width: 90%"
          :class="{ disabledField: !isAnonymous }"
          :disabled="!isAnonymous"
        />
        <span
          @click="
            phoneInputCnt--;
            phone2 = '';
          "
          class="addRemoveInput"
          ><i style="margin-left: 17px; color: red"> - </i></span
        >
      </div>

      <div
        v-if="isAnonymous"
        v-show="showDetails && phoneInputCnt > 2"
        style="width: 100%; display: flex"
      >
        <input
          type="text"
          v-model="phone3"
          :placeholder="lang[appLang].phone + ' 3'"
          class="edit-input half-bottom"
          style="width: 90%"
          :class="{ disabledField: !isAnonymous }"
          :disabled="!isAnonymous"
        />
        <span
          @click="
            phoneInputCnt--;
            phone3 = '';
          "
          class="addRemoveInput"
          ><i style="margin-left: 17px; color: red"> - </i></span
        >
      </div>

      <div v-if="isAnonymous" v-show="showDetails">
        <input
          type="text"
          v-model="title"
          :placeholder="lang[appLang].contact_card_job_title"
          class="edit-input half-bottom"
          style="margin: 0 auto; width: 100%"
          :class="{ disabledField: !isAnonymous }"
          :disabled="!isAnonymous"
        />
      </div>

      <div v-if="isAnonymous" v-show="showDetails">
        <input
          type="text"
          v-model="companyName"
          :placeholder="lang[appLang].company"
          class="edit-input half-bottom"
          style="margin: 0 auto; width: 100%"
          :class="{ disabledField: !isAnonymous }"
          :disabled="!isAnonymous"
        />
      </div>

      <div v-if="isAnonymous" v-show="showDetails">
        <input
          type="text"
          v-model="address"
          :placeholder="lang[appLang].address"
          class="edit-input half-bottom"
          style="margin: 0 auto; width: 100%"
          :class="{ disabledField: !isAnonymous }"
          :disabled="!isAnonymous"
        />
      </div>

      <div
        v-if="isAnonymous"
        v-show="showDetails"
        style="width: 100%; display: flex"
      >
        <input
          type="text"
          v-model="website"
          :placeholder="lang[appLang].website"
          class="edit-input half-bottom"
          style="width: 90%"
          :class="{ disabledField: !isAnonymous }"
          :disabled="!isAnonymous"
        />
        <span
          @click="websiteInputCnt++"
          v-show="websiteInputCnt !== 3"
          class="addRemoveInput"
          ><i style="margin-left: 12px">+</i></span
        >
        <span v-show="websiteInputCnt === 3" class="addRemoveInput"
          ><i style="margin-left: 12px; color: #d7d7d7">+</i></span
        >
      </div>

      <div
        v-if="isAnonymous"
        v-show="showDetails && websiteInputCnt > 1"
        style="width: 100%; display: flex"
      >
        <input
          type="text"
          v-model="website2"
          :placeholder="lang[appLang].website + ' 2'"
          class="edit-input half-bottom"
          style="width: 90%"
          :class="{ disabledField: !isAnonymous }"
          :disabled="!isAnonymous"
        />
        <span
          @click="
            websiteInputCnt--;
            website2 = '';
          "
          class="addRemoveInput"
          ><i style="margin-left: 17px; color: red"> - </i></span
        >
      </div>

      <div
        v-if="isAnonymous"
        v-show="showDetails && websiteInputCnt > 2"
        style="width: 100%; display: flex"
      >
        <input
          type="text"
          v-model="website3"
          :placeholder="lang[appLang].website + ' 3'"
          class="edit-input half-bottom"
          style="width: 90%"
          :class="{ disabledField: !isAnonymous }"
          :disabled="!isAnonymous"
        />
        <span
          @click="
            websiteInputCnt--;
            website3 = '';
          "
          class="addRemoveInput"
          ><i style="margin-left: 17px; color: red"> - </i></span
        >
      </div>

      <textarea
        v-autogrow
        v-model="note"
        v-show="showDetails"
        :placeholder="lang[appLang].note_p"
        class="edit-input half-bottom small-top"
        rows="2"
        style="
          width: 100%;
          margin: 0 auto;
          min-height: 65px;
          resize: none;
          line-height: 20px;
        "
      />

      <multiselect
        v-show="showDetails"
        v-model="markers"
        :options="options"
        :multiple="true"
        :close-on-select="false"
        :clear-on-select="false"
        :preserve-search="true"
        :placeholder="lang[appLang].createMarkerPlaceholder"
        label="name"
        track-by="name"
        selectLabel=""
        deselectLabel=""
        :tagPlaceholder="lang[appLang].clickToCreateMarker"
        :taggable="true"
        @tag="addTag($event)"
        style="
          border-radius: 12px;
          margin: 0 auto !important;
          width: 100% !important;
        "
        class="full-bottom"
        selectedLabel=""
        :loading="isLoading"
      />
    </div>

    <div
      class="full-top"
      style="
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
      "
      v-if="!addConnection"
    >
      <a
        v-if="!addConnection && isNative"
        @click="openFollowUpSchedule"
        class="button white-button small-bottom pointer vibrate"
        style="width: 100%; margin: 0"
      >
        <img
          src="https://cdn.tapni.co/icons/bell.png"
          height="22"
          width="22"
          class="btn-icon"
          alt="bell"
        />
        {{lang[appLang].follow_up_reminder }}
      </a>

      <a
        v-if="!addConnection"
        @click="downloadVcard"
        class="button white-button small-bottom pointer vibrate"
        style="width: 100%; margin: 0"
      >
        <img
          src="https://cdn.tapni.co/icons/download.png"
          height="25"
          width="25"
          class="btn-icon"
          alt="download"
        />
        {{lang[appLang].download_contact }}
      </a>
    </div>
    <div
      class="half-top"
      style="display: flex; flex-direction: row; justify-content: space-between"
    >
      <!-- Delete -->
      <a
        v-if="!confirmDelete && !addConnection"
        @click="confirmDelete = true"
        class="button white-button pointer"
        style="
          width: 60px;
          margin: 0;
          border-radius: 8px;
          padding-left: 7px;
          padding-right: 7px;
        "
      >
        <img
          src="https://cdn.tapni.co/icons/delete.png"
          height="25"
          width="25"
          class="btn-icon"
          style="margin-right: 0px"
          alt="delete"
        />
      </a>

      <a
        v-if="confirmDelete"
        @click="removeConnection"
        class="button white-button pointer"
        style="
          color: #fc1414;
          width: auto;
          margin: 0;
          border-radius: 12px;
          font-size: 14px;
        "
      >
        {{lang[appLang].confirm }}
      </a>
      <!-- Delete -->
      <a
        class="button black-button pointer"
        style="margin: 0; width: 63%; margin-left: auto"
        @click="submit"
        v-if="!addConnection"
      >
        {{lang[appLang].save }}
      </a>

      <div v-if="addConnection" class="addConnectionButtonContainer">
        <!--add connection button  START-->
        <button
          v-if="!addingConnection"
          class="button-center half-top black-button pointer no-bottom"
          style="width: 100%"
          @click="submit"
        >
          {{lang[appLang].create_connection }}
        </button>
        <button
          v-if="addingConnection"
          class="button-center half-top black-button pointer no-bottom button--loading"
          style="margin: 0 auto; width: 100%"
          @click.stop.prevent
        >
          <span class="button__loader"></span>
        </button>
        <!--add connection button  END-->

        <div
          v-if="manualInput"
          class="decoration decoration-lines-thin half-bottom"
        ></div>

        <!-- scan paper business card button START-->
        <button
          v-if="manualInput"
          class="button-center white-button pointer small-bottom"
          style="width: 100%"
          @click="addByScanning"
        >
          <img
            src="https://cdn.tapni.co/icons/card-scanner.png"
            height="22"
            width="22"
            class="btn-icon"
            alt="card-scanner"
          />
          {{lang[appLang].create_using_scanner }}
        </button>

        <button
          v-if="manualInput && false"
          class="button-center white-button pointer small-bottom"
          style="width: 100%"
          @click="addByQR"
        >
          <img
            src="https://cdn.tapni.co/icons/qr.png"
            height="20"
            width="20"
            class="btn-icon"
            alt="qr-scanner"
          />
          {{lang[appLang].scan_qr_code }}
        </button>
        <!-- add connection button (by scanning)  END-->

      </div>
    </div>
  </form>
</template>

<script>
import { EventBus } from "@/store/event-bus";
import { mapState, mapActions, mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.esm.css"
import UserService from "../../services/UserService";
import { Camera, CameraResultType } from "@capacitor/camera";
import Connection from "../../components/Connection";
import LinkIcon from "../../components/LinkIcon";
import { Contacts, PhoneType, EmailType } from '@capacitor-community/contacts';

export default {
  name: "ConnectionModal",
  components: {
    Multiselect,
    Connection,
    LinkIcon,
  },
  computed: {
    ...mapState(["loading"]),
    showCompanyName() {
      return this.companyName || this.isAnonymous;
    },
    showCompanyTitle() {
      return this.title || this.isAnonymous;
    },
    vcardAvailable() {
      return Object.keys(this.data?.vcard || {}).length;
    },
  },
  data() {
    return {
      data: {},
      id: "",
      name: "",
      email: "",
      email2: "",
      email3: "",
      phone: "",
      phone2: "",
      phone3: "",
      note: "",
      companyName: "",
      address: "",
      title: "",
      website: "",
      website2: "",
      website3: "",
      scanID: "",
      scanImage: "",
      showImage: false,
      addingConnection: false,
      manualInput: false,

      toggle: false,
      showDetails: false,
      confirmDelete: false,

      isLoading: false,
      options: [],
      markers: [],
      isAnonymous: false,
      addConnection: false,

      emailInputCnt: 1,
      phoneInputCnt: 1,
      websiteInputCnt: 1,
    };
  },
  mounted() {
    EventBus.$on("toggleConnectionModal", this.toggleModal);
    EventBus.$on("closeModal", () => {
      this.toggle = false;
      this.resetForm();
    });
    EventBus.$on("runCardScanner", async () => {
      await this.addByScanning();
    }),
      EventBus.$on("runQRScanner", async () => {
        setTimeout(async () => {
          await this.addByQR();
        }, 500);
      });
    EventBus.$on("qrDecoded", async (data) => {
      let resp;
      let user;
      if (data.usage === "addConnection") {
        if (data.type === "tapniQR") {
          try {
            if (data.payload.username)
              resp = await UserService.getByUsername({
                username: data.payload.username,
              });
            if (data.payload.hash)
              resp = await UserService.getByNumber({
                serial: data.payload.hash,
              });
            if (resp.data && resp.data.user) {
              user = resp.data.user;
              if (user.is_public && user.is_active) {
                if (user.connected)
                  return this.errorSnack(
                    "You are already connected to this user."
                  );
                else {
                  EventBus.$emit("connectionsSetLoading", true);
                  await UserService.connect({
                    UserId: user.id,
                    captchaToken: await this.getCaptchaToken("qr_connect"),
                  });
                  EventBus.$emit("refreshConnections");
                  EventBus.$emit("connectionsSetLoading", false);
                  this.successSnack("New connection successfully added");
                }
              } else return this.errorSnack("User is private!");
            } else return this.errorSnack("User does not exist!");
          } catch (err) {
            console.log(err);
            return this.errorSnack(
              "Server error! Please try again in a few moments."
            );
          }
        }
        if (
          data.type === "vcfQR" ||
          data.type === "urlQR" ||
          data.type === "otherQR"
        ) {
          EventBus.$emit("toggleConnectionModal", data.payload);
        }
      }
      this.vibrate("high");
    });
  },
  beforeDestroy() {
    EventBus["_events"]["toggleConnectionModal"].pop();
    EventBus["_events"]["qrDecoded"].pop();
    EventBus["_events"]["runCardScanner"].pop();
    EventBus["_events"]["runQRScanner"].pop();
  },
  methods: {
    ...mapActions([
      "saveConnection",
      "getUser",
      "deleteConnection",
      "createConnection",
    ]),

    openFollowUpSchedule() {
      this.close();
      EventBus.$emit("toggleFollowUpScheduleModal", this.data);
    },

    async addTag(name) {
      await UserService.createMarker({ name });

      await this.getMarkers();

      this.selectMarkerByName(name);
    },

    selectMarkerByName(name) {
      const marker = this.options.find((marker) => marker.name === name);
      this.markers.push(marker);
    },

    join() {
      this.close();
      this.$router.push("/register");
    },

    close() {
      EventBus.$emit("closeModal");
    },

    contact(type, data) {
      this.redirectLink({
        type,
        url: data,
      });
    },

    async removeConnection() {
      await this.deleteConnection(this.id);
      this.successSnack(this.lang[this.appLang].connection_delete_success);
      EventBus.$emit("refreshConnections");
      this.close();
      this.$forceUpdate();
    },

    async submit() {
      if (this.isAnonymous) {
        if (!this.name) return this.errorSnack("Please enter valid name");
        if (!this.email && !this.phone)
          return this.errorSnack("Please enter valid email or phone");
      }

      try {
        this.addingConnection = true;
        if (this.addConnection) {
          const response = await this.createConnection({
            name: this.name,
            email: this.email,
            email2: this.email2,
            email3: this.email3,
            phone: this.phone,
            phone2: this.phone2,
            phone3: this.phone3,
            address: this.address,
            website: this.website,
            website2: this.website2,
            website3: this.website3,
            note: this.note,
            markers: this.markers.map((marker) => marker.id),
            company: this.companyName,
            scanID: this.scanID,
            title: this.title,
          });
          if (response?.data?.success) {
            this.successSnack(this.lang[this.appLang].createConnectionSuccess);
            EventBus.$emit("closeModal");
          }
        } else {
          await this.saveConnection({
            id: this.id,
            name: this.name,
            email: this.email,
            email2: this.email2,
            email3: this.email3,
            phone: this.phone,
            phone2: this.phone2,
            phone3: this.phone3,
            address: this.address,
            website: this.website,
            website2: this.website2,
            website3: this.website3,
            note: this.note,
            markers: this.markers.map((marker) => marker.id),
            company: this.companyName,
            title: this.title,
          });
          this.successSnack(this.lang[this.appLang].connection_edit_success);
        }
        this.addingConnection = false;
      } catch (err) {
        this.addingConnection = false;
      }

      if (this.$route.name === "HomeUsername") {
        await this.getUser({ username: this.user.username });
      } else {
        EventBus.$emit("refreshConnections");
      }

      this.close();
      this.$forceUpdate();
    },

    toggleModal(data) {
      this.data = data;
      this.resetForm();

      // Check if the connection is form or direct
      this.isAnonymous =
        data.addConnection || Object.keys(data.payload || {}).length;

      this.manualInput = data.manualInput;

      //this.showDetails = !this.isAnonymous;
      this.showDetails = data.showDetails;

      if (data.addConnection) {
        this.addConnection = true;
        this.name = data.name;
        this.email = data.email;
        this.email2 = data.email2;
        if (this.email2) this.emailInputCnt = 2;
        this.email3 = data.email3;
        if (this.email3) this.emailInputCnt = 3;
        this.phone = data.phone;
        this.phone2 = data.phone2;
        if (this.phone2) this.phoneInputCnt = 2;
        this.phone3 = data.phone3;
        if (this.phone3) this.phoneInputCnt = 3;
        this.address = data.address;
        this.title = data.title;
        this.website = data.website;
        this.website2 = data.website2;
        if (this.website2) this.websiteInputCnt = 2;
        this.website3 = data.website3;
        if (this.website3) this.websiteInputCnt = 3;
        this.note = data.note;
        this.companyName = data.company;
        this.markers = [];
        if (data.scanID) {
          this.scanID = data.scanID;
          this.scanImage = data.scanImage;
        }
      } else {
        this.id = data.id;
        this.markers = data.markers;
        if (data && data.payload) {
          this.name = data.payload.name;
          this.email = data.payload.email;
          this.email2 = data.payload.email2;
          if (this.email2) this.emailInputCnt = 2;
          this.email3 = data.payload.email3;
          if (this.email3) this.emailInputCnt = 3;
          this.phone = data.payload.phone;
          this.phone2 = data.payload.phone2;
          if (this.phone2) this.phoneInputCnt = 2;
          this.phone3 = data.payload.phone3;
          if (this.phone3) this.phoneInputCnt = 3;
          this.website = data.payload.website;
          this.website2 = data.payload.website2;
          if (this.website2) this.websiteInputCnt = 2;
          this.website3 = data.payload.website3;
          if (this.website3) this.websiteInputCnt = 3;
          this.note = data.payload.note;
          this.companyName = data.payload.company;
          this.address = data.payload.address;
          this.title = data.payload.title;
          this.scanImage = data.scan?.front_picture;
        } else {
          this.name = `${data.vcard.vcardFirstName || ""} ${
            data.vcard.vcardLastName || ""
          }`.trim();
          this.email = data.vcard.vcardEmail;
          this.phone = data.vcard.vcardPhone;
          this.note = data.note;
          this.companyName = data.company;
          this.title = data.vcard.vcardTitle;
        }
      }
      this.getMarkers(data);
      this.toggle = !this.toggle;
    },

    resetForm() {
      this.id = "";
      this.name = "";
      this.email = "";
      this.email2 = "";
      this.email3 = "";
      this.phone = "";
      this.phone2 = "";
      this.phone3 = "";
      this.address = "";
      this.website = "";
      this.website2 = "";
      this.website3 = "";
      this.note = "";
      this.companyName = "";
      this.markers = [];
      this.addConnection = false;
      this.confirmDelete = false;
      this.showImage = false;
      this.scanImage = "";
      this.scanID = null;
      this.emailInputCnt = 1;
      this.phoneInputCnt = 1;
      this.websiteInputCnt = 1;
    },

    async getMarkers() {
      const {
        data: { markers },
      } = await UserService.getAllMarkers();
      this.options = markers;
    },

    closeAddConnection() {
      EventBus.$emit("closeModal");
    },

    async addByScanning() {
      EventBus.$emit("closeModal");
      try {
        // camera plugin
        if (this.isNative) {
          // run native camera
          await Camera.requestPermissions();
          EventBus.$emit("startScanner", "card", "universal");
        } else {
          // run webcam
          const image = await Camera.getPhoto({
            quality: 100,
            limit: 1,
            allowEditing: false,
            resultType: CameraResultType.Base64,
          });
          if (image)
            EventBus.$emit("toggleScanCropperModal", image.base64String);
        }
      } catch (err) {
        console.error(err);
      }
    },
    addByQR() {
      EventBus.$emit("closeModal");
      EventBus.$emit("startScanner", "QR", "addConnection");
    },
    resizeScanImage(e) {
      let el = e.target.children[0];

      if (el && el.classList) {
        if (el.classList.contains("fullWidthScanImage")) {
          el.classList.remove("fullWidthScanImage");
        } else {
          el.classList.add("fullWidthScanImage");
        }
      } else {
        console.error('Element is undefined or does not have classList');
      }
    },
    async downloadVcard() {
      //for anonymous connections
      let url =
        import.meta.env.VITE_APP_API_ROOT +
        "/v1/users/connection/" +
        this.data.id +
        "/vcard";

      //for direct connections
      if (this.data.status === "connected") {
        let userId;
        if (this.storage && this.storage.UserId !== this.data.requester?.id) {
          userId = this.data.requester?.id;
        } else {
          userId = this.data.receiver?.id;
        }
        url = import.meta.env.VITE_APP_API_ROOT + "/v1/users/" + userId + "/vcard";
      }

      if (this.user.username === 'werbifi' || this.user.email.includes("@tapni.co") || this.user.username === 'applereview'){
        const phones = [];
        const emails = [];
        const urls = [];

        if (this.phone) {
          phones.push({
            type: PhoneType.Work,
            label: 'work',
            number: this.phone,
          })
        }

        if (this.phone2) {
          phones.push({
            type: PhoneType.Work,
            label: 'work',
            number: this.phone2,
          })
        }

        if (this.phone3) {
          phones.push({
            type: PhoneType.Work,
            label: 'work',
            number: this.phone3,
          })
        }

        if (this.email) {
          emails.push({
            type: EmailType.Work,
            label: 'work',
            address: this.email,
          })
        }

        if (this.email2) {
          emails.push({
            type: EmailType.Work,
            label: 'work',
            address: this.email2,
          })
        }

        if (this.email3) {
          emails.push({
            type: EmailType.Work,
            label: 'work',
            address: this.email3,
          })
        }

        if (this.website) {
          urls.push(this.website)
        }

        if (this.website2) {
          urls.push(this.website2)
        }

        if (this.website3) {
          urls.push(this.website3)
        }

        const check = await Contacts.checkPermissions();
        console.log({check});
        if (!check) {
          const req = await Contacts.requestPermissions();
          console.log({req});
          if (!req) {
            return this.errorSnack('Permission denied.');
          }
        }

        const res = await Contacts.createContact({
          contact: {
            name: {
              given: this.name.split(' ')[0],
              family: this.name.split(' ')[1] || '',
            },
            phones,
            emails,
            urls,
          },
        });

        console.log(res.contactId);
        this.successSnack('Contact added successfully');
      } else {
        this.downloadContent(url, true);
      }
    },
  },
};
</script>

<style scoped>
input {
  margin: 7px 0;
  margin: 0 auto;
  height: 40px;
}
</style>

<style>
.multiselect * {
  font-size: 15px !important;
  font-weight: 400;
  color: #757575 !important;
}

.multiselect__option > span {
  color: black !important;
}

.multiselect__option:after {
  font-size: 15px !important;
  font-weight: 400;
  color: #757575 !important;
}

.multiselect__input::placeholder {
  font-size: 15px !important;
  font-weight: 400;
  color: #757575 !important;
}

.multiselect__tags {
  border: none;
  border-radius: 12px;
  overflow-x: scroll;
  padding-top: 0px !important;
  height: 65px !important;
  background: rgb(244, 244, 244) !important;
}

.multiselect__tags input {
  background: rgb(244, 244, 244);
  margin-top: 7px !important;
  color: black !important;
}

.multiselect__tag {
  background: black;
  margin-top: 10px !important;
  margin-bottom: -5px !important;
}

.multiselect__tag > span {
  color: white !important;
}

.multiselect__tag-icon:after {
  color: white;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #f3f3f3;
  color: #35495e;
}

.multiselect__option--highlight {
  background: #f3f3f3;
  color: #35495e;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: black;
}

.multiselect__option--highlight:after {
  background: #f3f3f3;
  color: #35495e;
}

.multiselect__element {
  display: none;
}

.multiselect__element:nth-last-child(-n + 5) {
  display: block;
}

.multiselect__placeholder {
  padding-top: -5px !important;
  color: #7b7777;
}
.multiselect__input {
  font-size: 14px;
  color: #7b7777;
  padding-left: 0px;
  margin-left: 2px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 5px 5px 20px;
  max-height: calc(85vh - 346px);
  overflow-y: scroll;
}

.formContainer input,
.formContainer textarea {
  margin: unset;
}

.formContainer textarea {
  /* margin-bottom: 27px; */
}

.formContainer p {
  margin-bottom: 5px;
}

.phoneContainer {
  display: flex;
  align-items: center;
  /* margin-bottom: 20px; */
}

.phoneContainer input {
  flex: 1;
}

.phoneContainer img {
  width: 40px;
}

.emailContainer {
  display: flex;
  align-items: center;
}

.emailContainer input {
  flex: 0.71;
}

.emailContainer img {
  flex: 0.29;
  width: 40px;
}

.bottomInputMargin {
  margin-bottom: 7px !important;
}

.topInputMargin {
  margin-top: 7px !important;
}

.disabledField {
  opacity: 0.5 !important;
}

.addConnectionButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
}

.decoration {
  margin-top: 20px;
}

.scan-image {
  width: 170px;
  border-radius: 12px;
  transition: width 0.5s, height 0.5s;
}

.fullWidthScanImage {
  width: 100% !important;
}

.vpd-wrapper {
  background-color: transparent !important;
}

.addRemoveInput {
  font-size: 32px;
  font-weight: 400;
  line-height: 50px;
  height: 50px;
  padding-bottom: 10px;
}
.addRemoveInput i {
  font-style: normal;
}
</style>
