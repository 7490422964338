<template>
    <form @submit.prevent="saveTag" class="menu-wrapper menu-light menu-modal center-text activate-page" :class="{'active-menu': toggle}" style="height: auto; margin-top: -172.5px;">
      <span v-if="data">
		    <a @click="close" class="color-black pull-right pointer" style="margin-top: 5px; position: absolute; right: 5px;">
		      <i class="font-17 fa color-black">
			    <img src="https://cdn.tapni.co/icons/down-arrow.png" class="fa responsive-image" style="width: 45%;"/>
		      </i>
		    </a>
        <img :src="data.photo" class="tag-img half-bottom" :style="confirmDelete ? 'width: 120px;' : 'margin-top: 10px;'"/>
        <input id="tagName" type="text" v-model="data.name" :placeholder="lang[appLang].tag_text_p" style="width: 50%; margin: 0 auto;"
               class="edit-input" maxlength="30"/>
        <p class="close-text close-menu center-text small-top half-bottom">{{lang[appLang].tag_text_p2}}</p>

        <h3 v-if="false" class="close-text close-menu center-text small-top half-bottom"> {{ formatProductName(data) }} </h3>

        <h4 class="close-text close-menu center-text small-top">
          {{lang[appLang].activation_code}}:
          <br>
          <a class="pointer" @click="copy(data.hash.replace(/:/g, '').toLowerCase())">
            {{data.hash.replace(/:/g, '').toLowerCase()}}
          </a>
        </h4>
        <h5 class="close-text close-menu center-text half-top bold">
          {{lang[appLang].total_taps}}: {{data.visits}} {{tapsEmoji(data.visits)}}
        </h5>

        <span v-if="!rules.editCompanyTags && data.CompanyId && !data.CompanyEventId">
          <p class="close-text close-menu center-text full-bottom font-18" style="width: 80%; margin: 1em auto;"><br>{{lang[appLang].forbidden_rule}}</p>
        </span>
        <span v-else>
          <!-- Active -->
          <div style="display: inline-block; padding: 10px 20px 1px 20px; background-color: #fff; border-radius: 20px; border: 1px solid #e8e8e8" class="half-top">
            <p style="float: left; margin-right: 8px; margin-top: 5px; font-size: 16px;"><b>{{lang[appLang].active}}</b></p>
            <div class="ios-switch" style="display: inline-block;">
              <input v-model="data.is_active" type="checkbox" name="ios-switch" class="ios-switch-checkbox" :id="'active-switch'"/>
              <label class="ios-switch-label" :for="'active-switch'"></label>
            </div>
          </div>
	         <p class="close-text close-menu center-text small-top-padding half-bottom" v-html="lang[appLang].tag_active_p" />

          <div style="display: flex; justify-content: space-between;">
            <!-- Delete -->
            <a v-if="data.id && !confirmDelete" @click="confirmDelete = true" class="button white-button pointer"
               style="width: 60px; margin: 0; border-radius: 8px; padding-left:7px; padding-right: 7px;">
              <img src="https://cdn.tapni.co/icons/delete.png" height="25" width="25" class="btn-icon" style="margin-right: 0px;">
            </a>
            <a v-if="data.id && confirmDelete" @click="deleteTag(data.id)" class="button white-button pointer"
               style="margin: 0; color: #fc1414; width: auto;">
              {{lang[appLang].confirm}}
            </a>

            <button type="submit" class="button black-button" style="margin: 0; width: 62%;">
              {{lang[appLang].save}}
            </button>
          </div>
          <p v-if="confirmDelete" class="close-text bold close-menu center-text half-top" style="color: #fc1414" v-html="lang[appLang].tag_delete_confirm" />
        </span>
      </span>
    </form>
</template>

<script>
import {EventBus} from '../../store/event-bus'
import {mapState, mapActions, mapGetters} from 'vuex'
export default {
  name: 'Tag',
  data () {
    return {
      data: null,
      confirmDelete: false,
      toggle: false
    }
  },
  mounted () {
    EventBus.$on('toggleTagModal', this.toggleModal)
    EventBus.$on('closeModal', () => {
      setTimeout(() => {
        this.confirmDelete = false
      }, 1000)
      this.toggle = false
    })
  },
  beforeDestroy() {
    EventBus['_events']['toggleTagModal'].pop()
  },
  computed: {
    rules (){
      return this.$store.getters.rules
    }
  },
  methods: {
    ...mapActions(['updateTag', 'removeTag']),
    close () {
      EventBus.$emit('closeModal')
    },
    deleteTag (id) {
      this.removeTag({id})
      this.close()
      setTimeout(() => {
        this.confirmDelete = false
      }, 1000)
    },
    saveTag () {
      this.updateTag({
        id: this.data.id,
        is_active: this.data.is_active,
        name: this.data.name
        // is_direct: this.data.is_direct,
        // mode: this.data.mode
      })
      this.close()
      setTimeout(() => {
        this.confirmDelete = false
      }, 1000)
    },
    toggleModal (data) {
      this.data = data

      if (!this.isEmpty(this.data) && !this.data.name) {
        this.data.name = this.formatProductName(data);
      }

      this.toggle = !this.toggle
    },
    formatProductName (data) {
      return `Tapni ${data.is_brand ? 'x': ''}${lang[appLang][data.type.toLowerCase()]}`;
    }
  }
}
</script>

<style scoped>
  .tag-img {
    margin: 0 auto;
    width: 130px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(200,200,200,0.8);
  }
  #linkText {
    font-size:22px;
  }
</style>
