<template>
	<div class="center-text" style="padding: 10px;">
	  <h1 class="center-text" style="margin-top: 80px;">
		  <b>{{lang[appLang].offline_p1}}</b>
	  </h1>
	  <p class="center-text half-top">
		  {{lang[appLang].offline_p2}}
	  </p>
	  <br>
	  <a @click="reload" style="z-index: 999; margin: 0;" class="button white-button">
		  {{lang[appLang].refresh}}
	  </a>
    <div id="qr-code-offline" class="center-text small-bottom" style="margin-top: 10px;"></div>
    <p class="center-text" v-if="vcard">{{lang[appLang].offline_p3_1}}<br>{{lang[appLang].offline_p3_2}}</p>
	</div>
</template>

<script>
  /* eslint-disable */
  import QRCodeStyling from 'qr-code-styling';
  import { generateVcard } from "@tapni/js-utils";

  export default {
  name: 'ErrorOffline',
    data () {
      return {
        vcard: ''
      }
    },
    methods: {
      init () {

        // remove old qr code from DOM
        let divQR = document.getElementById("qr-code-offline");
        let element = divQR.getElementsByTagName("canvas"), index;
        for (index = element.length - 1; index >= 0; index--) {
          element[index].parentNode.removeChild(element[index]);
        }

        if (this.storage.localUser && this.storage.localUser.vcard && (Object.keys(this.storage.localUser.vcard).length > 0)) {
          setTimeout(() => {
            this.vcard = generateVcard(this.storage.localUser.vcard)
            let windowWidth = window.innerWidth;
            if(windowWidth > 500) windowWidth = 500;
            const qrCode = new QRCodeStyling({
              width: windowWidth - 20,
              height: windowWidth - 20,
              data: this.vcard,
              margin: 0,
              dotsOptions: {
                color: "#000000",
                type: "extra-rounded",
              },
              backgroundOptions: {
                color: "#efefef00",
              },
              imageOptions: {
                crossOrigin: "anonymous",
                imageSize: 0.5,
              },
              cornersSquareOptions: {
                type: "extra-rounded",
              },
            });

            qrCode.append(document.getElementById("qr-code-offline"));
          }, 0);

          this.$store.commit('setLoading', false)
        }

      },
      reload () {
        location.reload()
      }
    },
    mounted () {
      if (this.route) this.init()
    },
    watch: {
      'route.name' (nv, ov) {
        if (!ov && nv) this.init()
      },
    }
}
</script>

<style scoped>

</style>
