<template>
    <form @submit.prevent="saveLink" class="menu-wrapper menu-light menu-modal center-text activate-page" :class="{'active-menu': toggle}" style="height: auto; margin-top: -172.5px;">
      <a @click="close" class="color-black pull-right pointer" style="margin-top: 5px; position: absolute; right: 5px;">
        <i class="font-17 fa color-black">
          <img src="https://cdn.tapni.co/icons/down-arrow.png" class="fa responsive-image" style="width: 45%;"/>
        </i>
      </a>
	  <img v-if="data && data.type !== 'customlink'" :src="'https://cdn.tapni.co/icons/' + data.type + '.png'" class="link-img half-bottom half-top" :alt="data.name"/>
	  <img v-else-if="data && !linkImageBase64" :src="data.custom_icon ? data.style.custom_icon : 'https://cdn.tapni.co/icons/customlink.png'" style="border-radius: 22.46%;" class="custom-link-img half-bottom half-top" :alt="data.name"/>
	  <img v-else-if="data && linkImageBase64" :src="linkImageBase64" class="custom-link-img half-bottom half-top"  style="border-radius: 15px;" :alt="data.name"/>

	  <span class="center-text">
        <input v-if="data" id="linkText" type="text" v-model="data.text" :placeholder="lang[appLang].link_text_p"
               style="width: 100%"
               class="edit-input" maxlength="30"/>
        <p class="close-text close-menu center-text small-top half-bottom">{{lang[appLang].link_icon}}</p>

        <div class="relative">
          <vue-google-autocomplete
             v-show="toggle"
             :id="'googleAddressReview'"
             ref="googleAddressReview"
             :placeholder="lang[appLang].search"
             types="establishment"
             @placechanged="getAddressData"
             @keypress="(e) => movePacContainer(e, 'googleAddressReview')"
             style="width: 100%"
             classname="edit-input margin-center"
          />
        </div>
        <p v-if="data" class="close-text close-menu center-text small-top half-bottom">{{data.desc[this.appLang].long}}</p>

        <p v-if="data && data.hash" style="margin-top: 30px; margin-bottom: 25px;"> Share "{{data.text}}" directly and track clicks using our link shorter: <span @click="copy('https://t.link/' + data.hash)" style="color: #0000EE; cursor: pointer;">https://t.link/{{data.hash}}</span></p>

        <div class="half-top" style="display: flex; flex-direction: row; justify-content: space-between;">
          <!-- Delete -->
          <span>
            <a v-if="data && data.id && !confirmDelete" @click="confirmDelete = true" class="button white-button small-top pointer"
               style="width: 60px; margin: 0; border-radius: 8px; padding-left:7px; padding-right: 7px;">
              <img src="https://cdn.tapni.co/icons/delete.png" height="25" width="25" class="btn-icon" style="margin-right: 0px;">

            </a>
            <a v-if="data && data.id && confirmDelete" @click="deleteLink(data.id)" class="button small-top white-button pointer"
               style="width: auto; margin: 0; color: #fc1414;">
              {{lang[appLang].confirm}}
            </a>
          </span>

          <button type="submit" class="button black-button small-top pointer" :class="{ 'w-100': !data }" style="margin: 0; width:62%;">
            {{lang[appLang].save}}
          </button>
        </div>
      </span>
    </form>
</template>

<script>
import {EventBus} from '../../store/event-bus'
import {mapActions} from 'vuex'
import GoogleMapsMixin from "@/mixins/google-maps.mixin.js";

export default {
  name: 'GoogleReview',
  mixins: [GoogleMapsMixin],
  data () {
    return {
      data: null,
      confirmDelete: false,
      toggle: false,
	    linkImageBase64: '',
	    placeID: '',
      placeName: '',
    }
  },
  computed: {
      linkText () {
          if (this.appLang !== 'en' &&  this.data.text) {
              return this.lang[this.appLang]['link_text_' + this.data.text.toLowerCase().trim().replace(/ /g, '_')] || this.data.text
          }
          return this.data.text
      }
  },
  mounted () {
    EventBus.$on('toggleGoogleReviewModal', this.toggleModal)
    EventBus.$on('closeModal', () => {
      setTimeout(() => {
        this.confirmDelete = false
        this.linkImageBase64 = ''
      }, 1000)
      this.toggle = false
    })
  },
  beforeDestroy() {
    EventBus['_events']['toggleGoogleReviewModal'].pop()
  },
  methods: {
    ...mapActions(['addOrUpdateLink', 'removeLink']),
    uploadLinkIcon () {
      EventBus.$emit('typeUploadModal', 'link')
      document.getElementById('media').click()
    },
    close () {
      EventBus.$emit('closeModal')
    },
    deleteLink (id) {
      this.removeLink({id})
      EventBus.$emit('closeModal')
      setTimeout(() => {
        this.confirmDelete = false
        this.linkImageBase64 = ''
      }, 1000)
    },
    saveLink () {
      if (!this.placeID) return this.errorSnack(this.lang[this.appLang].link_validate);
      this.addOrUpdateLink({
        id: this.data.id,
        type: this.data.type,
        url: this.placeID,
        subtext: this.placeName,
        text: this.data.text,
        is_direct: this.data.is_direct,
        custom_icon: this.linkImageBase64 ? this.data.type === 'customlink' : undefined,
        linkImageBase64: this.linkImageBase64 ? this.linkImageBase64 : undefined
      })
      EventBus.$emit('toggleGoogleReviewModal')
      this.$router.push({
        path: this.home.split('#')[0],
        hash: 'edit'
      })
      this.confirmDelete = false
    },
    toggleModal (data) {
      this.toggle = !this.toggle
      this.confirmDelete = false
      if (data) {
        this.data = {...data}
        const linkType = this.linkTypes.filter(e => e.type === data.type)[0]
        this.data.desc = linkType.desc

        this.data.text = this.linkText

        this.$refs.googleAddressReview?.update(this.data?.subtext);

        if (linkType && linkType.prefill_url) {
          if (!data.id) {
            this.data.url = linkType.base_url
          }
        } else {
          if (data.id && this.data.url) {
            this.data.url = this.data.url.replace(linkType.base_url, '')
          }
        }
      }
    },
    async getAddressData(address, place) {
        this.placeID = place.place_id
        this.placeName = place.name
    }
  }
}
</script>

<style scoped>
  .link-img {
    margin: 0 auto;
    width: 70px;
  }
  .custom-link-img {
	  margin: 0 auto;
	  width: 85px;
  }
  #linkText {
    font-size:22px;
	  margin: 0 auto;
  }

  .notransition {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
  }

  #linkUrl {
    font-size:17px;
	  margin: 0 auto;
  }
</style>
