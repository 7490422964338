<template>
  <div
      class="menu-wrapper menu-light menu-modal activate-page center-text"
      :class="{ 'active-menu': toggle }"
      style="height: auto; max-height: 90vh; margin-top: -172.5px"
  >
    <a
        @click="close"
        class="color-black pull-right pointer"
        style="margin-top: 5px; position: absolute; right: 5px"
    >
      <i class="font-17 fa color-black">
        <img
            src="https://cdn.tapni.co/icons/down-arrow.png"
            class="fa responsive-image"
            style="width: 45%"
            alt="down-arrow"
        />
      </i>
    </a>

    <!-- LOADER -->
    <div class="action-block-top full-top" style="height: 40vh">
      <div class="loader-wrapper">
        <img src="https://cdn.tapni.co/images/save-planet.gif" width="200" height="200" style="margin: 0 auto;" alt="save-planet"/>
        <p style="margin-top: 20px; position: absolute; text-align: center; width: 100%; font-weight: 600; font-size:20px;">Analyzing...</p>
        <p style="margin-top: 48px; position: absolute; text-align: center; width: 100%; font-size:16px;">Save the planet, switch to digital business cards.</p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { EventBus } from "../../store/event-bus";
import  {CapacitorPluginMlKitTextRecognition}  from '@pantrist/capacitor-plugin-ml-kit-text-recognition'
import UtilService from '@/services/UtilService';

export default {
  name: "ScanLoading",
  components: {},
  data() {
    return {
      imageBase64: "",
      toggle: false,
    };
  },
  mounted() {
    EventBus.$on("closeModal", () => {
      this.toggle = false;
    });
    EventBus.$on("toggleScanLoadingModal", this.toggleModal);
  },
  beforeDestroy() {
    EventBus['_events']['toggleScanLoadingModal'].pop()
  },
  methods: {
    ...mapActions(["createCardScan"]),
    close() {
      EventBus.$emit("closeModal");
    },
    async toggleModal(image) {
      this.toggle = !this.toggle;
        this.imageBase64 = image;
        await this.addScan()
    },
    async addScan() {
      this.loader = true;
      let postData = { image1: this.imageBase64, text: null }

      if (this.isNative) {
        let extractedText = await CapacitorPluginMlKitTextRecognition.detectText({base64Image: this.imageBase64.split(",")[1]})
        if(extractedText.text) {
          postData.text = extractedText.text;
          let blobImg = UtilService.base64ImageToBlob(this.imageBase64);
          let compBlobImg = await UtilService.compressImage(blobImg)
          let compBase64Img = await UtilService.blobToBase64(compBlobImg)
          postData = { image1: compBase64Img, text: extractedText.text }
        }
      }

      const response = await this.createCardScan(postData);

      let preparedData = {
        addConnection: true,
        name: "",
        email: "",
        email2: "",
        email3: "",
        phone: "",
        phone2: "",
        phone3: "",
        company: "",
        address: "",
        website: "",
        website2: "",
        website3: "",
        //note: response.data?.comment || "",
        note: "",
        scanID: response.data?.id || "",
        scanImage: response.data?.front_picture || "",
        showDetails: true
      };

      if(response.data?.name) preparedData.name = response.data.name[0].toUpperCase() + response.data.name.substring(1);
      if(response.data?.email) preparedData.email = response.data.email.toLowerCase();
      if(response.data?.email2) preparedData.email2 = response.data.email2.toLowerCase();
      if(response.data?.email3) preparedData.email3 = response.data.email3.toLowerCase();
      if(response.data?.phone) preparedData.phone = response.data.phone.replace(/\s/g, '')
      if(response.data?.phone2) preparedData.phone2 = response.data.phone2.replace(/\s/g, '')
      if(response.data?.phone3) preparedData.phone3 = response.data.phone3.replace(/\s/g, '')
      if(response.data?.company) preparedData.company = response.data.company[0].toUpperCase() + response.data.company.substring(1);
      if(response.data?.address) preparedData.address = response.data.address
      if(response.data?.title) preparedData.title = response.data.title
      if(response.data?.website) {
        preparedData.website = response.data.website
        if(!preparedData.website.startsWith("http")) preparedData.website = "https://" + preparedData.website;
      }
      if(response.data?.website2) {
        preparedData.website2 = response.data.website2
        if(!preparedData.website2.startsWith("http")) preparedData.website2 = "https://" + preparedData.website2;
      }
      if(response.data?.website3) {
        preparedData.website3 = response.data.website3
        if(!preparedData.website3.startsWith("http")) preparedData.website3 = "https://" + preparedData.website3;
      }

      // email, name, company, phone, phone2, website, website1, address ,comment
      EventBus.$emit("closeModal");
      EventBus.$emit("toggleConnectionModal", preparedData);
      this.vibrate('high');

    },
  },
};
</script>

<style>
.loader-wrapper {
  margin-top: 10%;
}

</style>
