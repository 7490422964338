<template>
  <div
    :class="{ 'pointer delete-menu': true, 'delete-menu-active': toggle }"
    @click="toggleEmit"
  ></div>

  <!--
  <transition name="fade">
    <div v-if="toggle" class="pointer delete-menu" @click="toggleEmit"></div>
  </transition>
  -->
</template>

<script>
import { EventBus } from "../../store/event-bus";
import {mapGetters, mapState} from 'vuex';

export default {
  name: "ModalOverlay",
  data() {
    return {
      toggle: false,
      payload: null,
    };
  },
  computed: {
    ...mapGetters(['isLoggedIn'])
  },
  mounted() {
    EventBus.$on("closeModal", () => {
      this.toggle = false;
      window.document.body.style.overflow = "auto";
      window.document.body.style.height = "auto";
    });
    EventBus.$on("openModalOverlay", () => {
      this.toggle = true;
    });
    EventBus.$on("toggleQRModal", this.toggleModal);
    EventBus.$on("toggleLinkModal", this.toggleModal);
    EventBus.$on("toggleContactCardModal", this.toggleModal);
    EventBus.$on("toggleGoogleReviewModal", this.toggleModal);
    EventBus.$on("toggleTagModal", this.toggleModal);
    EventBus.$on("toggleUploadModal", this.toggleModal);
    EventBus.$on("toggleQRScanModal", this.toggleModal);
    // EventBus.$on('togglePasswordModal', this.toggleModal)
    // EventBus.$on('toggleUsernameModal', this.toggleModal)
    EventBus.$on("toggleLeadCaptureSetupModal", this.toggleModal);
    EventBus.$on("toggleAccountSettingsModal", this.toggleModal);
    EventBus.$on("toggleLanguageModal", this.toggleModal);
    EventBus.$on("toggleAmbassadorModal", this.toggleModal);
    EventBus.$on("toggleProfileSetupModal", this.toggleModal);
    EventBus.$on("toggleDirectModal", this.toggleModal);
    EventBus.$on("toggleConnectModal", this.toggleModal);
    EventBus.$on("toggleAfterConnectModal", this.toggleModal);
    EventBus.$on("toggleConnectionModal", this.toggleModal);
    EventBus.$on("toggleSSOModal", this.toggleModal);
    EventBus.$on("toggleSSOPickModal", this.toggleModal);
    EventBus.$on("toggleForbiddenRuleModal", this.toggleModal);
    EventBus.$on("toggleSidebar", this.toggleModal);
    EventBus.$on("toggleEditConnectionModal", this.toggleModal);
    EventBus.$on("addConnectionModal", this.toggleModal);
    EventBus.$on("toggleScanCropperModal", this.toggleModal);
    // EventBus.$on("toggleFilterModal", this.toggleModal);
    EventBus.$on("toggleScanLoadingModal", this.toggleModal);
    EventBus.$on("toggleFollowUpScheduleModal", this.toggleModal);
    EventBus.$on("toggleTemplatesModal", this.toggleModal);
    EventBus.$on('toggleAddAccountModal', this.toggleModal)
    EventBus.$on('toggleAuthModal', this.toggleModal)
  },
  methods: {
    toggleModal(shouldToggle = true, payload = null) {
      this.payload = payload;
      if (shouldToggle) {
        this.toggle = !this.toggle;
        if (this.toggle) {
          window.document.body.style.overflow = "hidden";
          window.document.body.style.height = "100vh";
        } else {
          window.document.body.style.overflow = "auto";
          window.document.body.style.height = "auto";
        }
      }
    },
    toggleEmit() {
      if(!this.isLoggedIn && this.payload?.profile?.lead_form?.forceFilling) return;
      EventBus.$emit("closeModal");
    },
  },
};
</script>

<style scoped>
.fade-enter-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 1;
}
</style>
