<template>
  <div
    id="sidebar-menu"
    class="menu-wrapper menu-light menu-white menu-sidebar-left menu-large"
    :class="{ 'active-menu': toggle }"
    :style="`${addingAccount ? 'z-index: 9998;' : ''}`"
  >
    <div class="menu-scroll menu-wrap">
      <!-- Logged In Account -->
      <a @click="expandSwitch = !expandSwitch" class="menu-sub-username bold">
        <img
          v-if="loggedInAccounts[storage.username]"
          :src="loggedInAccounts[storage.username].photo"
          :alt="loggedInAccounts[storage.username].name"
          style="width: 50px; border-radius: 50px; float: left"
        />
        <span
          v-if="loggedInAccounts[storage.username]"
          style="
            margin: 10px;
            float: left;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 145px;
          "
        >
          {{ loggedInAccounts[storage.username].username }}
        </span>
        <a
          v-if="!expandSwitch"
          class="color-black pull-right pointer"
          style="
            margin-top: 15px;
            position: absolute;
            right: 10px;
            text-align: right;
          "
        >
          <i class="font-11 fa color-black">
            <img
              src="https://cdn.tapni.co/icons/down-arrow.png"
              class="fa responsive-image"
              style="width: 30%"
            />
          </i>
        </a>
        <a
          v-if="expandSwitch"
          class="color-black pull-right pointer"
          style="
            margin-top: 15px;
            position: absolute;
            right: 10px;
            text-align: right;
          "
        >
          <i class="font-11 fa color-black">
            <img
              src="https://cdn.tapni.co/icons/up-arrow.png"
              class="fa responsive-image"
              style="width: 30%"
            />
          </i>
        </a>
      </a>
      <!-- Expanded All Accounts section -->
      <div v-show="expandSwitch" v-if="loggedInAccounts">
        <a
          v-for="username in Object.keys(loggedInAccounts)"
          v-if="loggedInAccounts[username] && username !== storage.username"
          @click="switchAccount(username)"
          class="menu-sub-username bold pointer"
        >
          <img
            :src="loggedInAccounts[username].photo"
            :alt="loggedInAccounts[username].name"
            style="width: 50px; border-radius: 50px; float: left"
          />
          <span style="margin: 10px; float: left">
            {{ loggedInAccounts[username].username }}
          </span>
          <a
            v-if="!expandSwitch"
            @click="expandSwitch = !expandSwitch"
            class="color-black pull-right pointer"
            style="
              margin-top: 15px;
              position: absolute;
              right: 10px;
              text-align: right;
            "
          >
            <i class="font-11 fa color-black">
              <img
                src="https://cdn.tapni.co/icons/down-arrow.png"
                class="fa responsive-image"
                style="width: 30%"
              />
            </i>
          </a>
        </a>

        <!-- Add Account -->
        <a class="menu-sub-username bold" @click="addAccount">
          <img
            src="https://cdn.tapni.co/icons/Add.png"
            alt="Add account"
            style="width: 50px; border-radius: 50px; float: left"
          />
          <span style="margin: 10px; float: left">
            {{lang[appLang].add_account }}
          </span>
          <a
            @click=""
            class="color-black pull-right pointer"
            style="
              margin-top: 15px;
              position: absolute;
              right: 10px;
              text-align: right;
            "
          >
            <i class="font-11 fa color-black">
              <img
                src="https://cdn.tapni.co/icons/Plus.png"
                class="fa responsive-image"
                style="width: 14%"
              />
            </i>
          </a>
        </a>
      </div>
      <div class="menu">
        <em
          v-if="!isCompletedProfileSetup && isLoggedInUser"
          class="menu-divider"
          style="height: 5px"
        ></em>
        <a
          v-if="!isCompletedProfileSetup && isLoggedInUser"
          @click="toggleProfileSetup"
          class="menu-item pulse"
        >
          <i class="font-15 fa color-black">
            <img
              src="https://cdn.tapni.co/icons/progress.png"
              class="fa responsive-image"
            />
          </i>
          <strong>{{lang[appLang].complete_profile }}</strong>
          <i class="fa font-14 pull-right"></i>
        </a>
        <em
          v-if="!isCompletedProfileSetup && isLoggedInUser"
          class="menu-divider"
          style="height: 5px"
        ></em>
        <!--
        <a @click="redirectSidebar('/analytics')" class="menu-item vibrate">
          <i class="font-20 fa color-black">
            <img
                src="https://cdn.tapni.co/icons/analytics.png"
                class="fa responsive-image"
            />
          </i>
          <strong>{{lang[appLang].analyticsHeader }}</strong>
        </a>
        -->
        <a
          @click="redirectSidebar(home.replace('#edit', '#preview'))"
          class="menu-item vibrate"
        >
          <i class="font-15 fa color-black">
            <img
              src="https://cdn.tapni.co/icons/profile.png"
              class="fa responsive-image"
            />
          </i>
          <strong>{{lang[appLang].my_profile }}</strong>
        </a>
        <a @click="redirectSidebar('/contacts')" class="menu-item vibrate">
          <i class="font-14 fa color-black">
            <img
              src="https://cdn.tapni.co/icons/contacts.png"
              class="fa responsive-image"
            />
          </i>
          <strong>{{lang[appLang].contacts }}</strong>
        </a>
        <a
          @click="
            redirectSidebar(
              (user && user.tags && user.tags.length > 0) || !isLoggedInUser
                ? '/tags'
                : '/tags#activate'
            )
          "
          class="menu-item vibrate"
        >
          <i class="font-14 fa color-black">
            <img
              src="https://cdn.tapni.co/icons/tags.png"
              class="fa responsive-image"
            />
          </i>
          <strong>
            {{
              (user && user.tags && user.tags.length > 0) || !isLoggedInUser
                ? lang[appLang].my_tags
                : lang[appLang].activate_tag
            }}
          </strong>
        </a>
        <a @click="redirectSidebar('/products')" class="menu-item vibrate">
          <i class="font-14 fa color-black">
            <img
              src="https://cdn.tapni.co/icons/shop.png"
              class="fa responsive-image"
            />
          </i>
          <strong>{{lang[appLang].buy_tapni }}</strong>
        </a>
        <!--
	    <a v-if="user && user.tags && user.tags.length > 0 && !user.CompanyId" @click="redirectSidebar('/referral')" class="menu-item vibrate">
		    <i class="font-14 fa color-black">
			    <img src="https://cdn.tapni.co/icons/amba.png" class="fa responsive-image"/>
		    </i>
		    <strong>{{lang[appLang].referral_program}}</strong>
		    <i class="fa font-14 pull-right"></i>
	    </a>
	    -->
        <a
            @click="redirectSidebar('/for-business')"
            class="menu-item vibrate"
        >
          <i class="font-17 fa color-black">
            <img
                src="https://cdn.tapni.co/icons/forbusiness.png"
                class="fa responsive-image"
            />
          </i>
          <strong>Tapni for Business</strong>
          <i class="fa font-14 pull-right">💥</i>
        </a>
        <a @click="redirectSidebar('/devices')" class="menu-item vibrate">
          <i class="font-20 fa color-black">
            <img
              src="https://cdn.tapni.co/icons/compat.png"
              class="fa responsive-image"
            />
          </i>
          <strong>{{lang[appLang].device_compatibility }}</strong>
        </a>
        <a
            href="https://help.tapni.co/"
            target="_blank"
            class="menu-item vibrate"
        >
          <i class="font-17 fa color-black">
            <img
                src="https://cdn.tapni.co/icons/faq.png"
                class="fa responsive-image"
            />
          </i>
          <strong>{{lang[appLang].help }}</strong>
        </a>

        <em class="menu-divider" style="height: 5px"></em>
        <a @click="redirectSidebar('/settings')" class="menu-item vibrate">
          <i class="font-14 fa color-black">
            <img
              src="https://cdn.tapni.co/icons/settings.png"
              class="fa responsive-image"
            />
          </i>
          <strong>{{lang[appLang].settings }}</strong>
        </a>
        <a class="menu-item vibrate" @click="logoutSidebar">
          <i class="font-14 fa color-black">
            <img
              src="https://cdn.tapni.co/icons/logout.png"
              class="fa responsive-image"
            />
          </i>
          <strong>{{lang[appLang].logout }}</strong>
        </a>
      </div>

      <div>
        <img
          v-if="isB2B"
          style="max-width: 80px; margin-left: 7px; margin-top: 15px"
          src="https://cdn.tapni.co/images/logo-dark.png"
          alt="Tapni"
        />
        <p class="app-version" style="margin-top: 10px">
          {{lang[appLang].version }}: {{ appVersion }}
        </p>
        <p class="app-version">Bundle: {{ bundleVersion }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../store/event-bus";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "Sidebar",
  data() {
    return {
      toggle: false,
      expandSwitch: false,
      addingAccount: false,
    };
  },
  mounted() {
    EventBus.$on("closeModal", () => {
      this.toggle = false;
      this.addingAccount = false;
      this.expandSwitch = false;
    });
    EventBus.$on("toggleSidebar", this.toggleModal);
    EventBus.$on("toggleAddAccountModal", () => {
      this.addingAccount = false;
    });
  },
  beforeDestroy() {
    EventBus["_events"]["toggleSidebar"].pop();
    EventBus["_events"]["toggleAddAccountModal"].pop();
  },
  computed: {
    ...mapState(["loggedInAccounts"]),
    ...mapGetters(["appVersion", "bundleVersion"]),
    isLoggedInUser() {
      return this.user && this.storage &&
          this.user.id === this.storage.UserId &&
          this.user.username === this.storage.username &&
          this.isLoggedIn
    },
  },
  methods: {
    ...mapActions(["logout", "getUser"]),
    close() {
      EventBus.$emit("closeModal");
    },
    toggleModal() {
      this.toggle = !this.toggle;
    },
    async toggleProfileSetup() {
      this.$router.push(this.home);
      this.close();
      setTimeout(() => {
        EventBus.$emit("toggleProfileSetupModal");
      }, 300);
    },
    async toggleQR() {
      this.$router.push(this.home);
      this.close();
      setTimeout(() => {
        EventBus.$emit("toggleModal");
      }, 300);
    },
    async toggleProfileModal() {
      this.$router.push(this.home);
      this.close();
      setTimeout(() => {
        EventBus.$emit("toggleProfileModal");
      }, 300);
    },
    async logoutSidebar() {
      this.close();
      await this.logout();
      this.$forceUpdate();
    },
    redirectSidebar(url) {
      this.close();
      this.$router.push(url);
    },
    switchAccount(username) {
      this.expandSwitch = false;
      this.addingAccount = false;
      EventBus.$emit("switchAccount", username);
    },
    addAccount() {
      EventBus.$emit("toggleAddAccountModal");
      this.addingAccount = true;
    },
  },
};
</script>

<style scoped>
.menu-item i:first-child {
  padding-left: 10px;
  padding-top: 9px;
}
.menu-item i:first-child img {
  width: 90%;
}
.app-version {
  margin-left: 17px;
}
</style>
