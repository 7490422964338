import axios from 'axios'
import store from '@/store/store'
import to from 'await-to-js'
import { jwtDecode } from 'jwt-decode'
import { Capacitor } from '@capacitor/core';
import CONSTANTS from '@/constants';

function getAppInfo() {
  const platform = Capacitor.getPlatform()
  let version;
  switch (platform) {
    case 'android': version = CONSTANTS.ANDROID_VERSION; break;
    case 'ios': version = CONSTANTS.IOS_VERSION; break;
    case 'web': version = CONSTANTS.WEB_VERSION; break;
  }
  return {
    platform: platform || 'web',
    version : version || '0.0'
  }
}

export default (test = false) => {
  const appInfo = getAppInfo()
  let apiInstance = axios.create({
    baseURL: import.meta.env.VITE_APP_API_ROOT + '/v1/',
    headers: {
      Authorization: `Bearer ${store.getters.accessToken}`,
      "X-Client-Name": "tapni-" + appInfo.platform,
      "X-Client-Version": appInfo.version
    }
  })

  // Add a request interceptor
  apiInstance.interceptors.request.use(async function (config) {

    if (['post', 'put', 'delete'].includes(config.method.toLowerCase())) {
      config.data = {
        ...config.data,
        lang: store.getters.appLang
      }
    } else if (config.method.toLowerCase() === 'get') {
      config.params = {
        ...config.params,
        lang: store.getters.appLang
      }
    }

    // Check refresh token expiration before request is sent
    if (store.getters.accessToken) {
      const decoded = jwtDecode(store.getters.accessToken)

      // Check if access token expired
      if (decoded.exp - 30 < Math.floor(Date.now() / 1000)) {
        return store.dispatch('auth/refreshTokenAction', decoded)
            .then(() => {
              config.headers = {
                ...config.headers,
                Authorization: `Bearer ${store.getters.accessToken}`
              }
              return config;
            })
      }
    }
    return config;
  });

  return apiInstance;
}
